import React, { Component } from 'react';
import Slider from '@mui/material/Slider';
import './lineFollower.scss';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';

export class LineFollowerElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      position: 0,
      sliderOpen: true,
      rotation: props.rotation || 0,
      id: props.id || '',
      onClickHandler: props.onClickHandler || null,
    }

    this.render = this.render.bind(this);
    this.openSlider = this.openSlider.bind(this);
    this.handlePositionChange = this.handlePositionChange.bind(this);
  }

  handleRotation = (e) => {
    const { rotation, id } = this.state;
    const newRotation = ((rotation - 90) < -270) ? 0 : rotation - 90;
    this.setState({ rotation: newRotation });
    const rotationEvent = new CustomEvent('component-rotation', { detail: { rotation: newRotation, component: id } });
    document.dispatchEvent(rotationEvent);
  }

  openSlider = (e) => {
    const { id, sliderOpen } = this.state;
    setTimeout(() => {
      if (!document.querySelector(`#${id}`).closest(".react-draggable").classList.contains("react-draggable-dragging")) {
        this.setState({ sliderOpen: !sliderOpen });
      }
    }, 100);
  }

  handlePositionChange = (event, newValue) => {
    const { onClickHandler } = this.state;
    event.preventDefault();
    event.stopPropagation();
    this.setState({ position: newValue });
    onClickHandler(newValue);
    // console.log('================', newValue);
  }

  render = () => {
    const { id, sliderOpen, position, rotation } = this.state;

    return (
      <div className="lineFollower" id={id}>
        <div className={`slider${sliderOpen ? ' open' : ''}${(rotation === -90 || rotation === -270) ? ' rotate_90' : ''}`}>
          <div className="position">
            <div className="icon"><img src={`${process.env.IS_ELECTRON ? '.' : ''}/images/simulator/components/linefollower.svg`} height="20" /></div>
            <Slider
              min={0}
              max={80}
              step={1}
              marks
              defaultValue={0}
              className="bar"
              getAriaValueText={(v) => `${v}`}
              valueLabelDisplay="auto"
              onChange={this.handlePositionChange}
              aria-labelledby="input-slider"
            />
            {/* <div className="value">{(position < 20) ? 'Izq' : ((position >= 20 && position < 60) ? 'Cen' : 'Der')}</div> */}
          </div>
        </div>

        <div className={`rotation${sliderOpen ? ' open' : ''}${(rotation === -90 || rotation === -270) ? ' rotate_90' : ''}`} onClick={this.handleRotation}><ScreenRotationIcon fontSize="inherit" htmlColor="white" /></div>

        <svg
          id="Capa_2"
          data-name="Capa 2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 166 142"
          width="31mm"
          height="27mm"
          transform={`translate(0 0) rotate(${rotation})`}
        >
          <g id="Capa_1-2" data-name="Capa 1" onMouseDown={this.openSlider}>
            <g>
              <rect
                class="cls-15"
                id="rect937"
                width="161"
                height="120"
                x="5"
                y="10"
              />
              <rect
                class="cls-14"
                id="rect937"
                width="166"
                height="60"
                x="0"
                y="58.737602"
                transform={`translate(0 ${Math.floor((position * (34 / 80) - 32))})`}
              />
              <g id="LINE">
                <line class="cls-6" x1="14.59" y1="88.42" x2="14.59" y2="88.42" />
              </g>
              <g id="ARC">
                <path class="cls-5" d="m1.12,8.34c0,.78.62,1.41,1.4,1.42,0,0,0,0,.01,0h2.81s0,0,0,0c2.5,0,4.53,2.03,4.53,4.54s-2.03,4.53-4.54,4.53h-2.78c-.8,0-1.45.66-1.45,1.46v101.44c0,.78.64,1.41,1.42,1.42,0,0,0,0,0,0h2.81s0,0,0,0c2.5,0,4.53,2.03,4.53,4.54s-2.03,4.53-4.54,4.53h-2.78c-.8.01-1.45.66-1.45,1.46v3.93c0,2.35,1.91,4.25,4.26,4.25h104.88c2.35,0,4.25-1.9,4.25-4.25V4.38c0-2.35-1.9-4.25-4.25-4.25,0,0,0,0,0,0H5.38c-2.35,0-4.25,1.9-4.25,4.25,0,0,0,0,0,0v3.97Z" />
              </g>
              <rect class="cls-10" x="76.1" y="3.68" width="9.38" height="23.79" rx="1.14" ry="1.14" transform="translate(65.21 96.36) rotate(-90)" />
              <rect class="cls-2" x="68.55" y="64.13" width="12.67" height="12.18" transform="translate(4.66 145.1) rotate(-90)" />
              <g id="LINE-2" data-name="LINE">
                <path class="cls-1" d="m74.02,67.02s0,6.39,0,6.39c-1.14-.31-2.03-1.2-2.33-2.33-.48-1.77.57-3.58,2.33-4.06Z" />
              </g>
              <g id="LINE-3" data-name="LINE">
                <path class="cls-1" d="m75.75,67.02s0,6.39,0,6.39c1.77-.48,2.81-2.3,2.33-4.06-.31-1.14-1.2-2.03-2.33-2.33Z" />
              </g>
              <g id="LINE-4" data-name="LINE">
                <rect class="cls-4" x="1.5" y="53.84" width="42.52" height="34.58" />
              </g>
              <g id="SPLINE">
                <path class="cls-12" d="m76.6,116.56v.57s-1.13,0-1.13,0h-2.27s-1.13,0-1.13,0v-.57c.31,0,.57-.25.57-.57s-.25-.57-.57-.57v-.57s1.13,0,1.13,0h2.27s1.13,0,1.13,0v.57c-.07,0-.15.02-.22.04s-.13.07-.18.12-.1.12-.12.18-.03.14-.04.22c0,.07.02.15.04.22s.07.13.12.18.12.1.18.12.14.03.22.04Z" />
              </g>
              <g id="SPLINE-2" data-name="SPLINE">
                <path class="cls-12" d="m29.74,48.24h-1.13s-2.27,0-2.27,0h-1.13s0-.57,0-.57c.31,0,.57-.25.57-.57s-.25-.57-.57-.57v-.57s1.13,0,1.13,0h2.27s1.13,0,1.13,0v.57c-.07,0-.15.02-.22.04s-.13.07-.18.12-.1.12-.12.18-.03.14-.04.22c0,.07.02.15.04.22s.07.13.12.18.12.1.18.12.14.03.22.04v.57Z" />
              </g>
              <rect class="cls-13" x="26.34" y="45.77" width="2.27" height="2.67" transform="translate(-19.63 74.58) rotate(-90)" />
              <g id="CIRCLE">
                <path class="cls-7" d="m28.05,133.35c-3.13,0-5.67-2.54-5.67-5.67s2.54-5.67,5.67-5.67,5.67,2.54,5.67,5.67-2.54,5.67-5.67,5.67h0Z" />
              </g>
              <g id="CIRCLE-2" data-name="CIRCLE">
                <path class="cls-7" d="m28.05,19.97c-3.13,0-5.67-2.54-5.67-5.67s2.54-5.67,5.67-5.67,5.67,2.54,5.67,5.67-2.54,5.67-5.67,5.67h0Z" />
              </g>
              <rect class="cls-3" x="68.55" y="97.06" width="12.67" height="12.18" transform="translate(-28.26 178.03) rotate(-90)" />
              <g id="LINE-5" data-name="LINE">
                <path class="cls-1" d="m74.02,99.95s0,6.39,0,6.39c-1.14-.31-2.03-1.2-2.33-2.33-.48-1.77.57-3.58,2.33-4.06Z" />
              </g>
              <g id="LINE-6" data-name="LINE">
                <path class="cls-1" d="m75.75,99.95s0,6.39,0,6.39c1.77-.48,2.81-2.3,2.33-4.06-.31-1.14-1.2-2.03-2.33-2.33Z" />
              </g>
              <rect class="cls-2" x="68.55" y="32.38" width="12.67" height="12.18" transform="translate(36.41 113.36) rotate(-90)" />
              <g id="LINE-7" data-name="LINE">
                <path class="cls-1" d="m74.02,35.28s0,6.39,0,6.39c-1.14-.31-2.03-1.2-2.33-2.33-.48-1.77.57-3.58,2.33-4.06Z" />
              </g>
              <g id="LINE-8" data-name="LINE">
                <path class="cls-1" d="m75.75,35.28s0,6.39,0,6.39c1.77-.48,2.81-2.3,2.33-4.06-.31-1.14-1.2-2.03-2.33-2.33Z" />
              </g>
              <rect class="cls-13" x="73.21" y="114.65" width="2.26" height="2.67" transform="translate(-41.65 190.33) rotate(-90)" />
              <g id="SPLINE-3" data-name="SPLINE">
                <path class="cls-12" d="m76.61,83.68v.57s-1.13,0-1.13,0h-2.27s-1.13,0-1.13,0v-.57c.31,0,.57-.25.57-.57s-.25-.57-.57-.57v-.57s1.13,0,1.13,0h2.27s1.13,0,1.13,0v.57c-.07,0-.15.02-.22.04s-.13.07-.18.12-.1.12-.12.18-.03.14-.04.22c0,.07.02.15.04.22s.07.13.12.18.12.1.18.12.14.03.22.04Z" />
              </g>
              <rect class="cls-13" x="73.21" y="81.77" width="2.26" height="2.67" transform="translate(-8.77 157.45) rotate(-90)" />
              <g id="SPLINE-4" data-name="SPLINE">
                <path class="cls-12" d="m76.6,51.64v.57s-1.13,0-1.13,0h-2.27s-1.13,0-1.13,0v-.57c.31,0,.57-.25.57-.57s-.25-.57-.57-.57v-.57s1.13,0,1.13,0h2.27s1.13,0,1.13,0v.57c-.07,0-.15.02-.22.04s-.13.07-.18.12-.1.12-.12.18-.03.14-.04.22c0,.07.02.15.04.22s.07.13.12.18.12.1.18.12.14.03.22.04Z" />
              </g>
              <rect class="cls-13" x="73.21" y="49.74" width="2.26" height="2.67" transform="translate(23.26 125.41) rotate(-90)" />
              <g id="ARC-2" data-name="ARC">
                <circle class="cls-8" cx="70.86" cy="18.29" r=".81" />
              </g>
              <path class="cls-9" d="m36.5,63.84h141.97v10.04c0,2.35-1.91,4.25-4.25,4.25H40.75c-2.35,0-4.26-1.91-4.26-4.26v-10.04h0Z" transform="translate(36.5 178.47) rotate(-90)" />
              <polygon class="cls-9" points="16.75 118.21 20.84 122.3 20.84 133.07 16.75 137.16 .99 137.16 .99 130.91 6.15 130.91 6.48 130.91 7.13 130.78 7.74 130.52 8.29 130.16 8.52 129.92 8.74 129.7 9.09 129.19 9.32 128.62 9.44 128.01 9.44 127.65 9.44 127.32 9.32 126.69 9.07 126.1 8.71 125.57 8.48 125.34 8.26 125.12 7.73 124.76 7.15 124.52 6.53 124.4 6.21 124.4 .99 124.4 .99 118.21 16.75 118.21" />
              <polygon class="cls-9" points="16.75 4.82 20.83 8.91 20.83 19.68 16.75 23.77 .99 23.77 .99 17.52 6.15 17.52 6.48 17.52 7.12 17.39 7.73 17.14 8.28 16.77 8.52 16.54 8.74 16.32 9.08 15.8 9.32 15.23 9.44 14.62 9.44 14.26 9.44 13.94 9.31 13.31 9.06 12.72 8.7 12.18 8.48 11.95 8.25 11.73 7.73 11.38 7.14 11.14 6.52 11.01 6.2 11.01 .99 11.01 .99 4.82 16.75 4.82" />
              <g>
                <path class="cls-11" d="m109.37,95.17c.16-.24.29-.6.29-.97,0-.56-.31-.88-.75-.88-.41,0-.64.22-.87.79-.25.69-.62,1.11-1.24,1.11-.68,0-1.19-.54-1.19-1.36,0-.43.1-.74.21-.93l.46.15c-.08.14-.21.42-.21.8,0,.57.36.79.66.79.41,0,.61-.26.84-.84.29-.71.64-1.07,1.29-1.07.68,0,1.26.48,1.26,1.47,0,.41-.12.85-.28,1.07l-.47-.14Z" />
                <path class="cls-11" d="m108.01,89.84v1.64h1.58v-1.82h.47v2.37h-4.38v-2.27h.47v1.73h1.38v-1.64h.47Z" />
                <path class="cls-11" d="m109.86,85.74c.09.24.24.72.24,1.29,0,.64-.17,1.16-.58,1.57-.36.36-.95.59-1.63.59-1.31,0-2.26-.87-2.26-2.28,0-.49.11-.87.2-1.05l.46.13c-.1.22-.19.51-.19.93,0,1.02.66,1.69,1.76,1.69s1.77-.64,1.77-1.62c0-.36-.05-.6-.12-.72h-1.31v.85h-.46v-1.39h2.11Z" />
                <path class="cls-11" d="m105.68,84.37h2.59c.98,0,1.4-.42,1.4-.98,0-.62-.43-1.02-1.4-1.02h-2.59v-.55h2.55c1.34,0,1.9.68,1.9,1.59,0,.86-.51,1.51-1.87,1.51h-2.58v-.55Z" />
                <path class="cls-11" d="m105.68,80.33h4.38v.54h-4.38v-.54Z" />
                <path class="cls-11" d="m105.74,79.38c-.05-.33-.09-.72-.09-1.15,0-.78.19-1.34.55-1.7.36-.37.86-.59,1.57-.59s1.3.21,1.7.6c.41.39.63,1.04.63,1.86,0,.39-.02.71-.05.99h-4.31Zm3.87-.54c.03-.14.03-.34.03-.55,0-1.16-.68-1.79-1.86-1.79-1.03,0-1.69.56-1.69,1.7,0,.28.03.49.06.64h3.46Z" />
                <path class="cls-11" d="m107.82,71.63c1.51,0,2.31.88,2.31,1.95s-.9,1.89-2.22,1.89c-1.39,0-2.3-.83-2.3-1.95s.92-1.89,2.22-1.89Zm.07,3.26c.94,0,1.78-.49,1.78-1.34s-.83-1.35-1.82-1.35c-.87,0-1.78.44-1.78,1.34s.86,1.35,1.83,1.35Z" />
                <path class="cls-11" d="m105.74,70.92c-.06-.27-.09-.67-.09-1.04,0-.58.11-.95.36-1.22.2-.21.49-.33.83-.33.58,0,.96.35,1.12.79h.02c.12-.32.43-.52.88-.62.61-.14,1.03-.24,1.2-.32v.56c-.12.07-.5.16-1.05.28-.6.12-.83.35-.85.84v.51h1.9v.54h-4.32Zm2-.54v-.56c0-.58-.33-.95-.83-.95-.57,0-.81.39-.82.97,0,.26.03.45.05.54h1.6Z" />
                <path class="cls-11" d="m105.74,66.24c-.05-.33-.09-.72-.09-1.15,0-.78.19-1.34.55-1.7.36-.37.86-.59,1.57-.59s1.3.21,1.7.6c.41.39.63,1.04.63,1.86,0,.39-.02.71-.05.99h-4.31Zm3.87-.54c.03-.14.03-.34.03-.55,0-1.16-.68-1.79-1.86-1.79-1.03,0-1.69.56-1.69,1.7,0,.28.03.49.06.64h3.46Z" />
                <path class="cls-11" d="m108.01,59.91v1.64h1.58v-1.82h.47v2.37h-4.38v-2.27h.47v1.73h1.38v-1.64h.47Z" />
                <path class="cls-11" d="m105.68,57.69v-.54h3.91v-1.8h.47v2.34h-4.38Z" />
                <path class="cls-11" d="m104.68,53.56l.76.8v.44l-.76-.57v-.67Zm1,.64h4.38v.54h-4.38v-.54Z" />
                <path class="cls-11" d="m110.06,53.25h-4.38v-.59l2.22-1.35c.51-.31.98-.56,1.42-.76h0c-.59.04-1.12.05-1.8.05h-1.83v-.51h4.38v.55l-2.22,1.33c-.49.29-.99.57-1.46.79v.02c.56-.03,1.09-.04,1.81-.04h1.87v.51Z" />
                <path class="cls-11" d="m108.01,46.97v1.64h1.58v-1.82h.47v2.37h-4.38v-2.27h.47v1.73h1.38v-1.64h.47Z" />
                <path class="cls-11" d="m108.68,45.39l1.38.44v.56l-4.38-1.43v-.66l4.38-1.44v.58l-1.38.45v1.49Zm-.44-1.38l-1.26.41c-.29.09-.55.16-.8.22h0c.26.08.53.14.79.22l1.27.41v-1.27Z" />
              </g>
              <g>
                <path class="cls-9" d="m94.21,6.94v2.16l1.44.22c-.02-.13-.03-.25-.03-.46,0-.44.09-.87.3-1.22.25-.44.73-.81,1.44-.81,1.1,0,1.92.88,1.92,2.11,0,.62-.17,1.14-.34,1.41l-.58-.19c.14-.24.31-.7.31-1.21,0-.72-.46-1.34-1.22-1.34-.72,0-1.24.5-1.24,1.63,0,.32.04.58.06.78l-2.69-.37v-2.71h.64Z" />
                <path class="cls-9" d="m97.76,5.91c-.71,0-1.21-.43-1.5-1.12h-.03c-.29.64-.78.91-1.26.91-.89,0-1.49-.76-1.49-1.75,0-1.1.68-1.65,1.38-1.65.48,0,.98.24,1.31.93h.03c.28-.71.77-1.14,1.45-1.14.97,0,1.63.85,1.63,1.93,0,1.18-.7,1.9-1.52,1.9Zm-.04-3.05c-.68,0-1.01.48-1.22,1.25.19.66.62,1.02,1.16,1.02.57.03,1.07-.41,1.07-1.13s-.42-1.13-1-1.13Zm-2.81,2.11c.56,0,.87-.43,1.04-1.08-.16-.49-.51-.86-1.02-.86-.45,0-.92.27-.92.96,0,.64.42.98.9.98Z" />
              </g>
              <g>
                <rect class="cls-9" x="30.44" y="94.27" width=".85" height=".85" />
                <rect class="cls-9" x="30.44" y="98.5" width=".85" height=".85" />
                <polygon class="cls-9" points="27.05 94.27 26.2 94.27 25.36 94.27 25.36 95.12 24.51 95.12 24.51 95.96 23.66 95.96 22.81 95.96 22.81 97.65 23.66 97.65 24.51 97.65 24.51 98.5 25.36 98.5 25.36 99.35 26.2 99.35 27.05 99.35 27.05 100.19 27.9 100.19 30.44 100.19 30.44 99.35 27.9 99.35 27.9 94.27 30.44 94.27 30.44 93.43 27.9 93.43 27.05 93.43 27.05 94.27" />
                <rect class="cls-9" x="31.29" y="95.12" width=".85" height="3.38" />
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}
