import { BasicBlock, BLOCK_CATEGORY_TYPE, BlockConfig } from '@sections/creabots/types/kits'
import getBlocksInfoSchema from '../../../config/getBlocksInfoSchema'

const blocks: BasicBlock[] = [
  {
    id: 0,
    name: 'SALIDAS_motor',
  },
];

export const motorsConfig: BlockConfig = {
  blockCategory: 8,
  canvasDisplacement: { x: 600, y: 0 },
  blocks: getBlocksInfoSchema(blocks, BLOCK_CATEGORY_TYPE.MOTORS),
}
