/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import SelectComponent from '../SelectComponent';
import '../SelectComponent/selectComponent.scss';


class SelectWifi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wifiForm: {
        wifi: '',
      },
      networks: props.networks || [],
      id: props.id,
    };
    this.changeSelect = this.changeSelect.bind(this);
  }

  componentWillReceiveProps(newprops) {
    const { wifi, wifiForm } = this.state;
    let selectedWifi = wifi;
    if (newprops.networks.length <= 0) {
      selectedWifi = '';
    } else {
      selectedWifi = newprops.networks[0].ssid;
      for (let i = 0; i < newprops.networks.length; i += 1) {
        if (newprops.networks[i].connected) {
          selectedWifi = newprops.networks[i].ssid;
        }
      }
    }
    wifiForm.wifi = selectedWifi;

    this.setState({
      wifiForm,
      networks: newprops.networks,
    });
  }


  changeSelect(field, value) {
    const { parentFunctionListener } = this.props;
    const { wifiForm } = this.state;
    wifiForm[field] = value;
    parentFunctionListener(value);
    this.setState({ wifiForm });
  }

  render() {
    const { networks, id, wifiForm } = this.state;
    const length = networks.length;
    let select;

    if (length > 0) {
      select = (
        <div className="selectWIfIComponentContainer">
          <SelectComponent
            changeHandler={this.changeSelect}
            options={networks.map(item => item.ssid || '')}
            form={wifiForm}
            keyname="wifi"
            placeholder="Red WiFi"
            label={false}
            id={id}
          />
        </div>
      );
    } else {
      select = '';
    }
    return (select);
  }
}

export default SelectWifi;
