import axios from 'axios';
import * as routes from '@educabot/educablocks-cosmos';
import types from './types';
import { checkAuthAction } from './authAction';

axios.defaults.withCredentials = true;

function actionAssignmentSessionFetchingGet() {
  return { type: types.ASSIGNMENT_SESSION_GET_FETCHING, payload: { statusAssignmentSessionGet: 'fetching' } };
}

function actionAssignmentSessionFetchGet(dataAssignmentSessionGet) {
  return { type: types.ASSIGNMENT_SESSION_GET_FETCH, payload: { statusAssignmentSessionGet: 'fetch', dataAssignmentSessionGet } };
}

function actionAssignmentSessionFetchErrorGet(dataAssignmentSessionGet) {
  return { type: types.ASSIGNMENT_SESSION_GET_FETCH_ERROR, payload: { statusAssignmentSessionGet: 'fetch error', dataAssignmentSessionGet } };
}

export function actionAssignmentSessionGet(id) {
  return (dispatch) => {
    checkAuthAction(dispatch).then(() => {
      dispatch(actionAssignmentSessionFetchingGet());
      return axios.get(`${routes.AssignmentSessionUri}/${id}`).then((response) => {
        dispatch(actionAssignmentSessionFetchGet(response.data));
      }).catch((e) => {
        dispatch(actionAssignmentSessionFetchErrorGet(e));
      });
    });
  };
}


function actionAssignmentSessionFetchingGetList() {
  return { type: types.ASSIGNMENT_SESSION_GET_LIST_FETCHING, payload: { statusAssignmentSessionGetList: 'fetching' } };
}

function actionAssignmentSessionFetchGetList(dataAssignmentSessionGetList) {
  return { type: types.ASSIGNMENT_SESSION_GET_LIST_FETCH, payload: { statusAssignmentSessionGetList: 'fetch', dataAssignmentSessionGetList } };
}

function actionAssignmentSessionFetchErrorGetList(dataAssignmentSessionGetList) {
  return { type: types.ASSIGNMENT_SESSION_GET_LIST_FETCH_ERROR, payload: { statusAssignmentSessionGetList: 'fetch error', dataAssignmentSessionGetList } };
}

export function actionAssignmentSessionGetList() {
  return (dispatch) => {
    // checkAuthAction(dispatch).then(() => {
    dispatch(actionAssignmentSessionFetchingGetList());
    return axios.get(`${routes.AssignmentSessionUri}`).then((response) => {
      dispatch(actionAssignmentSessionFetchGetList(response.data));
    }).catch((e) => {
      dispatch(actionAssignmentSessionFetchErrorGetList(e));
    });
    // });
  };
}


function actionAssignmentSessionLoginFetchingGet() {
  return { type: types.ASSIGNMENT_SESSION_LOGIN_GET_FETCHING, payload: { statusAssignmentSessionLoginGet: 'fetching' } };
}

function actionAssignmentSessionLoginFetchGet(dataAssignmentSessionLoginGet) {
  return { type: types.ASSIGNMENT_SESSION_LOGIN_GET_FETCH, payload: { statusAssignmentSessionLoginGet: 'fetch', dataAssignmentSessionLoginGet } };
}

function actionAssignmentSessionLoginFetchErrorGet(dataAssignmentSessionLoginGet) {
  return { type: types.ASSIGNMENT_SESSION_LOGIN_GET_FETCH_ERROR, payload: { statusAssignmentSessionLoginGet: 'fetch error', dataAssignmentSessionLoginGet } };
}

export function actionAssignmentSessionLoginGet(id, password) {
  return (dispatch) => {
    // checkAuthAction(dispatch).then(() => {
    dispatch(actionAssignmentSessionLoginFetchingGet());
    return axios.get(`${routes.AssignmentSessionUri}/${id}/password/${encodeURIComponent(password)}`).then((response) => {
      dispatch(actionAssignmentSessionLoginFetchGet(response.data));
    }).catch((e) => {
      dispatch(actionAssignmentSessionLoginFetchErrorGet(e));
    });
    // });
  };
}


function actionAssignmentSessionFetchingRefresh() {
  return { type: types.ASSIGNMENT_SESSION_REFRESH_FETCHING, payload: { statusAssignmentSessionRefresh: 'fetching' } };
}

function actionAssignmentSessionFetchRefresh(dataAssignmentSessionRefresh) {
  return { type: types.ASSIGNMENT_SESSION_REFRESH_FETCH, payload: { statusAssignmentSessionRefresh: 'fetch', dataAssignmentSessionRefresh } };
}

function actionAssignmentSessionFetchErrorRefresh(data) {
  return { type: types.ASSIGNMENT_SESSION_REFRESH_FETCH_ERROR, payload: { statusAssignmentSessionRefresh: 'fetch error', dataAssignmentSessionRefresh: data } };
}

export function actionAssignmentSessionRefresh(id, assignmentSessionProjectId) {
  return (dispatch) => {
    checkAuthAction(dispatch).then(() => {
      dispatch(actionAssignmentSessionFetchingRefresh());
      return axios.get(`${routes.AssignmentSessionUri}/${id}/${assignmentSessionProjectId}`).then((response) => {
        dispatch(actionAssignmentSessionFetchRefresh(response.data));
      }).catch((e) => {
        const message = (e.response) ? e.response.data.message : 'Datos incorrectos';
        dispatch(actionAssignmentSessionFetchErrorRefresh({ message }));
      });
    });
  };
}


function actionAssignmentSessionProjectsFetchingPost() {
  return { type: types.ASSIGNMENT_SESSION_PROJECTS_POST_FETCHING, payload: { statusAssignmentSessionProjectPost: 'fetching' } };
}

function actionAssignmentSessionProjectsFetchPost(dataAssignmentSessionProjectPost) {
  return { type: types.ASSIGNMENT_SESSION_PROJECTS_POST_FETCH, payload: { statusAssignmentSessionProjectPost: 'fetch', dataAssignmentSessionProjectPost } };
}

function actionAssignmentSessionProjectsFetchErrorPost() {
  return { type: types.ASSIGNMENT_SESSION_PROJECTS_POST_FETCH_ERROR, payload: { statusAssignmentSessionProjectPost: 'fetch error' } };
}

export function actionAssignmentSessionProjectsPost(projectData) {
  return (dispatch) => {
    checkAuthAction(dispatch).then(() => {
      dispatch(actionAssignmentSessionProjectsFetchingPost());
      return axios.post(`${routes.AssignmentSessionUri}/project`, projectData).then((response) => {
        dispatch(actionAssignmentSessionProjectsFetchPost(response.data));
      }).catch((e) => {
        dispatch(actionAssignmentSessionProjectsFetchErrorPost(e));
      });
    });
  };
}


function actionAssignmentSessionProjectsFetchingUpdate() {
  return { type: types.ASSIGNMENT_SESSION_PROJECTS_UPDATE_FETCHING, payload: { statusAssignmentSessionProjectUpdate: 'fetching' } };
}

function actionAssignmentSessionProjectsFetchUpdate(dataAssignmentSessionProjectUpdate) {
  return { type: types.ASSIGNMENT_SESSION_PROJECTS_UPDATE_FETCH, payload: { statusAssignmentSessionProjectUpdate: 'fetch', dataAssignmentSessionProjectUpdate } };
}

function actionAssignmentSessionProjectsFetchErrorUpdate(error) {
  return { type: types.ASSIGNMENT_SESSION_PROJECTS_UPDATE_FETCH_ERROR, payload: { statusAssignmentSessionProjectUpdate: 'fetch error', dataAssignmentSessionProjectUpdate: error } };
}

export function actionAssignmentSessionProjectUpdate(assignmentSessionId, projectId, projectData) {
  return (dispatch) => {
    checkAuthAction(dispatch).then(() => {
      dispatch(actionAssignmentSessionProjectsFetchingUpdate());
      return axios.post(`${routes.AssignmentSessionUri}/${assignmentSessionId}/project/${projectId}`, projectData).then((response) => {
        dispatch(actionAssignmentSessionProjectsFetchUpdate(response.data));
      }).catch((e) => {
        const message = (e.response) ? e.response.data.message : 'Datos incorrectos';
        dispatch(actionAssignmentSessionProjectsFetchErrorUpdate(message));
      });
    });
  };
}


function actionPutAssignmentSessionProjectBulkFetching() {
  return { type: types.ASSIGNMENT_SESSION_PROJECTS_BULK_UPDATE_FETCHING, payload: { statusPutAssignmentSessionProjectBulk: 'fetching' } };
}

function actionPutAssignmentSessionProjectBulkFetch(dataPutAssignmentSessionProjectBulk) {
  return { type: types.ASSIGNMENT_SESSION_PROJECTS_BULK_UPDATE_FETCH, payload: { statusPutAssignmentSessionProjectBulk: 'fetch', dataPutAssignmentSessionProjectBulk } };
}

function actionPutAssignmentSessionProjectBulkFetchError() {
  return { type: types.ASSIGNMENT_SESSION_PROJECTS_BULK_UPDATE_FETCH_ERROR, payload: { statusPutAssignmentSessionProjectBulk: 'fetch error' } };
}

export function actionPutAssignmentSessionProjectBulk(assignmentSessionId, projectData) {
  return (dispatch) => {
    checkAuthAction(dispatch).then(() => {
      dispatch(actionPutAssignmentSessionProjectBulkFetching());
      return axios.put(`${routes.AssignmentSessionUri}/${assignmentSessionId}/project/bulk`, projectData).then((response) => {
        dispatch(actionPutAssignmentSessionProjectBulkFetch(response.data));
      }).catch((e) => {
        dispatch(actionPutAssignmentSessionProjectBulkFetchError(e));
      });
    });
  };
}


function actionPutAssignmentSessionFetching() {
  return { type: types.ASSIGNMENT_SESSION_UPDATE_FETCHING, payload: { statusPutAssignmentSession: 'fetching' } };
}

function actionPutAssignmentSessionFetch(dataPutAssignmentSession) {
  return { type: types.ASSIGNMENT_SESSION_UPDATE_FETCH, payload: { statusPutAssignmentSession: 'fetch', dataPutAssignmentSession } };
}

function actionPutAssignmentSessionFetchError(dataPutAssignmentSession) {
  return { type: types.ASSIGNMENT_SESSION_UPDATE_FETCH_ERROR, payload: { statusPutAssignmentSession: 'fetch error', dataPutAssignmentSession } };
}

export function actionPutAssignmentSession(assignmentSessionId, data) {
  return (dispatch) => {
    checkAuthAction(dispatch).then(() => {
      dispatch(actionPutAssignmentSessionFetching());
      return axios.put(`${routes.AssignmentSessionUri}/${assignmentSessionId}`, data).then((response) => {
        dispatch(actionPutAssignmentSessionFetch(response.data));
      }).catch((e) => {
        dispatch(actionPutAssignmentSessionFetchError(e));
      });
    });
  };
}


function actionAssignmentSessionProjectsFetchingDelete() {
  return { type: types.ASSIGNMENT_SESSION_PROJECTS_DELETE_FETCHING, payload: { statusAssignmentSessionProjectDelete: 'fetching' } };
}

function actionAssignmentSessionProjectsFetchDelete(dataAssignmentSessionProjectDelete) {
  return { type: types.ASSIGNMENT_SESSION_PROJECTS_DELETE_FETCH, payload: { statusAssignmentSessionProjectDelete: 'fetch', dataAssignmentSessionProjectDelete } };
}

function actionAssignmentSessionProjectsFetchErrorDelete() {
  return { type: types.ASSIGNMENT_SESSION_PROJECTS_DELETE_FETCH_ERROR, payload: { statusAssignmentSessionProjectDelete: 'fetch error' } };
}

export function actionAssignmentSessionProjectDelete(assignmentSessionId) {
  return (dispatch) => {
    checkAuthAction(dispatch).then(() => {
      dispatch(actionAssignmentSessionProjectsFetchingDelete());
      return axios.delete(`${routes.AssignmentSessionUri}/${assignmentSessionId}/project`).then((response) => {
        dispatch(actionAssignmentSessionProjectsFetchDelete(response.data));
      }).catch((e) => {
        dispatch(actionAssignmentSessionProjectsFetchErrorDelete(e));
      });
    });
  };
}


function actionAssignmentSessionProjectsFetchingDeleteOne() {
  return { type: types.ASSIGNMENT_SESSION_PROJECTS_DELETE_ONE_FETCHING, payload: { statusAssignmentSessionProjectDeleteOne: 'fetching' } };
}

function actionAssignmentSessionProjectsFetchDeleteOne(dataAssignmentSessionProjectDeleteOne) {
  return { type: types.ASSIGNMENT_SESSION_PROJECTS_DELETE_ONE_FETCH, payload: { statusAssignmentSessionProjectDeleteOne: 'fetch', dataAssignmentSessionProjectDeleteOne } };
}

function actionAssignmentSessionProjectsFetchErrorDeleteOne() {
  return { type: types.ASSIGNMENT_SESSION_PROJECTS_DELETE_ONE_FETCH_ERROR, payload: { statusAssignmentSessionProjectDeleteOne: 'fetch error' } };
}

export function actionAssignmentSessionProjectDeleteOne(assignmentSessionId, projectId) {
  return (dispatch) => {
    checkAuthAction(dispatch).then(() => {
      dispatch(actionAssignmentSessionProjectsFetchingDeleteOne());
      return axios.delete(`${routes.AssignmentSessionUri}/${assignmentSessionId}/project/${projectId}`).then((response) => {
        dispatch(actionAssignmentSessionProjectsFetchDeleteOne(response.data));
      }).catch((e) => {
        dispatch(actionAssignmentSessionProjectsFetchErrorDeleteOne(e));
      });
    });
  };
}


function actionResetAssignmentSessionStatusFetch(ObjectToAssign) {
  return { type: types.ASSIGNMENT_SESSIONS_RESET_STATUS, payload: ObjectToAssign };
}

export function actionResetAssignmentSessionStatus(ObjectToAssign) {
  return (dispatch) => {
    dispatch(actionResetAssignmentSessionStatusFetch(ObjectToAssign));
  };
}


function actionAssignmentDeliverableState(newState) {
  return { type: types.ASSIGNMENT_SESSION_DELIVERABLE, payload: { assignmentSessionDeliverableState: newState } };
}
export function actionSetAssignmentDeliverableState(deliveryDate) {
  const now = new Date();
  const assignmentDeliveryDate = new Date(deliveryDate);
  let newState = false;
  if (assignmentDeliveryDate.getTime() > now.getTime() || !deliveryDate || deliveryDate === '') {
    newState = true;
  }
  return (dispatch) => {
    dispatch(actionAssignmentDeliverableState(newState));
  };
}
export function actionGetAssignmentDeliverableState(state) {
  // console.log(state);
  return state.assignmentSessionReducer.assignmentSessionDeliverableState;
}


export default {
  actionAssignmentSessionGet,
  actionAssignmentSessionGetList,
  actionAssignmentSessionLoginGet,
  actionAssignmentSessionRefresh,
  actionAssignmentSessionProjectsPost,
  actionAssignmentSessionProjectUpdate,
  actionPutAssignmentSessionProjectBulk,
  actionPutAssignmentSession,
  actionAssignmentSessionProjectDelete,
  actionAssignmentSessionProjectDeleteOne,
  actionResetAssignmentSessionStatus,
  actionSetAssignmentDeliverableState,
  actionGetAssignmentDeliverableState,
};
