import React, { useState } from 'react';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { exportBlocklyProject } from '@modules/blockly/features/hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteProject,
  duplicateProject,
  openLabsToast,
  selectCreabots,
} from '@sections/creabots/features/creabotsSlice';
import { RenameProjectModal } from '../../ui/renameProjectModal';
import { LOADING_STATUS } from '@utils/redux';
import { useIntl } from 'react-intl';

type Props = {
  project: unknown;
};

export const ProjectMenuOptions: React.FC<Props> = ({ project }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [renameOpenModal, setRenameOpenModal] = useState(false);
  const open = Boolean(anchorEl);
  const { projectOptions } = useSelector(selectCreabots);
  const isLoading = Object.values(projectOptions).some(
    status => status === LOADING_STATUS
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDuplicateProject = async () => {
    dispatch(duplicateProject(project));
  };

  const handleExportProject = () => {
    exportBlocklyProject(project);
    dispatch(
      openLabsToast({
        open: true,
        message: 'creabots.dashboard.projectMenuOptions.downloadSuccess',
        severity: 'success',
      })
    );
  };

  const handleDeleteProject = () => {
    dispatch(deleteProject(project.id));
  };

  return (
    <>
      <IconButton
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disabled={isLoading}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={theme => ({
          '.MuiPaper-root': {
            marginTop: '2px',
            backgroundColor: theme.palette.common.white,
            boxShadow:
              '0px 7px 22px 0px #00000040, 0px 0px 1.5px 0px #0000004D, 0px 0px 1px 0px #00000066',
          },
        })}
      >
        <MenuItem onClick={handleExportProject}>
          <ListItemIcon>
            <FileDownloadOutlinedIcon />
          </ListItemIcon>
          <ListItemText>
            {intl.formatMessage({
              id: 'creabots.dashboard.projectMenuOptions.download',
            })}
          </ListItemText>
        </MenuItem>

        <MenuItem onClick={() => setRenameOpenModal(true)}>
          <ListItemIcon>
            <EditOutlinedIcon />
          </ListItemIcon>
          <ListItemText>
            {intl.formatMessage({
              id: 'creabots.dashboard.projectMenuOptions.rename',
            })}
          </ListItemText>
        </MenuItem>

        <MenuItem onClick={handleDuplicateProject}>
          <ListItemIcon>
            <ContentCopyRoundedIcon />
          </ListItemIcon>
          <ListItemText>
            {' '}
            {intl.formatMessage({
              id: 'creabots.dashboard.projectMenuOptions.clone',
            })}
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDeleteProject}>
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon
              sx={theme => ({
                color: theme.palette.error.main,
              })}
            />
          </ListItemIcon>
          <ListItemText
            sx={theme => ({
              color: theme.palette.error.main,
            })}
          >
            {intl.formatMessage({
              id: 'creabots.dashboard.projectMenuOptions.delete',
            })}
          </ListItemText>
        </MenuItem>
      </Menu>
      <RenameProjectModal
        project={project}
        isOpen={renameOpenModal}
        handleClose={() => setRenameOpenModal(false)}
      />
    </>
  );
};
