import * as creabotsService from '@services/rest/creabots';
import {
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  SUCCESS_STATUS,
} from '@utils/redux';
import { projectsService } from '@services/rest/projects';
import { Kits } from './kits/index.ts';

const GET_CREABOTS_PROJECT = 'CREABOTS/GET_PROJECT';
const GET_CREABOTS_PROJECT_FAIL = 'CREABOTS/GET_PROJECT_FAIL';
const GET_CREABOTS_PROJECT_SUCCESS = 'CREABOTS/GET_PROJECT_SUCCESS';
const RESET_CREABOTS_PROJECT_STATE = 'CREABOTS/RESET_PROJECT_STATE';

const GET_CREABOTS_PROJECTS = 'CREABOTS/GET_PROJECTS';
const GET_CREABOTS_PROJECTS_FAIL = 'CREABOTS/GET_PROJECTS_FAIL';
const GET_CREABOTS_PROJECTS_SUCCESS = 'CREABOTS/GET_PROJECTS_SUCCESS';
const ORDER_CREABOTS_PROJECTS = 'CREABOTS/ORDER_PROJECTS';

const GET_CREABOTS_KITS = 'CREABOTS/GET_KITS';
const GET_CREABOTS_KITS_FAIL = 'CREABOTS/GET_KITS_FAIL';
const GET_CREABOTS_KITS_SUCCESS = 'CREABOTS/GET_KITS_SUCCESS';

const GET_CREABOTS_PROGRESS = 'CREABOTS/GET_PROGRESS';
const GET_CREABOTS_PROGRESS_FAIL = 'CREABOTS/GET_PROGRESS_FAIL';
const GET_CREABOTS_PROGRESS_SUCCESS = 'CREABOTS/GET_PROGRESS_SUCCESS';

const SET_SELECTED_KIT = 'CREABOTS/SET_SELECTED_KIT';
const SET_NEW_PROFILE = 'CREABOTS/SET_NEW_PROFILE';
const SET_USER_PROFILES = 'CREABOTS/SET_USER_PROFILES';
const SET_DELETE_CURRENT_PROFILE = 'CREABOTS/SET_DELETE_CURRENT_PROFILE';

const POST_CREABOTS_PROGRESS = 'CREABOTS/POST_PROGRESS';
const POST_CREABOTS_PROGRESS_FAIL = 'CREABOTS/POST_PROGRESS_FAIL';
const POST_CREABOTS_PROGRESS_SUCCESS = 'CREABOTS/POST_PROGRESS_SUCCESS';

const DUPLICATE_PROJECT = 'CREABOTS/DUPLICATE_PROJECT';
const DUPLICATE_PROJECT_SUCCESS = 'CREABOTS/DUPLICATE_PROJECT_SUCCESS';
const DUPLICATE_PROJECT_FAILURE = 'CREABOTS/DUPLICATE_PROJECT_FAILURE';

const RENAME_PROJECT = 'CREABOTS/RENAME_PROJECT';
const RENAME_PROJECT_SUCCESS = 'CREABOTS/RENAME_PROJECT_SUCCESS';
const RENAME_PROJECT_FAILURE = 'CREABOTS/RENAME_PROJECT_FAILURE';

const DELETE_PROJECT = 'CREABOTS/DELETE_PROJECT';
const DELETE_PROJECT_SUCCESS = 'CREABOTS/DELETE_PROJECT_SUCCESS';
const DELETE_PROJECT_FAILURE = 'CREABOTS/DELETE_PROJECT_FAILURE';

const CREABOTS_OPEN_TOAST = 'CREABOTS/CREABOTS_OPEN_TOAST';
const CREABOTS_CLOSE_TOAST = 'CREABOTS/CREABOTS_CLOSE_TOAST';

const CREABOTS_RESET_STATE = 'CREABOTS/RESET_STATE';

export const initialState = {
  progress: {
    progressId: 0,
    projectId: 0,
    kitId: 0,
    tutorialId: 0,
    step: 0,
    project: {},
  },
  error: null,
  loading: false,
  profiles: [],
  projects: [],
  kits: {
    loading: false,
    list: [],
    selected: {
      id: 0,
      name: '',
      projects: [],
    },
    progress: [],
    error: null,
    orderBy: 'alphabetical',
  },
  profileModal: {
    deleteCurrentProfile: false,
  },
  toast: {
    severity: 'success',
    autoHideDuration: 3000,
    message: '',
    open: false,
  },
  projectOptions: {
    deleteStatus: IDLE_STATUS,
    renameStatus: IDLE_STATUS,
    duplicateStatus: IDLE_STATUS,
  },
};

export default function creabotsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CREABOTS_PROJECT: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_CREABOTS_PROJECT_SUCCESS: {
      const { id, creabotsKitId, tutorialId, step, projectId, project } = action.payload;
      return {
        ...state,
        loading: false,
        progress: {
          progressId: id,
          projectId,
          kitId: creabotsKitId,
          tutorialId,
          step,
          project,
        },
      };
    }
    case GET_CREABOTS_PROJECT_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error,
      };
    }
    case RESET_CREABOTS_PROJECT_STATE: {
      return {
        ...state,
        loading: false,
        progress: initialState.progress,
      };
    }
    case GET_CREABOTS_PROJECTS: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_CREABOTS_PROJECTS_SUCCESS: {
      const { projects } = action.payload;
      // Projects without progress are the ones that must to be listed as user's projects
      const projectsWithoutProgress = projects.filter(
        (project) => !state.kits.progress.some((kit) => kit.projectId === project.id),
      );
      return {
        ...state,
        loading: false,
        projects: projectsWithoutProgress,
      };
    }
    case GET_CREABOTS_PROJECTS_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        projects: [],
        error,
      };
    }
    case POST_CREABOTS_PROGRESS: {
      return {
        ...state,
        loading: true,
      };
    }
    case POST_CREABOTS_PROGRESS_SUCCESS: {
      const { creabotsKitId, tutorialId, step, projectId } = action.payload;
      return {
        ...state,
        loading: false,
        progress: {
          ...state.progress,
          projectId,
          kitId: creabotsKitId,
          tutorialId,
          step,
        },
      };
    }
    case POST_CREABOTS_PROGRESS_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error,
      };
    }
    case GET_CREABOTS_KITS: {
      return {
        ...state,
        kits: { ...state.kits, loading: true },
      };
    }
    case GET_CREABOTS_KITS_SUCCESS: {
      const { kits } = action.payload;
      return {
        ...state,
        kits: { ...state.kits, loading: false, list: kits },
      };
    }
    case GET_CREABOTS_KITS_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        kits: { ...state.kits, loading: false, list: error },
      };
    }
    case GET_CREABOTS_PROGRESS:
      return {
        ...state,
        kits: { ...state.kits, loading: true },
      };
    case GET_CREABOTS_PROGRESS_SUCCESS: {
      const { progress } = action.payload;
      return {
        ...state,
        kits: { ...state.kits, loading: false, progress },
      };
    }
    case GET_CREABOTS_PROGRESS_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        kits: { ...state.kits, loading: false, list: error },
      };
    }
    case SET_SELECTED_KIT: {
      const { kit } = action.payload;
      return {
        ...state,
        kits: {
          ...state.kits,
          selected: { ...kit, projects: kit.projects ? kit.projects : [] },
        },
      };
    }
    case SET_NEW_PROFILE: {
      const { profile } = action.payload;
      return {
        ...state,
        profiles: [
          ...state.profiles,
          {
            firstName: profile.name,
            birth: profile.birth,
            avatarUrl: profile.avatarUrl,
            parentId: profile.parentId,
            id: profile.id,
          },
        ],
      };
    }
    case SET_USER_PROFILES: {
      const { profiles } = action.payload;
      return {
        ...state,
        profiles: [...profiles],
      };
    }
    case SET_DELETE_CURRENT_PROFILE: {
      const { sameProfile } = action.payload;
      return {
        ...state,
        profileModal: { deleteCurrentProfile: sameProfile },
      };
    }
    case CREABOTS_OPEN_TOAST: {
      const { value } = action.payload;
      return { ...state, toast: { ...value } };
    }
    case CREABOTS_CLOSE_TOAST: {
      return { ...state, toast: initialState.toast };
    }
    case ORDER_CREABOTS_PROJECTS: {
      const { filterType } = action.payload;

      return {
        ...state,
        kits: { ...state.kits, orderBy: filterType },
      };
    }
    case DUPLICATE_PROJECT: {
      return {
        ...state,
        projectOptions: {
          ...state.projectOptions,
          duplicateStatus: LOADING_STATUS,
        },
      };
    }
    case DUPLICATE_PROJECT_SUCCESS: {
      return {
        ...state,
        projectOptions: {
          ...state.projectOptions,
          duplicateStatus: SUCCESS_STATUS,
        },
      };
    }
    case DUPLICATE_PROJECT_FAILURE: {
      return {
        ...state,
        projectOptions: {
          ...state.projectOptions,
          duplicateStatus: FAILURE_STATUS,
        },
      };
    }
    case RENAME_PROJECT: {
      return {
        ...state,
        projectOptions: {
          ...state.projectOptions,
          renameStatus: LOADING_STATUS,
        },
      }
    }
    case RENAME_PROJECT_SUCCESS: {
      const { project } = action.payload;

      const projects = state.projects.filter((p) => p.id !== project.id);
      const updatedList = [...projects, project];

      return {
        ...state,
        projects: updatedList,
        projectOptions: {
          ...state.projectOptions,
          renameStatus: SUCCESS_STATUS,
        },
      }
    }
    case RENAME_PROJECT_FAILURE: {
      return {
        ...state,
        projectOptions: {
          ...state.projectOptions,
          renameStatus: FAILURE_STATUS,
        },
      }
    }
    case DELETE_PROJECT: {
      return {
        ...state,
        projectOptions: {
          ...state.projectOptions,
          deleteStatus: LOADING_STATUS,
        },
      };
    }
    case DELETE_PROJECT_SUCCESS: {
      const { projects } = action.payload;
      return {
        ...state,
        projects,
        projectOptions: {
          ...state.projectOptions,
          deleteStatus: SUCCESS_STATUS,
        },
      };
    }
    case DELETE_PROJECT_FAILURE: {
      return {
        ...state,
        projectOptions: {
          ...state.projectOptions,
          deleteStatus: FAILURE_STATUS,
        },
      };
    }
    case CREABOTS_RESET_STATE: {
      return initialState;
    }
    default:
      return state;
  }
}

// selectors
export const selectCreabots = (state) => state.creabots;

// actions
export const resetCreabotsProjectState = () => (dispatch) => dispatch({
  type: RESET_CREABOTS_PROJECT_STATE,
  payload: {},
});

const getCreabotsProjectSuccess = (dispatch) => ({ id, creabotsKitId, tutorialId, step, projectId, Project }) => dispatch({
  type: GET_CREABOTS_PROJECT_SUCCESS,
  payload: {
    id,
    creabotsKitId,
    tutorialId,
    step,
    projectId,
    project: Project,
  },
});

const getCreabotsProjectFail = (dispatch) => (error) => dispatch({
  type: GET_CREABOTS_PROJECT_FAIL,
  payload: {
    error,
  },
});

export const getCreabotsProject = (creabotsKitId, tutorialId) => (dispatch) => {
  dispatch({ type: GET_CREABOTS_PROJECT });
  creabotsService
    .getCreabotsProject(creabotsKitId, tutorialId)
    .then(getCreabotsProjectSuccess(dispatch))
    .catch(getCreabotsProjectFail(dispatch));
};

const saveCreabotsProgressSuccess = (dispatch) => ({ id, creabotsKitId, tutorialId, step, projectId }) => dispatch({
  type: POST_CREABOTS_PROGRESS_SUCCESS,
  payload: {
    creabotsKitId,
    tutorialId,
    step,
    projectId,
  },
});

const saveCreabotsProgressFail = (dispatch) => (error) => dispatch({
  type: POST_CREABOTS_PROGRESS_FAIL,
  payload: {
    error,
  },
});

export const saveCreabotsProgress = (dispatch) => (progressDetails, projectDetails) => {
  dispatch({ type: POST_CREABOTS_PROGRESS });
  creabotsService
    .saveCreabotsProject(progressDetails, projectDetails)
    .then(saveCreabotsProgressSuccess(dispatch))
    .catch(saveCreabotsProgressFail(dispatch));
};

export const getCreabotsKits = (authenticated = false) => (dispatch) => {
  dispatch({ type: GET_CREABOTS_KITS });

  const handleSuccess = (resp) => {
    const kitsList = [Kits.iniciacion, Kits.automatizacion, Kits.conduccion];

    const kitsFiltered = kitsList.filter((kit) => resp.data.some(
      (item) => (item.creabotsKitId !== undefined
        ? item.creabotsKitId
        : item.id) === kit.id,
    ));
    const exists = kitsFiltered.some(
      (filteredKit) => filteredKit.id === Kits.iniciacion.id,
    );
    if (!exists) {
      kitsFiltered.unshift(Kits.iniciacion);
    }

    dispatch({
      type: GET_CREABOTS_KITS_SUCCESS,
      payload: {
        kits: kitsFiltered,
      },
    });
  };

  const handleError = (error) => dispatch({
    type: GET_CREABOTS_KITS_FAIL,
    payload: {
      error,
    },
  });

  const serviceCall = authenticated
    ? creabotsService.getLabsKitsByUser()
    : creabotsService.getLabsKits();

  serviceCall.then(handleSuccess).catch(handleError);
};

export const getCreabotsProgress = (dispatch) => {
  dispatch({ type: GET_CREABOTS_PROGRESS });

  const handleSuccess = (res) => {
    dispatch({
      type: GET_CREABOTS_PROGRESS_SUCCESS,
      payload: { progress: res.data },
    });
  };
  const handleError = (err) => {
    dispatch({ type: GET_CREABOTS_PROGRESS_FAIL, payload: { error: err } });
  };

  creabotsService.getLabsProgress().then(handleSuccess).catch(handleError);
};

export const getCreabotsUserProjects = (dispatch) => {
  dispatch({ type: GET_CREABOTS_PROJECTS });

  const handleSuccess = (res) => {
    dispatch({
      type: GET_CREABOTS_PROJECTS_SUCCESS,
      payload: { projects: res.data },
    });
  };
  const handleError = (err) => {
    dispatch({ type: GET_CREABOTS_PROJECTS_FAIL, payload: { error: err } });
  };

  creabotsService.getLabsProjects().then(handleSuccess).catch(handleError);
};

export const setSelectedKit = (kit) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_KIT,
    payload: {
      kit,
    },
  });
};

export const setNewProfile = (profile) => (dispatch) => {
  dispatch({
    type: SET_NEW_PROFILE,
    payload: {
      profile,
    },
  });
};

export const setUserProfiles = (profiles) => (dispatch) => {
  dispatch({
    type: SET_USER_PROFILES,
    payload: {
      profiles,
    },
  });
};

export const setDeleteCurrentProfile = (sameProfile) => (dispatch) => {
  dispatch({
    type: SET_DELETE_CURRENT_PROFILE,
    payload: {
      sameProfile,
    },
  });
};

export const orderCreabotsProjects = (filterType) => (dispatch) => dispatch({
  type: ORDER_CREABOTS_PROJECTS,
  payload: {
    filterType,
  },
});

export const openLabsToast = (value) => (dispatch) => dispatch({
  type: CREABOTS_OPEN_TOAST,
  payload: {
    value,
  },
});
export const closeLabsToast = () => (dispatch) => dispatch({
  type: CREABOTS_CLOSE_TOAST,
});


export const duplicateProject = (project) => (dispatch) => {
  dispatch({ type: DUPLICATE_PROJECT });
  dispatch(openLabsToast({
    open: true,
    message: 'creabots.dashboard.projectMenuOptions.actions.clonePending',
    severity: 'info',
  }))
  const handleSuccess = () => {
    dispatch({
      type: DUPLICATE_PROJECT_SUCCESS,
    });
    dispatch(
      openLabsToast({
        open: true,
        message: 'creabots.dashboard.projectMenuOptions.actions.cloneSuccess',
        severity: 'success',
      }),
    );
    dispatch(getCreabotsUserProjects);
  };
  const handleError = () => {
    dispatch({ type: DUPLICATE_PROJECT_FAILURE });
    dispatch(
      openLabsToast({
        open: true,
        message: 'creabots.dashboard.projectMenuOptions.actions.cloneFailure',
        severity: 'error',
      }),
    );
  };

  projectsService
    .createProject(project)
    .then(handleSuccess)
    .catch(handleError);
}


export const renameProject = (project) => (dispatch, getState) => {
  dispatch({ type: RENAME_PROJECT });
  const projectFinded = getState().creabots.projects.find(
    (p) => p.id === project.id,
  );

  dispatch(openLabsToast({
    open: true,
    message: 'creabots.dashboard.projectMenuOptions.actions.renamePending',
    severity: 'info',
  }))

  const handleSuccess = () => {
    dispatch({
      type: RENAME_PROJECT_SUCCESS,
      payload: {
        project: { ...projectFinded, title: project.title },
      },
    });
    dispatch(
      openLabsToast({
        open: true,
        message: 'creabots.dashboard.projectMenuOptions.actions.renameSuccess',
        severity: 'success',
      }),
    );
    dispatch(getCreabotsUserProjects);
  };
  const handleError = () => {
    dispatch({ type: RENAME_PROJECT_FAILURE });
    dispatch(
      openLabsToast({
        open: true,
        message: 'creabots.dashboard.projectMenuOptions.actions.renameFailure',
        severity: 'error',
      }),
    );
  };

  projectsService
    .updateProject(project.id, { ...project })
    .then(handleSuccess)
    .catch(handleError);
};

export const deleteProject = (projectId) => (dispatch, getState) => {
  dispatch({ type: DELETE_PROJECT });

  dispatch(openLabsToast({
    open: true,
    message: 'creabots.dashboard.projectMenuOptions.actions.deletePending',
    severity: 'info',
  }))

  const projects = getState().creabots.projects;

  const handleSuccess = () => {
    dispatch({
      type: DELETE_PROJECT_SUCCESS,
      payload: {
        projects: projects.filter((project) => project.id !== projectId),
      },
    });
    dispatch(
      openLabsToast({
        open: true,
        message: 'creabots.dashboard.projectMenuOptions.actions.deleteSuccess',
        severity: 'success',
      }),
    );
    dispatch(getCreabotsUserProjects);
  };
  const handleError = () => {
    dispatch({ type: DELETE_PROJECT_FAILURE });
    dispatch(
      openLabsToast({
        open: true,
        message: 'creabots.dashboard.projectMenuOptions.actions.deleteFailure',
        severity: 'error',
      }),
    );
  };

  projectsService
    .deleteProject(projectId)
    .then(handleSuccess)
    .catch(handleError);
};

export const resetCreabotsState = () => (dispatch) => dispatch({
  type: CREABOTS_RESET_STATE,
})