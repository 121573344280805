import { BasicBlock, BLOCK_CATEGORY_TYPE, BlockConfig } from '@sections/creabots/types/kits'
import getBlocksInfoSchema from '../../../config/getBlocksInfoSchema'

const blocks: BasicBlock[] = [
  {
    id: 0,
    name: 'ENTRADAS_button',
  },
  {
    id: 1,
    name: 'entradas_ultrasonido',
  },
  {
    id: 2,
    name: 'SALIDAS_read_dht11',
  },
  {
    id: 3,
    name: 'BHOOT_light_intensity',
  },
  {
    id: 4,
    name: 'BHOOT_light_color',
  },
  {
    id: 5,
    name: 'BHOOT_line_follower',
  }
]

export const digitalConfig: BlockConfig = {
  blockCategory: 6,
  blockSubCategory: 0,
  canvasDisplacement: { x: 460, y: 0 },
  blocks: getBlocksInfoSchema(blocks, BLOCK_CATEGORY_TYPE.DIGITAL),
}