import { EmailOutlined, PersonOutline } from '@mui/icons-material';
import clsx from 'clsx';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { getUserLocationData, signUpProspect } from '@services/rest/creabots';
import { Grid, InputAdornment, InputLabel, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import styles from './steps.mod.scss';

const muiStyles = makeStyles(() => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(66, 179, 255, 0.5)',
      },
      color: '#000',
    },
    fontWeight: 500,
  },
  error: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(244, 92, 58, 0.5)',
      },
      '&:hover fieldset': {
        borderColor: 'rgba(244, 92, 58, 0.5)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(244, 92, 58, 0.5)',
      },
    },
    color: '#F45C3A',
  },
}));

const initialValues = {
  name: '',
  email: '',
  request: '',
};

export const RegisterStep = () => {
  const [fields, setFields] = useState({
    email: '',
    name: '',
  });
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(initialValues);
  const isDisabled = errors.name || errors.email || isLoading;
  const mui = muiStyles();
  const intl = useIntl();

  const handleChangeField = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  const handleContinueStep = async (event) => {
    event.preventDefault();
    if (!fields.email) {
      setErrors((prev) => ({
        ...prev,
        email: intl.formatMessage({
          id: 'creabots.validations.enterEmail',
        }),
      }));
    }
    if (!fields.name) {
      setErrors((prev) => ({
        ...prev,
        name: intl.formatMessage({ id: 'creabots.validations.enterName' }),
      }));
    }

    if (!fields.name || !fields.email) {
      return;
    }
    try {
      setIsLoading(true);

      const { country, region: state } = await getUserLocationData();
      
      await signUpProspect({
        email: fields.email.trim(),
        name: fields.name.trim(),
        country,
        state,
      });
      setErrors(initialValues);
      history.push(`/kits/registrarse/verificacion?email=${fields.email}`);
    } catch (error) {
      if (error?.response?.status === 401) {
        history.push(`/kits/registrarse/verificacion?email=${fields.email}`);
      } else {
        setErrors((prev) => ({
          ...prev,
          request:
            error?.response?.data.message
            || intl.formatMessage({ id: 'common.errors.somethingWentWrong' }),
        }));
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <main className={styles.card}>
      <article className={styles.cardHeader}>
        <h1 className={styles.title}>
          {intl.formatMessage({ id: 'creabots.auth.register' })}
        </h1>
        <p className={styles.description}>
          {intl.formatMessage({ id: 'creabots.auth.registerDescription' })}
        </p>
      </article>

      <form className={styles.cardContent} onSubmit={handleContinueStep}>
        <Grid container style={{ gap: '3em', marginTop: '6.5em' }}>
          <Grid
            item
            xs={12}
            className={clsx('form-group bmd-form-group', styles.inputControl)}
          >
            <InputLabel
              shrink={false}
              htmlFor="name"
              className={clsx('bmd-label-static', styles.inputLabel)}
            >
              {intl.formatMessage({ id: 'creabots.labels.name' })}
            </InputLabel>

            <TextField
              type="text"
              name="name"
              id="name"
              fullWidth
              variant="outlined"
              value={fields.name}
              error={!!errors.name || !!errors.request}
              onChange={handleChangeField}
              onFocus={() => setErrors((prev) => ({ ...prev, name: '' }))}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PersonOutline
                      className={clsx(
                        styles.inputIcon,
                        (errors.name || errors.request) && styles.error,
                      )}
                    />
                  </InputAdornment>
                ),
              }}
              className={clsx(mui.root, errors.name && mui.error)}
            />

            {errors.name && (
              <p className={styles.errorMessage}>{errors.name}</p>
            )}
          </Grid>
          <Grid
            xs={12}
            item
            className={clsx('form-group bmd-form-group', styles.inputControl)}
          >
            <InputLabel
              shrink={false}
              htmlFor="email"
              className={clsx('bmd-label-static', styles.inputLabel)}
            >
              {intl.formatMessage({ id: 'creabots.labels.parentEmail' })}
            </InputLabel>
            <TextField
              type="email"
              name="email"
              id="email"
              fullWidth
              variant="outlined"
              value={fields.email}
              error={!!errors.email || !!errors.request}
              onFocus={() => setErrors((prev) => ({ ...prev, email: '' }))}
              onChange={(e) => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (e.target.value.match(pattern) === null) {
                  setErrors((prev) => ({
                    ...prev,
                    email: intl.formatMessage({
                      id: 'creabots.validations.invalidEmail',
                    }),
                  }));
                } else {
                  setErrors((prev) => ({ ...prev, email: '' }));
                }
                handleChangeField(e);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <EmailOutlined
                      className={clsx(
                        styles.inputIcon,
                        (errors.email || errors.request) && styles.error,
                      )}
                    />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: {
                  fontWeight: 500,
                  color: errors.email ? '#F45C3A' : 'black',
                },
              }}
              className={clsx(mui.root, errors.email && mui.error)}
            />

            {errors.email && (
              <p className={styles.errorMessage}>{errors.email}</p>
            )}
          </Grid>
        </Grid>
        <p className={styles.termsAndConditions}>
          {intl.formatMessage({ id: 'creabots.auth.registeringAcept' })}{' '}
          <Link to="https://docs.google.com/document/d/1kR_DtKnqoHXIB6JZ6PNf2FWpFyyWlzq0vFUrhax076I/edit?usp=sharing">
            {intl.formatMessage({ id: 'creabots.auth.termsAndConditions' })}
          </Link>{' '}
          {intl.formatMessage({ id: 'creabots.auth.andThe' })}{' '}
          <Link to="https://educabot.com/politicas/">
            {intl.formatMessage({ id: 'creabots.auth.privatePolicy' })}
          </Link>{' '}
          {intl.formatMessage({ id: 'common.of' })} Educabot Labs.
        </p>
        {errors.request && <p className={styles.error}>{errors.request}</p>}
        <button
          type="submit"
          className={styles.btnPrimary}
          disabled={isDisabled}
        >
          {intl.formatMessage({ id: 'common.continue' })}
        </button>
      </form>

      <article className={styles.cardFooter}>
        <h4 className={styles.haveAccount}>
          {intl.formatMessage({ id: 'creabots.auth.haveAccount' })}{' '}
          <Link to="/kits/ingresar">
            {intl.formatMessage({ id: 'creabots.auth.signIn' })}
          </Link>
        </h4>
      </article>
    </main>
  );
};
