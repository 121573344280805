const types = {
  //  auth
  GET_INTERNET_STATUS: 'GET_INTERNET_STATUS',

  AUTH_LOGIN_FETCH: 'AUTH_LOGIN_FETCH',
  AUTH_LOGIN_FETCHING: 'AUTH_LOGIN_FETCHING',
  AUTH_LOGIN_FETCH_ERROR: 'AUTH_LOGIN_FETCH_ERROR',
  AUTH_LOGOUT_FETCH: 'AUTH_LOGOUT_FETCH',
  AUTH_LOGOUT_FETCHING: 'AUTH_LOGOUT_FETCHING',
  AUTH_LOGOUT_FETCH_ERROR: 'AUTH_LOGOUT_FETCH_ERROR',
  AUTH_RESET_PASS_FETCH: 'AUTH_RESET_PASS_FETCH',
  AUTH_RESET_PASS_FETCHING: 'AUTH_RESET_PASS_FETCHING',
  AUTH_RESET_PASS_FETCH_ERROR: 'AUTH_RESET_PASS_FETCH_ERROR',
  AUTH_UPDATE_LOGIN_FETCH: 'AUTH_UPDATE_LOGIN_FETCH',
  AUTH_UPDATE_LOGIN_FETCHING: 'AUTH_UPDATE_LOGIN_FETCHING',
  AUTH_UPDATE_LOGIN_FETCH_ERROR: 'AUTH_UPDATE_LOGIN_FETCH_ERROR',

  AUTH_CHECK_AUTH_FETCH: 'AUTH_CHECK_AUTH_FETCH',
  AUTH_CHECK_AUTH_FETCHING: 'AUTH_CHECK_AUTH_FETCHING',
  AUTH_CHECK_AUTH_FETCH_ERROR: 'AUTH_CHECK_AUTH_FETCH_ERROR',

  AUTH_SIGN_UP_FETCH: 'AUTH_SIGN_UP_FETCH',
  AUTH_SIGN_UP_FETCHING: 'AUTH_SIGN_UP_FETCHING',
  AUTH_SIGN_UP_FETCH_ERROR: 'AUTH_SIGN_UP_FETCH_ERROR',
  AUTH_RESET_STATE: 'AUTH_RESET_STATE',

  // bloques
  GET_PORTS: 'GET_PORTS',
  GET_AGENT_PORTS: 'GET_AGENT_PORTS',
  GET_NEW_MESSAGE: 'GET_NEW_MESSAGE',
  GET_NEW_MONITOR_MESSAGE: 'GET_NEW_MONITOR_MESSAGE',
  GET_NEW_STATE: 'GET_NEW_STATE',
  GET_RIGHT_PANEL_DATA: 'GET_RIGHT_PANEL_DATA',
  BLOQUES_RESET_STATUS: 'BLOQUES_RESET_STATUS',

  // projects
  PROJECTS_GET_PAGED_FETCHING: 'PROJECTS_GET_PAGED_FETCHING',
  PROJECTS_GET_PAGED_FETCH: 'PROJECTS_GET_PAGED_FETCH',
  PROJECTS_GET_PAGED_FETCH_ERROR: 'PROJECTS_GET_PAGED_FETCH_ERROR',
  PROJECTS_USER_GET_PAGED_FETCHING: 'PROJECTS_USER_GET_PAGED_FETCHING',
  PROJECTS_USER_GET_PAGED_FETCH: 'PROJECTS_USER_GET_PAGED_FETCH',
  PROJECTS_USER_GET_PAGED_FETCH_ERROR: 'PROJECTS_USER_GET_PAGED_FETCH_ERROR',
  PROJECTS_GET_FETCHING: 'PROJECTS_GET_FETCHING',
  PROJECTS_GET_FETCH: 'PROJECTS_GET_FETCH',
  PROJECTS_GET_FETCH_ERROR: 'PROJECTS_GET_FETCH_ERROR',
  PROJECTS_USER_GET_FETCHING: 'PROJECTS_USER_GET_FETCHING',
  PROJECTS_USER_GET_FETCH: 'PROJECTS_USER_GET_FETCH',
  PROJECTS_USER_GET_FETCH_ERROR: 'PROJECTS_USER_GET_FETCH_ERROR',
  PROJECTS_LIBRARY_GET_FETCHING: 'PROJECTS_LIBRARY_GET_FETCHING',
  PROJECTS_LIBRARY_GET_FETCH: 'PROJECTS_LIBRARY_GET_FETCH',
  PROJECTS_LIBRARY_GET_FETCH_ERROR: 'PROJECTS_LIBRARY_GET_FETCH_ERROR',
  PROJECTS_LIBRARY_GET_PAGED_FETCHING: 'PROJECTS_LIBRARY_GET_PAGED_FETCHING',
  PROJECTS_LIBRARY_GET_PAGED_FETCH: 'PROJECTS_LIBRARY_GET_PAGED_FETCH',
  PROJECTS_LIBRARY_GET_PAGED_FETCH_ERROR: 'PROJECTS_LIBRARY_GET_PAGED_FETCH_ERROR',
  PROJECTS_POST_FETCHING: 'PROJECTS_POST_FETCHING',
  PROJECTS_POST_FETCH: 'PROJECTS_POST_FETCH',
  PROJECTS_POST_FETCH_ERROR: 'PROJECTS_POST_FETCH_ERROR',
  PROJECTS_PUT_FETCHING: 'PROJECTS_PUT_FETCHING',
  PROJECTS_PUT_FETCH: 'PROJECTS_PUT_FETCH',
  PROJECTS_PUT_FETCH_AUTO: 'PROJECTS_PUT_FETCH_AUTO',
  PROJECTS_PUT_FETCH_CODE: 'PROJECTS_PUT_FETCH_CODE',
  PROJECTS_PUT_FETCH_ERROR: 'PROJECTS_PUT_FETCH_ERROR',
  PROJECTS_GET_ONE_FETCHING: 'PROJECTS_GET_ONE_FETCHING',
  PROJECTS_GET_ONE_FETCH: 'PROJECTS_GET_ONE_FETCH',
  PROJECTS_GET_ONE_FETCH_ERROR: 'PROJECTS_GET_ONE_FETCH_ERROR',

  PROJECTS_RESET_STATUS: 'PROJECTS_RESET_STATUS',

  TAGS_GET_FETCHING: 'TAGS_GET_FETCHING',
  TAGS_GET_FETCH: 'TAGS_GET_FETCH',
  TAGS_GET_FETCH_ERROR: 'TAGS_GET_FETCH_ERROR',
  TAGS_POST_FETCHING: 'TAGS_POST_FETCHING',
  TAGS_POST_FETCH: 'TAGS_POST_FETCH',
  TAGS_POST_FETCH_ERROR: 'TAGS_POST_FETCH_ERROR',
  TAGS_RESET_STATUS: 'TAGS_RESET_STATUS',

  PROJECTS_DELETE_FETCHING: 'PROJECTS_DELETE_FETCHING',
  PROJECTS_DELETE_FETCH: 'PROJECTS_DELETE_FETCH',
  PROJECTS_DELETE_FETCH_ERROR: 'PROJECTS_DELETE_FETCH_ERROR',

  // Remote sessions
  REMOTE_SESSION_GET_FETCHING: 'REMOTE_SESSION_GET_FETCHING',
  REMOTE_SESSION_GET_FETCH: 'REMOTE_SESSION_GET_FETCH',
  REMOTE_SESSION_GET_FETCH_ERROR: 'REMOTE_SESSION_GET_FETCH_ERROR',
  REMOTE_SESSION_GET_LIST_FETCHING: 'REMOTE_SESSION_GET_LIST_FETCHING',
  REMOTE_SESSION_GET_LIST_FETCH: 'REMOTE_SESSION_GET_LIST_FETCH',
  REMOTE_SESSION_GET_LIST_FETCH_ERROR: 'REMOTE_SESSION_GET_LIST_FETCH_ERROR',
  REMOTE_SESSION_LOGIN_GET_FETCHING: 'REMOTE_SESSION_LOGIN_GET_FETCHING',
  REMOTE_SESSION_LOGIN_GET_FETCH: 'REMOTE_SESSION_LOGIN_GET_FETCH',
  REMOTE_SESSION_LOGIN_GET_FETCH_ERROR: 'REMOTE_SESSION_LOGIN_GET_FETCH_ERROR',
  REMOTE_SESSIONS_RESET_STATUS: 'REMOTE_SESSIONS_RESET_STATUS',
  REMOTE_SESSION_PROJECTS_POST_FETCHING: 'REMOTE_SESSION_PROJECTS_POST_FETCHING',
  REMOTE_SESSION_PROJECTS_POST_FETCH: 'REMOTE_SESSION_PROJECTS_POST_FETCH',
  REMOTE_SESSION_PROJECTS_POST_FETCH_ERROR: 'REMOTE_SESSION_PROJECTS_POST_FETCH_ERROR',
  REMOTE_SESSION_PROJECTS_UPDATE_FETCHING: 'REMOTE_SESSION_PROJECTS_UPDATE_FETCHING',
  REMOTE_SESSION_PROJECTS_UPDATE_FETCH: 'REMOTE_SESSION_PROJECTS_UPDATE_FETCH',
  REMOTE_SESSION_PROJECTS_UPDATE_FETCH_ERROR: 'REMOTE_SESSION_PROJECTS_UPDATE_FETCH_ERROR',
  REMOTE_SESSION_PROJECTS_DELETE_FETCHING: 'REMOTE_SESSION_PROJECTS_DELETE_FETCHING',
  REMOTE_SESSION_PROJECTS_DELETE_FETCH: 'REMOTE_SESSION_PROJECTS_DELETE_FETCH',
  REMOTE_SESSION_PROJECTS_DELETE_FETCH_ERROR: 'REMOTE_SESSION_PROJECTS_DELETE_FETCH_ERROR',
  REMOTE_SESSION_PROJECTS_DELETE_ONE_FETCHING: 'REMOTE_SESSION_PROJECTS_DELETE_ONE_FETCHING',
  REMOTE_SESSION_PROJECTS_DELETE_ONE_FETCH: 'REMOTE_SESSION_PROJECTS_DELETE_ONE_FETCH',
  REMOTE_SESSION_PROJECTS_DELETE_ONE_FETCH_ERROR: 'REMOTE_SESSION_PROJECTS_DELETE_ONE_FETCH_ERROR',
  REMOTE_SESSION_REFRESH_FETCHING: 'REMOTE_SESSION_REFRESH_FETCHING',
  REMOTE_SESSION_REFRESH_FETCH: 'REMOTE_SESSION_REFRESH_FETCH',
  REMOTE_SESSION_REFRESH_FETCH_ERROR: 'REMOTE_SESSION_REFRESH_FETCH_ERROR',
  REMOTE_SESSION_PROJECTS_BULK_UPDATE_FETCHING: 'REMOTE_SESSION_PROJECTS_BULK_UPDATE_FETCHING',
  REMOTE_SESSION_PROJECTS_BULK_UPDATE_FETCH: 'REMOTE_SESSION_PROJECTS_BULK_UPDATE_FETCH',
  REMOTE_SESSION_PROJECTS_BULK_UPDATE_FETCH_ERROR: 'REMOTE_SESSION_PROJECTS_BULK_UPDATE_FETCH_ERROR',
  REMOTE_SESSION_UPDATE_FETCHING: 'REMOTE_SESSION_UPDATE_FETCHING',
  REMOTE_SESSION_UPDATE_FETCH: 'REMOTE_SESSION_UPDATE_FETCH',
  REMOTE_SESSION_UPDATE_FETCH_ERROR: 'REMOTE_SESSION_UPDATE_FETCH_ERROR',
  POST_REMOTE_SESSION_COMPILATION_FETCHING: 'POST_REMOTE_SESSION_COMPILATION_FETCHING',
  POST_REMOTE_SESSION_COMPILATION_FETCH: 'POST_REMOTE_SESSION_COMPILATION_FETCH',
  POST_REMOTE_SESSION_COMPILATION_FETCH_ERROR: 'POST_REMOTE_SESSION_COMPILATION_FETCH_ERROR',
  PUT_REMOTE_SESSION_COMPILATION_FETCHING: 'PUT_REMOTE_SESSION_COMPILATION_FETCHING',
  PUT_REMOTE_SESSION_COMPILATION_FETCH: 'PUT_REMOTE_SESSION_COMPILATION_FETCH',
  PUT_REMOTE_SESSION_COMPILATION_FETCH_ERROR: 'PUT_REMOTE_SESSION_COMPILATION_FETCH_ERROR',
  PUT_BULK_REMOTE_SESSION_COMPILATION_FETCHING: 'PUT_BULK_REMOTE_SESSION_COMPILATION_FETCHING',
  PUT_BULK_REMOTE_SESSION_COMPILATION_FETCH: 'PUT_BULK_REMOTE_SESSION_COMPILATION_FETCH',
  PUT_BULK_REMOTE_SESSION_COMPILATION_FETCH_ERROR: 'PUT_BULK_REMOTE_SESSION_COMPILATION_FETCH_ERROR',

  REMOTE_SESSION_STREAM_GET_FETCHING: 'REMOTE_SESSION_STREAM_GET_FETCHING',
  REMOTE_SESSION_STREAM_GET_FETCH: 'REMOTE_SESSION_STREAM_GET_FETCH',
  REMOTE_SESSION_STREAM_GET_FETCH_ERROR: 'REMOTE_SESSION_STREAM_GET_FETCH_ERROR',


  // Assignment sessions
  ASSIGNMENT_SESSION_GET_FETCHING: 'ASSIGNMENT_SESSION_GET_FETCHING',
  ASSIGNMENT_SESSION_GET_FETCH: 'ASSIGNMENT_SESSION_GET_FETCH',
  ASSIGNMENT_SESSION_GET_FETCH_ERROR: 'ASSIGNMENT_SESSION_GET_FETCH_ERROR',
  ASSIGNMENT_SESSION_GET_LIST_FETCHING: 'ASSIGNMENT_SESSION_GET_LIST_FETCHING',
  ASSIGNMENT_SESSION_GET_LIST_FETCH: 'ASSIGNMENT_SESSION_GET_LIST_FETCH',
  ASSIGNMENT_SESSION_GET_LIST_FETCH_ERROR: 'ASSIGNMENT_SESSION_GET_LIST_FETCH_ERROR',
  ASSIGNMENT_SESSION_LOGIN_GET_FETCHING: 'ASSIGNMENT_SESSION_LOGIN_GET_FETCHING',
  ASSIGNMENT_SESSION_LOGIN_GET_FETCH: 'ASSIGNMENT_SESSION_LOGIN_GET_FETCH',
  ASSIGNMENT_SESSION_LOGIN_GET_FETCH_ERROR: 'ASSIGNMENT_SESSION_LOGIN_GET_FETCH_ERROR',
  ASSIGNMENT_SESSIONS_RESET_STATUS: 'ASSIGNMENT_SESSIONS_RESET_STATUS',
  ASSIGNMENT_SESSION_PROJECTS_POST_FETCHING: 'ASSIGNMENT_SESSION_PROJECTS_POST_FETCHING',
  ASSIGNMENT_SESSION_PROJECTS_POST_FETCH: 'ASSIGNMENT_SESSION_PROJECTS_POST_FETCH',
  ASSIGNMENT_SESSION_PROJECTS_POST_FETCH_ERROR: 'ASSIGNMENT_SESSION_PROJECTS_POST_FETCH_ERROR',
  ASSIGNMENT_SESSION_PROJECTS_UPDATE_FETCHING: 'ASSIGNMENT_SESSION_PROJECTS_UPDATE_FETCHING',
  ASSIGNMENT_SESSION_PROJECTS_UPDATE_FETCH: 'ASSIGNMENT_SESSION_PROJECTS_UPDATE_FETCH',
  ASSIGNMENT_SESSION_PROJECTS_UPDATE_FETCH_ERROR: 'ASSIGNMENT_SESSION_PROJECTS_UPDATE_FETCH_ERROR',
  ASSIGNMENT_SESSION_PROJECTS_DELETE_FETCHING: 'ASSIGNMENT_SESSION_PROJECTS_DELETE_FETCHING',
  ASSIGNMENT_SESSION_PROJECTS_DELETE_FETCH: 'ASSIGNMENT_SESSION_PROJECTS_DELETE_FETCH',
  ASSIGNMENT_SESSION_PROJECTS_DELETE_FETCH_ERROR: 'ASSIGNMENT_SESSION_PROJECTS_DELETE_FETCH_ERROR',
  ASSIGNMENT_SESSION_PROJECTS_DELETE_ONE_FETCHING: 'ASSIGNMENT_SESSION_PROJECTS_DELETE_ONE_FETCHING',
  ASSIGNMENT_SESSION_PROJECTS_DELETE_ONE_FETCH: 'ASSIGNMENT_SESSION_PROJECTS_DELETE_ONE_FETCH',
  ASSIGNMENT_SESSION_PROJECTS_DELETE_ONE_FETCH_ERROR: 'ASSIGNMENT_SESSION_PROJECTS_DELETE_ONE_FETCH_ERROR',
  ASSIGNMENT_SESSION_REFRESH_FETCHING: 'ASSIGNMENT_SESSION_REFRESH_FETCHING',
  ASSIGNMENT_SESSION_REFRESH_FETCH: 'ASSIGNMENT_SESSION_REFRESH_FETCH',
  ASSIGNMENT_SESSION_REFRESH_FETCH_ERROR: 'ASSIGNMENT_SESSION_REFRESH_FETCH_ERROR',
  ASSIGNMENT_SESSION_PROJECTS_BULK_UPDATE_FETCHING: 'ASSIGNMENT_SESSION_PROJECTS_BULK_UPDATE_FETCHING',
  ASSIGNMENT_SESSION_PROJECTS_BULK_UPDATE_FETCH: 'ASSIGNMENT_SESSION_PROJECTS_BULK_UPDATE_FETCH',
  ASSIGNMENT_SESSION_PROJECTS_BULK_UPDATE_FETCH_ERROR: 'ASSIGNMENT_SESSION_PROJECTS_BULK_UPDATE_FETCH_ERROR',
  ASSIGNMENT_SESSION_UPDATE_FETCHING: 'ASSIGNMENT_SESSION_UPDATE_FETCHING',
  ASSIGNMENT_SESSION_UPDATE_FETCH: 'ASSIGNMENT_SESSION_UPDATE_FETCH',
  ASSIGNMENT_SESSION_UPDATE_FETCH_ERROR: 'ASSIGNMENT_SESSION_UPDATE_FETCH_ERROR',

  ASSIGNMENT_SESSION_DELIVERABLE: 'ASSIGNMENT_SESSION_DELIVERABLE',

  // Collaboration session
  COLLABORATION_PROJECTS_GET_ONE_FETCHING: 'COLLABORATION_PROJECTS_GET_ONE_FETCHING',
  COLLABORATION_PROJECTS_GET_ONE_FETCH: 'COLLABORATION_PROJECTS_GET_ONE_FETCH',
  COLLABORATION_PROJECTS_GET_ONE_FETCH_ERROR: 'COLLABORATION_PROJECTS_GET_ONE_FETCH_ERROR',
};

export default types;
