import Tutorial1Step1 from '@sections/creabots/components/steps/Tutorial1Step1';
import { BLOCK_CATEGORY_TYPE, BOARD_ID_TYPE, COMMON_CLASSES, MODAL_TYPE, Project } from '@sections/creabots/types/kits';
import * as routes from '@educabot/educablocks-cosmos';
import { getAutomationConfigForBlock, getAutomationElementForBlock } from '../config';
import Tutorial2Step2 from '@sections/creabots/components/steps/automation/Tutorial2Step2';
import { higherThan1000Regex } from '@sections/creabots/features/utils/commonRegexs';

const intlPrefix = 'creabots.tutorials.automatization.projects.intruderAlarm';
const imgBaseUrl = '/images/creabots/automatization/projects/intruderAlarm';

export const intruderAlarm: Project = {
  id: 2,
  name: `${intlPrefix}.name`,
  description: `${intlPrefix}.description`,
  image: `${routes.bloquesUri}${imgBaseUrl}/project_image.png`,
  boardId: BOARD_ID_TYPE.AUTOMATION,
  tutorial: [
    // Step 1.
    {
      step: {
        stepId: 0,
        modal: MODAL_TYPE.FULL,
        component: Tutorial1Step1,
        videoUrl: 'https://storage.googleapis.com/educabot-aec-cdn-1/labs/alarma-anti-intrusos.mp4',
      },
    },
    // Step 2.
    {
      step: {
        stepId: 1,
        modal: MODAL_TYPE.FULL,
        component: Tutorial2Step2,
      },
    },
    // Step 3.
    {
      step: {
        stepId: 2,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step3`,
        image: `${imgBaseUrl}/steps/step3.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 1),
        completionCode: 'voidsetup\\(\\)\\{\\}voidloop\\(\\)\\{if\\(0==0\\)\\{\\}\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getAutomationElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 1),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 75,
          },
        },
      ],
    },
    // Step 4.
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/steps/step4.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.DIGITAL, 1),
        completionCode: 'voidsetup\\(\\)\\{pinMode\\(A1,INPUT\\);pinMode\\(A0,OUTPUT\\);\\}voidloop\\(\\)\\{if\\(u_distancia\\(A0,A1\\)==0\\)\\{\\}\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getAutomationElementForBlock(BLOCK_CATEGORY_TYPE.DIGITAL, 1),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 95,
            yOffset: 75,
          },
        },
      ],
    },
    // Step 5.
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/steps/step5.png`,
        completionCode: 'voidsetup\\(\\)\\{pinMode\\(A1,INPUT\\);pinMode\\(A0,OUTPUT\\);\\}voidloop\\(\\)\\{if\\(u_distancia\\(A0,A1\\)<0\\)\\{\\}\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 415,
            yOffset: 85,
          },
        },
      ],
    },
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/steps/step5.png`,
        completionCode: 'voidsetup\\(\\)\\{pinMode\\(A1,INPUT\\);pinMode\\(A0,OUTPUT\\);\\}voidloop\\(\\)\\{if\\(u_distancia\\(A0,A1\\)<[1-9]\\d*\\)\\{\\}\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 473,
            yOffset: 85,
          },
        },
      ],
    },
    // Step 6.
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/steps/step6.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.OUTPUTS, 4),
        completionCode: 'voidsetup\\(\\)\\{pinMode\\(A1,INPUT\\);pinMode\\(A0,OUTPUT\\);\\}voidloop\\(\\)\\{if\\(u_distancia\\(A0,A1\\)<[1-9]\\d*\\)\\{tone\\(12,495,1000\\);\\}\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getAutomationElementForBlock(BLOCK_CATEGORY_TYPE.OUTPUTS, 4),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 60,
            yOffset: 130,
          },
        },
      ],
    },
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/steps/step6.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        completionCode: 'voidsetup\\(\\)\\{pinMode\\(A1,INPUT\\);pinMode\\(A0,OUTPUT\\);\\}voidloop\\(\\)\\{if\\(u_distancia\\(A0,A1\\)<[1-9]\\d*\\)\\{tone\\(12,495,1000\\);delay\\(1000\\);\\}\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getAutomationElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 60,
            yOffset: 165,
          },
        },
      ],
    },
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/steps/step6.png`,
        completionCode: `voidsetup\\(\\)\\{pinMode\\(A1,INPUT\\);pinMode\\(A0,OUTPUT\\);\\}voidloop\\(\\)\\{if\\(u_distancia\\(A0,A1\\)<[1-9]\\d*\\)\\{tone\\(12,495,1000\\);delay\\(${higherThan1000Regex}\\);\\}\\}`,
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 145,
            yOffset: 180,
          },
        },
      ],
    },
    // Step 7.
    {
      step: {
        stepId: 6,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step7`,
        image: `${imgBaseUrl}/steps/step7.gif`,
        completionCodeCheck: 'built',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.BUILD_BLOCKLY_BTN_CLASS,
            xOffset: -10,
            yOffset: -10,
          },
        },
      ],
    },
    // Step 8.
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/steps/step7.gif`,
      },
    },
  ],
};
