import React from 'react';
import {
  Card,
  alpha,
  CardActionArea,
  Box,
  Checkbox,
  CardMedia,
  CardContent,
  Typography,
} from '@mui/material';
import { Kit } from '@sections/creabots/types/kits';
import { useIntl } from 'react-intl';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';

type KitCardProps = {
  kit: Kit & { isDisabled: boolean; imageUrl: string; kitName: string };
  isSelected: boolean;
  setIsSelected: (id: number) => void;
  maxImageHeight?: string
};

export function KitCard({ maxImageHeight = '276px', kit, isSelected, setIsSelected }: KitCardProps) {
  const intl = useIntl();

  return (
    <Card
      elevation={0}
      sx={theme => ({
        height: '100%',
        backgroundColor: kit.isDisabled ? '#f8f8f6' : '#F7F8FC',
        border: '1px solid',
        borderColor: alpha(theme.palette.secondary.main, 0.08),
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        width: '100%',
        borderRadius: theme.spacing(1),
      })}
    >
      <CardActionArea
        sx={{ height: '100%' }}
        disabled={kit.isDisabled}
        onClick={() => setIsSelected(kit.id)}
      >
        <Box display="flex" width="100%" justifyContent="flex-end">
          <Checkbox
            name="kitSelected"
            id="kitSelected"
            checked={kit.isDisabled || isSelected}
            readOnly
            color="primary"
            icon={<CircleUnchecked />}
            checkedIcon={
              <CircleCheckedFilled
                sx={theme => ({
                  color: kit.isDisabled
                    ? '#DFDFDA'
                    : theme.palette.success.main,
                })}
              />
            }
            sx={theme => ({
              color: theme.palette.text.secondary,
              '&:hover': {
                backgroundColor: 'transparent',
              },
              cursor: 'default',
            })}
          />
        </Box>
        <CardMedia
          component="img"
          width="100%"
          style={{
            maxHeight: maxImageHeight,
            objectFit: 'contain',
            ...(kit.isDisabled && { filter: 'grayscale(100%)' }),
          }}
          src={kit.imageUrl}
          alt={`Paquete del kit ${kit.name}`}
        />

        <CardContent
          sx={theme => ({
            paddingX: theme.spacing(2),
            paddingY: theme.spacing(1),
          })}
        >
          <Typography
            sx={theme => ({
              fontWeight: theme.typography.fontWeightMedium,
              color: theme.palette.text.primary,
              fontSize: '1rem',
              lineHeight: '24px',
            })}
          >
            {intl.formatMessage({ id: kit.name })}
          </Typography>
          <Typography
            sx={theme => ({
              fontWeight: theme.typography.fontWeightRegular,
              color: theme.palette.text.secondary,
              fontSize: '.875rem',
              lineHeight: '20px',
            })}
          >
            {kit.kitName}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
