/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { detectBrowserAndOS } from './CoolFunctions';

class ModalBrowser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detect: detectBrowserAndOS(),
      browserModalState: (sessionStorage.getItem('EduBrowserModalState')) ? sessionStorage.getItem('EduBrowserModalState') : 'open',
    };
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillReceiveProps(newProps) {
    const { browserModalState } = this.state;
    this.setState({
      detect: detectBrowserAndOS(),
    });
  }

  closeModal() {
    this.setState({ browserModalState: 'closed' });
    sessionStorage.setItem('EduBrowserModalState', 'closed');
  }

  render() {
    const { detect, browserModalState } = this.state;
    let showModal = false;
    showModal = !(detect.device === 'Desktop');
    showModal = showModal || !(detect.browser === 'Chrome' || detect.browser === 'Firefox' || detect.browser === 'Edge');
    showModal = showModal || !((detect.os === 'Linux') || (detect.os.indexOf('Windows') >= 0) || (detect.os.indexOf('Macintosh') >= 0));
    // showModal = (window.location.hostname === 'localhost') ? false : showModal; // development
    return (
      <div className={`globalDetectBrowser ${(showModal && browserModalState === 'open') ? 'active' : ''}`}>
        <div className="background" />
        <div className="modal-body">
          <div className="content">
            <div className="header">
              <h2><b>¡Ups!</b></h2>
            </div>
            <div className="body">
              <div className="image" />
              <p>La plataforma no está disponible para tu configuración de sistema operativo + navegador</p>
              <p><button type="button" className="btn btn-primary btnClose" onClick={this.closeModal}>Continuar de todas formas</button></p>
            </div>
            <div className="footer" />
          </div>
        </div>
      </div>
    );
  }
}

export default ModalBrowser;
