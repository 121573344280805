/* eslint no-underscore-dangle: 0 */
/* eslint no-nested-ternary: 0 */
/* eslint no-prototype-builtins: 0 */
import React from 'react';
import './ArduinoSimulator.scss';
import Compile from '../../helpers/simulator/compile';
import { formatSimulatorParts, formatTime, castValue } from '../../helpers/simulator/utils';
import { FlowChartClass } from '../../helpers/simulator/flowChart';
import { SimulatorModes } from '../../helpers/simulator/constants';

class ArduinoSimulator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      simulator: props.simulator || null,
      challenges: props.simulator ? JSON.parse(JSON.stringify(props.simulator.challenges || [])) : [],
      workspace: props.workspace || null,
      arduinoSimulatorToggleAction: props.toggleSimulator || null,
      arduinoSimulatorToggle: props.arduinoSimulatorToggle || false,
      running: false,
      compiling: false,
      board: props.board,
      code: props.code || '',
      flowChart: null,
      simulationTime: 0,
      changeState: props.changeState || null,
    };

    this.handleCompile = this.handleCompile.bind(this);
    this.runEmulation = this.runEmulation.bind(this);
    this.stopEmulation = this.stopEmulation.bind(this);
    this.checkChallenges = this.checkChallenges.bind(this);
  }

  componentDidMount() {
    const { board, changeState, simulator, arduinoSimulatorToggleAction, arduinoSimulatorToggle } = this.state;

    const flowChart = new FlowChartClass({ boardId: board.id, changeState });
    document.addEventListener('component-rotation', flowChart.refreshConnections, { passive: true });
    // console.log('============simulator', board, simulator);

    let newSimulator = simulator;
    if (!simulator?.mode && board.simulator) {
      newSimulator = {
        mode: 'auto',
      };
    }

    this.setState({ flowChart, simulator: newSimulator });
    changeState(newSimulator);

    // Force re render when switching project's tabs
    setTimeout(() => {
      arduinoSimulatorToggleAction(arduinoSimulatorToggle);
    }, 10);
  }

  componentWillUnmount() {
    const { flowChart } = this.state;
    if (flowChart) {
      document.removeEventListener('component-rotation', flowChart.refreshConnections, { passive: true });
    }
    this.stopEmulation();
  }

  componentWillReceiveProps(newProps) {
    const { workspace, flowChart, simulator, arduinoSimulatorToggle } = this.state;

    let newComponents = null;
    if (workspace && simulator?.mode && flowChart) {
      flowChart.setDefaultCircuitSettings(simulator.circuitSettings || {});
      /// if mode=auto
      if (simulator.mode === SimulatorModes.AUTO && workspace?.pinDB_) {
        newComponents = formatSimulatorParts(simulator.circuit || {}, flowChart.type, workspace.pinDB_?.pins || []);
        flowChart.emptyComponents();
        flowChart.addComponents(newComponents);
        // console.log('=================save flowChart', simulator.circuit, flowChart.circuitSettingsCustom, flowChart.circuitCustom);
      }

      /// if mode=manual
      // take simulator parts and connections and send to parent component to save in db

      /// if mode=challenge
      // take simulator parts and connections and set newComponents
      if (simulator.mode === SimulatorModes.CHALLENGE) {
        newComponents = simulator.circuit || {};
        flowChart.emptyComponents();
        flowChart.addComponents(newComponents);
        // console.log('=================simulator.challenges', simulator.challenges);
      }
    }

    let newArduinoSimulatorToggle = arduinoSimulatorToggle;
    if (arduinoSimulatorToggle !== newProps.arduinoSimulatorToggle) {
      newArduinoSimulatorToggle = newProps.arduinoSimulatorToggle || false;
    }
    this.setState({ workspace: newProps.workspace || null, code: newProps.code || '', arduinoSimulatorToggle: newArduinoSimulatorToggle });
  }

  checkChallenges(pinStates = []) {
    const { simulator, challenges, simulationTime } = this.state;
    if (simulator.mode === 'challenge') {
      let newChallenges = [...challenges];
      pinStates.map((component) => {
        newChallenges = newChallenges.map((c, i) => {
          const challenge = { ...c };
          // check if pin corresponds to a condition
          challenge.conditions = challenge.conditions.map((cond) => {
            const condition = { ...cond };
            if (condition.pin === component.pin) {
              condition.completed = false;
              // check if action is meet
              let componentValue = component.value;
              let conditionValue = condition.value;
              if (typeof conditionValue === 'object') {
                componentValue = component.value?.hasOwnProperty(condition.value.KEY) ? component.value[condition.value.KEY] : 0;
                conditionValue = condition.value.VALUE;
              }
              componentValue = castValue(conditionValue, componentValue);
              // if (component.pin === '3') {
              //   console.log('==============condition', (componentValue === conditionValue), component, challenge);
              // }
              switch (condition.operator) {
                default:
                case '==':
                  if (componentValue === conditionValue) condition.completed = true;
                  break;
                case '<':
                  if (componentValue < conditionValue) condition.completed = true;
                  break;
                case '>':
                  if (componentValue > conditionValue) condition.completed = true;
                  break;
                case '<=':
                  if (componentValue <= conditionValue) condition.completed = true;
                  break;
                case '>=':
                  if (componentValue >= conditionValue) condition.completed = true;
                  break;
                case '!=':
                  if (componentValue !== conditionValue) condition.completed = true;
                  break;
              }
            }
            return condition;
          });

          // check if pin corresponds to an action
          challenge.actions = challenge.actions.map((a, index) => {
            const action = { ...a };
            if (action.pin === component.pin && (typeof action.completed === 'undefined' || !action.completed)) {
              action.completed = false;
              const conditionsMeet = challenge.conditions.every((cond) => (cond.completed));
              if ((!challenge.conditions.length || conditionsMeet) && ((index === 0 && !challenge.actions[0].completed) || challenge.actions[index - 1].completed === true)) {
                // check if action is meet
                // console.log('==============action', action, component);
                let componentValue = component.value;
                let actionValue = action.value;
                if (typeof actionValue === 'object') {
                  componentValue = component.value?.hasOwnProperty(action.value.KEY) ? component.value[action.value.KEY] : 0;
                  actionValue = action.value.VALUE;
                  if (typeof actionValue === 'object') {
                    const otherComponent = pinStates.find((comp) => comp.pin === action.value.VALUE.PIN);
                    if (action.value.VALUE.KEY) {
                      actionValue = otherComponent.value?.hasOwnProperty(action.value.VALUE.KEY) ? otherComponent.value[action.value.VALUE.KEY] : 0;
                    } else {
                      actionValue = otherComponent.value;
                    }
                  }
                }
                actionValue = castValue(componentValue, actionValue);
                // if (component.pin === '3') {
                //   console.log('==============action', componentValue, action.operator, actionValue);
                // }
                switch (action.operator) {
                  default:
                  case '==':
                    if (componentValue === actionValue) action.completed = true;
                    break;
                  case '<':
                    if (componentValue < actionValue) action.completed = true;
                    break;
                  case '>':
                    if (componentValue > actionValue) action.completed = true;
                    break;
                  case '<=':
                    if (componentValue <= actionValue) action.completed = true;
                    break;
                  case '>=':
                    if (componentValue >= actionValue) action.completed = true;
                    break;
                  case '!=':
                    if (componentValue !== actionValue) action.completed = true;
                    break;
                  case 'starts':
                    if (typeof componentValue === 'string' && componentValue.match(new RegExp(`^${actionValue}`, 'gi'))) action.completed = true;
                    break;
                  case 'ends':
                    if (typeof componentValue === 'string' && componentValue.match(new RegExp(`${actionValue}$`, 'gi'))) action.completed = true;
                    break;
                  case 'includes':
                    if (typeof componentValue === 'string' && componentValue.match(new RegExp(actionValue, 'gi'))) action.completed = true;
                    break;
                }
                if (action.time) {
                  const milliseconds = (action.time / 1000) / 1.1;
                  // console.log('===============', (simulationTime - (action.timeStart || 0)), milliseconds, action.completed);
                  if (!action.timeStart) {
                    action.timeStart = simulationTime;
                    action.completed = false;
                  } else if ((simulationTime - (action.timeStart || 0)) < milliseconds) {
                    if (!action.completed) {
                      action.timeStart = 0;
                    }
                    action.completed = false;
                  } else if ((simulationTime - (action.timeStart || 0)) >= milliseconds && !action.completed) {
                    action.timeStart = 0;
                  }
                }
              }
            }
            return action;
          });

          challenge.completed = false;
          const actionsCompleted = challenge.actions.every((action) => (action.completed));
          if (actionsCompleted) {
            challenge.completed = true;
          }

          return challenge;
        });
        return true;
      });

      // console.log('==============', challenges, pinStates);
      this.setState({ challenges: newChallenges });
    }
  }

  handleCompile() {
    const { board, code } = this.state;
    return Compile(board, code);
  }

  runEmulation() {
    const { flowChart, simulator, running, compiling } = this.state;
    if (!running && !compiling) {
      const newChallenges = JSON.parse(JSON.stringify(simulator.challenges || []));
      this.setState({ challenges: newChallenges, running: true, compiling: true }, () => {
        // console.log('=======================newChallenges', newChallenges);
        this.handleCompile().then((hex) => {
          flowChart.hardware.executeProgram(hex, (status) => {
            if (status.pinStates.length) {
              // console.log('=======================status.pinStates', status.pinStates);
              this.checkChallenges(status.pinStates);
            }
            this.setState({ simulationTime: status.simulationTime, compiling: false });
          });
        }).catch((err) => {
          console.log('=======================error', err);
          this.setState({ running: false, compiling: false });
        });
      });
    }
  }

  stopEmulation() {
    const { flowChart } = this.state;

    this.setState({ running: false, compiling: false, simulationTime: 0 });
    if (flowChart) {
      flowChart.hardware.stopExecute();
    }
  }

  simulatorToggle() {
    if (document.querySelector('.blocks')) {
      const ele = document.querySelector('.blocks');
      if (ele.classList.contains('maximized')) {
        ele.classList.remove('maximized');
      } else {
        ele.classList.add('maximized');
      }
    }
  }

  render() {
    const { arduinoSimulatorToggle, arduinoSimulatorToggleAction, running, compiling, simulator, flowChart, simulationTime, challenges } = this.state;
    return (
      simulator?.mode ? (
        <div className={`col-12 p-0 avrSimulator ${(arduinoSimulatorToggle) ? 'avrSimulator-active' : ''}`}>
          <div className="content-avrSimulator">
            <div className="challenge">
              <ol>
                {challenges.map((challenge, i) => (
                  <li key={`li-${challenge.text.length}-${challenge.text.substring(0, 5)}`} className={challenge.completed ? 'completed' : 'uncompleted'}><span>{challenge.text}</span>{challenge.completed ? <img alt="" src={`${process.env.IS_ELECTRON ? '.' : ''}/images/simulator/check.svg`} height="16" /> : ''}</li>
                ))}
              </ol>
            </div>
            <div className="blocks">
              {flowChart ? flowChart.render() : null}
              <div className="timer">
                <div className="clock">
                  <button type="button" className="compile" onClick={(running) ? this.stopEmulation : this.runEmulation} disabled={compiling}>
                    {running ? (
                      <img alt="" src={`${process.env.IS_ELECTRON ? '.' : ''}/images/simulator/stop_red.svg`} height="24" />
                    ) : (
                      <img alt="" src={`${process.env.IS_ELECTRON ? '.' : ''}/images/simulator/play_green.svg`} height="24" />
                    )}
                  </button>
                  <span>{(compiling) ? 'Compilando...' : formatTime(simulationTime)}</span>
                </div>
                <div className="actions">
                  <img className="fullscreen-toggle" alt="" src={`${process.env.IS_ELECTRON ? '.' : ''}/images/simulator/fullScreen.svg`} onClick={this.simulatorToggle} height="16" />
                </div>
              </div>
            </div>

          </div>
          <div className="toggle-avrSimulator" onKeyPress={() => { }} role="button" tabIndex="0" onClick={arduinoSimulatorToggleAction}>
            <span className="curly-braces"><img alt="Cargar codigo" src={`${process.env.IS_ELECTRON ? '.' : ''}/images/simulator/memory.svg`} height="24" /></span>
            <span className={`icon-chevron-left ${(arduinoSimulatorToggle) ? 'rotate-arrow' : ''}`} />
          </div>
        </div>
      ) : (null)
    );
  }
}

export default ArduinoSimulator;
