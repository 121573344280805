import { Alert, CircularProgress, Snackbar, useTheme } from '@mui/material';
import {
  selectCreabots,
  closeLabsToast,
} from '@sections/creabots/features/creabotsSlice';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

export const LabsToast: React.FC = () => {
  const intl = useIntl();
  const { toast } = useSelector(selectCreabots);
  const dispatch = useDispatch();
  const theme = useTheme();

  const handleClose = () => {
    dispatch(closeLabsToast());
  };

  const getBackgroundColor = useCallback(() => {
    switch (true) {
      case toast.severity === 'info':
        return '#EBF9FF';
      case toast.severity === 'error':
        return theme.palette.status.error;
      case toast.severity === 'success':
        return theme.palette.status.success;
      default:
        return theme.palette.status.success;
    }
  }, [toast.severity]);

  const getTextColor = useCallback(() => {
    switch (true) {
      case toast.severity === 'info':
        return '#42B3FF';
      case toast.severity === 'error':
        return theme.palette.error.main;
      case toast.severity === 'success':
        return theme.palette.success.main;
      default:
        return theme.palette.success.main;
    }
  }, [toast.severity]);

  return (
    <Snackbar
      open={toast.open}
      autoHideDuration={toast.autoHideDuration}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      onClose={handleClose}
    >
      <Alert
        icon={
          toast.severity === 'info' ? <CircularProgress size={22} /> : undefined
        }
        onClose={handleClose}
        severity={toast.severity}
        variant="filled"
        sx={{
          width: '100%',
          backgroundColor: getBackgroundColor(),
          color: getTextColor(),
        }}
      >
        {toast.message && intl.formatMessage({ id: toast.message })}
      </Alert>
    </Snackbar>
  );
};
