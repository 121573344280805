import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllProjects,
  getAllProjectsBySeniority,
  selectProjects,
  putProject,
  createProject,
  updateProject,
  getUserProject,
  updateProjectState,
} from '@modules/projects/features/projectsSlice';
import { useUserSeniority } from '@utils/hooks';

export const useProjects = () => useSelector(selectProjects);

export const useGetProjects = () => {
  const dispatch = useDispatch();
  const projects = useSelector(selectProjects);
  useEffect(() => {
    dispatch(getAllProjects());
  }, []);

  return projects;
};

export const useGetProjectsBySeniority = () => {
  const dispatch = useDispatch();
  const seniorityId = useUserSeniority();
  const projects = useSelector(selectProjects);
  useEffect(() => {
    dispatch(getAllProjectsBySeniority(seniorityId));
  }, []);

  return projects;
};

export const useGetSessionStorageProjects = (key = 'EduCodeGenerated') => {
  try {
    const sessionProjects = sessionStorage.getItem(key);
    if (sessionProjects) {
      const projects = JSON.parse(sessionProjects);
      return Object
        .entries(projects)
        .map(([blocklyInstance, project]) => ({ blocklyInstance, project }));
    }

    return {};
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const usePutProject = (projectId, id, project) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (projectId && id && project && projectId === project.id) {
      dispatch(putProject(id, project));
    }
  }, [projectId, id, project]);
};

export const useGetUserProject = (projectId) => {
  const dispatch = useDispatch();
  const projectState = useSelector(selectProjects);

  useEffect(() => {
    if (projectId) {
      dispatch(getUserProject(projectId));
    }
  }, [projectId]);

  return projectState;
};

export const useCreateUpdateProject = (userId, timeout = 3000) => {
  const dispatch = useDispatch();
  const projectRef = useRef();
  const userRef = useRef();
  let previousCode = '';

  useEffect(() => {
    const id = setInterval(() => {
      const currentProject = projectRef.current;
      if (!(previousCode === currentProject.jsonBody.withWorkspace) && userRef.current && currentProject) {
        previousCode = currentProject.jsonBody.withWorkspace;
        if (currentProject.id) {
          dispatch(updateProject(currentProject.id, currentProject));
        } else {
          dispatch(createProject(currentProject));
        }
      }
    }, timeout);
    return () => clearInterval(id);
  }, []);

  useEffect(() => {
    if (userId) {
      userRef.current = userId;
    }
  }, [userId]);

  const createUpdateProject = (project) => {
    if (project?.jsonBody?.withWorkspace) {
      projectRef.current = project;
    }
  };

  return {
    createUpdateProject,
  };
};
