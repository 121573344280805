import React from 'react';
import { alpha, Badge, Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import CanvasSVG from '../../../components/CanvasSVG/CanvasSVG';
import { TextBodySmallEmphasized } from './ui/typographies';
import { ProjectMenuOptions } from './dashboard/content/projectMenuOptions';

const prependCSS = (canvas, css) => {
  let prependedCanvas = canvas;
  if (css && css !== '') {
    prependedCanvas = `${css}">${canvas}`;
  }
  return prependedCanvas;
};

/**
 *
 * @param {Object} props
 * @param {String} props.type // card | item
 * @param {String} props.title
 * @param {String} props.description
 * @param {String} props.kitName
 * @param {String} [props.image]
 * @param {Object} props.canvas
 * @param {Object} props.project
 * @param {string} props.redirectTo
 * @returns
 */

export const UserProjectCard = (props) => {
  const { type } = props;
  return <>{type === 'card' ? <Card {...props} /> : <ListItem {...props} />}</>;
};

function Card(props) {
  const { id, title, description, project, image, canvas, redirectTo } = props;
  const { isCreabots } = project;

  const updateDate = new Date(description);

  const formattedDate = format(updateDate, "d 'de' MMMM 'de' yyyy", {
    locale: es,
  });

  const BaseCard = (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.common.white,
        elevation: 1,
        display: 'flex',
        flexDirection: 'column',
        height: 'min-content',
        width: '100%',
        border: '1px solid',
        borderColor: alpha(theme.palette.common.black, 0.05),
        borderRadius: theme.spacing(1.5),
        '&:hover': {
          boxShadow: `
        0px 0px 1px 0px rgba(255, 255, 255, 0.1) inset,
        0px 0px 9px 0px rgba(0, 0, 0, 0.2),
        0px 0px 2px 0px rgba(0, 0, 0, 0.25)
      `,
          '--chipVisibility': 'hidden',
          '--boxVisibility': 'visible',
        },
        '--chipVisibility': 'visible',
        '--boxVisibility': 'hidden',
        position: 'relative',
      })}
    >
      <Box
        className="projectCard"
        sx={(theme) => ({
          height: 256,
          width: '100%',
          backgroundColor: alpha(theme.palette.secondary.main, 0.08),
          borderStartStartRadius: 'inherit !important',
          borderStartEndRadius: 'inherit !important',
          position: 'relative',
          filter: 'none',
        })}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            margin: 1.5,
            zIndex: 90,
          }}
        >
          <ProjectMenuOptions project={project} />
        </Box>
        {isCreabots && (
          <Badge
            sx={(theme) => ({
              margin: 1.5,
              position: 'absolute',
              top: 0,
              left: 0,
              backgroundColor: '#EBF9FF',
              color: theme.palette.primary.main,
              paddingX: 1,
              borderRadius: 1,
            })}
          >
            <TextBodySmallEmphasized
              sx={(theme) => ({
                fontWeight: theme.typography.fontWeightBold,
              })}
            >
              Creabots
            </TextBodySmallEmphasized>
          </Badge>
        )}
        <Link to={redirectTo}>
          {image ? (
            <img
              src={image}
              alt={description}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
            />
          ) : (
            ''
          )}
          {canvas ? (
            <CanvasSVG
              content={prependCSS(canvas.canvas, canvas.canvasCss)}
              svgX={canvas.canvasBBoxX || 10}
              svgY={canvas.canvasBBoxY || 10}
              svgWidth={canvas.canvasBBoxWidth || 300}
              svgHeight={canvas.canvasBBoxHeight || 240}
              width="100%"
              height="240px"
              iframeHeight="240px"
            />
          ) : (
            ''
          )}
        </Link>
      </Box>
      <Link to={redirectTo} style={{ textDecoration: 'none' }}>
        <Box
          sx={(theme) => ({
            paddingY: theme.spacing(1.5),
            paddingX: theme.spacing(2),
            height: '130px',
          })}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={(theme) => ({
                color: theme.palette.text.primary,
                fontWeight: theme.typography.fontWeightMedium,
                fontSize: '1rem',
                lineHeight: '24px',
                fontFamily: theme.typography.fontFamily,
              })}
            >
              {title}
            </Typography>
          </Box>
          <Typography
            sx={(theme) => ({
              color: theme.palette.text.secondary,
              fontWeight: theme.typography.fontWeightRegular,
              fontSize: '1rem',
              lineHeight: '24px',
              fontFamily: theme.typography.fontFamily,
              textAlign: 'left',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'wrap',
            })}
          >
            Editado el {formattedDate}
          </Typography>
        </Box>
      </Link>
    </Box>
  );

  return BaseCard;
}

function ListItem(props) {
  const { id, title, description, project, image, canvas, redirectTo } = props;
  const { isCreabots } = project;

  const updateDate = new Date(description);

  const formattedDate = format(updateDate, "d 'de' MMMM 'de' yyyy", {
    locale: es,
  });

  const BaseListItem = (
    <Box
      component="section"
      sx={(theme) => ({
        height: 124,
        width: '100%',
        padding: theme.spacing(1),
        borderRadius: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        '&:hover': {
          backgroundColor: alpha(theme.palette.primary.main, 0.08),
        },
      })}
    >
      <Link to={redirectTo} style={{ textDecoration: 'none', width: '70%' }}>
        <Box
          width="70%"
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            height={108}
            width={192}
            sx={(theme) => ({
              backgroundColor: alpha(theme.palette.secondary.main, 0.08),
              filter: 'none',
              borderRadius: '4px',
            })}
          >
            {image ? (
              <img
                src={image}
                alt={description}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }}
              />
            ) : (
              ''
            )}
            {canvas ? (
              <CanvasSVG
                content={prependCSS(canvas.canvas, canvas.canvasCss)}
                svgX={canvas.canvasBBoxX || 10}
                svgY={canvas.canvasBBoxY || 10}
                svgWidth={canvas.canvasBBoxWidth || 110}
                svgHeight={canvas.canvasBBoxHeight || 108}
                width="100%"
                height="95px"
                iframeHeight="95px"
              />
            ) : (
              ''
            )}
          </Box>
          <Box
            sx={(theme) => ({
              width: '100%',
              marginLeft: theme.spacing(2),
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            })}
          >
            <Box
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                gap: theme.spacing(3),
              })}
            >
              <Typography
                sx={(theme) => ({
                  color: theme.palette.text.primary,
                  fontWeight: theme.typography.fontWeightMedium,
                  fontSize: '1rem',
                  lineHeight: '24px',
                  fontFamily: theme.typography.fontFamily,
                })}
              >
                {title}
              </Typography>
              {isCreabots && (
                <Badge
                  sx={(theme) => ({
                    backgroundColor: '#EBF9FF',
                    color: theme.palette.primary.main,
                    paddingX: 1,
                    borderRadius: 1,
                  })}
                >
                  <TextBodySmallEmphasized
                    sx={(theme) => ({
                      fontWeight: theme.typography.fontWeightBold,
                    })}
                  >
                    Creabots
                  </TextBodySmallEmphasized>
                </Badge>
              )}
            </Box>
          </Box>
        </Box>
      </Link>
      <Box sx={{ width: '20%' }}>
        <Link to={redirectTo} style={{ textDecoration: 'none' }}>
          <Typography
            sx={(theme) => ({
              color: theme.palette.text.secondary,
              fontWeight: theme.typography.fontWeightRegular,
              fontSize: '1rem',
              lineHeight: '24px',
              fontFamily: theme.typography.fontFamily,
              textAlign: 'left',
            })}
          >
            Editado el {formattedDate}
          </Typography>
        </Link>
      </Box>
      <Box sx={{ width: '10%' }}>
        <ProjectMenuOptions project={project} />
      </Box>
    </Box>
  );

  return BaseListItem;
}
