import React from 'react';
import TutorialBuildInstructions from '@sections/creabots/components/steps/TutorialBuildInstructions';

interface Tutorial4Step2Props {
  onNextStep: () => void,
  onPrevStep: () => void,
  currentStep: number,
  totalSteps: number,
}

const Tutorial4Step2 = ({
  onNextStep,
  onPrevStep,
  currentStep,
  totalSteps,
}: Tutorial4Step2Props) => {
  const intlPrefix = 'creabots.tutorials.driving.projects.automaticHandling.build'
  const imgBaseUrl = '/images/creabots/driving/projects/automaticHandling/slider'
  const images = [
    {
      text: `${intlPrefix}.step1`,
      src: `${imgBaseUrl}/project_image.png`,
    },
    {
      text: `${intlPrefix}.step2`,
      src: `${imgBaseUrl}/project_image.png`,
    },
    {
      text: `${intlPrefix}.step3`,
      src: `${imgBaseUrl}/project_image.png`,
    },
    {
      text: `${intlPrefix}.step4`,
      src: `${imgBaseUrl}/project_image.png`,
    },
    {
      text: `${intlPrefix}.step5`,
      src: `${imgBaseUrl}/project_image.png`,
    },
    {
      text: `${intlPrefix}.step6`,
      src: `${imgBaseUrl}/project_image.png`,
    },
    {
      text: `${intlPrefix}.step7`,
      src: `${imgBaseUrl}/project_image.png`,
    },
    {
      text: `${intlPrefix}.step8`,
      src: `${imgBaseUrl}/project_image.png`,
    },
    {
      text: `${intlPrefix}.step9`,
      src: `${imgBaseUrl}/project_image.png`,
    },
    {
      text: `${intlPrefix}.step10`,
      src: `${imgBaseUrl}/project_image.png`,
    },
    {
      text: `${intlPrefix}.step11`,
      src: `${imgBaseUrl}/project_image.png`,
    },
    {
      text: `${intlPrefix}.step12`,
      src: `${imgBaseUrl}/project_image.png`,
    },
    {
      text: `${intlPrefix}.step13`,
      src: `${imgBaseUrl}/project_image.png`,
    },
    {
      text: `${intlPrefix}.step14`,
      src: `${imgBaseUrl}/project_image.png`,
    },
    {
      text: `${intlPrefix}.step15`,
      src: `${imgBaseUrl}/project_image.png`,
    },
    {
      text: `${intlPrefix}.step16`,
      src: `${imgBaseUrl}/project_image.png`,
    },
    {
      text: `${intlPrefix}.step17`,
      src: `${imgBaseUrl}/project_image.png`,
    },
    {
      text: `${intlPrefix}.step18`,
      src: `${imgBaseUrl}/project_image.png`,
    },
    {
      text: `${intlPrefix}.step19`,
      src: `${imgBaseUrl}/project_image.png`,
    },
    {
      text: `${intlPrefix}.step20`,
      src: `${imgBaseUrl}/project_image.png`,
    },
    {
      text: `${intlPrefix}.step21`,
      src: `${imgBaseUrl}/project_image.png`,
    },
    {
      text: `${intlPrefix}.step22`,
      src: `${imgBaseUrl}/project_image.png`,
    },
    {
      text: `${intlPrefix}.step23`,
      src: `${imgBaseUrl}/project_image.png`,
    },
    {
      text: `${intlPrefix}.step24`,
      src: `${imgBaseUrl}/project_image.png`,
    },
  ];

  return (
    <TutorialBuildInstructions
      onNextStep={onNextStep}
      onPrevStep={onPrevStep}
      currentStep={currentStep}
      totalSteps={totalSteps}
      images={images}
    />
  );
};


export default Tutorial4Step2;