import React from 'react';
import { alpha, Box, Typography } from '@mui/material';
import * as routes from '@educabot/educablocks-cosmos';
import { useIntl } from 'react-intl';
import { Parts } from '../parts';
import { AutoAsistProjects } from '../autoAsistProjects';
import { ImageKit } from './imageKit';


export function MovementTab() {
  const intl = useIntl();

  const electronicParts = [
    {
      name: intl.formatMessage({ id: 'creabots.landing.electronicParts.engineDC' }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/driving-kit/electronic_parts/motor_dc.png`,
    },
    {
      name: intl.formatMessage({ id: 'creabots.landing.electronicParts.wheels' }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/driving-kit/electronic_parts/ruedas.png`,
    },
    {
      name: intl.formatMessage({ id: 'creabots.landing.electronicParts.lineFollower' }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/driving-kit/electronic_parts/seguidor_de_linea.png`,
    },
    {
      name: intl.formatMessage({ id: 'creabots.landing.electronicParts.batteries' }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/driving-kit/electronic_parts/baterias_portabaterias.png`,
    },
  ];

  const plasticParts = [
    {
      name: intl.formatMessage({ id: 'creabots.landing.plasticParts.gradesL' }, { number: 90 }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/driving-kit/plastic_parts/90_grados_L.png`,
    },
    {
      name: intl.formatMessage({ id: 'creabots.landing.plasticParts.grades' }, { number: 90 }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/driving-kit/plastic_parts/90_grados.png`,
    },
    {
      name: intl.formatMessage({ id: 'creabots.landing.plasticParts.board' }, { number: 10 }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/driving-kit/plastic_parts/placa_10.png`,
    },
  ];

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: alpha(theme.palette.alternatives.a10, 0.1),
        padding: { xs: 1.5, md: 3 },
        minHeight: '100dvh',
      })}
    >
      <Box
        sx={{
          marginTop: { xs: '3rem', md: '6rem' },
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          width: '100%',
          maxWidth: '1080px',
          marginX: 'auto',
        }}
      >
        <Typography
          sx={(theme) => ({
            width: { xs: '100%', md: '90%' },
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.primary,
            fontSize: { xs: '2rem', md: '4rem' },
            letterSpacing: { xs: '-0.01rem', md: '-0.03rem' },
            lineHeight: { xs: '40px', md: '72px' },
          })}
        >
          {intl.formatMessage({ id: 'creabots.landing.kits.driving.description' })}
        </Typography>
        <Typography
          sx={(theme) => ({
            width: { xs: '100%', md: '90%' },
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightRegular,
            color: theme.palette.text.secondary,
            fontSize: { xs: '1rem', md: '1.75rem' },
            letterSpacing: { xs: 0, md: '-0.01rem' },
            lineHeight: { xs: '24px', md: '36px' },
          })}
        >
          {intl.formatMessage({ id: 'creabots.landing.kits.driving.description' })}
        </Typography>
      </Box>

      <ImageKit kit="driving-kit" />

      <AutoAsistProjects kit="conduccion" />
      <Parts kitName={intl.formatMessage({ id: 'creabots.landing.kits.driving.name' })} plasticParts={plasticParts} electronicParts={electronicParts} />
    </Box>
  );
}
