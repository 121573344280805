import React, { useState } from 'react';
import {
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { EmailOutlined } from '@mui/icons-material';
import { Link, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { resetPassword } from '@services/rest/creabots';
import { useIntl } from 'react-intl';
import styles from '../../containers/signIn.mod.scss';

const muiStyles = makeStyles(() => ({
  container: {
    marginTop: 50,
    gap: '3em',
  },
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(66, 179, 255, 0.5)',
      },
      color: '#000',
    },
    fontWeight: 500,
  },
  error: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(244, 92, 58, 0.5)',
      },
      '&:hover fieldset': {
        borderColor: 'rgba(244, 92, 58, 0.5)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(244, 92, 58, 0.5)',
      },
    },
    color: '#F45C3A',
  },
  errorText: {
    marginTop: '0.4em',
    paddingLeft: '1em',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    textAlign: 'left',
    color: '#F45C3A',
    fontFamily: 'DM Sans, sans-serif',
  },
}));

export const EmailStep = ({ setFields, fields, errors, setErrors }) => {
  const history = useHistory();
  const [requestError, setRequestError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const mui = muiStyles();
  const intl = useIntl();

  const handleChangeField = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  const handleNextStep = async (e) => {
    e.preventDefault();
    if (errors.email) return;
    if (!fields.email) {
      setErrors((prev) => ({
        ...prev,
        email: intl.formatMessage({
          id: 'creabots.validations.requiredEmail',
        }),
      }));
    }
    try {
      setIsLoading(true);
      await resetPassword({ email: fields.email.trim() });
      history.push('/kits/recuperar-contraseña/verificar');
    } catch (error) {
      setRequestError(
        error.response?.data?.message
          || intl.formatMessage({ id: 'common.errors.somethingWentWrong' }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <main className={styles.card}>
      <article className={styles.cardHeader}>
        <h1 className={styles.title}>
          {intl.formatMessage({ id: 'creabots.auth.recoverPassword' })}
        </h1>
        <p className={styles.description}>
          {intl.formatMessage({
            id: 'creabots.auth.recoverPasswordDescription',
          })}
        </p>
      </article>
      <form className={styles.cardContent} onSubmit={handleNextStep}>
        <Grid container className={clsx(styles.inputsContainer, mui.container)}>
          <Grid
            xs={12}
            item
            className={clsx('form-group bmd-form-group', styles.inputControl)}
          >
            <InputLabel
              shrink={false}
              htmlFor="email"
              className={clsx('bmd-label-static', styles.inputLabel)}
            >
              {intl.formatMessage({ id: 'creabots.labels.parentEmail' })}
            </InputLabel>
            <TextField
              autoFocus
              type="email"
              name="email"
              id="email"
              fullWidth
              variant="outlined"
              value={fields.email}
              error={!!errors.email || !!requestError}
              onChange={(e) => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (e.target.value.match(pattern) === null) {
                  setErrors((prev) => ({
                    ...prev,
                    email: intl.formatMessage({ id: 'creabots.validations.invalidEmail' }),
                  }));
                } else {
                  setRequestError('');
                  setErrors((prev) => ({ ...prev, email: '' }));
                }
                handleChangeField(e);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <EmailOutlined
                      className={clsx(
                        styles.inputIcon,
                        (errors.email || requestError) && styles.error,
                      )}
                    />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: {
                  fontWeight: 500,
                  color: errors.email || requestError ? '#F45C3A' : 'black',
                },
              }}
              className={clsx(
                mui.root,
                (errors.email || requestError) && mui.error,
              )}
            />
            {requestError && (
              <Typography className={mui.errorText}>{requestError}</Typography>
            )}
            {errors.email && (
              <Typography className={mui.errorText}>{errors.email}</Typography>
            )}
          </Grid>
        </Grid>
        <button
          type="submit"
          className={styles.btnPrimary}
          disabled={isLoading}
        >
          {intl.formatMessage({ id: 'common.continue' })}
        </button>
      </form>
      <article className={styles.cardFooter}>
        <h4 className={styles.haveAccount}>
          {intl.formatMessage({ id: 'common.backTo' })}{' '}
          <Link to="/kits/ingresar">
            {intl.formatMessage({ id: 'creabots.auth.signIn' })}
          </Link>
        </h4>
      </article>
    </main>
  );
};
