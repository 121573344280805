import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useIntl } from 'react-intl';

export function Parts({ kitName, electronicParts, plasticParts }) {
  const isMdSize = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const intl = useIntl();

  return (
    <Box
      mt={{ xs: '8rem', md: '10rem' }}
      display="flex"
      flexDirection="column"
      gap={{ xs: 4, md: 5 }}
      sx={{
        width: '100%',
        maxWidth: '1080px',
        marginX: 'auto',
      }}
    >
      <Typography
        sx={(theme) => ({
          fontFamily: theme.typography.fontFamily,
          fontWeight: theme.typography.fontWeightBold,
          color: theme.palette.text.secondary,
          fontSize: { xs: '1.25rem', md: '1.75rem' },
          letterSpacing: { xs: '-0.015rem', md: '-0.01rem' },
          lineHeight: { xs: '28px', md: '36px' },
        })}
      >
        {intl.formatMessage({ id: 'creabots.landing.whatIncludes' }, { kitName })}
      </Typography>
      <Box display="flex" flexDirection="column" gap={{ xs: 1, md: 3 }}>
        <Typography
          sx={(theme) => ({
            width: { xs: '100%', md: '90%' },
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.primary,
            fontSize: { xs: '1.5rem', md: '2.5rem' },
            letterSpacing: { xs: '-0.015rem', md: '-0.01rem' },
            lineHeight: { xs: '32px', md: '56px' },
          })}
        >
          {intl.formatMessage({ id: 'creabots.landing.electronicPieces' })}

        </Typography>
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            paddingX: { xs: 1.5, md: '3rem' },
            paddingY: { xs: 1.5, md: '4rem' },
            borderRadius: 2,
          })}
        >
          <Box
            sx={{
              display: 'grid',
              columnGap: { xs: 1, md: 4 },
              rowGap: 3,
              gridTemplateColumns: isMdSize ? 'repeat(auto-fit, minmax(300px, 1fr))' : 'repeat(2, 1fr)',
              placeItems: 'center',
            }}
          >
            {electronicParts.map((part) => (
              <Box
                key={part.name}
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  alignItems: 'center',
                  gap: '0.75rem',
                  width: { md: '300px' },
                }}
              >
                <img
                  src={part.imageUrl}
                  alt={part.name}
                  style={{
                    objectFit: 'contain',
                    height: 90,
                    width: 120,
                  }}
                />
                <Typography
                  sx={(theme) => ({
                    color: theme.palette.text.secondary,
                    fontWeight: theme.typography.fontWeightRegular,
                    fontFamily: theme.typography.fontFamily,
                    fontSize: { xs: '0.875rem', md: '1rem' },
                    textAlign: 'center',
                  })}
                >
                  {part.name}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap={{ xs: 1, md: 3 }}>
        <Typography
          sx={(theme) => ({
            width: { xs: '100%', md: '90%' },
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.primary,
            fontSize: { xs: '1.5rem', md: '2.5rem' },
            letterSpacing: { xs: '-0.015rem', md: '-0.01rem' },
            lineHeight: { xs: '32px', md: '56px' },
          })}
        >
          {intl.formatMessage({ id: 'creabots.landing.plasticPieces' })}
        </Typography>
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.common.white,
            paddingX: { xs: 1.5, md: '3rem' },
            paddingY: { xs: 1.5, md: '4rem' },
            borderRadius: 2,
            marginBottom: { xs: '2.5rem', md: '5rem' },
          })}
        >
          <Box
            sx={{
              display: 'grid',
              columnGap: { xs: 1, md: 4 },
              rowGap: 3,
              gridTemplateColumns: isMdSize ? 'repeat(auto-fit, minmax(300px, 1fr))' : 'repeat(2, 1fr)',
              placeItems: 'center',
            }}
          >
            {plasticParts.map((part) => (
              <Box
                key={part.name}
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  alignItems: 'center',
                  gap: '0.75rem',
                  width: { md: '300px' },
                }}
              >
                <img
                  src={part.imageUrl}
                  alt={part.name}
                  style={{
                    objectFit: 'contain',
                    height: 90,
                    width: 120,
                  }}
                />
                <Typography
                  sx={(theme) => ({
                    color: theme.palette.text.secondary,
                    fontWeight: theme.typography.fontWeightRegular,
                    fontFamily: theme.typography.fontFamily,
                    fontSize: { xs: '0.875rem', md: '1rem' },
                    textAlign: 'center',
                  })}
                >
                  {part.name}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
