import { projectsService } from '@services/rest/projects';
import { IDLE_STATUS, LOADING_STATUS, SUCCESS_STATUS, FAILURE_STATUS } from '@utils/redux';

const GET_ALL_PROJECTS = 'PROJECTS/GET_ALL';
const GET_ALL_PROJECTS_FAIL = 'PROJECTS/GET_ALL_FAIL';
const GET_ALL_PROJECTS_SUCCESS = 'PROJECTS/GET_ALL_SUCCESS';

const PUT_PROJECT = 'PROJECTS/PUT';
const PUT_PROJECT_FAIL = 'PROJECTS/PUT_FAIL';
const PUT_PROJECT_SUCCESS = 'PROJECTS/PUT_SUCCESS';

const GET_PROJECT = 'PROJECTS/GET';
const GET_PROJECT_FAIL = 'PROJECTS/GET_FAIL';
const GET_PROJECT_SUCCESS = 'PROJECTS/GET_SUCCESS';

const CREATE_PROJECT = 'PROJECTS/CREATE';
const CREATE_PROJECT_FAIL = 'PROJECTS/CREATE_FAIL';
const CREATE_PROJECT_SUCCESS = 'PROJECTS/CREATE_SUCCESS';

const UPDATE_PROJECT = 'PROJECTS/UPDATE';
const UPDATE_PROJECT_FAIL = 'PROJECTS/UPDATE_FAIL';
const UPDATE_PROJECT_SUCCESS = 'PROJECTS/UPDATE_SUCCESS';
const UPDATE_PROJECT_STATE = 'PROJECTS/UPDATE_STATE';

export const initialState = {
  projects: [],
  selectedProject: {},
  error: null,
  status: IDLE_STATUS,
};

export default function projectsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_PROJECTS: {
      return {
        ...state,
        status: LOADING_STATUS,
      };
    }
    case GET_ALL_PROJECTS_SUCCESS: {
      const { projects } = action.payload;
      return {
        ...state,
        status: SUCCESS_STATUS,
        projects,
      };
    }
    case GET_ALL_PROJECTS_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        status: FAILURE_STATUS,
        error,
      };
    }
    case PUT_PROJECT: {
      return {
        ...state,
        status: LOADING_STATUS,
      };
    }
    case PUT_PROJECT_SUCCESS: {
      return {
        ...state,
        status: SUCCESS_STATUS,
      };
    }
    case PUT_PROJECT_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        status: FAILURE_STATUS,
        error,
      };
    }
    case GET_PROJECT: {
      return {
        ...state,
        status: LOADING_STATUS,
      };
    }
    case GET_PROJECT_SUCCESS: {
      const { project } = action.payload;
      return {
        ...state,
        status: SUCCESS_STATUS,
        selectedProject: project,
      };
    }
    case GET_PROJECT_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        status: FAILURE_STATUS,
        error,
      };
    }
    case UPDATE_PROJECT_STATE: {
      const { project } = action.payload;
      return {
        ...state,
        selectedProject: project,
      };
    }
    case CREATE_PROJECT: {
      return {
        ...state,
        status: LOADING_STATUS,
      };
    }
    case CREATE_PROJECT_SUCCESS: {
      const { project } = action.payload;
      return {
        ...state,
        status: SUCCESS_STATUS,
        selectedProject: project,
      };
    }
    case CREATE_PROJECT_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        status: FAILURE_STATUS,
        error,
      };
    }
    case UPDATE_PROJECT: {
      return {
        ...state,
        status: LOADING_STATUS,
      };
    }
    case UPDATE_PROJECT_SUCCESS: {
      const { project } = action.payload;
      return {
        ...state,
        status: SUCCESS_STATUS,
        selectedProject: {
          ...state.selectedProject,
          id: project.id,
          userId: project.userId,
          title: project.title,
          desciption: project.desciption,
          imageUrl: project.imageUrl,
        },
      };
    }
    case UPDATE_PROJECT_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        status: FAILURE_STATUS,
        error,
      };
    }
    default:
      return state;
  }
}

// selectors
export const selectProjects = (state) => state.projects;

// actions
const getAllProjectsSuccess = (dispatch) => (projects) => dispatch({
  type: GET_ALL_PROJECTS_SUCCESS,
  payload: {
    projects,
  },
});

const getAllProjectsFail = (dispatch) => (error) => dispatch({
  type: GET_ALL_PROJECTS_FAIL,
  payload: {
    error,
  },
});

export const getAllProjects = () => (dispatch) => {
  dispatch({ type: GET_ALL_PROJECTS });
  projectsService
    .getAll()
    .then(getAllProjectsSuccess(dispatch))
    .catch(getAllProjectsFail(dispatch));
};

export const getAllProjectsBySeniority = (seniorityId) => (dispatch) => {
  dispatch({ type: GET_ALL_PROJECTS });
  projectsService
    .getAllBySeniorityId(seniorityId)
    .then(getAllProjectsSuccess(dispatch))
    .catch(getAllProjectsFail(dispatch));
};


const putProjectSuccess = (dispatch) => () => dispatch({
  type: PUT_PROJECT_SUCCESS,
  payload: {},
});

const putProjectFail = (dispatch) => (error) => dispatch({
  type: PUT_PROJECT_FAIL,
  payload: {
    error,
  },
});

export const putProject = (id, project) => (dispatch) => {
  dispatch({ type: PUT_PROJECT });
  projectsService
    .putProject(id, project)
    .then(putProjectSuccess(dispatch))
    .catch(putProjectFail(dispatch));
};


const getUserProjectSuccess = (dispatch) => (project) => dispatch({
  type: GET_PROJECT_SUCCESS,
  payload: { project },
});

const getUserProjectFail = (dispatch) => (error) => dispatch({
  type: GET_PROJECT_FAIL,
  payload: {
    error,
  },
});

export const getUserProject = (projectId) => (dispatch) => {
  dispatch({ type: GET_PROJECT });
  projectsService
    .getUserProject(projectId)
    .then(getUserProjectSuccess(dispatch))
    .catch(getUserProjectFail(dispatch));
};

export const updateProjectState = (dispatch) => (project) => {
  dispatch({
    type: UPDATE_PROJECT_STATE,
    payload: {
      project,
    },
  });
};

const createProjectSuccess = (dispatch) => (project) => dispatch({
  type: CREATE_PROJECT_SUCCESS,
  payload: { project },
});

const createProjectFail = (dispatch) => (error) => dispatch({
  type: CREATE_PROJECT_FAIL,
  payload: {
    error,
  },
});

export const createProject = (project) => (dispatch) => {
  dispatch({ type: CREATE_PROJECT });
  projectsService
    .createProject(project)
    .then(createProjectSuccess(dispatch))
    .catch(createProjectFail(dispatch));
};

const updateProjectSuccess = (dispatch) => (project) => dispatch({
  type: UPDATE_PROJECT_SUCCESS,
  payload: { project },
});

const updateProjectFail = (dispatch) => (error) => dispatch({
  type: UPDATE_PROJECT_FAIL,
  payload: {
    error,
  },
});

export const updateProject = (id, project) => (dispatch) => {
  dispatch({ type: UPDATE_PROJECT });
  projectsService
    .updateProject(id, project)
    .then(updateProjectSuccess(dispatch))
    .catch(updateProjectFail(dispatch));
};