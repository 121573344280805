import React, { Component } from 'react';
import Slider from '@mui/material/Slider';
import './rain.scss';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';

export class RainElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      water: 0,
      temperature: 0,
      sliderOpen: true,
      rotation: props.rotation || 0,
      id: props.id || '',
    }

    this.render = this.render.bind(this);
    this.openSlider = this.openSlider.bind(this);
    this.handleWaterChange = this.handleWaterChange.bind(this);
  }

  handleRotation = (e) => {
    const { rotation, id } = this.state;
    const newRotation = ((rotation - 90) < -270) ? 0 : rotation - 90;
    this.setState({ rotation: newRotation });
    const rotationEvent = new CustomEvent('component-rotation', { detail: { rotation: newRotation, component: id } });
    document.dispatchEvent(rotationEvent);
  }

  openSlider = (e) => {
    const { id, sliderOpen } = this.state;
    setTimeout(() => {
      if (!document.querySelector(`#${id}`).closest(".react-draggable").classList.contains("react-draggable-dragging")) {
        this.setState({ sliderOpen: !sliderOpen });
      }
    }, 100);
  }

  handleWaterChange = (event, newValue) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ water: newValue });
    // console.log('================', newValue);
  }

  render = () => {
    const { id, sliderOpen, water, rotation } = this.state;

    return (
      <div className="rain" id={id}>
        <div className={`slider${sliderOpen ? ' open' : ''}${(rotation === -90 || rotation === -270) ? ' rotate_90' : ''}`}>
          <div className="water">
            <div className="icon"><img src={`${process.env.IS_ELECTRON ? '.' : ''}/images/simulator/components/water.svg`} height="20" /></div>
            <Slider
              min={0}
              max={1023}
              step={10}
              marks
              defaultValue={0}
              className="bar"
              getAriaValueText={(v) => `${v}`}
              valueLabelDisplay="auto"
              onChange={this.handleWaterChange}
              aria-labelledby="input-slider"
            />
            <div className="value">{water}</div>
          </div>
        </div>

        <div className={`rotation${sliderOpen ? ' open' : ''}${(rotation === -90 || rotation === -270) ? ' rotate_90' : ''}`} onClick={this.handleRotation}><ScreenRotationIcon fontSize="inherit" htmlColor="white" /></div>

        <svg
          id="Capa_2"
          data-name="Capa 2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 208.38 56.99"
          width="31mm"
          height="9mm"
          transform={`translate(0 0) rotate(${rotation})`}
        >
          <g id="Capa_1-2" data-name="Capa 1" onMouseDown={this.openSlider}>
            <g id="LINE">
              <line class="cls-3" x1="5.5" y1=".17" x2="51.95" y2=".16" />
            </g>
            <g id="LINE-2" data-name="LINE">
              <line class="cls-3" x1="51.95" y1="56.87" x2="5.5" y2="56.87" />
            </g>
            <g id="LINE-3" data-name="LINE">
              <path class="cls-2" d="m51.95.16c.8,0,1.45.65,1.45,1.45v2.78c0,2.5,2.03,4.53,4.54,4.54,2.5,0,4.53-2.03,4.54-4.53,0,0,0,0,0,0V1.59c0-.78.63-1.42,1.42-1.42l144.36-.03v56.73H63.89c-.78,0-1.42-.64-1.42-1.42v-2.81c0-2.51-2.03-4.54-4.54-4.54-2.5,0-4.53,2.03-4.54,4.54v2.78s0,0,0,0c0,.8-.65,1.44-1.45,1.44H5.5c-2.35,0-4.25-1.9-4.25-4.25V4.42s0,0,0,0c0-2.35,1.91-4.25,4.25-4.25" />
            </g>
            <g id="LINE-4" data-name="LINE">
              <path class="cls-9" d="m29.29,6.3h-2.27s-1.13,0-1.13,0v-.57c.31,0,.57-.25.57-.57s-.25-.57-.57-.57v-.57s1.13,0,1.13,0h2.27s1.13,0,1.13,0v.57c-.07,0-.15.02-.22.04s-.13.07-.18.12-.1.12-.12.18-.03.14-.04.22c0,.07.02.15.04.22s.07.13.12.18.12.1.18.12.14.03.22.04v.57s-1.13,0-1.13,0Z" />
            </g>
            <g id="LINE-5" data-name="LINE">
              <line class="cls-3" x1="72.8" y1="30.87" x2="74.07" y2="30.87" />
            </g>
            <g id="LINE-6" data-name="LINE">
              <line class="cls-3" x1="72.8" y1="31.03" x2="74.07" y2="31.03" />
            </g>
            <g id="LINE-7" data-name="LINE">
              <line class="cls-3" x1="74.07" y1="30.87" x2="74.07" y2="31.03" />
            </g>
            <g id="LINE-8" data-name="LINE">
              <line class="cls-3" x1="72.8" y1="30.87" x2="72.8" y2="31.03" />
            </g>
            <polygon class="cls-7" points="95.53 .03 95.53 .03 95.54 .03 95.56 .04 95.58 .07 95.59 .1 95.59 .12 95.59 56.98 80.99 56.98 80.99 .03 95.53 .03" />
            <g>
              <path class="cls-7" d="m77.55,7.21h-1.56v2.48h-.51l-3.67-2.38v-.78h3.57v-.75h.61v.75h1.56v.68Zm-2.17,0h-1.92c-.3,0-.6,0-.9-.02v.02c.33.16.58.3.85.44l1.95,1.31h.02v-1.75Z" />
              <path class="cls-7" d="m77.55,5.23h-.48l-.59-.57c-1.4-1.36-2.14-1.98-3-1.99-.58,0-1.12.26-1.12,1.06,0,.48.27.89.49,1.13l-.55.23c-.34-.37-.58-.89-.58-1.51,0-1.15.85-1.63,1.67-1.63,1.06,0,1.92.71,3.08,1.84l.42.43h.02V1.83h.64v3.41Z" />
            </g>
            <polygon class="cls-7" points="52.22 37.35 63.66 37.35 68 41.37 68 56.87 61.36 56.87 61.36 51.79 61.36 51.47 61.23 50.83 60.96 50.23 60.57 49.69 60.32 49.46 60.09 49.24 59.54 48.91 58.93 48.67 58.28 48.55 57.95 48.55 57.9 48.55 57.56 48.55 56.89 48.68 56.26 48.92 55.69 49.28 55.45 49.5 55.21 49.72 54.84 50.24 54.58 50.81 54.45 51.42 54.45 51.74 54.45 56.87 47.88 56.87 47.88 41.37 52.22 37.35" />
            <polygon class="cls-7" points="54.51 0 54.51 5.1 54.51 5.43 54.65 6.07 54.92 6.67 55.31 7.22 55.56 7.45 55.79 7.67 56.34 8.01 56.95 8.24 57.59 8.36 57.92 8.36 57.98 8.36 58.32 8.36 58.99 8.23 59.62 7.99 60.19 7.63 60.43 7.41 60.66 7.19 61.04 6.67 61.29 6.09 61.43 5.47 61.43 5.16 61.43 0 68 0 68 15.59 63.66 19.63 52.22 19.63 47.88 15.59 47.88 0 54.51 0" />
            <g>
              <line class="cls-5" x1="29.62" y1="55.26" x2="29.62" y2="54.36" />
              <line class="cls-5" x1="29.69" y1="55.26" x2="29.69" y2="54.36" />
              <line class="cls-5" x1="29.76" y1="55.26" x2="29.76" y2="54.36" />
              <line class="cls-5" x1="29.83" y1="55.26" x2="29.83" y2="54.36" />
              <line class="cls-5" x1="29.91" y1="55.26" x2="29.91" y2="54.36" />
              <line class="cls-5" x1="29.98" y1="55.26" x2="29.98" y2="54.36" />
              <line class="cls-5" x1="30.05" y1="55.26" x2="30.05" y2="54.36" />
              <line class="cls-5" x1="30.12" y1="55.26" x2="30.12" y2="54.36" />
              <line class="cls-5" x1="30.19" y1="55.26" x2="30.19" y2="54.36" />
              <line class="cls-5" x1="30.27" y1="55.26" x2="30.27" y2="54.36" />
              <line class="cls-5" x1="30.34" y1="55.26" x2="30.34" y2="54.36" />
              <line class="cls-5" x1="30.41" y1="55.26" x2="30.41" y2="53.53" />
              <line class="cls-5" x1="30.48" y1="54.43" x2="30.48" y2="53.53" />
              <line class="cls-5" x1="30.63" y1="54.43" x2="30.63" y2="53.53" />
              <line class="cls-5" x1="30.7" y1="54.43" x2="30.7" y2="53.53" />
              <line class="cls-5" x1="30.77" y1="54.43" x2="30.77" y2="53.53" />
              <line class="cls-5" x1="30.84" y1="54.43" x2="30.84" y2="53.53" />
              <line class="cls-5" x1="30.91" y1="54.43" x2="30.91" y2="53.53" />
              <line class="cls-5" x1="30.99" y1="54.43" x2="30.99" y2="53.53" />
              <line class="cls-5" x1="31.06" y1="54.43" x2="31.06" y2="53.53" />
              <line class="cls-5" x1="31.13" y1="54.43" x2="31.13" y2="53.53" />
              <line class="cls-5" x1="31.2" y1="54.43" x2="31.2" y2="53.53" />
              <line class="cls-5" x1="31.27" y1="54.43" x2="31.27" y2="53.53" />
              <line class="cls-5" x1="30.55" y1="54.43" x2="30.55" y2="53.53" />
              <line class="cls-5" x1="27.82" y1="55.26" x2="27.82" y2="54.36" />
              <line class="cls-5" x1="27.89" y1="55.26" x2="27.89" y2="54.36" />
              <line class="cls-5" x1="27.96" y1="55.26" x2="27.96" y2="54.36" />
              <line class="cls-5" x1="28.03" y1="55.26" x2="28.03" y2="54.36" />
              <line class="cls-5" x1="28.11" y1="55.26" x2="28.11" y2="54.36" />
              <line class="cls-5" x1="28.18" y1="55.26" x2="28.18" y2="54.36" />
              <line class="cls-5" x1="28.25" y1="55.26" x2="28.25" y2="54.36" />
              <line class="cls-5" x1="28.32" y1="55.26" x2="28.32" y2="54.36" />
              <line class="cls-5" x1="28.39" y1="55.26" x2="28.39" y2="54.36" />
              <line class="cls-5" x1="28.47" y1="55.26" x2="28.47" y2="54.36" />
              <line class="cls-5" x1="28.54" y1="55.26" x2="28.54" y2="54.36" />
              <line class="cls-5" x1="28.61" y1="55.26" x2="28.61" y2="54.36" />
              <line class="cls-5" x1="28.68" y1="55.26" x2="28.68" y2="54.36" />
              <line class="cls-5" x1="28.75" y1="55.26" x2="28.75" y2="54.36" />
              <line class="cls-5" x1="28.83" y1="55.26" x2="28.83" y2="54.36" />
              <line class="cls-5" x1="28.9" y1="55.26" x2="28.9" y2="54.36" />
              <line class="cls-5" x1="28.97" y1="55.26" x2="28.97" y2="54.36" />
              <line class="cls-5" x1="29.04" y1="55.26" x2="29.04" y2="54.36" />
              <line class="cls-5" x1="29.11" y1="55.26" x2="29.11" y2="54.36" />
              <line class="cls-5" x1="29.19" y1="55.26" x2="29.19" y2="54.36" />
              <line class="cls-5" x1="29.26" y1="55.26" x2="29.26" y2="54.36" />
              <line class="cls-5" x1="29.33" y1="55.26" x2="29.33" y2="54.36" />
              <line class="cls-5" x1="29.4" y1="55.26" x2="29.4" y2="54.36" />
              <line class="cls-5" x1="29.47" y1="55.26" x2="29.47" y2="54.36" />
              <line class="cls-5" x1="29.55" y1="55.26" x2="29.55" y2="54.36" />
              <line class="cls-5" x1="29.62" y1="49.23" x2="29.62" y2="48.33" />
              <line class="cls-5" x1="30.41" y1="50.06" x2="30.41" y2="48.33" />
              <line class="cls-5" x1="30.48" y1="50.06" x2="30.48" y2="49.16" />
              <line class="cls-5" x1="29.69" y1="49.23" x2="29.69" y2="48.33" />
              <line class="cls-5" x1="29.76" y1="49.23" x2="29.76" y2="48.33" />
              <line class="cls-5" x1="29.83" y1="49.23" x2="29.83" y2="48.33" />
              <line class="cls-5" x1="29.91" y1="49.23" x2="29.91" y2="48.33" />
              <line class="cls-5" x1="29.98" y1="49.23" x2="29.98" y2="48.33" />
              <line class="cls-5" x1="30.05" y1="49.23" x2="30.05" y2="48.33" />
              <line class="cls-5" x1="30.12" y1="49.23" x2="30.12" y2="48.33" />
              <line class="cls-5" x1="30.19" y1="49.23" x2="30.19" y2="48.33" />
              <line class="cls-5" x1="30.27" y1="49.23" x2="30.27" y2="48.33" />
              <line class="cls-5" x1="30.34" y1="49.23" x2="30.34" y2="48.33" />
              <line class="cls-5" x1="30.63" y1="50.06" x2="30.63" y2="49.16" />
              <line class="cls-5" x1="30.7" y1="50.06" x2="30.7" y2="49.16" />
              <line class="cls-5" x1="30.77" y1="50.06" x2="30.77" y2="49.16" />
              <line class="cls-5" x1="30.84" y1="50.06" x2="30.84" y2="49.16" />
              <line class="cls-5" x1="30.91" y1="50.06" x2="30.91" y2="49.16" />
              <line class="cls-5" x1="30.99" y1="50.06" x2="30.99" y2="49.16" />
              <line class="cls-5" x1="31.06" y1="50.06" x2="31.06" y2="49.16" />
              <line class="cls-5" x1="31.13" y1="50.06" x2="31.13" y2="49.16" />
              <line class="cls-5" x1="31.2" y1="50.06" x2="31.2" y2="49.16" />
              <line class="cls-5" x1="31.27" y1="50.06" x2="31.27" y2="49.16" />
              <line class="cls-5" x1="30.55" y1="50.06" x2="30.55" y2="49.16" />
              <line class="cls-5" x1="27.82" y1="49.23" x2="27.82" y2="48.33" />
              <line class="cls-5" x1="27.89" y1="49.23" x2="27.89" y2="48.33" />
              <line class="cls-5" x1="27.96" y1="49.23" x2="27.96" y2="48.33" />
              <line class="cls-5" x1="28.03" y1="49.23" x2="28.03" y2="48.33" />
              <line class="cls-5" x1="28.11" y1="49.23" x2="28.11" y2="48.33" />
              <line class="cls-5" x1="28.18" y1="49.23" x2="28.18" y2="48.33" />
              <line class="cls-5" x1="28.25" y1="49.23" x2="28.25" y2="48.33" />
              <line class="cls-5" x1="28.32" y1="49.23" x2="28.32" y2="48.33" />
              <line class="cls-5" x1="28.39" y1="49.23" x2="28.39" y2="48.33" />
              <line class="cls-5" x1="28.47" y1="49.23" x2="28.47" y2="48.33" />
              <line class="cls-5" x1="28.54" y1="49.23" x2="28.54" y2="48.33" />
              <line class="cls-5" x1="28.61" y1="49.23" x2="28.61" y2="48.33" />
              <line class="cls-5" x1="28.68" y1="49.23" x2="28.68" y2="48.33" />
              <line class="cls-5" x1="28.75" y1="49.23" x2="28.75" y2="48.33" />
              <line class="cls-5" x1="28.83" y1="49.23" x2="28.83" y2="48.33" />
              <line class="cls-5" x1="28.9" y1="49.23" x2="28.9" y2="48.33" />
              <line class="cls-5" x1="28.97" y1="49.23" x2="28.97" y2="48.33" />
              <line class="cls-5" x1="29.04" y1="49.23" x2="29.04" y2="48.33" />
              <line class="cls-5" x1="29.11" y1="49.23" x2="29.11" y2="48.33" />
              <line class="cls-5" x1="29.19" y1="49.23" x2="29.19" y2="48.33" />
              <line class="cls-5" x1="29.26" y1="49.23" x2="29.26" y2="48.33" />
              <line class="cls-5" x1="29.33" y1="49.23" x2="29.33" y2="48.33" />
              <line class="cls-5" x1="29.4" y1="49.23" x2="29.4" y2="48.33" />
              <line class="cls-5" x1="29.47" y1="49.23" x2="29.47" y2="48.33" />
              <line class="cls-5" x1="29.55" y1="49.23" x2="29.55" y2="48.33" />
              <line class="cls-5" x1="31.35" y1="54.43" x2="31.35" y2="49.16" />
              <line class="cls-5" x1="27.53" y1="55.26" x2="27.53" y2="48.33" />
              <line class="cls-5" x1="27.6" y1="55.26" x2="27.6" y2="48.33" />
              <line class="cls-5" x1="27.67" y1="55.26" x2="27.67" y2="48.33" />
              <line class="cls-5" x1="27.75" y1="55.26" x2="27.75" y2="48.33" />
              <line class="cls-5" x1="31.42" y1="53.53" x2="31.42" y2="50.06" />
              <line class="cls-5" x1="31.49" y1="53.53" x2="31.49" y2="50.06" />
              <line class="cls-5" x1="31.56" y1="53.53" x2="31.56" y2="50.06" />
              <line class="cls-5" x1="31.63" y1="53.53" x2="31.63" y2="50.06" />
              <line class="cls-5" x1="31.71" y1="53.53" x2="31.71" y2="50.06" />
              <line class="cls-5" x1="31.78" y1="53.53" x2="31.78" y2="50.06" />
              <line class="cls-5" x1="31.85" y1="53.53" x2="31.85" y2="50.06" />
              <line class="cls-5" x1="31.92" y1="53.53" x2="31.92" y2="50.06" />
              <line class="cls-5" x1="31.99" y1="53.53" x2="31.99" y2="50.06" />
              <line class="cls-5" x1="32.07" y1="53.53" x2="32.07" y2="50.06" />
              <line class="cls-5" x1="32.14" y1="53.53" x2="32.14" y2="50.06" />
              <line class="cls-5" x1="32.21" y1="53.53" x2="32.21" y2="50.06" />
              <line class="cls-5" x1="26.59" y1="54.43" x2="26.59" y2="49.16" />
              <line class="cls-5" x1="26.67" y1="54.43" x2="26.67" y2="49.16" />
              <line class="cls-5" x1="26.74" y1="54.43" x2="26.74" y2="49.16" />
              <line class="cls-5" x1="27.1" y1="55.26" x2="27.1" y2="48.33" />
              <line class="cls-5" x1="26.81" y1="55.26" x2="26.81" y2="48.33" />
              <line class="cls-5" x1="26.88" y1="55.26" x2="26.88" y2="48.33" />
              <line class="cls-5" x1="26.95" y1="55.26" x2="26.95" y2="48.33" />
              <line class="cls-5" x1="27.03" y1="55.26" x2="27.03" y2="48.33" />
              <line class="cls-5" x1="27.17" y1="55.26" x2="27.17" y2="48.33" />
              <line class="cls-5" x1="27.24" y1="55.26" x2="27.24" y2="48.33" />
              <line class="cls-5" x1="27.31" y1="55.26" x2="27.31" y2="48.33" />
              <line class="cls-5" x1="27.39" y1="55.26" x2="27.39" y2="48.33" />
              <line class="cls-5" x1="23.86" y1="52.7" x2="23.86" y2="50.89" />
              <line class="cls-5" x1="25.01" y1="54.43" x2="25.01" y2="49.16" />
              <line class="cls-5" x1="25.08" y1="54.43" x2="25.08" y2="49.16" />
              <line class="cls-5" x1="25.15" y1="54.43" x2="25.15" y2="49.16" />
              <line class="cls-5" x1="25.23" y1="54.43" x2="25.23" y2="49.16" />
              <line class="cls-5" x1="25.3" y1="54.43" x2="25.3" y2="49.16" />
              <line class="cls-5" x1="25.37" y1="54.43" x2="25.37" y2="49.16" />
              <line class="cls-5" x1="25.44" y1="54.43" x2="25.44" y2="49.16" />
              <line class="cls-5" x1="25.51" y1="54.43" x2="25.51" y2="49.16" />
              <line class="cls-5" x1="25.59" y1="54.43" x2="25.59" y2="49.16" />
              <line class="cls-5" x1="25.66" y1="54.43" x2="25.66" y2="49.16" />
              <line class="cls-5" x1="25.73" y1="54.43" x2="25.73" y2="49.16" />
              <line class="cls-5" x1="25.8" y1="54.43" x2="25.8" y2="49.16" />
              <line class="cls-5" x1="25.87" y1="54.43" x2="25.87" y2="49.16" />
              <line class="cls-5" x1="25.95" y1="54.43" x2="25.95" y2="49.16" />
              <line class="cls-5" x1="26.02" y1="54.43" x2="26.02" y2="49.16" />
              <line class="cls-5" x1="26.09" y1="54.43" x2="26.09" y2="49.16" />
              <line class="cls-5" x1="26.16" y1="54.43" x2="26.16" y2="49.16" />
              <line class="cls-5" x1="26.23" y1="54.43" x2="26.23" y2="49.16" />
              <line class="cls-5" x1="26.31" y1="54.43" x2="26.31" y2="49.16" />
              <line class="cls-5" x1="26.38" y1="54.43" x2="26.38" y2="49.16" />
              <line class="cls-5" x1="26.45" y1="54.43" x2="26.45" y2="49.16" />
              <line class="cls-5" x1="23.93" y1="52.7" x2="23.93" y2="50.89" />
              <line class="cls-5" x1="24" y1="52.7" x2="24" y2="50.89" />
              <line class="cls-5" x1="24.07" y1="52.7" x2="24.07" y2="50.89" />
              <line class="cls-5" x1="24.15" y1="53.53" x2="24.15" y2="50.06" />
              <line class="cls-5" x1="24.22" y1="53.53" x2="24.22" y2="50.06" />
              <line class="cls-5" x1="24.29" y1="53.53" x2="24.29" y2="50.06" />
              <line class="cls-5" x1="24.36" y1="53.53" x2="24.36" y2="50.06" />
              <line class="cls-5" x1="24.43" y1="53.53" x2="24.43" y2="50.06" />
              <line class="cls-5" x1="24.51" y1="53.53" x2="24.51" y2="50.06" />
              <line class="cls-5" x1="24.58" y1="53.53" x2="24.58" y2="50.06" />
              <line class="cls-5" x1="24.65" y1="53.53" x2="24.65" y2="50.06" />
              <line class="cls-5" x1="24.72" y1="53.53" x2="24.72" y2="50.06" />
              <line class="cls-5" x1="24.87" y1="53.53" x2="24.87" y2="50.06" />
              <line class="cls-5" x1="24.94" y1="53.53" x2="24.94" y2="50.06" />
              <line class="cls-5" x1="24.79" y1="53.53" x2="24.79" y2="50.06" />
              <line class="cls-5" x1="22.35" y1="52.7" x2="22.35" y2="50.89" />
              <line class="cls-5" x1="22.42" y1="52.7" x2="22.42" y2="50.89" />
              <line class="cls-5" x1="22.49" y1="52.7" x2="22.49" y2="50.89" />
              <line class="cls-5" x1="22.56" y1="52.7" x2="22.56" y2="50.89" />
              <line class="cls-5" x1="22.63" y1="52.7" x2="22.63" y2="50.89" />
              <line class="cls-5" x1="22.71" y1="52.7" x2="22.71" y2="50.89" />
              <line class="cls-5" x1="22.78" y1="52.7" x2="22.78" y2="50.89" />
              <line class="cls-5" x1="22.85" y1="52.7" x2="22.85" y2="50.89" />
              <line class="cls-5" x1="22.92" y1="52.7" x2="22.92" y2="50.89" />
              <line class="cls-5" x1="22.99" y1="52.7" x2="22.99" y2="50.89" />
              <line class="cls-5" x1="23.07" y1="52.7" x2="23.07" y2="50.89" />
              <line class="cls-5" x1="23.14" y1="52.7" x2="23.14" y2="50.89" />
              <line class="cls-5" x1="23.21" y1="52.7" x2="23.21" y2="50.89" />
              <line class="cls-5" x1="23.28" y1="52.7" x2="23.28" y2="50.89" />
              <line class="cls-5" x1="23.35" y1="52.7" x2="23.35" y2="50.89" />
              <line class="cls-5" x1="23.43" y1="52.7" x2="23.43" y2="50.89" />
              <line class="cls-5" x1="23.5" y1="52.7" x2="23.5" y2="50.89" />
              <line class="cls-5" x1="23.57" y1="52.7" x2="23.57" y2="50.89" />
              <line class="cls-5" x1="23.64" y1="52.7" x2="23.64" y2="50.89" />
              <line class="cls-5" x1="23.71" y1="52.7" x2="23.71" y2="50.89" />
              <line class="cls-5" x1="23.79" y1="52.7" x2="23.79" y2="50.89" />
              <line class="cls-5" x1="26.52" y1="54.43" x2="26.52" y2="49.16" />
              <line class="cls-5" x1="27.46" y1="55.26" x2="27.46" y2="48.33" />
            </g>
            <g id="CIRCLE">
              <path class="cls-4" d="m58.02,34.19c-3.13,0-5.67-2.54-5.67-5.67s2.54-5.67,5.67-5.67,5.67,2.54,5.67,5.67-2.54,5.67-5.67,5.67h0Z" />
            </g>
            <rect class="cls-6" x="5.47" y="7.27" width="34.58" height="42.52" transform="translate(-5.77 51.29) rotate(-90)" />
            <rect class="cls-12" x="27.02" y="3.83" width="2.27" height="2.67" transform="translate(22.99 33.32) rotate(-90)" />
            <rect class="cls-9" x="72.47" y="34.58" width="2.27" height="4.54" transform="translate(36.76 110.45) rotate(-90)" />
            <g id="LINE-9" data-name="LINE">
              <rect class="cls-11" x="72.19" y="35.71" width="2.83" height="2.27" />
            </g>
            <rect class="cls-9" x="72.64" y="17.76" width="2.27" height="4.54" transform="translate(53.75 93.81) rotate(-90)" />
            <g id="LINE-10" data-name="LINE">
              <rect class="cls-11" x="72.36" y="18.9" width="2.83" height="2.27" />
            </g>
            <rect class="cls-1" x="71.45" y="24.77" width="3.97" height="8.23" transform="translate(44.55 102.33) rotate(-90)" />
            <path class="cls-13" d="m207.88,20.88c-.43.03-.85.09-1.28.09-36.5,0-73,0-109.5,0-.76,0-1.56.14-1.51-1.07.03-.76.28-1.16,1.08-1.09.07,0,.14,0,.21,0,36.93,0,73.86,0,110.79,0,.23.05.33.25.46.41v1.29c-.14.08-.05.31-.25.35Z" />
            <path class="cls-13" d="m208.12,43.33c-.22.45-.55.72-1.09.63-.17-.03-.36,0-.54,0-36.44,0-72.88,0-109.33,0-.62,0-1.44.29-1.57-.78-.13-1.1.12-1.36,1.41-1.36,25.66,0,51.31,0,76.97,0,10.86,0,21.71,0,32.57,0,.53,0,1.08-.06,1.56.23v1.29Z" />
            <path class="cls-13" d="m208.12,37.74c-.27.37-.64.51-1.09.46-.14-.02-.29,0-.43,0-36.51,0-73.01,0-109.52,0-.59,0-1.36.28-1.48-.75-.12-1.08.14-1.39,1.3-1.39,4.41,0,8.81,0,13.22,0,32.14,0,64.27,0,96.41,0,.57,0,1.16-.09,1.6.41v1.29Z" />
            <path class="cls-13" d="m207.69,3.76c-36.93,0-73.87,0-110.8.02-.79,0-1.31-.1-1.3-1.08,0-.97.5-1.09,1.3-1.09,36.93.01,73.87.01,110.8.02.22.06.32.26.45.42v1.29c-.13.16-.23.35-.44.43Z" />
            <path class="cls-14" d="m207.9,15.13c-.46.04-.93.1-1.39.1-36.44,0-72.88,0-109.33,0-.55,0-1.28.31-1.55-.55-.32-1.02.09-1.57,1.17-1.6.25,0,.5,0,.75,0,36.19,0,72.38,0,108.57,0,.43,0,.86-.03,1.29-.04.26.08.56.1.7.4v1.51c-.05.09-.13.15-.22.18Z" />
            <path class="cls-14" d="m208.12,54.94c-.27.26-.52.56-.97.47-.21-.04-.43,0-.64,0-36.44,0-72.87,0-109.31-.01-.53,0-1.2.29-1.53-.42-.43-.93.07-1.69,1.11-1.72.14,0,.29,0,.43,0,36.44,0,72.87,0,109.31,0,.57,0,1.15-.07,1.61.4v1.29Z" />
            <path class="cls-14" d="m207.62,9.53c-36.83,0-73.67,0-110.5,0-1.39,0-1.65-.26-1.52-1.39.06-.53.31-.77.83-.76.29,0,.57,0,.86,0,36.37,0,72.73,0,109.1,0,.5,0,1,.06,1.5.09.17.07.09.29.23.38v1.08c-.18.19-.09.61-.5.6Z" />
            <path class="cls-14" d="m207.64,26.74c-36.91,0-73.82,0-110.72,0-1.16,0-1.37-.23-1.32-1.27.03-.63.3-.92.93-.88.29.02.57,0,.86,0,36.3,0,72.6,0,108.9,0,.54,0,1.07.05,1.61.08.18.06.1.29.24.37v1.08c-.18.19-.09.59-.48.61Z" />
            <path class="cls-14" d="m208.12,49.13c-.25.35-.54.64-1.03.56-.11-.02-.21,0-.32,0-36.59,0-73.18,0-109.76,0-1.28,0-1.55-.29-1.41-1.4.06-.47.27-.72.74-.75.32-.01.64-.01.97-.01,36.37,0,72.75,0,109.12,0,.61,0,1.29-.17,1.69.52v1.08Z" />
            <path class="cls-10" d="m95.88,32.38c-.41-.66-.42-1.31,0-1.97.38-.16.78-.08,1.17-.08,36.56,0,73.13,0,109.69,0,.47,0,1.02-.23,1.38.31v1.51c-.12.19-.29.31-.52.32-.32,0-.64,0-.97,0-36.49,0-72.98,0-109.48,0-.42,0-.86.09-1.27-.09Z" />
            <g>
              <path class="cls-8" d="m90.35,37.44h-4.2v-.47h3.8v-1.82h.4v2.29Z" />
              <path class="cls-8" d="m90.35,34.5h-4.2v-.47h3.8v-1.82h.4v2.29Z" />
              <path class="cls-8" d="m90.43,30.32c0,.27-.06.51-.17.73-.12.22-.29.39-.53.52-.24.13-.54.2-.92.2h-2.65v-.47h2.66c.27,0,.49-.04.67-.12s.3-.2.38-.35c.08-.15.12-.32.12-.51s-.04-.37-.12-.51c-.08-.14-.21-.26-.38-.34s-.39-.12-.67-.12h-2.66v-.47h2.65c.37,0,.68.07.92.2.24.13.42.31.53.52.12.22.17.47.17.74Z" />
              <path class="cls-8" d="m90.35,26.9l-4.2,1.47v-.51l3.67-1.23-3.67-1.25v-.5l4.2,1.47v.55Z" />
              <path class="cls-8" d="m90.35,24.33h-4.2v-.47h4.2v.47Z" />
              <path class="cls-8" d="m90.35,23.28l-4.2-1.44v-.5l4.2-1.43v.5l-3.57,1.19,3.57,1.19v.49Zm-1.08-.66l-.41-.13v-1.77l.41-.13v2.04Z" />
            </g>
          </g>
        </svg>
      </div>
    );
  }
}
