/* eslint prefer-promise-reject-errors: 0 */
import axios from 'axios';
import * as routes from '@educabot/educablocks-cosmos';
import io from 'socket.io-client';
import { getAgentPorts, getNewMessage, getNewState, getPorts } from '../actions/bloquesAction';
import { range } from './CoolFunctions';

class SocketAgent {
  constructor(store, socketElectronConnection, httpElectronConnection, serialMonitorMessages, intl) {
    if (window.socketAgent) return window.socketAgent;
    this.state = {
      socketElectronConnection,
      httpElectronConnection,
      store,
      socket: null,
      wsPath: '',
      httpPath: '',
      portList: [],
      monitor: '',
      intervalConnectAgent: null,
      intervalListPorts: null,
      selectedBoard: '',
      soVersion: '',
      agentConnectionTry: 0,
    };
    this.constOfSingleton = new Date();
    this.IsJsonString = this.IsJsonString.bind(this);
    this.listPorts = this.listPorts.bind(this);
    this.uploadToPort = this.uploadToPort.bind(this);
    this.uploadRemoteSession = this.uploadRemoteSession.bind(this);
    this.openMonitor = this.openMonitor.bind(this);
    this.closeMonitor = this.closeMonitor.bind(this);
    this.sendMonitor = this.sendMonitor.bind(this);
    // this.changeBaudios = this.changeBaudios.bind(this);
    this.showConsoleMessage = this.showConsoleMessage.bind(this);
    this.findMicrobitDrive = this.findMicrobitDrive.bind(this);
    this.changeBoardSelected = this.changeBoardSelected.bind(this);
    this.getSOVersion = this.getSOVersion.bind(this);
    this.getPort = this.getPort.bind(this);
    this.startCheckingAgent = this.startCheckingAgent.bind(this);
    this.stopCheckingAgent = this.stopCheckingAgent.bind(this);
    this.connectSocket = this.connectSocket.bind(this);
    this.disconnectSocket = this.disconnectSocket.bind(this);

    this.startCheckingAgent();
    this.serialMonitorMessages = serialMonitorMessages;
    this.intl = intl;

    window.socketAgent = this;
  }

  connectSocket() {
    const { socket, wsPath, store, socketElectronConnection } = this.state;

    store.dispatch(getNewState('#### PORT CONNECTED'));
    if (socket) {
      if (process.env.IS_ELECTRON) socket.emit('setpath', wsPath);
      // socket.emit('command', 'downloadtool windows-drivers latest arduino keep');
      socket.emit('command', 'downloadtool bossac 1.7.0 arduino keep');
      socket.emit('command', 'downloadtool fwupdater latest arduino keep');

      if (window.navigator.userAgentData) {
        window.navigator.userAgentData.getHighEntropyValues(
          ['architecture', 'model', 'bitness', 'platformVersion', 'fullVersionList'],
        ).then(ua => {
          // console.log('===============', ua);
          if ((ua.platform === 'Mac OS' || ua.platform === 'macOS') && ua.platformVersion < '10.15.0') {
            socket.emit('command', 'downloadtool avrdude 6.3.0-arduino9 arduino keep');
          } else {
            socket.emit('command', 'downloadtool avrdude 6.3.0-arduino17 arduino keep');
          }
        });
      } else {
        socket.emit('command', 'downloadtool avrdude 6.3.0-arduino17 arduino keep');
      }

      // console.log('Socket connected -> ', (process.env.IS_ELECTRON) ? socketElectronConnection : wsPath);
    }
  }

  disconnectSocket() {
    const { store } = this.state;

    this.listPorts();
    this.stopCheckingAgent();
    if (this.state.socket) {
      this.state.socket.close();
    }
    this.state.socket = null;
    store.dispatch(getNewState('#### PORT NOT CONNECTED'));
    this.startCheckingAgent();
  }

  componentDidMount() {
    const { socket } = this.state;

    socket.on('connect', () => {
      // console.log('===================CONNECT');
      this.connectSocket();
    });
    socket.on('disconnect', () => {
      // console.log('===================DISCONNECT');
      this.disconnectSocket();
    });
    socket.on('message', (result) => {
      // console.log('===================message', result);
      this.showConsoleMessage(result);
    });
    socket.on('error', (err) => {
      // console.log('===================ERROR', err);
      this.disconnectSocket();
    });
    // socket.on('connect_error', (err) => {
    //   console.log('===================CONNECT ERROR', err);
    //   this.disconnectSocket();
    // });
  }

  stopCheckingAgent() {
    clearInterval(this.state.intervalConnectAgent);
    this.state.intervalConnectAgent = null;
    this.state.agentConnectionTry = 0;
  }

  startCheckingAgent() {
    const { store, socketElectronConnection } = this.state;

    if (!this.state.intervalConnectAgent) {
      this.state.intervalConnectAgent = setInterval(() => {
        this.state.agentConnectionTry += 1;
        this.getPort().then((wsPath) => {
          // if (wsPath != null && wsPath.indexOf('ws') !== -1) {
          if (wsPath != null) {
            this.state.httpPath = (wsPath.indexOf('wss') !== -1) ? wsPath.replace('wss://', 'https://') : wsPath.replace('ws://', 'http://');
            this.state.wsPath = wsPath;
            this.state.socket = (process.env.IS_ELECTRON) ? io(socketElectronConnection, {}) : io(wsPath, {});
            this.componentDidMount();
            this.stopCheckingAgent();
          }
          // console.log('===================get agent port', this.state.wsPath, this.state.socket);
        }).catch((e) => { throw e; });
        // if (this.state.agentConnectionTry > 2) {
        //   this.stopCheckingAgent();
        // }
      }, 2000);
    }
  }

  getSOVersion() {
    return new Promise((resolve, reject) => {
      axios.get(`${routes.usersUri}/osversion`, {
        withCredentials: false,
        // headers: {
        //   'Accept-CH': 'Sec-CH-UA-Platform-Version, ch-ua-full-version',
        //   'Critical-CH': 'Sec-CH-UA-Platform-Version, ch-ua-full-version',
        //   'Vary': 'Sec-CH-UA-Platform-Version, ch-ua-full-version',
        //   'Permissions-Policy': 'ch-ua=(*), ch-ua-full-version=(*), ch-ua-platform-version=(*)',
        // },
      }).then((response) => {
        console.log('==============response header', response.data);
        resolve(response.data.osVersion);
      }).catch((responseError) => {
        console.log('==============response header error', responseError);
        reject(responseError);
      });
    });
  }

  showConsoleMessage(message) {
    const { store } = this.state;
    let { monitor } = this.state;

    if (this.IsJsonString(message)) {
      const messageParsed = JSON.parse(message);

      if (messageParsed.Ports) {
        this.listPorts(message);
      } else if (messageParsed.Msg) {
        console.log('AGENT MESSAGE:', messageParsed.Msg);

        store.dispatch(getNewMessage(`${messageParsed.Msg}`));
        if (messageParsed.Msg.indexOf('Executing command: exit status 1') >= 0) store.dispatch(getNewState('#### ERROR EB_BUILD'));

        // if (messageParsed.Msg.indexOf("Flashing with command:wmic") >= 0) {
        //   console.log('?????????????????????????????????', selectedBoard, this.state);
        //   this.state.selectedBoard = 'microbit';
        // }
        // Microbit: copy to usb device successfully
        if (messageParsed.Msg.indexOf('ROBOCOPY') >= 0) {
          store.dispatch(getNewState('#### FINISH AGENT'));
        }
        // Microbit: get port list for usb devices
        if (messageParsed.Msg.indexOf('MICROBIT') >= 0) {
          const drive = messageParsed.Msg.substring(messageParsed.Msg.indexOf(':') - 1, messageParsed.Msg.indexOf(':'));
          const jsonPort = JSON.stringify({
            Ports: [{ Name: drive }],
            Network: false,
          });
          this.listPorts(jsonPort);
          store.dispatch(getNewMessage(`${this.intl.formatMessage({ id: 'agent.portsUpdated' })}: ${[drive]}`));
        }

        if (messageParsed.Msg.indexOf("avrdude: ser_open(): can't open device") >= 0) store.dispatch(getNewState('#### ERROR EB_BUILD'));
        if (messageParsed.Msg.indexOf('programmer is not responding') >= 0) store.dispatch(getNewState('#### ERROR EB_BUILD'));
        if (messageParsed.Msg.indexOf('avrdude: stk500v2_ReceiveMessage(): timeout') >= 0) store.dispatch(getNewState('#### ERROR EB_BUILD'));

        if (messageParsed.Msg.indexOf('bytes of flash verified') >= 0) store.dispatch(getNewState('#### FINISH AGENT'));
        if (messageParsed.Msg.indexOf('avrdude: writing flash') >= 0) store.dispatch(getNewState('#### UPLOAD TO AGENT'));
      } else if (messageParsed.D) {
        // Serial Monitor
        messageParsed.D = messageParsed.D.replace(/\r/g, '');
        monitor += messageParsed.D;
        if (messageParsed.D.match(/\n/g) || monitor.length > 100) {
          const date = new Date();
          const time = `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}.${String(date.getMilliseconds()).padStart(3, '0')}`;
          // store.dispatch(getNewMonitorMessage({ time, message: monitor }));
          this.serialMonitorMessages.next({ time, message: monitor });
          monitor = '';
        }
        this.state.monitor = monitor;
      }
    }
  }

  getPort() {
    return new Promise((resolve, reject) => {
      const scan = [...range(8991, 9000)];
      const portScan = new Array(0);
      scan.forEach((v) => {
        portScan.push(`https://localhost:${v}/info`);
        portScan.push(`http://localhost:${v}/info`);
      });
      const scanCalls = portScan.map(i => {
        try {
          axios.get(i).then((result) => {
            if (result.request.status === 200) {
              const ws = (i.indexOf('https') !== -1) ? result.data.wss : result.data.ws;
              resolve(ws);
            } else {
              reject();
            }
          });
        } catch (e) {
          reject();
        }
        return true;
      });
      Promise.all(scanCalls);
    });
  }

  getInstance() {
    const { socket } = this.state;
    return socket;
  }

  IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  listPorts(data) {
    const { portList, store } = this.state;
    if (this.IsJsonString(data)) {
      const dataParsed = JSON.parse(data);
      const self = this;
      let ports = portList;
      if (dataParsed.Ports && dataParsed.Network === false) {
        ports = [];
        dataParsed.Ports.forEach((port) => {
          if (port.Name) {
            ports.push(port.Name);
          }
        });
        ports.sort();
        if (JSON.stringify(self.portList) !== JSON.stringify(ports)) {
          self.portList = ports;
          store.dispatch(getPorts(self.portList));
          store.dispatch(getAgentPorts(dataParsed.Ports));
          if (self.portList.length > 0) {
            store.dispatch(getNewState('#### BOARD CONNECTED'));
          } else store.dispatch(getNewState('#### BOARD NOT CONNECTED'));
          store.dispatch(getNewMessage(`${this.intl.formatMessage({ id: 'agent.portsUpdated' })}: ${self.portList}`));
        }
      }
    } else {
      store.dispatch(getNewState('#### BOARD NOT CONNECTED'));
      store.dispatch(getPorts([]));
      store.dispatch(getAgentPorts([]));
      this.portList = [];
    }
  }


  uploadRemoteSession(hex, board = null, port = null) {
    const { httpElectronConnection } = this.state;

    return new Promise((resolve, reject) => {
      const { httpPath, store } = this.state;
      store.dispatch(getNewState('#### UPLOAD TO PORT'));
      /**
       * Response object
       * status: Possible values are SUCCESS, ERROR.
       * message: Error message, if there is any.
       */
      const selectedBoard = board.compilerBoard || 'arduino';
      this.state.selectedBoard = selectedBoard;
      if (selectedBoard !== 'microbit' && (!port || port === '')) {
        store.dispatch(getNewState('#### BOARD NOT CONNECTED'));
        store.dispatch(getNewMessage(this.intl.formatMessage({ id: 'agent.boardNotConnected' })));
        reject(this.intl.formatMessage({ id: 'agent.boardNotConnected' }));
      }
      store.dispatch(getNewState('#### BOARD CONNECTED'));
      if (!board) {
        store.dispatch(getNewMessage(this.intl.formatMessage({ id: 'agent.boardMustBeSelected' })));
        reject(this.intl.formatMessage({ id: 'agent.boardMustBeSelected' }));
      }

      store.dispatch(getNewMessage(this.intl.formatMessage({ id: 'agent.remoteCodeIsUploading' })));

      store.dispatch(getNewState('#### UPLOAD TO EB_BUILD'));

      /* eslint-disable */
      if (selectedBoard === 'microbit') {
        store.dispatch(getNewState('#### FINISH AGENT'));
        // MICROBIT BUILDER
        const blob = new Blob([atob(hex)], { type: 'octet/stream' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        a.download = 'microbit.hex';
        a.setAttribute('id', 'clickme');
        document.body.appendChild(a);
        a.click();
      } else {
        // ARDUINO BUILDER
        const postdata = {
          'board': board.compilerBoard,
          'port': port,
          'commandline': board.agentCommandLine,
          'signature': board.agentSignature,
          'hex': hex,
          'filename': `program.ino.${board.fileExtension}`,
          'extra': {
            'auth': {
              'password': null
            },
            'wait_for_upload_port': true,
            'use_1200bps_touch': board.use1200bpsTouch,
            'network': false,
            'params_verbose': '-v',
            'params_quiet': '-q -q',
            'verbose': true
          }
        };
        // axios.post(`${httpPath}/upload`, postdata,
        axios.post(`${(process.env.IS_ELECTRON) ? httpElectronConnection : httpPath}/upload`, postdata,
          {
            withCredentials: false,
          }).then((response) => {
            store.dispatch(getNewState('#### FINISH EB_BUILD'));
            // store.dispatch(getNewMessage(this.intl.formatMessage({ id: 'agent.codeIsUploading' })));
            resolve('OK');
          }).catch((responseError) => {
            store.dispatch(getNewState('#### ERROR EB_BUILD'));
            // store.dispatch(getNewMessage(responseError));
            reject(responseError);
          });
      }
    });
  }

  uploadToPort = async (code, board, port) => {
    const { httpPath, store, httpElectronConnection } = this.state;

    let selectedPort = port;

    /**
     * Response object
     * status: Possible values are SUCCESS, ERROR.
     * message: Error message, if there is any.
     */
    const selectedBoard = board.compilerBoard || 'arduino';
    this.state.selectedBoard = selectedBoard;
    if (selectedBoard !== 'microbit' && (!selectedPort || selectedPort === '')) {
      store.dispatch(getNewState('#### BOARD NOT CONNECTED'));
      store.dispatch(getNewMessage(this.intl.formatMessage({ id: 'agent.boardNotConnected' })));
      return;
    }
    store.dispatch(getNewState('#### BOARD CONNECTED'));
    if (!board) {
      store.dispatch(getNewMessage(this.intl.formatMessage({ id: 'agent.boardMustBeSelected' })));
      return;
    }

    store.dispatch(getNewMessage(this.intl.formatMessage({ id: 'agent.codeIsUploading' })));
    store.dispatch(getNewState('#### UPLOAD TO PORT'));

    let buildURI = (process.env.IS_ELECTRON) ? `${httpElectronConnection}/eb_build` : ((process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') ? routes.ebBuildUri : 'https://builder.staging.educabot.com/eb_build');
    let buildParams = `board=${board.compilerBoard}&text=${encodeURIComponent(code)}`;
    if (selectedBoard === 'microbit') {
      buildURI = (process.env.IS_ELECTRON) ? `${httpElectronConnection}/microbit_build` : routes.microbitBuildUri;
      buildParams = `text=${encodeURIComponent(code)}`;
      store.dispatch(getNewState('#### UPLOAD TO EB_BUILD'));
      // const testcode = `I2C_LCD1602.LcdInit(0)
      // I2C_LCD1602.ShowString("Hola LCD", 0, 0)`;
      // buildParams = `text=${encodeURIComponent(testcode)}`;
    }
    axios.post(
      buildURI,
      buildParams,
      {
        withCredentials: false,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': '*/*', //eslint-disable-line
        },
      },
    ).then((response) => {
      if (response.data.status === 'SUCCESS' && response.data.payload.hex) {
        store.dispatch(getNewState('#### UPLOAD TO EB_BUILD'));

        /* eslint-disable */
        // if (selectedBoard === 'microbit' && (!port || port === '')) {
        if (selectedBoard === 'microbit') {
          // MICROBIT BUILDER
          const blob = new Blob([atob(response.data.payload.hex)], { type: 'octet/stream' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.target = '_blank';
          a.download = 'microbit.hex';
          a.setAttribute('id', 'clickme');
          document.body.appendChild(a);
          a.click();

          store.dispatch(getNewState('#### FINISH AGENT'));
        } else {
          // ARDUINO BUILDER
          let postdata = {};
          if (selectedBoard === 'microbit') {
            // TODO: Check OS and replace board.agentCommandLine.windows and board.agentSignature.windows
            postdata = {
              'board': board.compilerBoard,
              'port': selectedPort,
              'commandline': board.agentCommandLine.windows,
              'signature': board.agentSignature.windows,
              'hex': response.data.payload.hex,
              'filename': `microbit.${board.fileExtension}`,
              'extra': {
                'auth': {
                  'password': null
                },
                'wait_for_upload_port': true,
                'use_1200bps_touch': board.use1200bpsTouch,
                'network': false,
                'params_verbose': '-v',
                'params_quiet': '-q -q',
                'verbose': true
              }
            };
          } else {
            postdata = {
              'board': board.compilerBoard,
              'port': selectedPort,
              'commandline': board.agentCommandLine,
              'signature': board.agentSignature,
              'hex': response.data.payload.hex,
              'filename': `program.ino.${board.fileExtension}`,
              'extra': {
                'auth': {
                  'password': null
                },
                'wait_for_upload_port': true,
                'use_1200bps_touch': board.use1200bpsTouch,
                'network': false,
                'params_verbose': '-v',
                'params_quiet': '-q -q',
                'verbose': true
              }
            };
          }
          // axios.post(`${httpPath}/upload`, postdata,
          axios.post(`${(process.env.IS_ELECTRON) ? httpElectronConnection : httpPath}/upload`, postdata,
            {
              withCredentials: false,
            }).then((response) => {
              store.dispatch(getNewState('#### FINISH EB_BUILD'));
              // store.dispatch(getNewMessage(this.intl.formatMessage({ id: 'agent.codeIsUploading' })));
              return;
            }).catch((responseError) => {
              store.dispatch(getNewState('#### ERROR EB_BUILD'));
              // store.dispatch(getNewMessage(responseError));
              return;
            });
        }
        // END OF ARDUINO BUILDER
      } else {
        store.dispatch(getNewState('#### ERROR EB_BUILD'));
        store.dispatch(getNewMessage(response.data.message));
        return;
      }

    }).catch((responseError) => {
      store.dispatch(getNewState('#### ERROR EB_BUILD'));
      // store.dispatch(getNewMessage(responseError));
      return;
    });

  }


  changeBoardSelected(newBoard) {
    console.log('AGENT BOARD CHANGED:', newBoard, this.state);
    // this.state.selectedBoard = newBoard || 'arduino';
    this.setState({ selectedBoard: newBoard || 'arduino' });
  }

  findPorts() {
    const { socket } = this.state;
    if (socket) {
      socket.emit('command', 'list');
    }
  }

  findMicrobitDrive() {
    const { httpPath, httpElectronConnection } = this.state;

    console.log('AGENT: Looking for microbit drives');
    axios.post(
      // `${httpPath}/upload`,
      `${(process.env.IS_ELECTRON) ? httpElectronConnection : httpPath}/upload`,
      {
        'port': '--',
        'board': '--',
        'hex': '00',
        'filename': 'microbit.hex',
        'commandline': '"wmic" "LOGICALDISK" "get" "DeviceID,VolumeName"',
        'signature': '2f87b46abc8bd5c3505201598dbfdee08ff83e414a6810fd094aa3e6acdbd0167741502d7da482ac1db5716e2c5f007e99e534019951b9a687ea77d721b3a01339ef13e55ca7fa52e2d672e9207e5a602bc2b51c30264cdcfe208324f5d9e1b5f9fc5bb21b735da05305019fd9e07c905247822bf29ffdd6f5ff806b80cc1e3345f3fb4cf92d79ece701dfa5efd50e048dcf95ef85e3a97a4e6243b79db4c5859d27af2386bc741afb96f452ac8b7b482bdc10a9c110448548394deb5ae080500f31a5e39ffdfffb04abdddc751e8f78044c4d84618dc3209d492192f68cde1866221781d568e51798a424e318f82e59cdf10adac2ff2e3c1dc43c07a3a9859d',
      },
      { withCredentials: false }
    );
  }

  openMonitor(port, baudios = 9600) {
    const { socket, store } = this.state;
    if (!port) {
      store.dispatch(getNewState('#### BOARD NOT CONNECTED'));
      store.dispatch(getNewMessage(this.intl.formatMessage({ id: 'agent.boardNotConnected' })));
      return;
    } else {
      if (socket) {
        socket.emit('command', 'open ' + port + ' ' + baudios + ' default'); // default, timed, timedraw
      }
    }
  }

  closeMonitor(port) {
    const { socket, store } = this.state;
    if (!port) {
      store.dispatch(getNewState('#### BOARD NOT CONNECTED'));
      store.dispatch(getNewMessage(this.intl.formatMessage({ id: 'agent.boardNotConnected' })));
      return;
    } else {
      if (socket) {
        socket.emit('command', 'close ' + port);
      }
    }
  }

  sendMonitor(port, msg) {
    const { socket, store } = this.state;
    if (!port) {
      store.dispatch(getNewState('#### BOARD NOT CONNECTED'));
      store.dispatch(getNewMessage(this.intl.formatMessage({ id: 'agent.boardNotConnected' })));
      return;
    } else {
      if (socket) {
        socket.emit('command', 'send ' + port + ' ' + msg);
      }
    }
  }

  // changeBaudios(port, baudios) {
  //   const { store } = this.state;
  //   if (!port) {
  //     store.dispatch(getNewState('#### BOARD NOT CONNECTED'));
  //     store.dispatch(getNewMessage(this.intl.formatMessage({ id: 'agent.boardNotConnected' })));
  //     return;
  //   } else {
  //     this.closeMonitor(port);
  //     this.openMonitor(port, baudios);
  //   }
  // }

}

export default SocketAgent;
