import { BasicBlock, BLOCK_CATEGORY_TYPE, BlockConfig } from '@sections/creabots/types/kits'
import getBlocksInfoSchema from '../../../config/getBlocksInfoSchema'

const blocks: BasicBlock[] = [
  {
    id: 0,
    name: 'text',
  },
  {
    id: 1,
    name: 'text_unir',
  },
  {
    id: 2,
    name: 'text_equalsIgnoreCase',
  },
]

export const textConfig: BlockConfig = {
  blockCategory: 4,
  canvasDisplacement: { x: 320, y: 0 },
  blocks: getBlocksInfoSchema(blocks, BLOCK_CATEGORY_TYPE.TEXT),
}