type FilterType = 'alphabetical' | 'newest' | 'oldest' ;

export const sortProjects = (a: any, b: any, filterType: FilterType) => {
    switch (filterType) {
      case 'alphabetical':
        return a.title.localeCompare(b.title);
      case 'newest':
        return new Date(b.createdAt) - new Date(a.createdAt);
      case 'oldest':
        return new Date(a.createdAt) - new Date(b.createdAt);
      default:
        return a.id - b.id;
    }
  };