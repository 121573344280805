import { BasicBlock, BLOCK_CATEGORY_TYPE, BlockConfig } from '@sections/creabots/types/kits'
import getBlocksInfoSchema from '../../../config/getBlocksInfoSchema'

const blocks: BasicBlock[] = [
  {
    id: 0,
    name: 'variables_declare_global_dynamic',
  },
  {
    id: 1,
    name: 'variables_declare_local_dynamic',
  },
  {
    id: 2,
    name: 'variables_set_dynamic',
  },
  {
    id: 3,
    name: 'variables_get_dynamic',
  },
  {
    id: 4,
    name: 'variables_get_array_dynamic',
  },
  {
    id: 5,
    name: 'variables_declare_array_dynamic',
  },
  {
    id: 6,
    name: 'variables_set_array_dynamic',
  },
]

export const variablesConfig: BlockConfig = {
  blockCategory: 3,
  canvasDisplacement: { x: 470, y: 0 },
  blocks: getBlocksInfoSchema(blocks, BLOCK_CATEGORY_TYPE.VARIABLES),
}