import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useBlockly } from '@modules/blockly/features/hooks';
import {
  webSerialModalChangeStep,
  webSerialModalClose,
  build,
} from '@modules/blockly/features/blocklySlice';
import { getAgentDownloadLink } from '../../../helpers/AgentDownloadLink';


const WebSerialModal = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const blocklyState = useBlockly();
  
  const open = blocklyState.webSerialModal.open;
  const id = props.id || '--';
  const isChromeOS = props.isChromeOS || false;
  const isMicrobit = props.isMicrobit || false;
  const userAgent = props.userAgent || {};
  const setAgent = props.setAgent || null;
  const socket = props.socketAgent || null;

  useEffect(() => {
    if (open) {
      $(`#webSerialModal-${id}`).modal('show');
    } else {
      $(`#webSerialModal-${id}`).modal('hide');
    }
  }, [open]);

  const changeStep = (step) => {
    dispatch(webSerialModalChangeStep(step));
    if (step === 2 && socket.agentType !== 'serial' && socket.agentType !== 'usb') {
      if (setAgent) {
        setAgent((isMicrobit) ? 'usb' : 'serial');
      }
    }
  };

  const downloadAgent = () => {
    if (setAgent) {
      setAgent('socket');
    }
    const link = getAgentDownloadLink(userAgent);
    window.open(link, '_blank', 'noreferrer');
    dispatch(webSerialModalClose());
  };

  const closeModal = () => {
    dispatch(webSerialModalClose());
  };

  const microbitForceDownload = () => {
    dispatch(webSerialModalClose());
    dispatch(build({ microbitForceDownload: true }));
  };

  const changeLink = () => {
    const { changeWebSerialLink } = props;
    if (changeWebSerialLink) {
      changeWebSerialLink();
    }
  };

  return (
    <div className="modal fade webSerialModal" data-backdrop="static" data-keyboard="false" id={`webSerialModal-${id}`} tabIndex="-1" role="dialog" aria-labelledby={`webSerialModal-${id}-label`} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id={`webSerialModal-${id}-label`}>
              {isChromeOS && blocklyState.webSerialModal.step === 0 ? (
                intl.formatMessage({ id: 'blocks.blockly.webserialModal.step0.title' })
              ) : ('')}
              {!isChromeOS && blocklyState.webSerialModal.step === 0 ? (
                intl.formatMessage({ id: 'blocks.blockly.webserialModal.step0.titleChrome' })
              ) : ('')}
              {blocklyState.webSerialModal.step === 1 ? (
                intl.formatMessage({ id: 'blocks.blockly.webserialModal.step1.title' })
              ) : ('')}
              {blocklyState.webSerialModal.step === 2 ? (
                intl.formatMessage({ id: 'blocks.blockly.webserialModal.step2.title' })
              ) : ('')}
              {blocklyState.webSerialModal.step === 3 ? (
                intl.formatMessage({ id: 'blocks.blockly.webserialModal.step3.title' })
              ) : ('')}
              {blocklyState.webSerialModal.connected && blocklyState.webSerialModal.step === 4 ? (
                <>
                  <img src="/images/web_serial/check_circle.svg" width="24" alt="" />
                  {intl.formatMessage({ id: 'blocks.blockly.webserialModal.step4.title' })}
                </>
              ) : ('')}
              {!blocklyState.webSerialModal.connected && blocklyState.webSerialModal.error && blocklyState.webSerialModal.step === 4 ? (
                <>
                  <img src="/images/web_serial/cancel.svg" width="24" alt="" />
                  {intl.formatMessage({ id: 'blocks.blockly.webserialModal.step4.titleError' })}
                </>
              ) : ('')}
            </h5>
            <button type="button" className="close" onClick={closeModal} aria-label="Close"><span aria-hidden="true">&times;</span></button>
          </div>
          <div className="modal-body">
            {!isChromeOS && blocklyState.webSerialModal.step === 0 && !isMicrobit ? (
              <>
                <div>{intl.formatMessage({ id: 'blocks.blockly.webserialModal.step0.messageLine1' })}</div>
                <div>{intl.formatMessage({ id: 'blocks.blockly.webserialModal.step0.messageLine2' })}</div>
              </>
            ) : ('')}
            {!isChromeOS && blocklyState.webSerialModal.step === 0 && isMicrobit ? (
              <>
                <div>{intl.formatMessage({ id: 'blocks.blockly.webserialModal.step0.messageLine1Microbit' })}</div>
                <div>{intl.formatMessage({ id: 'blocks.blockly.webserialModal.step0.messageLine2Microbit' })}</div>
              </>
            ) : ('')}
            {isChromeOS && blocklyState.webSerialModal.step === 0 ? (
              <>
                <div>{intl.formatMessage({ id: 'blocks.blockly.webserialModal.step0.messageLine1Chrome' })}</div>
                <div>{intl.formatMessage({ id: 'blocks.blockly.webserialModal.step0.messageLine2Chrome' })}</div>
              </>
            ) : ('')}
            {blocklyState.webSerialModal.step === 1 && !isMicrobit ? (
              <div className="step-one"><img src="/images/web_serial/webserial_1.gif" width="300" alt="" /></div>
            ) : ('')}
            {blocklyState.webSerialModal.step === 1 && isMicrobit ? (
              <div className="step-one"><img src="/images/web_serial/webserial_1_microbit.gif" width="300" alt="" /></div>
            ) : ('')}
            {blocklyState.webSerialModal.step === 2 ? (
              <div className="two-columns">
                <div>
                  <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'blocks.blockly.webserialModal.step2.messageLine1' }) }} />
                  <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'blocks.blockly.webserialModal.step2.messageLine2' }) }} />
                  <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'blocks.blockly.webserialModal.step2.messageLine3' }) }} />
                </div>
                <div>
                  <div>
                    {!isMicrobit ? (
                      <img src="/images/web_serial/webserial_2.gif" width="348" alt="" />
                    ) : (
                      <img src="/images/web_serial/webserial_2_microbit.gif" width="348" alt="" />
                    )}
                  </div>
                </div>
              </div>
            ) : ('')}
            {blocklyState.webSerialModal.step === 3 ? (
              <div className="loading">
                <div><img src="/images/web_serial/linking.png" width="80" alt="" /></div>
                <div>{intl.formatMessage({ id: 'blocks.blockly.webserialModal.step3.messageLine1' })}</div>
              </div>
            ) : ('')}
            {!blocklyState.webSerialModal.connected && blocklyState.webSerialModal.error && blocklyState.webSerialModal.step === 4 ? (
              <>
                <div>{intl.formatMessage({ id: 'blocks.blockly.webserialModal.step4.messageLine1Error' })}</div>
                <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'blocks.blockly.webserialModal.step4.messageLine2Error' }) }} />
              </>
            ) : ('')}
            {blocklyState.webSerialModal.connected && blocklyState.webSerialModal.step === 4 ? (
              <div className="two-columns">
                <div>
                  <div>{intl.formatMessage({ id: 'blocks.blockly.webserialModal.step4.messageLine1' })}</div>
                  <div>{intl.formatMessage({ id: 'blocks.blockly.webserialModal.step4.messageLine2' })}</div>
                </div>
                <div>
                  {!isMicrobit ? (
                    <div><img src="/images/web_serial/webserial_3.png" width="348" alt="" /></div>
                  ) : (
                    <div><img src="/images/web_serial/webserial_3_microbit.png" width="348" alt="" /></div>
                  )}
                </div>
              </div>
            ) : ('')}
          </div>
          <div className="modal-footer">
            {!isChromeOS && blocklyState.webSerialModal.step === 0 ? (
              <>
                {'serial' in navigator || 'usb' in navigator ? (
                  <button type="button" className="btn btn-secondary" onClick={() => changeStep(blocklyState.webSerialModal.step + 1)}>
                    {intl.formatMessage({ id: 'blocks.blockly.webserialModal.buttons.continueBrowser' })}
                  </button>
                ) : ('')}
                {isMicrobit ? (
                  <button type="button" className="btn btn-primary main-btn" onClick={microbitForceDownload}>
                    {intl.formatMessage({ id: 'blocks.blockly.webserialModal.buttons.downloadFile' })}
                  </button>
                ) : (
                  <button type="button" className="btn btn-primary main-btn" onClick={downloadAgent}>
                    {intl.formatMessage({ id: 'blocks.blockly.webserialModal.buttons.downloadPlugin' })}
                  </button>
                )}
              </>
            ) : ('')}
            {isChromeOS && blocklyState.webSerialModal.step === 0 ? (
              <button type="button" className="btn btn-secondary" onClick={() => changeStep(blocklyState.webSerialModal.step + 1)}>
                {intl.formatMessage({ id: 'blocks.blockly.webserialModal.buttons.continue' })}
              </button>
            ) : ('')}
            {blocklyState.webSerialModal.step === 1 ? (
              <button type="button" className="btn btn-primary main-btn" onClick={() => changeStep(blocklyState.webSerialModal.step + 1)}>
                {intl.formatMessage({ id: 'blocks.blockly.webserialModal.buttons.next' })}
              </button>
            ) : ('')}
            {blocklyState.webSerialModal.step === 2 ? (
              <button type="button" className="btn btn-primary main-btn" onClick={changeLink}>
                {intl.formatMessage({ id: 'blocks.blockly.webserialModal.buttons.link' })}
              </button>
            ) : ('')}
            {!blocklyState.webSerialModal.connected && blocklyState.webSerialModal.error && blocklyState.webSerialModal.step === 4 ? (
              <button type="button" className="btn btn-primary main-btn" onClick={changeLink}>
                {intl.formatMessage({ id: 'blocks.blockly.webserialModal.buttons.retry' })}
              </button>
            ) : ('')}
            {blocklyState.webSerialModal.connected && blocklyState.webSerialModal.step === 4 ? (
              <button type="button" className="btn btn-primary main-btn" onClick={closeModal} aria-label="Close">
                {intl.formatMessage({ id: 'blocks.blockly.webserialModal.buttons.ok' })}
              </button>
            ) : ('')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebSerialModal;