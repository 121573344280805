/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as routes from '@educabot/educablocks-cosmos';
import '../../components/LoginForm/login.scss';
import { resetPasswordAction } from '../../actions/authAction';
import './LoginRecover.scss';
import ResetPasswordForm from '../../components/LoginForm/ResetPasswordForm';

class LoginRecover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        email: '',
      },
      msgError: '',
      success: false,
      loginLink: `${routes.bloquesUri}/login`,
    };
    this.submitForm = this.submitForm.bind(this);
  }

  componentWillReceiveProps(newprops) {
    const { history } = this.props;
    if (history && newprops.auth.statusResetPassword === 'fetch') {
      // history.push('/login');
      this.setState({ success: true });
    }
    console.log(newprops.auth);
    if (
      newprops.auth.statusResetPassword === 'fetch error'
      && newprops.auth.dataResetPassword
      && newprops.auth.dataResetPassword.message
    ) this.setState({ msgError: newprops.auth.dataResetPassword.message });
  }

  submitForm(e) {
    e.preventDefault();
    e.stopPropagation();
    const { form } = this.state;
    const { resetPassword } = this.props;
    resetPassword({ email: form.email });
  }

  render() {
    const { form, msgError, success, loginLink } = this.state;
    return (
      <div className="recoverPage">
        <div className="container">
          <div className="row d-flex left">
            <div className="card">
              <div className="logo-container">
                <div className="icon-robot">
                  <img src="/images/logo_login.png" alt="logo" />
                </div>
                <span>Robots</span>
              </div>
              <div className="icon-powered">
                <a href="https://www.educabot.com">
                  <img src="/images/suite_educabot.svg" alt="" />
                </a>
              </div>
            </div>
          </div>
          <div className="row d-flex right">
            <div className="card">
              <div className="card-body">
                <ResetPasswordForm
                  submitForm={this.submitForm}
                  form={form}
                  formError={msgError}
                  loginLink={loginLink}
                />
              </div>
            </div>
            { /* eslint-disable */ (success) && (
            <div className="success">
              <svg id="successAnimation" className="animated" xmlns="https://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
                <path id="successAnimationResult" fill="#212c4c" d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z"/>
                <circle id="successAnimationCircle" cx="35" cy="35" r="24" stroke="#979797" stroke-width="2" stroke-linecap="round" fill="transparent"/>
                <polyline id="successAnimationCheck" stroke="#979797" stroke-width="2" points="23 34 34 43 47 27" fill="transparent"/>
              </svg>
              <h4>Enviamos un email a tu casilla para que puedas restablecer la contraseña.</h4>
            </div>
          ) /* eslint-enable */ }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.authReducer,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    resetPassword: resetPasswordAction,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginRecover);
