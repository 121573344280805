/* eslint-disable camelcase */
// @ts-nocheck
import ProfileSelector from '@modules/profileSelector/containers/profileSelectorWrapper';
import { hideProfileSelector as hideProfileSelectorAction, showProfileSelector as showProfileSelectorAction } from '@modules/profileSelector/features/profileSelectorSlice';
import { showToast as showToastAction } from '@modules/toasts/features/toastsSlice';
import * as routes from '@educabot/educablocks-cosmos';
import moment from 'moment';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { v1 as uuidv1 } from 'uuid';
// import Blockly from '@modules/blockly/containers/blockly';
import { boards as Boards, getBoardById } from '@educabot/educablocks-boards';
import {
  actionAssignmentSessionGet,
  actionAssignmentSessionGetList,
  actionAssignmentSessionLoginGet,
  actionGetAssignmentDeliverableState,
  actionResetAssignmentSessionStatus,
  actionSetAssignmentDeliverableState,
} from '../../actions/assignmentSessionAction';
import { checkAuthActionExternal } from '../../actions/authAction';
import { actionCollabProjectsGetOne, actionProjectsGetOne, actionProjectsUserGetAll, actionResetStatus } from '../../actions/projectsAction';
import { actionRemoteSessionGet, actionRemoteSessionGetList, actionRemoteSessionLoginGet, actionResetRemoteSessionStatus } from '../../actions/remoteSessionAction';
import { getRightPanelData } from '../../actions/rightPanelAction';
import { actionTagsGet } from '../../actions/tagsAction';
import BlocklyPanel from '../../components/BlocklyPanel'; //eslint-disable-line
import Header from '../../components/Header'; //eslint-disable-line
import { getBoardsByCosmos, getCosmosItem } from '../../cosmos';
import { killNotExactExt } from '../../helpers/LoadAndKillerScripts';
import Responsive from '../../helpers/Responsive';
import ButtonV2 from '../../resources/components/buttons/buttonV2';
import TextButton from '../../resources/components/buttons/textButton';
import './App.scss';
import ModalBrowser from '../../components/ModalBrowser';

const themeKey = getCosmosItem('theme');

const $ = window.$;

function buildFileImportSelector() {
  const fileSelector = document.createElement('input');
  fileSelector.setAttribute('type', 'file');
  fileSelector.setAttribute('accept', '.edu');
  return fileSelector;
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      boards: getBoardsByCosmos('senior'),
      boardType: 'senior',
      toolboxToggle: false,
      darkthemeToggle: !(themeKey === 'theme-aula' || themeKey === 'theme-labs'),
      responsive: new Responsive(this),
      tabs: [],
      maxTabs: 4,
      activeModalTab: 0, // 0 = create project || 1 = load project || 2 = import || 3 = load remote
      minModalChars: 3,
      tabDropdownOpen: false,
      form: {
        name: props.intl.formatMessage({ id: 'projects.unnamed' }),
        description: '',
        remoteSession: 0,
        remoteSessionNeedInit: false,
        unsavedTabCounter: 1,
        boardIndex: 0,
        boardGroupName: '',
        className: '',
        boardName: '',
        assignmentSessionId: '',
        collaborationProject: false,
        remoteSessionId: '',
        remoteSessionMsg: '',
        remoteSessionPassword: '',
        remoteSessionPasswordVisible: false,
      },
      remoteSessionObj: [],
      remoteSessionIAmTheOwner: false,
      dbProjects: [],
      oldSessions: {},
      selectedOldSession: false,
      selectedDbProject: false,
      isLogged: false,
      projectFetchStatus: 1, // 0 = initial, 1 = fetching, 2 = fetch, 3 = error
      loading: true,
      settingActive: false,
      scrollTabName: true,
      isContenidista: false,
      isPremium: false,
      electronUpdateAvailable: false,
    };
    if (process.env.IS_ELECTRON) this.state.haveInternet = false;
    this.changeField = this.changeField.bind(this);
    this.closeTab = this.closeTab.bind(this);
    this.getEnter = this.getEnter.bind(this);
    this.tabDropdown = this.tabDropdown.bind(this);
    this.changeToolboxToggle = this.changeToolboxToggle.bind(this);
    this.toggleDarkTheme = this.toggleDarkTheme.bind(this);
    this.addTab = this.addTab.bind(this);
    this.askForRightPanel = this.askForRightPanel.bind(this);
    this.toggleSettings = this.toggleSettings.bind(this);
    this.what2DoWhenBlocklyRender = this.what2DoWhenBlocklyRender.bind(this);
    this.toastSend = this.toastSend.bind(this);
    this.exportSVG = this.exportSVG.bind(this);
    this.export = this.exportProject.bind(this);
    this.checkForActiveTabs = this.checkForActiveTabs.bind(this);
    this.renderElectronAutoupdater = this.renderElectronAutoupdater.bind(this);
    this.onBoardItemClick = this.onBoardItemClick.bind(this);
    this.onStoredProjectClick = this.onStoredProjectClick.bind(this);
    this.selectActiveTab = this.selectActiveTab.bind(this);
    this.fileImportSelector = null;
  }

  componentDidMount() {
    const { boards, form } = this.state;
    const { match, checkAuth, projectsGetOne, remoteSessionGet, remoteSessionGetList, assignmentSessionGet, collabProjectsGetOne, hideProfileSelector } = this.props;
    // const self = this;
    checkAuth(false);
    let boardType = 'senior';
    if (match.url && match.url.indexOf('junior') !== -1) {
      boardType = 'junior';
    } else if (match.url && match.url.indexOf('codigo') !== -1) {
      boardType = 'code';
    }

    this.setState({ boards: getBoardsByCosmos(boardType), boardType }, () => {
      if (match.params && match.params.id) {
        if (match.url && match.url.indexOf('colaboracion-') !== -1) {
          form.collaborationProject = true;
          collabProjectsGetOne(match.params.id);
          form.assignmentSessionId = match.params.id;
        } else if (match.url && match.url.indexOf('actividad-') !== -1) {
          assignmentSessionGet(match.params.id);
          form.assignmentSessionId = match.params.id;
        } else if (match.url && match.url.indexOf('remote-') !== -1) {
          remoteSessionGet(match.params.id);
          form.remoteSessionId = match.params.id;
        } else {
          projectsGetOne(match.params.id);
          form.remoteSessionId = '';
          form.assignmentSessionId = '';
        }
        this.setState({ form });
      } else {
        const ele = document.querySelectorAll('.blockProfileSelector')[0];
        if (ele && ele.style) ele.style.width = `${(boards.length * 110)}px`;
        this.showTabModal();

        $('.blocklyTreeRoot').parent().remove();
        if (process.env.IS_ELECTRON) {
          killNotExactExt(['blockly_compressed', 'blockly_compressed_horizontal'], () => { });
        }
      }
      remoteSessionGetList();

      moment.locale('es');
    });

    if (match.params.id) {
      hideProfileSelector();
    }
  }

  componentWillReceiveProps(newprops) {
    const { statusInternet, userData } = newprops.auth;
    const { statusUserGetAll, dataUserGetAll, statusGetOne, dataGetOne, statusCollaborationGetOne, dataCollaborationGetOne } = newprops.projects;
    const { statusRemoteSessionGet, dataRemoteSessionGet, statusRemoteSessionLoginGet, dataRemoteSessionLoginGet, statusRemoteSessionGetList, dataRemoteSessionGetList } = newprops.remoteSession;
    const { statusAssignmentSessionGet, dataAssignmentSessionGet } = newprops.assignmentSession;
    const { resetStatus, tagsGet, tags, projectsUserGetAll, GRPD, resetRemoteSessionStatus, resetAssignmentSessionStatus, match, setAssignmentDeliverableState } = this.props;
    let { loading, projectFetchStatus, tabs } = this.state;
    const { isLogged, form, boardType } = this.state;
    const { hideProfileSelector, showToast, intl } = this.props;

    const self = this;
    if (statusUserGetAll === 'fetch' || (!isLogged)) loading = false;

    if (projectFetchStatus !== 2) {
      switch (statusUserGetAll) {
        case 'fetching':
          projectFetchStatus = 1;
          break;
        case 'fetch':
          projectFetchStatus = 2;
          break;
        case 'error':
          projectFetchStatus = 3;
          break;
        default:
          projectFetchStatus = 1;
          break;
      }
    }

    if (Object.entries(userData).length > 0) {
      this.setState({
        isContenidista: (userData
          && userData.data
          && (userData.data.roles.names.includes('Contenidista')
            || userData.data.roles.names.includes('Contenidista Global')
            || userData.data.roles.names.includes('Administrador'))),
        isPremium: (userData
          && userData.data
          && (userData.data.roles.names.includes('Contenidista')
            || userData.data.roles.names.includes('Contenidista Global')
            || userData.data.roles.names.includes('Profesor')
            || userData.data.roles.names.includes('Alumno Premium')
            || userData.data.roles.names.includes('Premium')
            || userData.data.roles.names.includes('Administrador'))),
      });
    }

    let dbProjects = [];
    if (statusUserGetAll === 'fetch') {
      dbProjects = dataUserGetAll;
    }

    if (newprops.rightPanel.rightPanelData.tempData) {
      tabs = tabs.map((tab) => {
        const newTab = tab;
        if (newTab.active) newTab.saveForm = { ...newTab.saveForm, ...newprops.rightPanel.rightPanelData.tempData };
        return newTab;
      });
    }
    this.setState({ dbProjects, projectFetchStatus, tabs }, () => {
      if (newprops.rightPanel.rightPanelData.tempData) GRPD({ tempData: null });
    });
    if (process.env.IS_ELECTRON) this.setState({ haveInternet: statusInternet });
    this.setState({ isLogged: Object.entries(userData).length > 0, loading }, () => {
      if ((Object.entries(userData).length > 0)) {
        if (tags.statusGet === '') tagsGet();
        if (statusUserGetAll === '') projectsUserGetAll();
      }
    });

    if (statusGetOne === 'fetch error') {
      if (!isLogged) {
        window.location = `${routes.bloquesUri}/login`;
      } else {
        self.toastSend(intl.formatMessage({ id: 'projects.toasts.noAccess' }));
        this.showTabModal();
        resetStatus({ statusGetOne: '' });
      }
    }

    if (statusGetOne === 'fetch' && (statusUserGetAll === 'fetch' || !isLogged)) {
      if (dataGetOne.error || (dataGetOne.boardType && dataGetOne.boardType === 'animations')) {
        self.setState({ activeModalTab: 0 }, () => {
          resetStatus({ statusGetOne: '' });
          if (dataGetOne.boardType && dataGetOne.boardType === 'animations') {
            self.toastSend(intl.formatMessage({ id: 'projects.toasts.cantLoadAnimation' }));
          } else {
            self.toastSend(dataGetOne.error);
          }
          this.showTabModal();
        });
      } else if (((dataGetOne.boardType && dataGetOne.boardType === 'code') || dataGetOne.isCodeOnly === 1) && match.url && match.url.indexOf('codigo') === -1) {
        resetStatus({ statusGetOne: '' });
        window.location = `${routes.bloquesUri}/codigo-${dataGetOne.id}`;
      } else if (dataGetOne.boardType && dataGetOne.boardType === 'junior' && match.url && match.url.indexOf('junior') === -1) {
        resetStatus({ statusGetOne: '' });
        window.location = `${routes.bloquesUri}/junior-${dataGetOne.id}`;
      } else if ((!dataGetOne.boardType || dataGetOne.boardType === 'senior') && dataGetOne.isCodeOnly === 0 && match.url && match.url.indexOf('bloques') === -1) {
        resetStatus({ statusGetOne: '' });
        window.location = `${routes.bloquesUri}/bloques-${dataGetOne.id}`;
      } else {
        self.onLoadProject();

        const projectLoaded = {
          id: dataGetOne.id,
          userId: dataGetOne.userId,
          name: dataGetOne.title,
          description: dataGetOne.description,
          isPublic: dataGetOne.isPublic,
          isLibrary: dataGetOne.isLibrary,
          boardType,
          tabInfo: dataGetOne.tabInfo,
          tags: dataGetOne.Tags,
          workspace: (dataGetOne.tabInfo.withWorkspace) ? dataGetOne.tabInfo.withWorkspace : null,
        };
        // console.log('===============', projectLoaded);
        self.setState({ activeModalTab: 1 }, () => {
          resetStatus({ statusGetOne: '' });
          self.addTab(projectLoaded);
        });
      }
    }

    if (statusCollaborationGetOne === 'fetch error') {
      self.toastSend(intl.formatMessage({ id: 'collaboration.noAccess' }));
      this.showTabModal();
      resetStatus({ statusCollaborationGetOne: '' });
    }

    if (statusCollaborationGetOne === 'fetch') {
      if (!dataCollaborationGetOne.ProjectsCollabUsers
        || dataCollaborationGetOne.ProjectsCollabUsers.length === 0
        || dataCollaborationGetOne.ProjectsCollabUsers.filter((collabUser) => collabUser.userId === userData.data.userId).length === 0) {
        self.toastSend(intl.formatMessage({ id: 'collaboration.noAccess' }));
        this.showTabModal();
        resetStatus({ statusCollaborationGetOne: '' });
      } else {
        const newTabInfo = dataCollaborationGetOne.tabInfo;
        newTabInfo.collaborationProject = true;
        newTabInfo.collaborationProjectUserRole = dataCollaborationGetOne.ProjectsCollabUsers.filter((collabUser) => collabUser.userId === userData.data.userId)[0].userRole;
        const projectLoaded = {
          id: dataCollaborationGetOne.id,
          name: dataCollaborationGetOne.title,
          userId: dataCollaborationGetOne.userId,
          description: dataCollaborationGetOne.description,
          boardType,
          tabInfo: newTabInfo,
          tags: dataCollaborationGetOne.Tags,
          workspace: (dataCollaborationGetOne.tabInfo.withWorkspace) ? dataCollaborationGetOne.tabInfo.withWorkspace : null,
        };
        const newForm = form;
        if (dataCollaborationGetOne.jsonBody.remoteSession) {
          newForm.remoteSessionStarted = true;
          newForm.remoteProject = true;
          newForm.remoteSession = dataCollaborationGetOne.jsonBody.remoteSession;
          newForm.remoteSessionNeedInit = false;
        }
        self.setState({ activeModalTab: 1, form: newForm }, () => {
          resetStatus({ statusCollaborationGetOne: '' });
          self.addTab(projectLoaded);
        });
      }
    }

    if (statusRemoteSessionGetList === 'fetch') {
      // console.log('REMOTE SESSIONS: ', dataRemoteSessionGetList);
      const remoteSessionObj = dataRemoteSessionGetList.data;
      resetRemoteSessionStatus({ statusRemoteSessionGetList: '' });
      for (let i = 0; i < remoteSessionObj.length; i += 1) {
        if (remoteSessionObj[i].id === parseInt(form.remoteSessionId, 10) && remoteSessionObj[i].password) {
          form.remoteSessionPasswordVisible = true;
          form.remoteSessionMsg = intl.formatMessage({ id: 'remoteSessions.toasts.sessionIsPrivate.message' });
        }
      }
      this.setState({ remoteSessionObj, form });
    }


    if (statusRemoteSessionLoginGet === 'fetch error' || (statusRemoteSessionLoginGet === 'fetch' && !dataRemoteSessionLoginGet.data)) {
      resetRemoteSessionStatus({ statusRemoteSessionLoginGet: '' });
      showToast({
        type: 'error',
        message: intl.formatMessage({ id: 'remoteSessions.toasts.wrongKeys.message' }),
        options: {
          autoClose: true,
        },
      });
      form.remoteSessionMsg = intl.formatMessage({ id: 'remoteSessions.toasts.wrongKeys.message' });
      tabs = tabs.map((tab) => {
        const newTab = tab;
        if (tab.active) newTab.errorMsg = intl.formatMessage({ id: 'remoteSessions.toasts.wrongKeys.message' });
        return newTab;
      });
      self.setState({ activeModalTab: 3, form, tabs });
    }

    if (statusRemoteSessionLoginGet === 'fetch' && dataRemoteSessionLoginGet.data) {
      window.location = `${routes.bloquesUri}/remote-${dataRemoteSessionLoginGet.data.id}`;
      // window.location = `${routes.bloquesUri}/remote-${form.remoteSessionId}`;
    }

    if (statusRemoteSessionGet === 'fetch error') {
      resetRemoteSessionStatus({ statusRemoteSessionGet: '' });
      showToast({
        type: 'error',
        message: intl.formatMessage({ id: 'remoteSessions.toasts.sessionIsPrivate.message' }),
        options: {
          autoClose: true,
        },
      });

      self.setState({ activeModalTab: 3 }, () => {
        this.showTabModal();
      });
    }

    if (statusRemoteSessionGet === 'fetch') {
      resetRemoteSessionStatus({ statusRemoteSessionGet: '' });
      const remoteSession = dataRemoteSessionGet.data;
      if (remoteSession) {
        let password = '';
        if (userData.data.remoteSessions) {
          for (let s = 0; s < userData.data.remoteSessions.length; s += 1) {
            if (parseInt(userData.data.remoteSessions[s].id, 10) === remoteSession.id) {
              password = userData.data.remoteSessions[s].password;
            }
          }
        }
        if (remoteSession.password === '' || remoteSession.password === null || remoteSession.password === password) {
          // console.log('fetched remote session:----------', remoteSession, userData.data);
          let masterProjectId = null;
          let ownProjectId = null;
          const iAmTheMaster = (userData.data.userId === remoteSession.userId);
          let iAmTheOwner = false;

          for (let i = 0; i < remoteSession.RemoteSessionsProjects.length; i += 1) {
            if (remoteSession.RemoteSessionsProjects[i].Project.userId === remoteSession.userId) {
              masterProjectId = i;
            }
            if (remoteSession.RemoteSessionsProjects[i].Project.userId === userData.data.userId) {
              ownProjectId = i;
              if (remoteSession.RemoteSessionsProjects[i].userRole === 'OWNER' || remoteSession.RemoteSessionsProjects[i].userRole === 'TEACHER') {
                iAmTheOwner = true;
              }
            }
          }
          self.setState({ remoteSessionIAmTheOwner: iAmTheOwner });
          self.onLoadProject();
          hideProfileSelector();

          setTimeout(() => {
            showToast({
              type: 'success',
              message: intl.formatMessage({ id: 'projects.toasts.loaded.message' }),
              options: {
                autoClose: true,
              },
            });
          }, 3000);
          let projectLoaded = null;

          // console.log('Result fetched:', masterProjectId, ownProjectId, iAmTheMaster);
          if (ownProjectId !== null) {
            // console.log('LOAD MY OWN PROJECT', remoteSession.RemoteSessionsProjects[ownProjectId].Project);
            const projectToClone = remoteSession.RemoteSessionsProjects[ownProjectId].Project;
            const newTabInfo = projectToClone.jsonBody || {};
            if (projectToClone.isCodeOnly) {
              this.setState({ boardType: 'code' });
            }
            projectLoaded = this.getprojectToLoad(userData, newTabInfo, projectToClone, false);
            form.remoteSessionNeedInit = false;
            form.remoteUserRole = remoteSession.RemoteSessionsProjects[ownProjectId].userRole;
            form.assignmentUserRole = form.remoteUserRole;
          } else if (masterProjectId !== null && !iAmTheMaster) {
            // console.log('CLONE MASTER PROJECT', remoteSession.RemoteSessionsProjects[masterProjectId].Project);

            const projectToClone = remoteSession.RemoteSessionsProjects[masterProjectId].Project;
            projectToClone.userId = userData.data.userId;
            const newTabInfo = projectToClone.jsonBody;
            newTabInfo.remoteUserRole = 'STUDENT';
            newTabInfo.assignmentUserRole = 'STUDENT';
            if (projectToClone.isCodeOnly) {
              this.setState({ boardType: 'code' });
            }
            projectLoaded = this.getprojectToLoad(userData, newTabInfo, projectToClone, true);
            form.remoteSessionNeedInit = true;
            form.assignmentUserRole = 'STUDENT';
            form.remoteUserRole = 'STUDENT';
          } else if (iAmTheMaster) {
            // console.log('CREATE MASTER PROJECT');
            projectLoaded = null;
            form.remoteSessionNeedInit = true;
            form.assignmentUserRole = 'OWNER';
            form.remoteUserRole = 'OWNER';
          }

          form.userId = userData.data.userId;
          form.boardIndex = remoteSession.board;
          form.name = remoteSession.title;
          form.description = remoteSession.description;
          form.remoteSessionStarted = true;
          form.remoteProject = true;
          form.remoteSession = remoteSession.id;
          const sessionBoard = getBoardById(Boards, remoteSession.board);
          form.boardName = sessionBoard.name;
          form.className = sessionBoard.class;

          self.setState({ form }, () => {
            self.addTab(projectLoaded);
          });
        } else {
          showToast({
            type: 'error',
            message: intl.formatMessage({ id: 'remoteSessions.toasts.sessionIsPrivate.message' }),
            options: {
              autoClose: true,
            },
          });
        }
      } else {
        showToast({
          type: 'error',
          message: intl.formatMessage({ id: 'remoteSessions.toasts.sessionDontExist.message' }),
          details: intl.formatMessage({ id: 'remoteSessions.toasts.sessionDontExist.details' }),
          options: {
            autoClose: true,
          },
        });

        self.setState({ activeModalTab: 3 }, () => {
          this.showTabModal();
        });
      }
    }


    if (statusAssignmentSessionGet === 'fetch error') {
      resetAssignmentSessionStatus({ statusAssignmentSessionGet: '' });
      self.toastSend(intl.formatMessage({ id: 'assignments.privateSession' }));
      self.setState({ activeModalTab: 0 }, () => {
        this.showTabModal();
      });
    }

    if (statusAssignmentSessionGet === 'fetch') {
      resetAssignmentSessionStatus({ statusAssignmentSessionGet: '' });
      const assignmentSession = dataAssignmentSessionGet.data;
      if (assignmentSession) {
        if (assignmentSession.boardType !== 'animations') {
          // setAssignmentDeliverableState(assignmentSession.deliveryDate);
          let password = '';
          if (userData.data.assignmentSessions) {
            for (let s = 0; s < userData.data.assignmentSessions.length; s += 1) {
              if (parseInt(userData.data.assignmentSessions[s].id, 10) === assignmentSession.id) {
                password = userData.data.assignmentSessions[s].password;
              }
            }
          }
          if (assignmentSession.password === '' || assignmentSession.password === null || assignmentSession.password === password) {
            let masterProjectId = null;
            let ownProjectId = null;
            const iAmTheMaster = (userData.data.userId === assignmentSession.userId);
            for (let i = 0; i < assignmentSession.AssignmentSessionsProjects.length; i += 1) {
              if (assignmentSession.AssignmentSessionsProjects[i].Project.userId === assignmentSession.userId) {
                masterProjectId = i;
              }
              if (assignmentSession.AssignmentSessionsProjects[i].Project.userId === userData.data.userId) {
                ownProjectId = i;
              }
            }
            if (ownProjectId !== null && assignmentSession.AssignmentSessionsProjects[ownProjectId].Project.jsonBody.assignmentProjectDeliveryDate) {
              setAssignmentDeliverableState(assignmentSession.AssignmentSessionsProjects[ownProjectId].Project.jsonBody.assignmentProjectDeliveryDate);
            } else {
              setAssignmentDeliverableState(assignmentSession.deliveryDate);
            }

            setTimeout(() => {
              showToast({
                type: 'success',
                message: intl.formatMessage({ id: 'projects.toasts.loaded.message' }),
                options: {
                  autoClose: true,
                },
              });
            }, 3000);

            let projectLoaded = null;
            this.setState({ boardType: form.boardType });
            if (ownProjectId !== null) {
              // console.log('LOAD MY OWN PROJECT');
              const projectToClone = assignmentSession.AssignmentSessionsProjects[ownProjectId].Project;
              const newTabInfo = projectToClone.jsonBody || {};
              if (projectToClone.isCodeOnly) {
                this.setState({ boardType: 'code' });
              }
              projectLoaded = this.getprojectToLoad(userData, newTabInfo, projectToClone, false);
              projectLoaded.boardType = assignmentSession.boardType || assignmentSession.boardType || 'senior';
              form.assignmentUserRole = assignmentSession.AssignmentSessionsProjects[ownProjectId].userRole;
              form.remoteUserRole = form.assignmentUserRole;
              if (iAmTheMaster && typeof projectToClone.jsonBody.withWorkspace === 'undefined') {
                setTimeout(() => {
                  self.toastSend(
                    <span className="body">
                      <span className="icon icon-exclamation-solid" />
                      <span className="first-line">
                        {intl.formatMessage({ id: 'assignments.toasts.prepareProject.title' })}
                        <span className="second-line">
                          {intl.formatMessage({ id: 'assignments.toasts.prepareProject.message' })}
                        </span>
                      </span>
                    </span>,
                    'R',
                    { className: 'code-sending', autoClose: false },
                  );
                }, 3000);
              }
            } else if (masterProjectId !== null && !iAmTheMaster) {
              // console.log('CLONE MASTER PROJECT');
              const projectToClone = assignmentSession.AssignmentSessionsProjects[masterProjectId].Project;
              projectToClone.userId = userData.data.userId;
              const newTabInfo = projectToClone.jsonBody;
              newTabInfo.remoteUserRole = 'STUDENT';
              newTabInfo.assignmentUserRole = 'STUDENT';
              if (projectToClone.isCodeOnly) {
                this.setState({ boardType: 'code' });
              }
              projectLoaded = this.getprojectToLoad(userData, newTabInfo, projectToClone, true);
              projectLoaded.boardType = assignmentSession.boardType || assignmentSession.boardType || 'senior';
              form.assignmentUserRole = 'STUDENT';
              form.remoteUserRole = 'STUDENT';
            } else if (iAmTheMaster) {
              // console.log('CREATE MASTER PROJECT');
              projectLoaded = null;
              form.assignmentUserRole = 'OWNER';
              form.remoteUserRole = 'OWNER';
              form.imageUrl = assignmentSession.imageUrl;
            }

            form.boardType = assignmentSession.boardType || 'senior';
            form.userId = userData.data.userId;
            form.boardIndex = assignmentSession.board;
            form.name = assignmentSession.title;
            form.description = assignmentSession.description;
            form.deliveryDate = assignmentSession.deliveryDate;
            form.assignmentSessionStarted = true;
            form.assignmentProject = true;
            form.assignmentSession = assignmentSession.id;
            form.assignmentSessionNeedInit = !(ownProjectId !== null);
            const sessionBoard = getBoardById(Boards, assignmentSession.board);
            form.boardName = sessionBoard.name;
            form.className = sessionBoard.class;

            if (assignmentSession.remoteSessionId > 0) {
              form.remoteSessionStarted = true;
              form.remoteProject = true;
              form.remoteSession = assignmentSession.remoteSessionId;
              form.remoteSessionNeedInit = !(ownProjectId !== null);
            }
            self.setState({ form }, () => {
              self.addTab(projectLoaded);
            });
          } else {
            self.toastSend(intl.formatMessage({ id: 'assignments.privateSession' }));
          }
        } else {
          self.toastSend(intl.formatMessage({ id: 'projects.toasts.cantLoadAnimation' }));
          self.setState({ activeModalTab: 0 }, () => {
            this.showTabModal();
          });
        }
      } else {
        self.toastSend(intl.formatMessage({ id: 'assignments.doesntExists' }));
        self.setState({ activeModalTab: 0 }, () => {
          this.showTabModal();
        });
      }
    }
  }

  onStoredProjectClick(selectedDbProject, selectedOldSession = false, dbProjects) {
    if (dbProjects) {
      this.setState({ dbProjects, selectedDbProject, selectedOldSession });
      return;
    }

    this.setState({ selectedDbProject, selectedOldSession });
  }

  getprojectToLoad(userData, tabInfo, projectToClone, cloneFromMaster = false) {
    const newTabInfo = tabInfo;
    newTabInfo.name = projectToClone.title;
    newTabInfo.userId = projectToClone.userId;
    newTabInfo.description = projectToClone.description;
    return {
      id: (cloneFromMaster) ? null : projectToClone.id,
      userId: (cloneFromMaster) ? null : projectToClone.userId,
      name: projectToClone.title,
      description: projectToClone.description,
      boardType: projectToClone.boardType,
      tabInfo: newTabInfo,
      tags: [],
      workspace: (projectToClone.jsonBody) ? projectToClone.jsonBody.withWorkspace : '',
    };
  }

  getEnter() {
    const { form, minModalChars } = this.state;
    if (form.name.length >= minModalChars) {
      this.addTab(false, true);
      this.showTabModal();
    }
  }

  setZoom(direction) {
    const { tabs } = this.state;
    tabs.map((i) => {
      if (i.active) i.setZoom(direction);
      return i;
    });
  }

  setCenter() {
    const { tabs } = this.state;
    tabs.map((i) => {
      if (i.active) i.setCenter();
      return i;
    });
  }

  setUndo(direction) {
    const { tabs } = this.state;
    tabs.map((i) => {
      if (i.active) i.setUndo(direction);
      return i;
    });
  }


  getBoardList(boards, selectedGroupName) {
    const groups = {};
    for (let g = 0; g < boards.length; g += 1) {
      if (typeof groups[boards[g].groupName] === 'undefined') {
        groups[boards[g].groupName] = [];
      }
      groups[boards[g].groupName].push({ boardId: boards[g].id, ...boards[g] });
    }
    let list = [];
    if (selectedGroupName !== '' && groups[selectedGroupName] && groups[selectedGroupName].length > 1) {
      list = groups[selectedGroupName];
    } else {
      Object.entries(groups).forEach(([index, board]) => {
        const newBoard = board[0];
        newBoard.count = board.length;
        newBoard.tempName = index;
        list.push(newBoard);
      });
    }
    return { groups, list };
  }


  showHelpAgain() {
    const { tabs } = this.state;
    tabs.map((i) => {
      if (i.active) i.startSteps();
      return i;
    });
  }

  changeField(field, e) {
    const { form, remoteSessionObj } = this.state;
    const { intl } = this.props;

    form[field] = e.target.value;
    if (field === 'remoteSessionId') {
      let sessionExists = false;
      form.remoteSessionPasswordVisible = false;
      form.remoteSessionMsg = '';
      for (let i = 0; i < remoteSessionObj.length; i += 1) {
        if (remoteSessionObj[i].id === parseInt(e.target.value, 10)) {
          sessionExists = true;
          if (remoteSessionObj[i].password) {
            form.remoteSessionPasswordVisible = true;
            form.remoteSessionMsg = intl.formatMessage({ id: 'remoteSessions.toasts.sessionIsPrivate.message' });
          }
        }
      }
      if (!sessionExists) {
        form.remoteSessionMsg = intl.formatMessage({ id: 'remoteSessions.toasts.sessionDontExist.title' });
      }
    }
    this.setState({ form });
  }

  checkForActiveTabs() {
    const { tabs } = this.state;
    let activeTabs = false;

    if (tabs.length > 0) {
      activeTabs = (tabs.filter((tab) => tab.disabled === false).length > 0);
    }
    return activeTabs;
  }

  clickOnProfile(item) {
    this.changeField('boardIndex', { target: { value: item.id } });
    this.changeField('className', { target: { value: item.class } });
    this.changeField('boardName', { target: { value: item.name } });
    this.changeField('boardGroupName', { target: { value: '' } });
  }

  toggleActiveModalTab(tabIndex) {
    let { activeModalTab } = this.state;
    // activeModalTab = (activeModalTab === 0) ? 1 : 0;
    activeModalTab = tabIndex;
    this.setState({ activeModalTab });
  }

  changeToolboxToggle() {
    let { toolboxToggle } = this.state;
    toolboxToggle = !toolboxToggle;
    this.setState({ toolboxToggle });
  }

  toggleDarkTheme() {
    let { darkthemeToggle } = this.state;
    darkthemeToggle = !darkthemeToggle;

    if (!darkthemeToggle) window.document.querySelector('body').classList.add('theme-aula');
    else window.document.querySelector('body').classList.remove('theme-aula');

    this.setState({ darkthemeToggle });
  }

  tabDropdown(action) {
    let { tabDropdownOpen } = this.state;

    switch (action) {
      case 'open':
        tabDropdownOpen = true;
        break;
      case 'toggle':
        tabDropdownOpen = !tabDropdownOpen;
        break;
      default:
        tabDropdownOpen = false;
    }

    this.setState({ tabDropdownOpen });
  }

  askForRightPanel(tabIndex, type) {
    const { tabs } = this.state;
    const { GRPD } = this.props;
    GRPD({
      tutorialCodename: type,
      workspaceId: null,
      tabId: tabs[tabIndex].id,
    });
  }

  loadProjectTab() {
    const { dbProjects, projectFetchStatus, oldSessions, selectedDbProject, selectedOldSession, isLogged, tabs, boardType } = this.state;
    const { intl } = this.props;

    const loadingProjects = (
      <div className="anibot" />
    );

    const noProjects = (
      <div className="no-projects">
        <div className="no-projects-anibot-white" />
        <div className="no-projects-text">{intl.formatMessage({ id: 'blocks.profileSelector.project.load.noAvailableProjects' })}</div>
      </div>
    );

    // console.log('oldSessions------', oldSessions);
    const localProjectsList = (
      Object.entries(oldSessions).map((item) => (
        (item[1].boardType === boardType || (!item[1].boardType && boardType === 'senior')) ? (
          <li onKeyPress={() => { }} onClick={() => this.onStoredProjectClick(false, item[0])} className={`oldSession restoredSession ${item[1].tabInfo.board.class} ${(selectedOldSession === item[0]) ? 'selected-old-session' : ''}`}>
            <span className="oldSessionName">
              {item[1].tabInfo.name}
              <span className="oldSessionDate">
                <span className="oldSessionMarker">{intl.formatMessage({ id: 'blocks.profileSelector.project.load.unsavedRecovered' })}</span>
              </span>
            </span>
            <span className="oldSessionBoard">
              {intl.formatMessage({ id: 'blocks.profileSelector.project.load.profile' })}
              &nbsp;
              {item[1].tabInfo.board.name}
              <span className={`oldSessionBall ${item[1].tabInfo.board.class}`} />
            </span>
          </li>
        ) : ('')
      ))
    );

    const openedProjects = [];
    tabs.map((tab) => {
      if (!tab.disabled) { openedProjects.push(tab.projectId); }
      return tab;
    });

    const projectsAvailable = [];
    for (let i = 0; i < dbProjects.length; i += 1) {
      if (openedProjects.indexOf(dbProjects[i].id) === -1) {
        projectsAvailable.push(dbProjects[i]);
      }
    }

    const dbProjectsList = (
      dbProjects.map((item, index) => (
        (openedProjects.indexOf(item.id) === -1 && (item.boardType === boardType || (!item.boardType && boardType === 'senior') || (item.isCodeOnly && boardType === 'code'))) ? (
          <li onKeyPress={() => { }} onClick={() => this.onStoredProjectClick(index)} className={`oldSession ${item.tabInfo.board.class} ${(selectedDbProject === index) ? 'selected-old-session' : ''}`}>
            <span className="oldSessionName">
              {item.title}
              <span className="oldSessionDate">
                {intl.formatMessage({ id: 'blocks.profileSelector.project.load.lastModification' })}:
                {moment(item.updatedAt).format('D [de] MMMM H[:]mm [h]')}
              </span>
            </span>
            <span className="oldSessionBoard">
              {intl.formatMessage({ id: 'blocks.profileSelector.project.load.profile' })}
              &nbsp;
              {item.tabInfo.board.name}
              <span className={`oldSessionBall ${item.tabInfo.board.class}`} />
            </span>
          </li>
        )
          : ''
      ))
    );

    let html;

    if (!isLogged) {
      if (Object.entries(oldSessions).length === 0) {
        html = noProjects;
      } else {
        html = (
          <ul className="oldSessionsList">
            {localProjectsList}
          </ul>
        );
      }
    } else if (Object.entries(oldSessions).length === 0) {
      // 'fetching'
      if (projectFetchStatus === 1) {
        html = (
          <ul className="oldSessionsList">
            {loadingProjects}
          </ul>
        );
        // 'success'
      } else if (projectFetchStatus === 2 && projectsAvailable.length > 0) {
        html = (
          <ul className="oldSessionsList">
            {dbProjectsList}
          </ul>
        );
      } else {
        html = noProjects;
      }
    } else if (projectFetchStatus === 1) {
      html = (
        <ul className="oldSessionsList">
          {localProjectsList}
          {loadingProjects}
        </ul>
      );
    } else if (projectFetchStatus === 2 && projectsAvailable.length > 0) {
      html = (
        <ul className="oldSessionsList">
          {localProjectsList}
          {dbProjectsList}
        </ul>
      );
    } else {
      html = (
        <ul className="oldSessionsList">
          {localProjectsList}
        </ul>
      );
    }
    return html;
  }

  resetTabsStatus() {
    let { tabs } = this.state; // eslint-disable-line
    tabs = tabs.map((i) => {
      const newI = i;
      newI.active = false;
      return newI;
    });
  }

  toggleSettings(param) {
    const { settingActive } = this.state;
    this.setState({
      settingActive: (param !== undefined) ? param : !settingActive,
    });
  }

  showTabModal() {
    let { oldSessions } = this.state;
    const { tabs, boards } = this.state;
    const { showProfileSelector } = this.props;

    oldSessions = (sessionStorage.getItem('EduCodeGenerated')) ? JSON.parse(sessionStorage.getItem('EduCodeGenerated')) : {};
    // console.log('oldSessions', oldSessions);
    const filteredOldSessions = {};
    Object.entries(oldSessions).map((item) => {
      for (let i = 0; i < tabs.length; i += 1) {
        if ((item[0].replace('blockly-instance-', '') === tabs[i].id)) return oldSessions[item[0]];
      }
      filteredOldSessions[item[0]] = oldSessions[item[0]];
      return oldSessions[item[0]];
    });
    this.clickOnProfile(boards[0]);
    this.setState({
      oldSessions: filteredOldSessions,
      selectedOldSession: false,
    }, () => {
      showProfileSelector();
      document.querySelector('#exampleModalToggle').click();
    });
  }

  clickTab(tabItem) {
    let { tabs } = this.state;
    this.resetTabsStatus();
    tabs = tabs.map((i) => {
      const newI = i;
      newI.closeRightPanel();
      if (newI.id === tabItem.id) {
        newI.active = true;
      }
      return newI;
    });

    this.setState({
      tabs,
    });
  }

  workspaceAlreadyOpened(project) {
    const { tabs } = this.state;
    let tabItem = null;
    for (let i = 0; i < tabs.length; i += 1) {
      if (tabs[i].withWorkspace === project.tabInfo.withWorkspace) {
        tabItem = tabs[i];
        tabItem.disabled = false;
        break;
      }
    }
    return tabItem;
  }


  exportProject(tabItem) {
    const { tabs } = this.state;
    tabs[tabItem].export();
  }

  save(tabItem) {
    const { tabs } = this.state;
    tabs[tabItem].saveProjectInDb();
  }

  selectActiveTab(activeModalTab, callback) {
    this.setState({ activeModalTab }, callback);
  }

  exportSVG(tabItem) {
    const { tabs } = this.state;
    tabs[tabItem].exportSVG();
  }

  what2DoWhenBlocklyRender() {
    const { tabs } = this.state;
    // const { dbProjects, tabs } = this.state;
    // const { match } = this.props;
    // if (match.params.id) {
    //   const showModalDetails = !(dbProjects.filter(i => (Number(i.id) === Number(match.params.id))).length > 0);
    //   if (showModalDetails) this.askForRightPanel(0, 'details');
    // }
    for (let k = 0; k < tabs.length; k += 1) {
      tabs[k].initRemoteSession();
    }
  }

  addTab(loadState = false, blank = false) {
    const { tabs, form, selectedOldSession, selectedDbProject, dbProjects, oldSessions, boardType } = this.state;
    let { activeModalTab } = this.state;
    const { bloques, intl } = this.props;
    const dbProjectFromIndex = (selectedDbProject >= 0) ? (dbProjects[selectedDbProject] || false) : false;
    const oldSessionFromIndex = (selectedOldSession) ? (oldSessions[selectedOldSession] || false) : false;

    this.resetTabsStatus();
    /* eslint-disable */
    // const saveDataInfo = {};
    // if (dbProjectFromIndex) {
    //   saveDataInfo.id = dbProjectFromIndex.id;
    //   saveDataInfo.name = dbProjectFromIndex.title;
    //   saveDataInfo.description = (dbProjectFromIndex.description) ? dbProjectFromIndex.description : '';
    //   saveDataInfo.public = dbProjectFromIndex.isPublic;
    //   saveDataInfo.library = dbProjectFromIndex.isLibrary;
    //   saveDataInfo.userId = dbProjectFromIndex.userId;
    //   saveDataInfo.autoSave = dbProjectFromIndex.tabInfo.autoSave;
    //   saveDataInfo.remoteSession = dbProjectFromIndex.tabInfo.remoteSession;
    //   saveDataInfo.remoteProject = dbProjectFromIndex.tabInfo.remoteProject;
    //   // saveDataInfo.remoteAutoCompile = dbProjectFromIndex.tabInfo.remoteAutoCompile;
    //   saveDataInfo.remoteProjectPassword = dbProjectFromIndex.tabInfo.remoteProjectPassword;
    //   saveDataInfo.remoteProjectPasswordToggle = (dbProjectFromIndex.tabInfo.remoteProjectPasswordToggle);
    //   saveDataInfo.collaborationSession = dbProjectFromIndex.id;
    //   saveDataInfo.collaborationProject = dbProjectFromIndex.tabInfo.collaborationProject;
    //   saveDataInfo.assignmentSession = dbProjectFromIndex.tabInfo.assignmentSession;
    //   saveDataInfo.assignmentProject = dbProjectFromIndex.tabInfo.assignmentProject;
    //   saveDataInfo.assignmentProjectDeliveryDate = dbProjectFromIndex.tabInfo.assignmentProjectDeliveryDate;
    //   saveDataInfo.assignmentProjectPassword = dbProjectFromIndex.tabInfo.assignmentProjectPassword;
    //   saveDataInfo.assignmentProjectPasswordToggle = (dbProjectFromIndex.tabInfo.assignmentProjectPasswordToggle);

    //   saveDataInfo.videoUrl = dbProjectFromIndex.jsonBody.videoUrl;
    //   saveDataInfo.diagramImageUrl = dbProjectFromIndex.jsonBody.diagramImageUrl;
    //   saveDataInfo.challenge1 = (dbProjectFromIndex.jsonBody.challenges && dbProjectFromIndex.jsonBody.challenges[0]) ? dbProjectFromIndex.jsonBody.challenges[0] : '';
    //   saveDataInfo.challenge2 = (dbProjectFromIndex.jsonBody.challenges && dbProjectFromIndex.jsonBody.challenges[1]) ? dbProjectFromIndex.jsonBody.challenges[1] : '';
    //   saveDataInfo.challenge3 = (dbProjectFromIndex.jsonBody.challenges && dbProjectFromIndex.jsonBody.challenges[2]) ? dbProjectFromIndex.jsonBody.challenges[2] : '';
    //   saveDataInfo.extraMaterialUrl = dbProjectFromIndex.jsonBody.extraMaterialUrl;
    // }
    let newId = (loadState) ? loadState.tabInfo.id : (dbProjectFromIndex) ? dbProjectFromIndex.tabInfo.id : ((oldSessionFromIndex) ? oldSessionFromIndex.tabInfo.id : uuidv1());
    let existentTabIndex = (tabs.filter(i => i.id === newId).length > 0);
    if (!existentTabIndex) {
      let boardId = (loadState) ? loadState.tabInfo.board : (dbProjectFromIndex) ? dbProjectFromIndex.tabInfo.board : ((oldSessionFromIndex) ? oldSessionFromIndex.tabInfo.board : (form.boardIndex || 0));
      boardId = (!isNaN(boardId)) ? boardId : ((boardId.id) ? boardId.id : 0);
      const board = getBoardById(Boards, boardId || 0);
      // console.log('==========>>', loadState, dbProjectFromIndex, oldSessionFromIndex, form);
      tabs.push({
        tagsSelected: (loadState) ? loadState.tags : (dbProjectFromIndex) ? dbProjectFromIndex.Tags : [],
        projectId: (loadState && loadState.id) ? loadState.id : (dbProjectFromIndex) ? dbProjectFromIndex.id : null,
        id: (loadState) ? loadState.tabInfo.id : (dbProjectFromIndex && dbProjectFromIndex.tabInfo) ? dbProjectFromIndex.tabInfo.id : ((oldSessionFromIndex && oldSessionFromIndex.tabInfo) ? oldSessionFromIndex.tabInfo.id : uuidv1()),
        name: (loadState) ? loadState.name : (dbProjectFromIndex) ? dbProjectFromIndex.title : ((oldSessionFromIndex) ? oldSessionFromIndex.tabInfo.name : ((!blank) ? form.name : `${form.name} ${form.unsavedTabCounter}`)),
        description: (loadState) ? loadState.description : (dbProjectFromIndex) ? dbProjectFromIndex.description : ((oldSessionFromIndex) ? oldSessionFromIndex.tabInfo.description : ((form.description) ? form.description : '')),
        userId: (loadState) ? loadState.userId : (dbProjectFromIndex) ? dbProjectFromIndex.userId : ((oldSessionFromIndex) ? oldSessionFromIndex.tabInfo.userId : ((form.userId) ? form.userId : null)),
        isPublic:  (loadState) ? loadState.isPublic : (dbProjectFromIndex) ? dbProjectFromIndex.isPublic : ((oldSessionFromIndex) ? oldSessionFromIndex.isPublic : ((form.isPublic) ? form.isPublic : false)),
        isLibrary:  (loadState) ? loadState.isLibrary : (dbProjectFromIndex) ? dbProjectFromIndex.isLibrary : ((oldSessionFromIndex) ? oldSessionFromIndex.isLibrary : ((form.isLibrary) ? form.isLibrary : false)),
        active: true,
        board: board,
        port: (loadState) ? loadState.tabInfo.port : (dbProjectFromIndex) ? dbProjectFromIndex.tabInfo.port : ((oldSessionFromIndex) ? oldSessionFromIndex.tabInfo.port : (bloques.ports[0] || '')),
        boardName: board.Name || '',
        className: board.class || '',
        portOpen: false,
        disabled: false,
        withToolTip: (loadState) ? false : (((sessionStorage.getItem('showToolTip') && sessionStorage.getItem('showToolTip') === 'false')) ? false : true), // eslint-disable-line
        withWorkspace: (loadState) ? loadState.workspace : (dbProjectFromIndex) ? dbProjectFromIndex.tabInfo.withWorkspace : ((oldSessionFromIndex) ? oldSessionFromIndex.workspace : false),
        rightPanel: '',
        diagramImageUrl: (form.imageUrl) ? form.imageUrl : '',

        remoteSession: (form.remoteSession) ? form.remoteSession : ((loadState) ? loadState.tabInfo.remoteSession : (dbProjectFromIndex) ? dbProjectFromIndex.tabInfo.remoteSession : ((oldSessionFromIndex) ? oldSessionFromIndex.tabInfo.remoteSession : 0)),
        remoteProject: (form.remoteProject) ? form.remoteProject : ((loadState) ? loadState.tabInfo.remoteProject : (dbProjectFromIndex) ? dbProjectFromIndex.tabInfo.remoteProject : ((oldSessionFromIndex) ? oldSessionFromIndex.tabInfo.remoteProject : false)),
        remoteUserRole: (form.remoteUserRole) ? form.remoteUserRole : ((loadState) ? loadState.tabInfo.remoteUserRole : (dbProjectFromIndex) ? dbProjectFromIndex.tabInfo.remoteUserRole : ((oldSessionFromIndex) ? oldSessionFromIndex.tabInfo.remoteUserRole : 'STUDENT')),
        // remoteSession: (loadState) ? loadState.tabInfo.remoteSession : (dbProjectFromIndex) ? dbProjectFromIndex.tabInfo.remoteSession : ((oldSessionFromIndex) ? oldSessionFromIndex.tabInfo.remoteSession : ((!blank) ? form.remoteSession : 0)),
        // remoteProject: (loadState) ? loadState.tabInfo.remoteProject : (dbProjectFromIndex) ? dbProjectFromIndex.tabInfo.remoteProject : ((oldSessionFromIndex) ? oldSessionFromIndex.tabInfo.remoteProject : ((!blank) ? form.remoteProject : false)),
        // remoteUserRole: (loadState) ? loadState.tabInfo.remoteUserRole : ((form.remoteUserRole) ? form.remoteUserRole : 'STUDENT'),
        remoteSessionStarted: (!blank) ? form.remoteSessionStarted : false,
        remoteSessionNeedInit: (form.remoteSessionNeedInit) ? form.remoteSessionNeedInit : false,
        remoteProjectPassword: (loadState) ? loadState.tabInfo.remoteProjectPassword : ((dbProjectFromIndex) ? dbProjectFromIndex.tabInfo.remoteProjectPassword : null),
        remoteProjectPasswordToggle: (loadState) ? (loadState.tabInfo.remoteProjectPasswordToggle) : false,
        // remoteAutoCompile: (loadState && loadState.tabInfo.remoteAutoCompile) ? loadState.tabInfo.remoteAutoCompile : true,

        assignmentSession: (form.assignmentSession) ? form.assignmentSession : ((loadState) ? loadState.tabInfo.assignmentSession : (dbProjectFromIndex) ? dbProjectFromIndex.tabInfo.assignmentSession : ((oldSessionFromIndex) ? oldSessionFromIndex.tabInfo.assignmentSession : 0)),
        assignmentProject: (form.assignmentProject) ? form.assignmentProject : ((loadState) ? loadState.tabInfo.assignmentProject : (dbProjectFromIndex) ? dbProjectFromIndex.tabInfo.assignmentProject : ((oldSessionFromIndex) ? oldSessionFromIndex.tabInfo.assignmentProject : false)),
        assignmentUserRole: (form.assignmentUserRole) ? form.assignmentUserRole : ((loadState) ? loadState.tabInfo.assignmentUserRole : (dbProjectFromIndex) ? dbProjectFromIndex.tabInfo.assignmentUserRole : ((oldSessionFromIndex) ? oldSessionFromIndex.tabInfo.assignmentUserRole : 'STUDENT')),
        // assignmentSession: (loadState) ? loadState.tabInfo.assignmentSession : (dbProjectFromIndex) ? dbProjectFromIndex.tabInfo.assignmentSession : ((oldSessionFromIndex) ? oldSessionFromIndex.tabInfo.assignmentSession : ((!blank) ? form.assignmentSession : 0)),
        // assignmentProject: (loadState) ? loadState.tabInfo.assignmentProject : (dbProjectFromIndex) ? dbProjectFromIndex.tabInfo.assignmentProject : ((oldSessionFromIndex) ? oldSessionFromIndex.tabInfo.assignmentProject : ((!blank) ? form.assignmentProject : false)),
        // assignmentUserRole: (loadState) ? loadState.tabInfo.assignmentUserRole : ((form.assignmentUserRole) ? form.assignmentUserRole : 'STUDENT'),
        assignmentProjectDeliveryDate: (loadState) ? loadState.tabInfo.assignmentProjectDeliveryDate : ((dbProjectFromIndex) ? dbProjectFromIndex.tabInfo.assignmentProjectDeliveryDate : ''),
        assignmentProjectPassword: (loadState) ? loadState.tabInfo.assignmentProjectPassword : ((dbProjectFromIndex) ? dbProjectFromIndex.tabInfo.assignmentProjectPassword : null),
        assignmentProjectPasswordToggle: (loadState) ? (loadState.tabInfo.assignmentProjectPasswordToggle) : false,

        collaborationSession: (loadState) ? loadState.id : (dbProjectFromIndex) ? dbProjectFromIndex.id : 0,
        collaborationProject: (loadState) ? loadState.tabInfo.collaborationProject : (dbProjectFromIndex) ? dbProjectFromIndex.tabInfo.collaborationProject : ((oldSessionFromIndex) ? oldSessionFromIndex.tabInfo.collaborationProject : false),
        collaborationProjectUserRole: (loadState) ? loadState.tabInfo.collaborationProjectUserRole : 'OWNER',

        boardType: (loadState) ? loadState.boardType : ((form.boardType) ? form.boardType : boardType),
        code: (loadState) ? loadState.tabInfo.code : (dbProjectFromIndex) ? dbProjectFromIndex.tabInfo.code : ((oldSessionFromIndex) ? oldSessionFromIndex.tabInfo.code : (form.code || '')),

        simulator: (loadState) ? loadState.tabInfo.simulator : (dbProjectFromIndex && dbProjectFromIndex.tabInfo) ? dbProjectFromIndex.tabInfo.simulator : ((oldSessionFromIndex && oldSessionFromIndex.tabInfo) ? oldSessionFromIndex.tabInfo.simulator : null),
      });
      if (boardType === 'junior') {
        this.setState({ boards: getBoardsByCosmos('junior') });
      } else if (boardType === 'code') {
        this.setState({ boards: getBoardsByCosmos('code') });
      }

    } else {
      for (let i = 0; i < tabs.length; i += 1) {
        if (tabs[i].id === newId) {
          tabs[i].active = true;
          tabs[i].disabled = false;
        }
      }
    }
    /* eslint-enabled */
    form.name = intl.formatMessage({ id: 'projects.unnamed' });
    form.unsavedTabCounter += 1;
    activeModalTab = 0;
    this.tabDropdown('close');
    this.setState({
      selectedDbProject: false,
      selectedOldSession: false,
      tabs,
      form,
      activeModalTab,
    });
  }

  closeTab(e, tabItem, index, silent = false) {
    const { boardType } = this.state;
    const { intl, showToast, match } = this.props;

    if (boardType === 'junior') {
      if (process.env.IS_ELECTRON) {
        window.location = `${window.location.pathname}#/junior/${Math.random()}`;
      } else {
        window.location = `${routes.bloquesUri}/junior`;
      }
    } else if (!process.env.IS_ELECTRON && match.url && (((match.url.indexOf('actividad-') !== -1 || match.url.indexOf('remote-') !== -1) && boardType === 'code') || match.url.indexOf('codigo-') !== -1)) {
      window.location = `${routes.bloquesUri}/codigo`;
    } else if (!process.env.IS_ELECTRON && match.url && (match.url.indexOf('colaboracion-') !== -1 || match.url.indexOf('actividad-') !== -1 || match.url.indexOf('remote-') !== -1 || match.url.indexOf('bloques-') !== -1)) {
      window.location = `${routes.bloquesUri}/bloques`;
    } else {

      if (e) e.stopPropagation();
      if (e) e.preventDefault();
      const { tabs } = this.state;
      const tabsNotDisabled = tabs.filter(t => !t.disabled);

      for (let i = 0; i < tabsNotDisabled.length; i += 1) {
        if (tabsNotDisabled[i].id === tabItem.id) {
          tabsNotDisabled[i].disabled = true;
          tabsNotDisabled[i].active = false;
          this.askForRightPanel(index, 'close');
          if (tabsNotDisabled.length > 1) {
            if (i > 0 && tabsNotDisabled[i - 1]) {
              tabsNotDisabled[i - 1].active = true;
              tabsNotDisabled[i - 1].disabled = false;
            } else if (i < tabsNotDisabled.length && tabs[i + 1]) {
              tabsNotDisabled[i + 1].active = true;
              tabsNotDisabled[i + 1].disabled = false;
            }
          }
        }
      }

      const finalTabs = tabs.map(obj => tabsNotDisabled.find(o => o.id === obj.id) || obj);
      if (!silent) {
        showToast({
          type: 'success',
          message: intl.formatMessage({ id: 'projects.toasts.closed.message' }),
          options: {
            autoClose: true,
          },
        });
      }
      this.tabDropdown('close');
      this.setState({ tabs: finalTabs });
      if (!this.checkForActiveTabs()) this.showTabModal();
    }
  }

  onLoadProject(time = 3000, callback) {
    const { showToast, intl } = this.props;
    setTimeout(() => {
      showToast({
        type: 'success',
        message: intl.formatMessage({ id: 'projects.toasts.loaded.message' }),
        options: {
          autoClose: true,
        },
      });

      if (callback) {
        callback();
      }
    }, time);
  }

  onBoardItemClick(item) {
    if (item.enabled === 1) {
      this.changeField('boardIndex', { target: { value: item.boardId } });
      this.changeField('boardName', { target: { value: item.name } });
      this.changeField('boardGroupName', { target: { value: item.groupName } });
      this.changeField('className', { target: { value: item.class } });
    }
  }

  toastSend(message, containerId = 'B', options = {}) {
    if (typeof window.toast === 'function') {
      if (containerId === 'R') {
        const toastOptions = {};
        toastOptions.position = 'bottom-center';
        toastOptions.className = `toast-remote ${(options.className) ? options.className : ''}`;
        toastOptions.autoClose = (typeof options.autoClose !== 'undefined') ? options.autoClose : 5000;
        toastOptions.hideProgressBar = true;
        toastOptions.containerId = 'B';
        window.toast(message, toastOptions);
      } else {
        window.toast(message, { containerId });
      }
    }
  }

  loaderOverAll() {
    const { loading } = this.state;
    return (
      <div className={`${(loading) ? 'show' : ''} loaderOverAll fade`}>
        <div className="anibot-large-white" />
      </div>
    );
  }


  renderElectronAutoupdater() {
    const { electronUpdateAvailable } = this.state;
    
    let progress = {};
    window.ipcRenderer.on('updater-message', (event, arg) => {
      switch (arg) {
        case 'UPDATE_AVAILABLE':
          this.setState({ electronUpdateAvailable: true });
          // there is an update
          const ele = document.querySelector('#electron-autoupdater');
          ele.classList.add('active');
          break;
        case 'ERROR':
          // error
          showError();
          break;
        case 'UPDATE_READY':
          this.setState({ electronUpdateAvailable: true });
          // ready to install
          showInstall();
          break;
        default:
          this.setState({ electronUpdateAvailable: true });
          // percentage sent
          progress = JSON.parse(arg);
          const barStatus = document.querySelector('#updater-progress-bar-status');
          const barStatusText = document.querySelector('#updater-progress-bar-status-text');
          const barStatusRemaining = document.querySelector('#updater-progress-remaining');
          if (barStatus) {
            barStatus.style.width = `${parseInt(progress.percent)}%`;
          }
          if (barStatusText) {
            barStatusText.innerHTML = `${parseInt(progress.percent)}%`;
          }
          if (barStatusRemaining) {
            barStatusRemaining.innerHTML = `${Math.round(progress.transferred / 1000000)} / ${Math.round(progress.total / 1000000)} Mb`;
          }
          break;
      }
      console.log(arg);
    });

    window.ipcRenderer.on('message', (event, arg) => {
      console.log(arg);
    });

    const closeModal = () => {
      const ele = document.querySelector('#electron-autoupdater');
      if (ele && ele.classList.contains('active')) {
        ele.classList.remove('active');
      }
    }

    const showError = () => {
      const ele = document.querySelector('#electron-autoupdater');
      if (ele && !ele.classList.contains('active')) {
        ele.classList.add('active');
      }
      const ele2 = document.querySelector('#electron-progress-update');
      if (ele2 && ele2.classList.contains('visible')) {
        ele2.classList.add('hidden');
        ele2.classList.remove('visible');
      }
      const ele3 = document.querySelector('#electron-install-update');
      if (ele3 && ele3.classList.contains('visible')) {
        ele3.classList.add('hidden');
        ele3.classList.remove('visible');
      }
      const ele4 = document.querySelector('#electron-download-update');
      if (ele4 && ele4.classList.contains('visible')) {
        ele4.classList.add('hidden');
        ele4.classList.remove('visible');
      }

      const eleUpdate = document.querySelector('#electron-error-update');
      const eleCheck = document.querySelector('#electron-error-check');
      if (electronUpdateAvailable) {
        if (eleUpdate && eleUpdate.classList.contains('hidden')) {
          eleUpdate.classList.add('visible');
          eleUpdate.classList.remove('hidden');
        }
        if (eleCheck && eleCheck.classList.contains('visible')) {
          eleCheck.classList.remove('visible');
          eleCheck.classList.add('hidden');
        }
      } else {
        if (eleCheck && eleCheck.classList.contains('hidden')) {
          eleCheck.classList.add('visible');
          eleCheck.classList.remove('hidden');
        }
        if (eleUpdate && eleUpdate.classList.contains('visible')) {
          eleUpdate.classList.remove('visible');
          eleUpdate.classList.add('hidden');
        }
      }

    }

    const showInstall = () => {
      const ele = document.querySelector('#electron-autoupdater');
      if (ele && !ele.classList.contains('active')) {
        ele.classList.add('active');
      }
      const ele2 = document.querySelector('#electron-progress-update');
      if (ele2 && ele2.classList.contains('visible')) {
        ele2.classList.add('hidden');
        ele2.classList.remove('visible');
      }
      const ele3 = document.querySelector('#electron-install-update');
      if (ele3 && ele3.classList.contains('hidden')) {
        ele3.classList.add('visible');
        ele3.classList.remove('hidden');
      }
    }

    const sendDownloadResponse = () => {
      // TODO: Add intl to electron
      const ele = document.querySelector('#electron-download-update');
      if (ele && ele.classList.contains('visible')) {
        ele.classList.remove('visible');
        ele.classList.add('hidden');
      }
      const ele2 = document.querySelector('#electron-progress-update');
      if (ele2 && ele2.classList.contains('hidden')) {
        ele2.classList.remove('hidden');
        ele2.classList.add('visible');
      }
      window.ipcRenderer.send('renderer-message', 'DOWNLOAD');
    };

    const sendInstallResponse = () => {
      window.ipcRenderer.send('renderer-message', 'INSTALL');
    };

    return (
      <div className="electron-autoupdater" id="electron-autoupdater">
        <div className="close-panel" onClick={closeModal} onKeyPress={() => { }}>
          <span className="icon-cross11" />
        </div>

        <div className="download-update visible" id="electron-download-update">
          {/* <h6>{intl.formatMessage({ id: 'offline.update.title' })}</h6> */}
          <h6>¡Hay una nueva versión disponible!</h6>
          <div className="footer">
            <div className="actionButtons">
              {/* <TextButton className="install-later" onClick={closeModal}>{intl.formatMessage({ id: 'offline.update.downloadLater' })}</TextButton>
              <ButtonV2 className="install-now" onClick={sendDownloadResponse}>{intl.formatMessage({ id: 'offline.update.downloadNow' })}</ButtonV2> */}
              <TextButton className="install-later" onClick={closeModal}>Recordarme luego</TextButton>
              <ButtonV2 className="install-now" onClick={sendDownloadResponse}>Descargar ahora</ButtonV2>
            </div>
          </div>
        </div>

        <div className="progress-update hidden" id="electron-progress-update">
          {/* <h6>{intl.formatMessage({ id: 'offline.update.downloading' })}</h6> */}
          <h6>Descargando...</h6>
          <div className="updater-progress">
            <div className="updater-progress-bar">
              <div className="updater-progress-bar-status" id="updater-progress-bar-status" style={{ width: '0%' }}></div>
            </div>
            <h4 id="updater-progress-bar-status-text">0%</h4>
          </div>
          <div className="updater-progress-remaining" id="updater-progress-remaining">0 / 0 Mb</div>
        </div>

        <div className="error-update hidden" id="electron-error-check">
          {/* <h6>{intl.formatMessage({ id: 'offline.update.connectionError.title' })}</h6>
          <div className="error-text">{intl.formatMessage({ id: 'offline.update.connectionError.message' })}</div> */}
          <h6>Error de conexión</h6>
          <div className="error-text">No se puede comprobar si hay actualizaciones. Por favor revisa tu conexión a internet y reinicia la aplicación.</div>
        </div>

        <div className="error-update hidden" id="electron-error-update">
          {/* <h6>{intl.formatMessage({ id: 'offline.update.downloadError.title' })}</h6>
          <div className="error-text">{intl.formatMessage({ id: 'offline.update.downloadError.message' })}</div> */}
          <h6>Error de descarga</h6>
          <div className="error-text">Ocurrió un error durante la descarga. Por favor reinicia la aplicación para volver a intentarlo.</div>
        </div>

        <div className="install-update hidden" id="electron-install-update">
          {/* <h6>{intl.formatMessage({ id: 'offline.update.updateReady' })}</h6> */}
          <h6>¡Actualización lista para instalar!</h6>
          <div className="footer">
            <div className="actionButtons">
              {/* <TextButton className="install-later" onClick={closeModal}>{intl.formatMessage({ id: 'common.close' })}</TextButton>
              <ButtonV2 className="install-now" onClick={sendInstallResponse}>{intl.formatMessage({ id: 'offline.update.installRestart' })}</ButtonV2> */}
              <TextButton className="install-later" onClick={closeModal}>Cerrar</TextButton>
              <ButtonV2 className="install-now" onClick={sendInstallResponse}>Instalar y reiniciar</ButtonV2>
            </div>
          </div>
        </div>

      </div>
    );
  }

  render() {
    const { form, remoteSessionIAmTheOwner, isContenidista, rightPanelStatus, darkthemeToggle, maxTabs, selectedDbProject, tabs, responsive, boards, toolboxToggle, tabDropdownOpen, haveInternet, settingActive, scrollTabName, boardType } = this.state;
    const { tags, getAssignmentDeliverableState, match, intl } = this.props;
    const { userData } = this.props.auth;
    const isLogged = (Object.entries(userData).length > 0);
    const showAddBtn = ((tabs.filter(i => !i.disabled).length < maxTabs) && (tabs.length <= 0 || tabs[0].boardType !== 'junior'));

    return (
      <div className="App">
        {(!process.env.IS_ELECTRON && ((tabs.length > 0 && form.remoteSessionId === '') || remoteSessionIAmTheOwner)) ? (
          <ModalBrowser />
        ) : ('')}
        {this.loaderOverAll()}
        <ProfileSelector
          addTab={this.addTab}
          changeField={this.changeField}
          selectActiveTab={this.selectActiveTab}
          checkForActiveTabs={this.checkForActiveTabs}
          onBoardItemClick={this.onBoardItemClick}
          onStoredProjectClick={this.onStoredProjectClick}
        />
        <Header />
        <button type="button" className="d-none" data-toggle="modal" data-target="#exampleModal" id="exampleModalToggle" />
        <div className="tabManager">
          <ul className="tabs">
            {tabs.map((i, index) => (
              <li key={`keyName-${i.id}`} className={`tab ${(i.disabled) ? 'd-none' : ''} ${i.board.class}-tab ${(i.active) ? 'tab-active' : ''}`}>
                <div className="tabRectangle" onKeyPress={() => { }} onClick={() => this.clickTab(i)}>
                  <span className="tabName" onDoubleClick={() => this.setState({ scrollTabName: !scrollTabName })}>
                    <span className={`tabText ${(i.name.length > 17 && i.active && scrollTabName) ? 'scroll' : ''}`}>
                      {(i.name.length <= 17 || i.active) ? i.name : `${i.name.substring(0, 17)}...`}
                    </span>
                  </span>
                  {(i.active) && (<span className={`options-tab ${tabDropdownOpen ? 'open' : ''}`} aria-hidden="true" onClick={() => this.tabDropdown('toggle')} onKeyPress={() => { }}><span className="icon-dots-horizontal-triple" /></span>)}
                  {(tabDropdownOpen && i.active) && (
                    <div className="tab-dropdown" onClick={() => this.tabDropdown('close')} onKeyPress={() => { }}>
                      <div className="tab-dropdown-close-clickability" />
                      <ul className="tab-dropdown-options">
                        {(!i.projectId && !process.env.IS_ELECTRON && getAssignmentDeliverableState) && (
                          <li className="tab-save" onClick={() => this.askForRightPanel(index, 'save')} onKeyPress={() => { }}>
                            <span className="tab-dropdown-icon icon-save" />
                            {intl.formatMessage({ id: 'projects.save' })}
                          </li>
                        )}
                        {(i.projectId && isLogged && i.userId === userData.data.userId && !process.env.IS_ELECTRON && getAssignmentDeliverableState) && (
                          <li className="tab-export" onClick={() => this.save(index)} onKeyPress={() => { }}>
                            <span className="tab-dropdown-icon icon-save" />
                            {intl.formatMessage({ id: 'projects.save' })}
                          </li>
                        )}
                        {(i.projectId && isLogged && i.userId !== userData.data.userId && (!process.env.IS_ELECTRON)) && (
                          <li className="tab-save" onClick={() => this.askForRightPanel(index, 'save')} onKeyPress={() => { }}>
                            <span className="tab-dropdown-icon icon-save" />
                            {intl.formatMessage({ id: 'projects.clone' })}
                          </li>
                        )}
                        <li className="tab-export" onClick={() => this.exportProject(index)} onKeyPress={() => { }}>
                          <span className="tab-dropdown-icon icon-cloud-download" />
                          {intl.formatMessage({ id: 'projects.export' })}
                        </li>
                        {(isContenidista && boardType !== 'code') && (
                          <li className="tab-export-svg" onClick={() => this.exportSVG(index)} onKeyPress={() => { }}>
                            <span className="tab-dropdown-icon icon-export" />
                            {intl.formatMessage({ id: 'projects.saveSVG' })}
                          </li>
                        )}
                        {((i.projectId || i.assignmentProject || i.remoteProject || i.collaborationProject) && !process.env.IS_ELECTRON) && (
                          <li className="tab-details" onClick={() => this.askForRightPanel(index, 'details')} onKeyPress={() => { }}>
                            <span className="tab-dropdown-icon icon-information-solid" />
                            {intl.formatMessage({ id: 'projects.details' })}
                          </li>
                        )}
                        <li className="tab-close" onClick={e => this.closeTab(e, i, index)} onKeyPress={() => { }}>
                          <span className="tab-dropdown-icon icon-cross" />
                          {intl.formatMessage({ id: 'projects.close' })}
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </li>
            ))}
            {(showAddBtn && (
              <li className="addTabBtn" onKeyPress={() => { }} onClick={this.showTabModal.bind(this)}>
                <div onKeyPress={() => { }}> + </div>
              </li>
            ))}
          </ul>
          <div className="buttons">
            <div className="triangle" />
            <div className="triggers-col">
              <div className="m-auto text-center w-100">
                <div className="d-inline-block">
                  <button type="button" className="btn-transparent zoom-in" onClick={() => this.setZoom('up')}>
                    <span className="icon-zoom-in" />
                  </button>
                </div>
                <div className="d-inline-block">
                  <button type="button" className="btn-transparent zoom-out" onClick={() => this.setZoom('down')}>
                    <span className="icon-zoom-out" />
                  </button>
                </div>
                <div className="d-inline-block">
                  <button type="button" className="btn-transparent center" onClick={() => this.setCenter()}>
                    <span className="icon-radio-checked" />
                  </button>
                </div>
                <div className="d-inline-block">
                  <button type="button" className="btn-transparent undo2" onClick={() => this.setUndo(false)}>
                    <span className="icon-undo2" />
                  </button>
                </div>
                <div className="d-inline-block">
                  <button type="button" className="btn-transparent undo2 inverted" onClick={() => this.setUndo(true)}>
                    <span className="icon-undo2" />
                  </button>
                </div>
                <div className={`d-inline-block p-relative ${(settingActive) ? 'active' : ''}`}>
                  <button type="button" className="btn-transparent zoom-in" onClick={() => this.toggleSettings()}>
                    <span className="icon-cog1" />
                  </button>
                  {(settingActive) && (
                    <div className="settings tab-dropdown" onClick={() => this.toggleSettings(false)}>
                      <div className="tab-dropdown-close-clickability" />
                      <ul className="tab-dropdown-options">
                        <li className="tab-save" onClick={() => { this.changeToolboxToggle(); }} onKeyPress={() => { }}>
                          <span className="" onKeyPress={() => { }} >
                            {(toolboxToggle) && (intl.formatMessage({ id: 'projects.modeTxt' }))}
                            {(!toolboxToggle) && (intl.formatMessage({ id: 'projects.modeIco' }))}
                          </span>
                        </li>
                        <li className="tab-save" onClick={() => { this.toggleDarkTheme(); }} onKeyPress={() => { }}>
                          <span className="" onKeyPress={() => { }} >
                            {(darkthemeToggle) && (intl.formatMessage({ id: 'projects.modeLight' }))}
                            {(!darkthemeToggle) && (intl.formatMessage({ id: 'projects.modeDark' }))}
                          </span>
                        </li>
                        <li className="tab-save" onClick={() => { this.showHelpAgain(); }} onKeyPress={() => { }}>
                          <span className="" onKeyPress={() => { }} >
                            {intl.formatMessage({ id: 'projects.showHelp' })}
                          </span>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="panel-shadow ">
            {tabs.map(i => (
              <BlocklyPanel match={match} boards={Boards} doOnReady={this.what2DoWhenBlocklyRender} tags={tags.dataGet} closeTabHandler={() => { this.closeTab(null, i); }} tabInfo={i} darkthemeToggle={darkthemeToggle} toolboxToggle={toolboxToggle} id={`blockly-instance-${i.id}`} key={`blockly-instance-${i.id}`} className={`${(i.disabled) ? 'd-none' : ''} ${(i.active) ? 'active' : ''} `} />
            ))}
          </div>
        </div>
        {(process.env.IS_ELECTRON) ? (
          this.renderElectronAutoupdater()
        ) : ('')}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  rightPanel: state.rightPanelReducer,
  bloques: state.bloquesReducer,
  auth: state.authReducer,
  projects: state.projectsReducer,
  tags: state.tagsReducer,
  remoteSession: state.remoteSessionReducer,
  assignmentSession: state.assignmentSessionReducer,
  getAssignmentDeliverableState: actionGetAssignmentDeliverableState(state),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    checkAuth: checkAuthActionExternal,
    GRPD: getRightPanelData,
    projectsUserGetAll: actionProjectsUserGetAll,
    projectsGetOne: actionProjectsGetOne,
    resetStatus: actionResetStatus,
    tagsGet: actionTagsGet,
    remoteSessionGet: actionRemoteSessionGet,
    resetRemoteSessionStatus: actionResetRemoteSessionStatus,
    remoteSessionLoginGet: actionRemoteSessionLoginGet,
    remoteSessionGetList: actionRemoteSessionGetList,
    assignmentSessionGet: actionAssignmentSessionGet,
    resetAssignmentSessionStatus: actionResetAssignmentSessionStatus,
    assignmentSessionLoginGet: actionAssignmentSessionLoginGet,
    assignmentSessionGetList: actionAssignmentSessionGetList,
    setAssignmentDeliverableState: actionSetAssignmentDeliverableState,
    collabProjectsGetOne: actionCollabProjectsGetOne,
    showProfileSelector: showProfileSelectorAction,
    hideProfileSelector: hideProfileSelectorAction,
    showToast: showToastAction
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(App));
