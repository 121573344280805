import axios from 'axios';
import React from 'react';
import FacebookLogin from 'react-facebook-login';
import { GoogleLogin } from 'react-google-login';
import * as routes from '@educabot/educablocks-cosmos';

// const F  B = (typeof window !== 'undefined') ? window.FB : {};

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitForm: props.submitForm,
      embebLogin: props.embebLogin || false,
      checkAuth: props.checkAuth || (() => {}),
      checkedChange: props.checkedChange,
      checked: props.checked,
      form: props.form,
      resetPassLink: props.resetPassLink,
      signupLink: props.signupLink,
      loginLink: props.loginLink,
      referrer: props.referrer,
      showSocialFacebook: props.showSocialFacebook || false,
      showSocialGoogle: props.showSocialGoogle || false,
      FB: null,
      isLoading: false,
      loginOptions: routes.getCosmos('loginOptions'),
    }
    this.changeField = this.changeField.bind(this);
    this.checkSessionFacebook = this.checkSessionFacebook.bind(this);
    this.checkSessionGoogle = this.checkSessionGoogle.bind(this);
    this.showError = this.showError.bind(this);
    this.showPass = this.showPass.bind(this);
  }

  componentDidMount() {
    const timeoutFN = function (self) {
      setTimeout(() => {
        if (window.FB) self.setState({ FB: window.FB });
        else timeoutFN(self);
      }, 500);
    };

    timeoutFN(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      submitForm: newProps.submitForm,
      checkedChange: newProps.checkedChange,
      embebLogin: newProps.embebLogin || false,
      checkAuth: newProps.checkAuth || (() => {}),
      checked: newProps.checked,
      form: newProps.form,
      resetPassLink: newProps.resetPassLink,
      signupLink: newProps.signupLink,
      loginLink: newProps.loginLink,
      showSocialFacebook: newProps.showSocialFacebook || false,
      showSocialGoogle: newProps.showSocialGoogle || false,
      socialMediaError: null,
      formError: newProps.formError || null,
    });
  }

  checkSessionGoogle(data) {
    const { embebLogin, checkAuth, referrer, loginOptions } = this.state;
    const self = this;
    self.setState({ isLoading: true });
    axios.post(`${routes.apiUri}/google/front`, { ...data, user: { id: data.profileObj.googleId, firstName: data.profileObj.givenName, lastName: data.profileObj.familyName, email: data.profileObj.email, avatarUrl: data.profileObj.imageUrl } })
      .then((_) => {
        if (embebLogin) {
          setTimeout(() => {
            self.setState({ isLoading: false });
            checkAuth(false);
          }, 2000);
        } else if (referrer) {
          window.location = referrer;
        } else {
          window.location = routes.bloquesUri
        }
      }).catch((err) => {
        console.log('err', err)
        self.setState({ isLoading: false, socialMediaError: loginOptions.formError || 'Hubo un problema con el ingreso. Por favor, pruebe otro momento.' });
      })
  }

  showError() {
    const { loginOptions } = this.state;
    this.setState({ isLoading: false, socialMediaError: loginOptions.formError || 'Hubo un problema con el ingreso. Por favor, pruebe otro momento.' });
  }

  checkSessionFacebook(data) {
    const { embebLogin, checkAuth, referrer, loginOptions } = this.state;
    const self = this;
    self.setState({ socialMediaError: null }, () => {
      if (window.FB) {
        self.setState({ isLoading: true });
        window.FB.api('/me?fields=first_name,last_name,email',
          (response) => {
            if (response && !response.error) {
              axios.post(`${routes.apiUri}/facebook/front`, { ...data, user: { id: response.id, firstName: response.first_name, lastName: response.last_name, email: response.email } })
                .then((_) => {
                  if (embebLogin) {
                    setTimeout(() => {
                      self.setState({ isLoading: false });
                      checkAuth(false);
                    }, 2000);
                  } else if (referrer) {
                    window.location = referrer;
                  } else {
                    window.location = routes.bloquesUri
                  }
                }).catch((err) => {
                  console.log('err', err)
                  self.setState({ isLoading: false, socialMediaError: loginOptions.formError || 'Hubo un problema con el ingreso. Por favor, pruebe otro momento.' });
                })
            } else {
              self.setState({ isLoading: false, socialMediaError: loginOptions.formError || 'Hubo un problema con el ingreso. Por favor, pruebe otro momento.' });
            }
          })
      }
    })
  }

  changeField(field, e) {
    const { form } = this.state;
    this.changeField = this.changeField.bind(this);
    form[field] = e.target.value;
    this.setState({ form });
  }

  showPass() {
    const { form } = this.state;
    form.hidden = !form.hidden;
    this.setState({ form });
  }

  render() {
    const { submitForm, checkedChange, checked, form, resetPassLink, signupLink, showSocialGoogle, showSocialFacebook, formError, socialMediaError, FB, isLoading, loginOptions } = this.state;
    return (
      <form className={!loginOptions.footer && 'vertical-center'}>
        { loginOptions.footer
          && <div className="empty" />}
        <div>
          <div className={`form-group header ${!loginOptions.loginForm && 'header-center'}`}>
            { loginOptions.regular
              ? (
                <span className="first-line"><img alt="" src={`${routes.bloquesUri}/images/hello.png`} />{(loginOptions.formTitle || 'Iniciar sesión en Robots')}</span>
              )
              : (
                <div className="hello-logo">
                  <span className="hello"><img alt="" src={`${routes.bloquesUri}/images/hello.png`} /></span>
                  <span className="first-line">{(loginOptions.formTitle || 'Iniciar sesión en Robots')}</span>
                </div>
              )}
            <span className="second-line">{loginOptions.formText}</span>
          </div>
          { loginOptions.loginForm
            && (
            <>
              <div className="form-group">
                <label htmlFor="Email">{(loginOptions.formUser || 'Correo electrónico')} *</label>
                <input className={formError && 'input-error'} type="email" id="email" value={form.email} htmlref="Email" onChange={(e) => this.changeField('email', e)} />
                {/* <InputComponent changeField={this.changeField} form={form} name="Email" type="email" keyname="email" /> */}
              </div>
              <div className="form-group">
                <label htmlFor="Password">{(loginOptions.formPassword || 'Contraseña')} *</label>
                <input className={formError && 'input-error'} type={form.hidden ? 'password' : 'text'} id="password" value={form.password} htmlref="Password" onChange={(e) => this.changeField('password', e)} />
                {/* <InputComponent changeField={this.changeField} form={form} name="Password" type="password" keyname="password" /> */}
                <a href={resetPassLink} to="/recuperar">{(loginOptions.formForgot || '¿Olvidaste tu contraseña?')}</a>
              </div>
              {formError && (
              <div className="error">{formError}</div>
              )}
              <div className="form-group btn-action">
                {/* <a href={signupLink} className="d-block text-right" to="/registrar">¿No tenés usuario?</a> */}
                <button type="submit" onClick={submitForm} className="logBtn btn-primary btn-block btn-lg mt-40">
                  {(loginOptions.formEnter || 'Ingresar')}
                </button>
              </div>
              <div className="form-group social-media-label"><span>{(loginOptions.formContinue || 'o continuá con')}</span></div>
            </>
            )}
          {socialMediaError && (
            <div><p class="center c-red">{socialMediaError}</p></div>
          )}
          <div className={`form-group social-media ${loginOptions.loginGoogleOptions.className}`}>
            <>
              <div id="g_spinner" class={`${(!showSocialGoogle) ? 'd-none' : ''}`}>
                <GoogleLogin
                  clientId={routes.googleID}
                  onSuccess={this.checkSessionGoogle}
                  render={(renderProps) => (
                    <button type="button" className="login-button" onClick={renderProps.onClick} disabled={renderProps.disabled}>
                      <img alt="" src={`${routes.bloquesUri}/images/google.png`} height="20" width="20" />
                      <span>{loginOptions.loginGoogleOptions.text}</span>
                    </button>
                  )}
                  cookiePolicy="single_host_origin"
                />
              </div>
            </>
            { loginOptions.facebook
            && (
            <>
              <div id="fb_spinner" class={`${(!showSocialFacebook) ? 'd-none' : ''}`}>
                <FacebookLogin
                  appId={routes.facebookID}
                  autoLoad={false}
                  fields="name,email,picture"
                  callback={this.checkSessionFacebook}
                  textButton="Facebook"
                  cssClass="login-button"
                  icon="fa-facebook"
                />
              </div>
            </>
            )}
          </div>
          <div className="form-group annotation">
            {(loginOptions.formNotAccount || '¿No tenés cuenta?')} <a href={signupLink}>{(loginOptions.formCreateAccount || 'Crear cuenta')}</a>
          </div>

        </div>
        <div className="form-group footer">
          { loginOptions.footer
            ? (
              <div className="footer-img">
                <img alt="" src={`${routes.bloquesUri}/images/${loginOptions.footer}`} />
              </div>
            )
            : (
              <span>{(loginOptions.formDisclaimer)
                ? (
                  <span dangerouslySetInnerHTML={{ __html: loginOptions.formDisclaimer }} />
                ) : (
                  <span>Al cliquear "Continuar con Google/Facebook", aceptás nuestros <a href="https://educabot.com/nosotros/">Términos y Condiciones</a> y nuestra <a href="https://educabot.com/nosotros/">Política de Privacidad</a></span>
                )}
              </span>
            )}
        </div>
      </form>
    );
  }
}

export default LoginForm;
