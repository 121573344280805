import React, { useState } from 'react';
import { FileUploadOutlined, CheckRounded, Add } from '@mui/icons-material';
import {
  Box,
  Typography,
  Skeleton,
  alpha,
  Button,
  Select,
  MenuItem,
  ListItemText,
  InputAdornment,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  orderCreabotsProjects,
  selectCreabots,
} from '@sections/creabots/features/creabotsSlice';
import { useIntl } from 'react-intl';
import { LabsUserMenu } from '../labsUserMenu';
import { ToggleView } from './toggleView';
import { SelectKitToCreateProjectModal } from '../selectKitProjectModal';
import { useImportCreabotsProject } from '@modules/blockly/features/hooks';
import { LabsInput } from '../ui/input';
import SearchIcon from '@mui/icons-material/Search';
import clsx from 'clsx';

export type Props = {
  viewFormat: string;
  handleFormat: (
    _event: React.MouseEvent<HTMLElement>,
    format: string | null
  ) => void;
  projectSearch: string | null;
  handleSearchProject: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
};

export const TopBarLabs: React.FC<Props> = ({
  viewFormat,
  projectSearch,
  handleSearchProject,
  handleFormat,
}) => {
  const intl = useIntl();

  const orderByFilters = [
    {
      id: 1,
      value: 'alphabetical',
      label: intl.formatMessage({id: 'creabots.dashboard.topbar.orderFilters.alphabetical'}),
    },
    {
      id: 2,
      value: 'newest',
      label:  intl.formatMessage({id: 'creabots.dashboard.topbar.orderFilters.newest'}),
    },
    {
      id: 3,
      value: 'oldest',
      label:  intl.formatMessage({id: 'creabots.dashboard.topbar.orderFilters.oldest'}),
    },
  ];

  const dispatch = useDispatch();
  const [orderBy, setOrderBy] = useState(orderByFilters[0]);
  const { kits, projects } = useSelector(selectCreabots);
  const [openKitModal, setOpenKitModal] = useState(false);
  const handleLoadProjectButton = useImportCreabotsProject(kits.list);

  const handleOrderBy = (value: string) => {
    const filter = orderByFilters.find(flr => flr.value === value);
    if (!filter) return;
    setOrderBy(filter);
    dispatch(orderCreabotsProjects(filter.value));
  };

  const handleCreateProjectButton = () => {
    setOpenKitModal(true);
  };

  return (
    <Box
      sx={{
        height: 'min-content',
        width: '100%',
        paddingRight: '18.5rem',
        position: 'fixed',
        zIndex: 2,
        bgcolor: 'surface.default',
      }}
    >
      <Box
        sx={theme => ({
          height: 72,
          width: '100%',
          paddingY: theme.spacing(2),
          paddingX: theme.spacing(3),
          display: 'flex',
          justifyContent: 'space-between',
        })}
      >
        <LabsInput
          size="small"
          autoComplete="off"
          variant="outlined"
          type="text"
          name="searchProject"
          id="searchProject"
          placeholder="Buscar proyectos"
          value={projectSearch || ''}
          className={clsx('bmd-form-group', {
            paddingTop: 0,
            position: 'initial',
          })}
          sx={{
            bgcolor: 'white',
            borderRadius: 1,
          }}
          onChange={handleSearchProject}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ margin: 0, padding: 0 }}>
                <SearchIcon sx={{ margin: 0, padding: 0 }} />
              </InputAdornment>
            ),
          }}
        />
        <LabsUserMenu />
      </Box>
      <Box
        sx={theme => ({
          height: 96,
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(3),
          paddingX: theme.spacing(3),
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: theme => theme.spacing(1),
          }}
        >
          <Typography
            sx={theme => ({
              color: theme.palette.text.secondary,
              fontWeight: theme.typography.fontWeightMedium,
              fontSize: '14px',
              lineHeight: '20px',
              fontFamily: theme.typography.fontFamily,
            })}
          >
            Creabots
          </Typography>
          {kits.loading ? (
            <Skeleton height={32} width={80} />
          ) : (
            <Typography
              sx={theme => ({
                color: alpha(theme.palette.common.black, 0.8),
                fontWeight: theme.typography.fontWeightBold,
                fontSize: '2rem',
                lineHeight: '40px',
                fontFamily: theme.typography.fontFamily,
                letterSpacing: '-0.1rem',
              })}
            >
              {intl.formatMessage({
                id: kits.selected?.id
                  ? kits.selected.name
                  : 'creabots.myProjects',
              })}
            </Typography>
          )}
        </Box>
        {kits.selected.id !== 0 ? (
          <ToggleView viewFormat={viewFormat} handleFormat={handleFormat} />
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {kits.selected.id === 0 && (
              <Box display="flex" alignItems="center" gap={2}>
                <Button
                  variant="primary"
                  size="small"
                  onClick={handleCreateProjectButton}
                >
                  <Add /> {intl.formatMessage({id: 'creabots.dashboard.topbar.newProject'})}
                </Button>
                <Button
                  variant="secondary"
                  size="small"
                  onClick={handleLoadProjectButton}
                >
                  <FileUploadOutlined /> {intl.formatMessage({id: 'creabots.dashboard.topbar.uploadProject'})}
                </Button>
              </Box>
            )}
          </Box>
        )}
      </Box>
      {kits.selected.id === 0 && (
        <Box
          sx={theme => ({
            height: 'min-content',
            width: '100%',
            display: projects.length === 0 ? 'none' : 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(3),
            paddingX: theme.spacing(3),
          })}
        >
          <Box
            display={projects.length === 0 ? 'none' : 'flex'}
            alignItems="center"
            gap={2}
          >
            <Typography
              sx={theme => ({
                fontSize: '1rem',
                color: theme.palette.text.secondary,
                fontWeight: theme.typography.fontWeightRegular,
                fontFamily: theme.typography.fontFamily,
              })}
            >
              {intl.formatMessage({id: 'creabots.dashboard.topbar.order'})}
            </Typography>
            <Select
              id="order-by"
              value={orderBy.value}
              onChange={e => handleOrderBy(e.target.value)}
              size="small"
              sx={theme => ({
                minWidth: '240px',
                backgroundColor: theme.palette.common.white,
                borderRadius: 1,
              })}
              MenuProps={{
                MenuListProps: {
                  sx: theme => ({
                    backgroundColor: theme.palette.common.white,
                  }),
                },
              }}
              renderValue={() => <Typography>{orderBy.label}</Typography>}
            >
              {orderByFilters.map(filter => (
                <MenuItem
                  key={filter.id}
                  value={filter.value}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 2,
                  }}
                >
                  <ListItemText
                    sx={theme => ({
                      color:
                        orderBy.value === filter.value
                          ? theme.palette.primary.main
                          : theme.palette.text.primary,
                    })}
                    primary={filter.label}
                  />
                  {orderBy.value === filter.value && (
                    <CheckRounded
                      sx={theme => ({ color: theme.palette.primary.main })}
                    />
                  )}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <ToggleView viewFormat={viewFormat} handleFormat={handleFormat} />
          <SelectKitToCreateProjectModal
            isOpen={openKitModal}
            onClose={() => setOpenKitModal(false)}
          />
        </Box>
      )}
    </Box>
  );
};
