import React, { useEffect, useState, useRef } from 'react';
import TutorialBody from '@sections/creabots/components/tutorialBody';
import { movingHand, stopMovingHand } from '@sections/creabots/features/tutorialHints';
import { useBlockly } from '@modules/blockly/features/hooks';
import * as routes from '@educabot/educablocks-cosmos';
import { useSelector } from 'react-redux';
import useCreabotsTheme from '../hooks/useCreabotsTheme';
import { selectCreabots } from '../features/creabotsSlice';


const Tutorial = (props) => {
  const onSaveProgress = props.onSaveProgress || null;
  const data = props.data || null;
  const workspace = props.workspace || null;
  const [steps, setSteps] = useState([]);
  const [hints, setHints] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [totalStep, setTotalStep] = useState(data?.length || 0);
  const [currentStepId, setCurrentStepId] = useState(0);
  const [totalStepId, setTotalStepId] = useState(0);
  const blocklyState = useBlockly();
  const blocklyCodeRef = useRef();
  const blocklyBuiltRef = useRef();
  const { isDarkMode } = useCreabotsTheme()
  const { kits } = useSelector(selectCreabots);

  useEffect(() => {
    return () => {
      stopMovingHand();
      setCurrentStep(0);
      setCurrentStepId(0);
      setSteps([]);
      setHints([]);
      window.Blockly.WidgetDiv.hide();
      if (workspace) {
        workspace.removeChangeListener(refreshCategoryBlocks);
      }
      stopMovingHand();
    }
  }, []);

  useEffect(() => {
    const handImageTutorial = document.getElementById('hand-image-tutorial');
    if (!handImageTutorial) return;
    handImageTutorial.src = `/images/creabots/${isDarkMode ? 'hand-white.png' : 'hand.png'}`;
  }, [isDarkMode]);

  useEffect(() => {
    blocklyCodeRef.current = blocklyState?.project?.code || '';
  }, [blocklyState?.project?.code]);

  useEffect(() => {
    if (blocklyState?.build?.building && (blocklyState?.build?.port !== '' || blocklyState?.build?.microbitForceDownload)) {
      blocklyBuiltRef.current = true;
      workspace.fireChangeListener('resize');
    } else {
      blocklyBuiltRef.current = false;
    }
  }, [blocklyState?.build?.building]);

  useEffect(() => {
    let totalSteps = 0;
    let prevStepId = -1;
    if (data) {
      setSteps(data.map((tutorial, index) => {
        if (prevStepId !== tutorial.step.stepId) {
          totalSteps++;
          prevStepId = tutorial.step.stepId;
        }
        if (tutorial.completed && data.length > index + 1) {
          setCurrentStep(index + 1);
          setCurrentStepId(data[index + 1].step.stepId);
        }
        return (tutorial.step) ? {
          ...tutorial.step,
          // completed: false,
          completed: (tutorial.completed),
        } : {}
      }));
      setHints(data.map((tutorial) => tutorial.hints || []));
    }
    setTotalStep(data?.length || 0);
    setTotalStepId(totalSteps);
  }, [data]);

  useEffect(() => {
    if (workspace) {
      if (steps[currentStep]) {
        saveCheckedBlocksWS();
        resetWorkspace();
        stopMovingHand();
        setCategoryBlocks(true);
        workspace.removeChangeListener(refreshCategoryBlocks);
        workspace.addChangeListener(refreshCategoryBlocks);
        if (!steps[currentStep]?.completed) {
          movingHand(hints[currentStep], workspace);
        }
      } else {
        stopMovingHand();
      }
    }

    return () => {
      if (workspace && steps[currentStep]) {
        workspace.removeChangeListener(refreshCategoryBlocks);
      }
    }
  }, [workspace, currentStep]);

  const saveCheckedBlocksWS = () => {
    if (workspace) {
      workspace.checkedBlocksDB = workspace.getAllBlocks().filter((block) => !block.disabled)
        .map((block) => ({ id: block.id, type: block.type }));
    }
  }

  const sanitizeCodeString = (code) => {
    // TODO: these are rules to normalize Arduino's generated code for comparison.
    let sanitizedCode = code.replace(/\n|\s/gi, '');
    sanitizedCode = sanitizedCode.replace(/bitmap_[0-9]{1,6}/gi, 'bitmapMatrix');

    return sanitizedCode;
  };

  const challengeCompleted = () => {
    if (blocklyCodeRef.current) {
      // console.log('==================WITHWORKSPACE', new XMLSerializer().serializeToString(window.Blockly.Xml.workspaceToDom(workspace)));
      // console.log('==================CHECKWIDGET', window.Blockly.WidgetDiv.isVisible(), window.Blockly.WidgetDiv.DIV.className);
      if (steps[currentStep].completionCodeCheck && steps[currentStep].completionCodeCheck === 'built') {
        if (blocklyBuiltRef.current) {
          return true;
        }
        return false;
      } if (steps[currentStep].completionCodeCheck && steps[currentStep].completionCodeCheck === 'variable') {
        if (workspace.getAllVariables().length > 0) {
          return true;
        }
        return false;
      } if (!window.Blockly.WidgetDiv.isVisible() || window.Blockly.WidgetDiv.DIV.className.includes('fieldTextInput')) {
        const code = sanitizeCodeString(blocklyCodeRef.current);
        // console.log('Blockly code: ', code);
        // console.log('Current step code: ', steps[currentStep].completionCode);
        // console.log('==================CODE', workspace.getAllVariables(), new RegExp(steps[currentStep].completionCode, 'i').test(code), new RegExp(steps[currentStep].completionCode, 'i'), code);
        if (
          ((!steps[currentStep].completionCodeCheck || steps[currentStep].completionCodeCheck === 'equal') && steps[currentStep].completionCode && code === steps[currentStep].completionCode)
          || (steps[currentStep].completionCodeCheck === 'regex' && new RegExp(steps[currentStep].completionCode, 'i').test(code))
        ) {
          return true;
        }
      }
    }
    return false;
  }

  const scrollWorkspaceByX = (ws, x = 0, y = 0) => {
    const metrics = ws.getMetrics();
    if (metrics && ws.scrollbar) {
      let a = x + 20;
      let b = y + 10;
      a = Math.min(a, -metrics.contentLeft);
      b = Math.min(b, -metrics.contentTop);
      a = Math.max(a, metrics.viewWidth - metrics.contentLeft - metrics.contentWidth);
      b = Math.max(b, metrics.viewHeight - metrics.contentTop - metrics.contentHeight);
      ws.scrollbar.set(-a - metrics.contentLeft, -b - metrics.contentTop)
    }
  }

  const refreshCategoryBlocks = (event) => {
    stopMovingHand();
    setCategoryBlocks();

    if (!steps[currentStep]?.completed) {
      setTimeout(() => {
        if (challengeCompleted()) {
          if (onSaveProgress) {
            onSaveProgress(currentStep);
          }
          setSteps(steps.map((step, i) => {
            return (i === currentStep) ? {
              ...step,
              completed: true,
            } : step;
          }));
          // TODO: make an animation or hint to show step success
          workspace.removeChangeListener(refreshCategoryBlocks);
          stopMovingHand();
          saveCheckedBlocksWS();
          setTimeout(onNextStep, 500);
        } else {
          movingHand(hints[currentStep], workspace);
        }
      }, 200);
    }
  };

  const setCategoryBlocks = (move = false) => {
    if (workspace) {
      const enableOneBlock = (ws, block) => {
        if (typeof block !== 'undefined') {
          const blocks = ws.getFlyout().workspace_.getAllBlocks();
          if (blocks.length > 0) {
            for (let i = 0; i < blocks.length; i += 1) {
              if (!blocks[i].getParent()) {
                blocks[i].setDisabled(true);
              }
            }
            blocks[block].setDisabled(false);
          }
        }
      }

      if (typeof steps[currentStep].blockCategory !== 'undefined') {
        // OPEN TOOLBOX CATEGORY AND DISABLE SOME BLOCKS PROGRAMMATICALLY AND MOVE INITIAL BLOCK ASIDE OF THE CATEGORY MENU
        if (move && steps[currentStep].canvasDisplacement) {
          scrollWorkspaceByX(workspace, steps[currentStep].canvasDisplacement.x, steps[currentStep].canvasDisplacement.y);
        }
        const category = workspace.getToolbox();
        let child = (category) ? category.tree_.children_[steps[currentStep].blockCategory] : null;
        if (child) {
          if (child.hasChildren() && typeof steps[currentStep].blockSubCategory !== 'undefined') {
            child = child.children_[steps[currentStep].blockSubCategory];
          }
          category.tree_.setSelectedItem(child);
          if (typeof steps[currentStep].block !== 'undefined') {
            enableOneBlock(workspace, steps[currentStep].block);
            category.flyout_.scrollbar_.set(steps[currentStep].block * 50);
          }
        }
      } else {
        // CLOSE ANY CATEGORY PREVIOUSLY OPENED AND MOVE INITIAL BLOCK TO ORIGINAL POSITION
        if (move) {
          if (steps[currentStep].canvasDisplacement) {
            scrollWorkspaceByX(workspace, steps[currentStep].canvasDisplacement.x, steps[currentStep].canvasDisplacement.y);
          } else {
            scrollWorkspaceByX(workspace, 0, 0);
          }
          const category = workspace.getToolbox();
          if (category) {
            const child = category.tree_.children_[-1];
            category.tree_.setSelectedItem(child);
          }
        }
      }
    }
  }

  const setStepByStepId = (stepId) => {
    const lastIndex = steps.reduce((acc, step, index) => {
      return step.stepId === stepId && step.completed === true ? index : acc;
    }, -1);
    if (lastIndex !== -1) {
      if (steps[lastIndex + 1] && steps[lastIndex + 1].stepId === stepId) {
        setCurrentStep(lastIndex + 1);
      } else {
        setCurrentStep(lastIndex);
      }
    } else {
      const stepIndex = steps.findIndex((step) => step.stepId === stepId);
      if (stepIndex !== -1) {
        setCurrentStep(stepIndex);
      }
    }
  }

  const resetWorkspace = () => {
    if (steps[currentStep].resetWorkspace) {
      const blocks = workspace.getAllBlocks();
      blocks.forEach((block) => {
        if (block.type !== 'initial_block') {
          block.dispose();
        }
      });
    }
  }

  const onNextStepId = () => {
    if (currentStepId + 1 < totalStepId) {
      setCurrentStepId(currentStepId + 1);
      setStepByStepId(currentStepId + 1);
    } else {
      // END OF TUTORIAL
      setCurrentStep(currentStep + 1);
    }
  }

  const onPrevStepId = () => {
    if (currentStepId > 0) {
      setCurrentStepId(currentStepId - 1);
      setStepByStepId(currentStepId - 1);
    }
  }

  const setStepId = (stepIndex) => {
    if (typeof steps[stepIndex].stepId !== 'undefined') {
      setCurrentStepId(steps[stepIndex].stepId);
    }
  }

  const onNextStep = () => {
    if (currentStep < totalStep - 1) {
      setCurrentStep(currentStep + 1);
      setStepId(currentStep + 1);
    }
  }

  const onPrevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      setStepId(currentStep - 1);
    }
  }

  const backToHome = () => {
    window.location.href = `${routes.bloquesUri}/kits/creabots/${kits.selected.urlName}`
  }

  const nextStepEnabled = () => {
    if (
      totalStepId
      && (
        (!steps[currentStep]?.completionCode && (!steps[currentStep]?.completionCodeCheck || steps[currentStep]?.completionCodeCheck !== 'built'))
        || steps[currentStep]?.completed
      )
    ) {
      return true;
    }
    return false;
  };

  return (
    <TutorialBody
      steps={steps}
      currentStep={currentStep}
      currentStepId={currentStepId}
      totalStepId={totalStepId}
      onPrevStep={(totalStepId && currentStepId > 0) ? onPrevStepId : null}
      onNextStep={nextStepEnabled() ? onNextStepId : null}
      backToHome={backToHome}
    />
  );
};


export default Tutorial;