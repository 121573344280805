import axios from 'axios';
import * as routes from '@educabot/educablocks-cosmos';
import { checkAuthAction } from './authAction';
import types from './types';

axios.defaults.withCredentials = true;


function actionCollabProjectsFetchingGetOne() {
  return { type: types.COLLABORATION_PROJECTS_GET_ONE_FETCHING, payload: { statusCollaborationGetOne: 'fetching' } };
}

function actionCollabProjectsFetchGetOne(dataCollaborationGetOne) {
  return { type: types.COLLABORATION_PROJECTS_GET_ONE_FETCH, payload: { statusCollaborationGetOne: 'fetch', dataCollaborationGetOne } };
}

function actionCollabProjectsFetchErrorGetOne() {
  return { type: types.COLLABORATION_PROJECTS_GET_ONE_FETCH_ERROR, payload: { statusCollaborationGetOne: 'fetch error' } };
}

export function actionCollabProjectsGetOne(projectId) {
  return (dispatch) => {
    checkAuthAction(dispatch).then(() => {
      dispatch(actionCollabProjectsFetchingGetOne());
      return axios.get(`${routes.educablocksUri}/api/collaboration/${projectId}`).then((response) => {
        dispatch(actionCollabProjectsFetchGetOne(response.data.data));
      }).catch((e) => {
        dispatch(actionCollabProjectsFetchErrorGetOne(e));
      });
    });
  };
}


function actionProjectsFetchingGetOne() {
  return { type: types.PROJECTS_GET_ONE_FETCHING, payload: { statusGetOne: 'fetching' } };
}

function actionProjectsFetchGetOne(dataGetOne) {
  return { type: types.PROJECTS_GET_ONE_FETCH, payload: { statusGetOne: 'fetch', dataGetOne } };
}

function actionProjectsFetchErrorGetOne() {
  return { type: types.PROJECTS_GET_ONE_FETCH_ERROR, payload: { statusGetOne: 'fetch error' } };
}

export function actionProjectsGetOne(id) {
  return (dispatch) => {
    // checkAuthAction(dispatch).then(() => {
    dispatch(actionProjectsFetchingGetOne());
    return axios.get(`${routes.projectsUri}/${id}`).then((response) => {
      dispatch(actionProjectsFetchGetOne(response.data));
    }).catch((e) => {
      dispatch(actionProjectsFetchErrorGetOne(e));
    });
    // });
  };
}

function actionProjectsFetchingGetAll() {
  return { type: types.PROJECTS_GET_FETCHING, payload: { statusGetAll: 'fetching' } };
}

function actionProjectsFetchGetAll(dataGetAll) {
  return { type: types.PROJECTS_GET_FETCH, payload: { statusGetAll: 'fetch', dataGetAll } };
}

function actionProjectsFetchErrorGetAll() {
  return { type: types.PROJECTS_GET_FETCH_ERROR, payload: { statusGetAll: 'fetch error' } };
}

export function actionProjectsGetAll() {
  return (dispatch) => {
    checkAuthAction(dispatch).then(() => {
      dispatch(actionProjectsFetchingGetAll());
      return axios.get(routes.projectsUri).then((response) => {
        dispatch(actionProjectsFetchGetAll(response.data));
      }).catch((e) => {
        dispatch(actionProjectsFetchErrorGetAll(e));
      });
    });
  };
}


function actionProjectsUserFetchingGetAll() {
  return { type: types.PROJECTS_USER_GET_FETCHING, payload: { statusUserGetAll: 'fetching' } };
}

function actionProjectsUserFetchGetAll(dataUserGetAll) {
  return { type: types.PROJECTS_USER_GET_FETCH, payload: { statusUserGetAll: 'fetch', dataUserGetAll } };
}

function actionProjectsUserFetchErrorGetAll() {
  return { type: types.PROJECTS_USER_GET_FETCH_ERROR, payload: { statusUserGetAll: 'fetch error' } };
}

export function actionProjectsUserGetAll() {
  return (dispatch) => {
    checkAuthAction(dispatch).then(() => {
      dispatch(actionProjectsUserFetchingGetAll());
      return axios.get(routes.userProjectsUri).then((response) => {
        dispatch(actionProjectsUserFetchGetAll(response.data));
      }).catch((e) => {
        dispatch(actionProjectsUserFetchErrorGetAll(e));
      });
    });
  };
}


function actionProjectsFetchingGetAllPaged() {
  return { type: types.PROJECTS_GET_PAGED_FETCHING, payload: { statusGetAllPaged: 'fetching' } };
}

function actionProjectsFetchGetAllPaged(dataGetAllPaged) {
  return { type: types.PROJECTS_GET_PAGED_FETCH, payload: { statusGetAllPaged: 'fetch', dataGetAllPaged } };
}

function actionProjectsFetchErrorGetAllPaged() {
  return { type: types.PROJECTS_GET_PAGED_FETCH_ERROR, payload: { statusGetAllPaged: 'fetch error' } };
}

export function actionProjectsGetAllPaged(limit = 20, offset = 0) {
  return (dispatch) => {
    checkAuthAction(dispatch).then(() => {
      dispatch(actionProjectsFetchingGetAllPaged());
      return axios.get(`${routes.apiUri}/paged/projects?isPublic=true&limit=${limit}&offset=${offset}&view=robots`).then((response) => {
        dispatch(actionProjectsFetchGetAllPaged(response.data));
      }).catch((e) => {
        dispatch(actionProjectsFetchErrorGetAllPaged(e));
      });
    });
  };
}


function actionProjectsUserFetchingGetAllPaged() {
  return { type: types.PROJECTS_USER_GET_PAGED_FETCHING, payload: { statusUserGetAllPaged: 'fetching' } };
}

function actionProjectsUserFetchGetAllPaged(dataUserGetAllPaged) {
  return { type: types.PROJECTS_USER_GET_PAGED_FETCH, payload: { statusUserGetAllPaged: 'fetch', dataUserGetAllPaged } };
}

function actionProjectsUserFetchErrorGetAllPaged() {
  return { type: types.PROJECTS_USER_GET_PAGED_FETCH_ERROR, payload: { statusUserGetAllPaged: 'fetch error' } };
}

export function actionProjectsUserGetAllPaged(userId = 0, limit = 20, offset = 0) {
  return (dispatch) => {
    checkAuthAction(dispatch).then(() => {
      dispatch(actionProjectsUserFetchingGetAllPaged());
      return axios.get(`${routes.apiUri}/paged/projects?userId=${userId}&limit=${limit}&offset=${offset}&view=robots`).then((response) => {
        dispatch(actionProjectsUserFetchGetAllPaged(response.data));
      }).catch((e) => {
        dispatch(actionProjectsUserFetchErrorGetAllPaged(e));
      });
    });
  };
}


function actionLibraryFetchingGetAll() {
  return { type: types.PROJECTS_LIBRARY_GET_FETCHING, payload: { statusLibraryGetAll: 'fetching' } };
}

function actionLibraryFetchGetAll(dataLibraryGetAll) {
  return { type: types.PROJECTS_LIBRARY_GET_FETCH, payload: { statusLibraryGetAll: 'fetch', dataLibraryGetAll } };
}

function actionLibraryFetchErrorGetAll() {
  return { type: types.PROJECTS_LIBRARY_GET_FETCH_ERROR, payload: { statusLibraryGetAll: 'fetch error' } };
}

export function actionLibraryGetAll() {
  return (dispatch) => {
    checkAuthAction(dispatch).then(() => {
      dispatch(actionLibraryFetchingGetAll());
      return axios.get(routes.LibraryUri).then((response) => {
        dispatch(actionLibraryFetchGetAll(response.data));
      }).catch((e) => {
        dispatch(actionLibraryFetchErrorGetAll(e));
      });
    });
  };
}

function actionLibraryFetchingGetAllPaged() {
  return { type: types.PROJECTS_LIBRARY_GET_PAGED_FETCHING, payload: { statusLibraryGetAllPaged: 'fetching' } };
}

function actionLibraryFetchGetAllPaged(dataLibraryGetAllPaged) {
  return { type: types.PROJECTS_LIBRARY_GET_PAGED_FETCH, payload: { statusLibraryGetAllPaged: 'fetch', dataLibraryGetAllPaged } };
}

function actionLibraryFetchErrorGetAllPaged() {
  return { type: types.PROJECTS_LIBRARY_GET_PAGED_FETCH_ERROR, payload: { statusLibraryGetAllPaged: 'fetch error' } };
}

export function actionLibraryGetAllPaged(limit = 20, offset = 0) {
  return (dispatch) => {
    checkAuthAction(dispatch).then(() => {
      dispatch(actionLibraryFetchingGetAllPaged());
      return axios.get(`${routes.apiUri}/paged/projects?isLibrary=true&limit=${limit}&offset=${offset}&view=robots`).then((response) => {
        dispatch(actionLibraryFetchGetAllPaged(response.data));
      }).catch((e) => {
        dispatch(actionLibraryFetchErrorGetAllPaged(e));
      });
    });
  };
}

function actionProjectsFetchingPost() {
  return { type: types.PROJECTS_POST_FETCHING, payload: { statusPost: 'fetching' } };
}

function actionProjectsFetchPost(dataPost) {
  return { type: types.PROJECTS_POST_FETCH, payload: { statusPost: 'fetch', dataPost } };
}

function actionProjectsFetchErrorPost() {
  return { type: types.PROJECTS_POST_FETCH_ERROR, payload: { statusPost: 'fetch error' } };
}

export function actionProjectsPost(projectData) {
  return (dispatch) => {
    checkAuthAction(dispatch).then(() => {
      dispatch(actionProjectsFetchingPost());
      // console.log('=============', projectData); return;
      return axios.post(routes.userProjectsUri, projectData).then((response) => {
        dispatch(actionProjectsFetchPost(response.data));
      }).catch((e) => {
        dispatch(actionProjectsFetchErrorPost(e));
      });
    });
  };
}


function actionProjectsFetchingPut() {
  return { type: types.PROJECTS_PUT_FETCHING, payload: { statusPut: 'fetching' } };
}

function actionProjectsFetchPut(dataPut) {
  return { type: types.PROJECTS_PUT_FETCH, payload: { statusPut: 'fetch', dataPut } };
}

function actionProjectsFetchPutAuto(dataPut) {
  return { type: types.PROJECTS_PUT_FETCH_AUTO, payload: { statusPut: 'fetch_auto', dataPut } };
}

function actionProjectsFetchSaveAsCode(dataPut) {
  return { type: types.PROJECTS_PUT_FETCH_CODE, payload: { statusPut: 'fetch_code', dataPut } };
}

function actionProjectsFetchErrorPut() {
  return { type: types.PROJECTS_PUT_FETCH_ERROR, payload: { statusPut: 'fetch error' } };
}

export function actionProjectsPut(projectId, projectData, auto = false) {
  return (dispatch) => {
    checkAuthAction(dispatch).then(() => {
      dispatch(actionProjectsFetchingPut());
      return axios.put(`${routes.userProjectsUri}/${projectId}`, projectData).then((response) => {
        if (auto) {
          dispatch(actionProjectsFetchPutAuto(response.data));
        } else {
          dispatch(actionProjectsFetchPut(response.data));
        }
      }).catch((e) => {
        dispatch(actionProjectsFetchErrorPut(e));
      });
    });
  };
}

export function actionProjectsSaveAsCode(projectId, projectData) {
  return (dispatch) => {
    checkAuthAction(dispatch).then(() => {
      dispatch(actionProjectsFetchingPut());
      return axios.put(`${routes.userProjectsUri}/${projectId}`, projectData).then((response) => {
        dispatch(actionProjectsFetchSaveAsCode(response.data));
      }).catch((e) => {
        dispatch(actionProjectsFetchErrorPut(e));
      });
    });
  };
}


function actionProjectsFetchingDelete() {
  return { type: types.PROJECTS_DELETE_FETCHING, payload: { statusDelete: 'fetching' } };
}

function actionProjectsFetchDelete(dataDelete) {
  return { type: types.PROJECTS_DELETE_FETCH, payload: { statusDelete: 'fetch', dataDelete } };
}

function actionProjectsFetchErrorDelete() {
  return { type: types.PROJECTS_DELETE_FETCH_ERROR, payload: { statusDelete: 'fetch error' } };
}

export function actionProjectsDelete(projectId) {
  return (dispatch) => {
    checkAuthAction(dispatch).then(() => {
      dispatch(actionProjectsFetchingDelete());
      return axios.delete(`${routes.educablocksUri}/api/users/projects/${projectId}`).then((response) => {
        dispatch(actionProjectsFetchDelete(response.data));
      }).catch((e) => {
        dispatch(actionProjectsFetchErrorDelete(e));
      });
    });
  };
}

function actionResetStatusFetch(ObjectToAssign) {
  return { type: types.PROJECTS_RESET_STATUS, payload: ObjectToAssign };
}

export function actionResetStatus(ObjectToAssign) {
  return (dispatch) => {
    dispatch(actionResetStatusFetch(ObjectToAssign));
  };
}
