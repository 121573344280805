import React from 'react';
import { LabsLogoWhite } from '@assets/icons/labsLogoWhite';
import {
  alpha,
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import * as routes from '@educabot/educablocks-cosmos';
import { useTheme } from '@mui/styles';
import { getLabsUrl } from '@sections/creabots/helpers/getLabsUrl';
import { useIntl } from 'react-intl';
import { Carousel } from './carousel';

export function StartUseYourKit() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const labsUrl = getLabsUrl();
  const intl = useIntl();
  return (
    <Box
      sx={() => ({
        backgroundColor: '#2A205E',
        minHeight: '150dvh',
        width: '100%',
        position: 'relative',
      })}
    >
      <img
        src={`${routes.bloquesUri}/images/creabots/landing/pattern_landing.png`}
        alt=""
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 0,
          width: '100%',
          height: isMobile ? '70vh' : '95vh',
          objectFit: 'cover',
        }}
      />
      <Box
        sx={{
          zIndex: 10,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          paddingTop: { xs: '4rem', md: '6rem' },
        }}
      >
        <LabsLogoWhite />
        <Typography
          sx={(theme) => ({
            paddingX: 5,
            marginTop: { xs: 4, md: 5 },
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.common.white,
            letterSpacing: { xs: '-0.1rem', md: '-0.3rem' },
            fontSize: { xs: '2.5rem', md: '4rem' },
            lineHeight: { xs: '42px', md: '67.2px' },
            textAlign: 'center',
          })}
        >
          {intl.formatMessage({ id: 'creabots.landing.startToUseYourKits' })}

        </Typography>
        {isMobile ? (
          <Typography
            sx={(theme) => ({
              paddingX: 5,
              marginTop: 2,
              fontWeight: theme.typography.fontWeightRegular,
              color: theme.palette.common.white,
              fontFamily: theme.typography.fontFamily,
              fontSize: '1rem',
              lineHeight: '24px',
              textAlign: 'center',
            })}
          >
            {intl.formatMessage({ id: 'creabots.landing.accessToThePlatformFromCompute' })}
          </Typography>
        ) : (
          <a href={`${labsUrl}/kits/ingresar`}>
            <Button
              variant="primary"
              size="large"
              sx={{
                marginTop: 3,
                textTransform: 'none',
                textDecoration: 'none',
              }}
            >
              {intl.formatMessage({ id: 'creabots.landing.accessToPlatform' })}
            </Button>
          </a>
        )}
        <img
          src={`${routes.bloquesUri}/images/creabots/landing/use_your_kit.png`}
          alt="Usa tu kit ilustración"
          style={{
            ...(isMobile && { width: '100%' }),
            zIndex: 1,
          }}
        />
      </Box>
      <Box
        sx={{
          maxWidth: '1080px',
          marginY: '3.87rem',
          marginX: 'auto',
          paddingX: 0,
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
        }}
      >
        <Typography
          sx={(theme) => ({
            paddingX: { xs: 1.5, md: 0 },
            color: theme.palette.common.white,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: { xs: '2rem', md: '3rem' },
            lineHeight: { xs: '40px', md: '50.4px' },
            letterSpacing: { xs: '-0.01rem', md: '-0.03rem' },
          })}
        >
          {intl.formatMessage({ id: 'creabots.landing.exploreFunctions' })}
        </Typography>
        <Functions />
      </Box>
    </Box>
  );
}


function Functions() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isBetweenSize = useMediaQuery((theme) => theme.breakpoints.between('sm', 'md'));
  const theme = useTheme();
  const intl = useIntl();

  const functions = [
    {
      id: 1,
      title: intl.formatMessage({ id: 'creabots.landing.functions.firstTitle' }),
      description:
        intl.formatMessage({ id: 'creabots.landing.functions.firstDescription' }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/step_1.png`,
    },
    {
      id: 2,
      title: intl.formatMessage({ id: 'creabots.landing.functions.secondTitle' }),
      description:
        intl.formatMessage({ id: 'creabots.landing.functions.secondDescription' }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/step_2.png`,
    },
    {
      id: 3,
      title: intl.formatMessage({ id: 'creabots.landing.functions.thirdTitle' }),
      description:
        intl.formatMessage({ id: 'creabots.landing.functions.thirdDescription' }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/step_3.png`,
    },
  ];

  return (
    <>
      {isMobile ? (
        <Box
          sx={{
            backgroundColor: alpha(theme.palette.common.white, 0.03),
            paddingY: '2rem',
          }}
        >
          <Carousel steps={functions} />
        </Box>
      ) : (
        <Grid
          container
          sx={{
            margin: 0,
            width: '100%',
            paddingX: { xs: '1.8rem', lg: '4.5rem' },
            paddingY: '3rem',
            backgroundColor: alpha(theme.palette.common.white, 0.03),
            gap: '3.75rem',
            borderRadius: theme.spacing(3),
          }}
        >
          {functions.map((func) => (
            <Grid
              xs
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gapX: 6,
                width: '288px',
              }}
            >
              <img
                src={func.imageUrl}
                alt={`Función ${func.id} `}
                height={240}
                width="100%"
                style={{
                  objectFit: 'contain',
                }}
              />
              <Typography
                sx={{
                  color: theme.palette.common.white,
                  fontFamily: theme.typography.fontFamily,
                  fontWeight: theme.typography.fontWeightMedium,
                  fontSize: isBetweenSize ? '1.125rem' : '1.25rem',
                  lineHeight: '28px',
                  letterSpacing: '-0.015rem',
                }}
              >
                {func.title}{' '}
                <span
                  style={{
                    color: alpha(theme.palette.common.white, 0.8),
                    fontFamily: theme.typography.fontFamily,
                    fontWeight: theme.typography.fontWeightMedium,
                    fontSize: isBetweenSize ? '1.125rem' : '1.25rem',
                    lineHeight: '28px',
                    letterSpacing: '-0.015rem',
                  }}
                >
                  {func.description}
                </span>
              </Typography>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
}
