import React from 'react';
import { alpha, Box, Typography } from '@mui/material';
import * as routes from '@educabot/educablocks-cosmos';
import { useIntl } from 'react-intl';
import { Parts } from '../parts';
import { AutoAsistProjects } from '../autoAsistProjects';
import { ImageKit } from './imageKit';



export function InitiationTab() {
  const intl = useIntl();


  const electronicParts = [
    {
      name: intl.formatMessage({ id: 'creabots.landing.electronicParts.controllerBoard' }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/starter-kit/electronic_parts/placa_controladora.png`,
    },
    {
      name: intl.formatMessage({ id: 'creabots.landing.electronicParts.humiditySensor' }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/starter-kit/electronic_parts/sensor_humedad.png`,
    },
    {
      name: intl.formatMessage({ id: 'creabots.landing.electronicParts.matriz8x8' }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/starter-kit/electronic_parts/matriz_8_x_8.png`,
    },
    {
      name: intl.formatMessage({ id: 'creabots.landing.electronicParts.pulseButton' }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/starter-kit/electronic_parts/boton_pulsador.png`,
    },
    {
      name: intl.formatMessage({ id: 'creabots.landing.electronicParts.ultrasonicSensor' }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/starter-kit/electronic_parts/sensor_ultrasonido.png`,
    },
    {
      name: intl.formatMessage({ id: 'creabots.landing.electronicParts.helixEngine' }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/starter-kit/electronic_parts/motor_helice.png`,
    },
    {
      name: intl.formatMessage({ id: 'creabots.landing.electronicParts.ledModule' }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/starter-kit/electronic_parts/modulo_led.png`,
    },
    {
      name: intl.formatMessage({ id: 'creabots.landing.electronicParts.lcdScreen' }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/starter-kit/electronic_parts/pantalla_lcd.png`,
    },
  ];

  const plasticParts = [
    {
      name: intl.formatMessage({ id: 'creabots.landing.plasticParts.boardSupport' }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/starter-kit/plastic_parts/soporte_placas.png`,
    },
    {
      name: intl.formatMessage({ id: 'creabots.landing.plasticParts.gradesL' }, { number: 90 }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/starter-kit/plastic_parts/90_grados_L.png`,
    },
    {
      name: intl.formatMessage({ id: 'creabots.landing.plasticParts.grades' }, { number: 45 }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/starter-kit/plastic_parts/45_grados.png`,
    },

    {
      name: intl.formatMessage({ id: 'creabots.landing.plasticParts.board' }, { number: '02' }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/starter-kit/plastic_parts/placa_02.png`,
    },
    {
      name: intl.formatMessage({ id: 'creabots.landing.plasticParts.board' }, { number: '03' }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/starter-kit/plastic_parts/placa_03.png`,
    },
    {
      name: intl.formatMessage({ id: 'creabots.landing.plasticParts.board' }, { number: '06' }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/starter-kit/plastic_parts/placa_06.png`,
    },
    {
      name: intl.formatMessage({ id: 'creabots.landing.plasticParts.board' }, { number: '07' }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/starter-kit/plastic_parts/placa_07.png`,
    },
    {
      name: intl.formatMessage({ id: 'creabots.landing.plasticParts.board' }, { number: 11 }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/starter-kit/plastic_parts/placa_11.png`,
    },
    {
      name: intl.formatMessage({ id: 'creabots.landing.plasticParts.board' }, { number: 13 }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/starter-kit/plastic_parts/placa_13.png`,
    },
  ];

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
        padding: { xs: 1.5, md: 3 },
        minHeight: '100dvh',
      })}
    >
      <Box
        sx={{
          marginTop: { xs: '3rem', md: '6rem' },
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          width: '100%',
          maxWidth: '1080px',
          marginX: 'auto',
        }}
      >
        <Typography
          sx={(theme) => ({
            width: { xs: '100%', md: '90%' },
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.primary,
            fontSize: { xs: '2rem', md: '4rem' },
            letterSpacing: { xs: '-0.01rem', md: '-0.03rem' },
            lineHeight: { xs: '40px', md: '72px' },
          })}
        >
          {intl.formatMessage({ id: 'creabots.landing.kits.initiation.title' })}
        </Typography>
        <Typography
          sx={(theme) => ({
            width: { xs: '100%', md: '90%' },
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightRegular,
            color: theme.palette.text.secondary,
            fontSize: { xs: '1rem', md: '1.75rem' },
            letterSpacing: { xs: 0, md: '-0.01rem' },
            lineHeight: { xs: '24px', md: '36px' },
          })}
        >
          {intl.formatMessage({ id: 'creabots.landing.kits.initiation.description' })}
        </Typography>
      </Box>
      <ImageKit kit="starter-kit" />
      <AutoAsistProjects kit="iniciacion" />
      <Parts kitName={intl.formatMessage({ id: 'creabots.landing.kits.initiation.name' })} plasticParts={plasticParts} electronicParts={electronicParts} />
    </Box>
  );
}