// import Ract, { Component } from 'react';
import io from 'socket.io-client';
import * as routes from '@educabot/educablocks-cosmos';
import * as jwt from 'jsonwebtoken';
import moment from 'moment';
import { jwtSecret } from '@utils';

class WebSocketClient {
  constructor(options = {}) {
    this.jwtOptions = {
      id: options.userId || 0,
      rooms: options.rooms || [],
      role: options.role || '',
      iat: parseInt(moment().add(1, 'minutes').format('X'), 10),
    };
    this.socket = io.connect(routes.educablocksWebSocket, { pingInterval: 2000, pingTimeout: 5000, transports: ['websocket'] }).emit('authenticate', { token: this.getToken() });
    this.listenToEvents();
  }


  getToken() {
    return jwt.sign(this.jwtOptions, jwtSecret());
  }


  disconnect() {
    this.socket.disconnect();
  }


  listenToEvents() {
    this.socket.on('connect', () => {
      console.log('[WS] Client connected');
    });

    this.socket.on('disconnect', (reason) => {
      console.log('[WS] Disconnect', reason);
      if (reason === 'io server disconnect' || reason === 'io client disconnect') {
        console.log('[WS] Reconnect manually');
        this.socket.connect().emit('authenticate', { token: this.getToken() });
      }
    });

    this.socket.on('unauthorized', (result) => {
      console.log('[WS] Unauthorized', result);
    });

    this.socket.on('authenticated', () => {
      console.log('[WS] Authenticated');
      // this.socket.emit('message', 'hola authenticated');
    });
  }
}

export default WebSocketClient;
