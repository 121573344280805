/* eslint-disable max-len */
/* eslint react/prop-types: 0 */
/* eslint no-unneeded-ternary: 0 */
import axios from 'axios';
import React, { Component } from 'react';
import * as routes from '@educabot/educablocks-cosmos';
import ImageUploader from './react-images-upload';

class UploadImageField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      picture: null,
      apiURL: (props.apiURL && props.apiURL !== '') ? props.apiURL : `${routes.educablocksUri}/api/files`,
      defaultImages: (props.defaultImage && props.defaultImage !== '') ? [props.defaultImage] : [],
      lang: routes.getCosmos('lang'),
    };
    this.onDrop = this.onDrop.bind(this);
    this.submitImage = this.submitImage.bind(this);
  }

  componentDidMount() {}

  componentWillReceiveProps(newProps) {
    this.setState({
      defaultImages: (newProps.defaultImage && newProps.defaultImage !== '') ? [newProps.defaultImage] : [],
    })
  }

  submitImage(image, callbackFn) {
    const { id, apiURL } = this.state;
    const formData = new FormData(document.getElementById((id) ? id : 'tempImageForm'));
    console.log('SUBMIT image', image, apiURL, formData);
    // formData.append('image', { uri: image.name, type: image.type });
    axios.defaults.withCredentials = true;
    axios.post(apiURL, formData).then((response) => {
      callbackFn(null, response);
    }).catch((e) => {
      console.log('error', e);
      callbackFn(e, 'error');
    });
  }

  sendData(data) {
    const { sendDataBack } = this.props;
    sendDataBack(data);
  }

  onDrop(pictures, srcs) {
    const self = this;
    const { defaultImages } = this.state;
    const imagesWithData = pictures[0];
    if (imagesWithData) {
      imagesWithData.status = 'uploading';
      imagesWithData.src = srcs[0];
      self.submitImage(imagesWithData, (err, resp) => {
        if (err) console.log('error', err);
        else {
          imagesWithData.status = 'upload';
          imagesWithData.onlineSrc = (resp.data.data.location) ? resp.data.data.location : '';
          self.setState({
            pictures: imagesWithData,
          }, () => {
            self.sendData(self.state);
          });
        }
      });
    }
    this.setState({
      picture: imagesWithData,
      defaultImages: (imagesWithData) ? defaultImages : [],
    }, () => {
      self.sendData(self.state);
    });
  }

  render() {
    const { picture, id, defaultImages, lang } = this.state;
    return (
      <form
        id={`${(id) ? id : 'tempImageForm'}`}
        className={`image-wrapper ${(picture || defaultImages.length > 0) ? `no-show-btn ${(picture) ? picture.status : ''}` : ''}`}
      >
        <ImageUploader
          withIcon={false}
          withPreview={true}
          name="image"
          defaultImages={defaultImages}
          label={(lang === 'es') ? 'Solo formatos jpg | png' : 'Only jpg | png file format'}
          singleImage={true}
          // singleImage="true"
          fileTypeError={(lang === 'es') ? ' No es de formato soportado' : ' Format not supported'}
          fileSizeError={(lang === 'es') ? ' El archivo es muy pesado' : ' File size is too big'}
          buttonText={(lang === 'es') ? 'Seleccione una imagen' : 'Select an image'}
          onChange={this.onDrop}
          imgExtension={['.jpg', '.png']}
          maxFileSize={5242880}
        />
      </form>
    );
  }
}

export default UploadImageField;
