import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  alpha,
  Box,
  Button,
  Skeleton,
  Typography,
} from '@mui/material';
import { Add, ExpandMore } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useCheckLogged, useIsAuthenticated } from '@utils/hooks';
import * as routes from '@educabot/educablocks-cosmos';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  getCreabotsKits,
  getCreabotsProgress,
  getCreabotsUserProjects,
  selectCreabots,
  setSelectedKit,
} from '../features/creabotsSlice';
import { AddKitModal } from './addKitModal';

export const SidebarLabs = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [expanded, setExpanded] = useState('creabots');
  const dispatch = useDispatch();
  const intl = useIntl();
  const { kits: kitsSelector, projects } = useSelector(selectCreabots);
  const userData = useCheckLogged(false);
  const isUserLogged = useIsAuthenticated();
  const { pathname } = useLocation();
  const history = useHistory();
  const { kitName } = useParams();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSelectedKit = (kit) => {
    dispatch(setSelectedKit(kit));
    if (kit.id === 0) {
      history.push('/kits/mis-proyectos');
      return;
    }
    history.push(`/kits/creabots/${kit.urlName}`);
  };

  useEffect(() => {
    dispatch(getCreabotsKits(isUserLogged));
    if (isUserLogged) {
      dispatch(getCreabotsProgress);
      dispatch(getCreabotsUserProjects);
    }
  }, [isUserLogged, userData?.data?.userId]);

  useEffect(() => {
    if (!kitsSelector.loading && kitsSelector.list.length > 0) {
      const prevKit = kitsSelector.list.find(
        (kit) => kit.urlName === (kitName || ''),
      );

      if (pathname !== '/kits/mis-proyectos' && !prevKit) {
        dispatch(setSelectedKit(kitsSelector.list[0]));
        history.push(`/kits/creabots/${kitsSelector.list[0].urlName}`);
      }

      if (pathname !== '/kits/mis-proyectos' && prevKit) {
        dispatch(setSelectedKit(prevKit));
        history.push(`/kits/creabots/${prevKit.urlName}`);
      }
    }
  }, [kitsSelector.loading]);

  return (
    <Box
      sx={(theme) => ({
        width: '18.5rem',
        height: '100dvh',
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        alignItems: 'flex-start',
        position: 'fixed',
        zIndex: 1,
        bgcolor: 'surface.default',
      })}
    >
      {/* TOP */}
      <Box
        sx={(theme) => ({
          width: '100%',
          display: 'flex',
          alignItems: 'start',
          flexDirection: 'column',
          gap: theme.spacing(4),
        })}
      >
        <img
          src={`${routes.bloquesUri}/images/creabots/labsLogo.png`}
          alt="Labs Creabots Logo"
          style={{ height: 40, maxWidth: 245, objectFit: 'contain' }}
        />
        <Button
          sx={(theme) => ({
            width: '100%',
            marginBottom: theme.spacing(4),
            flexWrap: 'wrap',
          })}
          variant="primary"
          size="large"
          type="button"
          onClick={() => setIsOpenModal(true)}
          disabled={!isUserLogged}
        >
          <Add />{' '}
          {intl.formatMessage({ id: 'creabots.dashboard.sidebar.addKit' })}
        </Button>
      </Box>

      {/* Medium */}
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        {kitsSelector.loading ? (
          <Skeleton width="100%" height={50} />
        ) : (
          <Accordion
            expanded={expanded === 'creabots'}
            onChange={handleChange('creabots')}
            disableGutters
            elevation={0}
            sx={{
              background: 'none',
              border: 'none',
              boxShadow: 'none',
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="creabotsbh-content"
              id="creabotsbh-header"
              sx={{
                flexDirection: 'row-reverse',
                padding: 0,
                gap: '8px',
              }}
            >
              <Typography
                sx={(theme) => ({
                  letterSpacing: '2px',
                  fontWeight: theme.typography.fontWeightBold,
                  color: theme.palette.text.secondary,
                  fontSize: '0.87rem',
                  textTransform: 'uppercase',
                })}
              >
                Creabots
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                paddingLeft: { xs: '.3rem', md: '3rem' },
              }}
            >
              {kitsSelector.list.length > 0
                && kitsSelector.list.map((kit) => (
                  <Box
                    width="100%"
                    height={50}
                    key={kit.id}
                    sx={{
                      cursor: 'pointer',
                    }}
                    onClick={() => handleSelectedKit(kit)}
                  >
                    <Typography
                      sx={(theme) => ({
                        fontWeight:
                          kitsSelector.selected.id === kit.id
                            ? theme.typography.fontWeightMedium
                            : theme.typography.fontWeightRegular,
                        fontSize: 16,
                        color:
                          kitsSelector.selected.id === kit.id
                            ? theme.palette.primary.main
                            : theme.palette.text.primary,
                      })}
                    >
                      {intl.formatMessage({ id: kit.name })}
                    </Typography>
                  </Box>
                ))}
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
      {/* Bottom */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          gap: 1,
        }}
      >
        {isUserLogged && (
          <Box
            width="100%"
            height={50}
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              gap: 2,
            }}
            onClick={() => {
              handleSelectedKit({
                id: 0,
                name: 'Mis proyectos',
                projects: [],
              });
            }}
          >
            <WidgetsOutlinedIcon
              sx={(theme) => ({
                color:
                  kitsSelector.selected.id === 0
                    ? theme.palette.primary.main
                    : theme.palette.text.secondary,
              })}
            />
            <Typography
              sx={(theme) => ({
                fontWeight: theme.typography.fontWeightBold,
                fontSize: '0.875rem',
                lineHeight: '1.25rem',
                letterSpacing: '2px',
                textTransform: 'uppercase',
                color:
                  kitsSelector.selected.id === 0
                    ? theme.palette.primary.main
                    : theme.palette.text.secondary,
              })}
            >
              {intl.formatMessage({
                id: 'creabots.dashboard.sidebar.myProjects',
              })}
            </Typography>
          </Box>
        )}

        <Box
          sx={{
            height: '1px',
            width: '80%',
            backgroundColor: alpha('#CBCBCB', 0.08),
          }}
        />
        <Box sx={{ width: '100%', height: 'min-content' }}>
          <Typography
            sx={(theme) => ({
              fontWeight: theme.typography.fontWeightMedium,
              fontSize: 12,
              color: theme.palette.text.primary,
              fontFamily: 'DM Sans',
              lineHeight: '15.62px',
              letterSpacing: '0.12em',
              textTransform: 'uppercase',
            })}
          >
            &copy; educabot 2024
          </Typography>
        </Box>
      </Box>
      <AddKitModal
        isOpen={isOpenModal}
        handleCloseModal={() => setIsOpenModal(false)}
      />
    </Box>
  );
};
