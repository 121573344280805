/* eslint react/prop-types: 0 */
import React, { Component } from 'react';

class SelectComponent extends Component {
  constructor(props) {
    super(props);
    this.selectRef = React.createRef();
    const { changeHandler, options, name, form, keyname, placeholder, helperText, label, id, forceDownwards } = this.props;
    this.state = {
      changeHandler,
      options, // ["option1", "option2, ...n] OR [{ name: '', value: '' }, { name: '', value: '' } ]
      form,
      name,
      keyname,
      placeholder,
      helperText,
      label,
      status: false, // false = options hidden; true = options shown
      id,
      forceDownwards: false,
    };
    this.handleOptionClick = this.handleOptionClick.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      changeHandler: newProps.changeHandler,
      options: newProps.options,
      form: newProps.form,
      name: newProps.name,
      keyname: newProps.keyname,
      placeholder: newProps.placeholder,
      helperText: newProps.helperText,
      label: newProps.label,
      id: newProps.id,
      forceDownwards: newProps.forceDownwards || false,
    });
  }

  handleOptionClick(e, value) {
    e.stopPropagation();
    e.preventDefault();
    const { changeHandler, keyname } = this.state;
    this.setState({ status: false }, () => {
      changeHandler(keyname, value);
    });
  }

  render() {
    const { form, name, keyname, placeholder, helperText, label, status, id } = this.state;
    const { options, forceDownwards } = this.state;

    let upwards = false;

    if (this.selectRef.current && !forceDownwards) {
      const selectX = this.selectRef.current.getBoundingClientRect().y;
      const optionHeight = 48;
      const optionsTotalHeight = optionHeight * options.length;

      if (selectX + optionsTotalHeight > window.innerHeight) {
        upwards = true;
      }
    }

    return (
      <div className={`eb-input-selector ${label ? 'with-padding' : ''}`} id={`select-${id}`} ref={this.selectRef}>
        {label && <label className={`eb-label ${placeholder || status || form[keyname] !== '' ? 'small' : ''} ${!placeholder ? 'mutable' : ''} ${status ? 'is-focused' : ''}`} htmlFor={`id-${keyname}`}>{name}</label>}
        <div className={`actual-selector ${status ? 'is-focused' : ''}`} name={`selector-${name}`} id={`id-${keyname}`} onClick={() => this.setState({ status: !status })} value={form[keyname]} onKeyDown={() => {}}>
          {(placeholder && !form[keyname]) ? (
            <div className="placeholder">{placeholder}</div>
          ) : (
            <div className="selected-option p-2">{form[keyname]}</div>
          )}
          <span className="icon-chevron-down" />
          <div className={`options ${status ? 'show' : 'hide'} ${upwards ? 'upwards' : ''}`}>
            {(options && !upwards) && options.map(i => (
              <div className="option" onClick={(e) => { if (i.name) this.handleOptionClick(e, i.name); else this.handleOptionClick(e, i); }} onKeyDown={() => {}}>{(i.name) ? i.name : i}</div>
            ))}
            {(options && upwards) && [...options].reverse().map(i => (
              <div className="option" onClick={(e) => { if (i.name) this.handleOptionClick(e, i.name); else this.handleOptionClick(e, i); }} onKeyDown={() => {}}>{(i.name) ? i.name : i}</div>
            ))}
          </div>
        </div>
        {helperText && <div className="eb-helper">{helperText}</div>}
        {status ? (
          <div className="options-close-clickability" onClick={() => this.setState({ status: false })} onKeyDown={() => {}} />
        ) : (
          <span />
        )}
      </div>
    );
  }
}

export default SelectComponent;
