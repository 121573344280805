import { BasicBlock, BLOCK_CATEGORY_TYPE, BlockConfig } from '@sections/creabots/types/kits'
import getBlocksInfoSchema from '../../../config/getBlocksInfoSchema'

const blocks: BasicBlock[] = [
  {
    id: 2,
    name: 'variables_declare_local_dynamic',
  },
  {
    id: 3,
    name: 'variables_get_dynamic',
  },
]

export const variablesConfig: BlockConfig = {
  blockCategory: 3,
  canvasDisplacement: { x: 470, y: 0 },
  blocks: getBlocksInfoSchema(blocks, BLOCK_CATEGORY_TYPE.VARIABLES),
}