import { BasicBlock, BLOCK_CATEGORY_TYPE, BlockConfig } from '@sections/creabots/types/kits'
import getBlocksInfoSchema from '../../../config/getBlocksInfoSchema'

const blocks: BasicBlock[] = [
  {
    id: 0,
    name: 'SALIDAS_motor',
  },
  {
    id: 1,
    name: 'BHOOT_servo_move',
  },
  {
    id: 2,
    name: 'BHOOT_servo_move_millis',
  },
  {
    id: 3,
    name: 'BHOOT_servo_read',
  },
];

export const motorsConfig: BlockConfig = {
  blockCategory: 9,
  canvasDisplacement: { x: 600, y: 0 },
  blocks: getBlocksInfoSchema(blocks, BLOCK_CATEGORY_TYPE.MOTORS),
}
