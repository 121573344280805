import axios from 'axios';
import * as routes from '@educabot/educablocks-cosmos';
import types from './types';
import { checkAuthAction } from './authAction';

axios.defaults.withCredentials = true;

function actionTagsFetchingGet() {
  return { type: types.TAGS_GET_FETCHING, payload: { statusGet: 'fetching' } };
}

function actionTagsFetchGet(dataGet) {
  return { type: types.TAGS_GET_FETCH, payload: { statusGet: 'fetch', dataGet } };
}

function actionTagsFetchErrorGet() {
  return { type: types.TAGS_GET_FETCH_ERROR, payload: { statusGet: 'fetch error' } };
}

export function actionTagsGet() {
  return (dispatch) => {
    checkAuthAction(dispatch).then(() => {
      dispatch(actionTagsFetchingGet());
      return axios.get(`${routes.educablocksUri}/api/tags`).then((response) => {
        dispatch(actionTagsFetchGet(response.data));
      }).catch((e) => {
        dispatch(actionTagsFetchErrorGet(e));
      });
    });
  };
}

function actionProjectTagsFetchingPost() {
  return { type: types.TAGS_POST_FETCHING, payload: { statusPost: 'fetching' } };
}

function actionProjectTagsFetchPost(dataPost) {
  return { type: types.TAGS_POST_FETCH, payload: { statusPost: 'fetch', dataPost } };
}

function actionProjectTagsFetchErrorPost() {
  return { type: types.TAGS_POST_FETCH_ERROR, payload: { statusPost: 'fetch error' } };
}

export function actionProjectTagsPost(projectId, tagsArray) {
  return (dispatch) => {
    checkAuthAction(dispatch).then(() => {
      dispatch(actionProjectTagsFetchingPost());
      return axios.post(`${routes.educablocksUri}/api/tags/project/${projectId}`, { tags: tagsArray.join(',') }).then((response) => {
        dispatch(actionProjectTagsFetchPost(response.data));
      }).catch((e) => {
        dispatch(actionProjectTagsFetchErrorPost(e));
      });
    });
  };
}

function actionResetStatusFetch(ObjectToAssign) {
  return { type: types.TAGS_RESET_STATUS, payload: ObjectToAssign };
}

export function actionResetTagsStatus(ObjectToAssign) {
  return (dispatch) => {
    dispatch(actionResetStatusFetch(ObjectToAssign));
  };
}

export default {};
