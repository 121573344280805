/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { useSelector, connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import styles from './collaboration.mod.scss';
// import Header from '../../../components/Header';
import Header from '@sections/collaboration/components/header';
import * as routes from '@educabot/educablocks-cosmos';
import { useCheckLogged } from '@utils/hooks/auth';
import {
  useGetCollaboration,
  useGetCollaborationSession,
  useGetCompilationList,
  useSetSocketListeners,
  useGetCollaborationLevels,
  useAgentGetNewMessage,
  usePostCompilation,
  usePutCompilation,
  useUpdateCollaborationCompilationState,
  useUpdateCollaborationTutorialState,
  useUpdateCollaborationNotificationsState,
  useUpdateSessionProjectState,
  useUpdateProjectInterval,
  useRefreshCompilationsInterval,
  useRefreshOnwerSession,
} from '@services/rest/collaboration/hooks';
import { usePutProject } from '@modules/projects/features/hooks';
import { useBlockly } from '@modules/blockly/features/hooks';
import Blockly from '@modules/blockly/containers/blockly';
import RightPanel from '@sections/collaboration/components/rightPanel';
import CollabModals from '@sections/collaboration/components/collabModals';
import IntroVideoModal from '@sections/collaboration/components/introVideoModal';
import ConnectingModal from '@sections/collaboration/components/connectingModal';
import SessionThumbnails from '@sections/collaboration/components/sessionThumbnails';
import { v1 as uuidv1 } from 'uuid';
import { userRoles, tutorialEvaluationTypes, compilationStates, sessionKeys } from '../features/constants';
import WebSocketClient from '../../../helpers/WebSocketClient';
import { store } from '../../../state/store';
import Tutorial from '@sections/collaboration/features/tutorial';
import * as showToastAction from '@modules/toasts/features/toastsSlice';
import { TOAST_TYPE } from '@modules/toasts/features/constants';
import LogRocket from 'logrocket';
import { format, differenceInSeconds, parseISO } from 'date-fns';
import Agent from '../../../helpers/agent';

if (process.env.NODE_ENV === 'production' && window.location.href.toLowerCase().indexOf('colaboracion') !== -1) {
  LogRocket.init('ggcdvl/robots');
}

// use memo for prevent re renders emited from his HOC component
const Collaboration = (props) => {
  const [projectId, setProjectId] = useState(null);
  const [blocklyId] = useState(uuidv1());
  const [socketClient, setSocketClient] = useState(null);
  const [socketAgent, setSocketAgent] = useState(null);
  const [buildPort, setBuildPort] = useState('');
  const [builderCode, setBuilderCode] = useState(null);
  const [intervalPortListing, setIntervalPortListing] = useState(null);
  const [projectData, setProjectData] = useState(null);
  const [buildProgress, setBuildProgress] = useState(0);
  const [userRole, setUserRole] = useState(null);
  const [streamsMaximized, setStreamsMaximized] = useState(false);
  const [teamList, setTeamList] = useState([]);
  const [compilationList, setCompilationList] = useState([]);
  const [compilationRetryCount, setCompilationRetryCount] = useState(0);
  const [introVideoActive, setIntroVideoActive] = useState(true);
  const [sessionEnded, setSessionEnded] = useState(false);
  const [showTutorialStepIntro, setShowTutorialStepIntro] = useState(false);
  const [showTutorialSuccess, setShowTutorialSuccess] = useState(false);
  const [showTutorialError, setShowTutorialError] = useState(false);
  const colors = ['#FF000E', '#05FF00', '#FF00E5', '#28FFBF', '#9B00AF', '#28DF99', '#FFCC29', '#C4C4C4', '#FF9DA3', '#04009A'];
  const agentState = useSelector((state) => state.bloquesReducer);

  const amITheOwner = () => (userRole === userRoles.OWNER);
  const amITheTeacher = () => (userRole === userRoles.TEACHER);
  const amITheLeader = () => (userRole === userRoles.LEADER);

  const intl = useIntl();
  const location = useLocation();
  const userDataState = useCheckLogged(false);
  const collabState = useGetCollaboration(projectId);
  const collabSessionState = useGetCollaborationSession(userDataState?.data?.userId || 0);
  useGetCompilationList(userDataState?.data?.userId || 0, agentState?.ports, socketClient?.socket, amITheOwner());
  const blocklyState = useBlockly();
  usePutProject(projectId, collabState.collaboration?.id || 0, projectData);
  useSetSocketListeners(socketClient?.socket);
  useGetCollaborationLevels(projectId, collabState.collaboration?.jsonBody?.board?.id);
  const { setNewAgentMessage } = useAgentGetNewMessage();
  const { postCompilation } = usePostCompilation();
  const { putCompilation } = usePutCompilation();
  const { updateCollaborationCompilationState } = useUpdateCollaborationCompilationState();
  const { updateCollaborationTutorialState } = useUpdateCollaborationTutorialState();
  const { updateCollaborationNotificationsState } = useUpdateCollaborationNotificationsState();
  const { updateSessionProjectState } = useUpdateSessionProjectState();
  Tutorial({ socket: socketClient, projectId, userDataState });


  const kickUserOut = () => {
    window.location.href = routes.aulaUri;
  }
  const onSessionEnds = () => {
    setSessionEnded(true);
  }

  const getSocketClient = (role = userRoles.STUDENT, sessionProjectIds = []) => {
    let client = null;
    if (sessionProjectIds.length > 0) {
      client = new WebSocketClient({ rooms: sessionProjectIds, userId: userDataState.data.userId || 0, role });
    } else {
      client = (userDataState?.data?.userId && projectId) ?
        new WebSocketClient({ rooms: [projectId], userId: userDataState.data.userId || 0, role }) :
        null;
    }
    return client;
  }

  const getUserRole = () => {
    let role = null;
    if (collabState.collaboration?.ProjectsCollabUsers?.length > 0 && userDataState.data) {
      role = collabState.collaboration.ProjectsCollabUsers.filter(user => user.userId === userDataState.data.userId)[0]?.userRole;
    }
    return role;
  }


  const getUserRoleFromSession = () => {
    let role = null;
    if (collabSessionState.session?.length > 0 && userDataState.data) {
      role = collabSessionState.session[0].ProjectsCollabUsers.filter(user => user.userId === userDataState.data.userId)[0]?.userRole;
    }
    return role;
  }

  const getUsersWithRole = (role) => {
    let user = [];
    if (collabState.collaboration?.ProjectsCollabUsers?.length > 0) {
      for (let i = 0; i < collabState.collaboration.ProjectsCollabUsers.length; i += 1) {
        if (collabState.collaboration.ProjectsCollabUsers[i].userRole === role) {
          user.push(collabState.collaboration.ProjectsCollabUsers[i]);
        }
      }
    }
    return user;
  }

  const amIAllowedToBuild = () => {
    return (userRole === userRoles.OWNER);
  }

  const isLeaderOnline = () => {
    let onLine = false;
    teamList.map((u) => {
      if (u.onLine && u.userRole === userRoles.LEADER) {
        onLine = true;
      }
    });
    return onLine;
  }

  const amIAllowedToSave = () => {
    let allowed = false;

    if (amITheOwner()) {
      // allowed = true;
    } else if (amITheLeader()) {
      allowed = true;
    } else if (amITheTeacher() && !isLeaderOnline()) {
      allowed = true;
    }
    return allowed;
  }

  const notifySocketUsers = (message = {}) => {
    if (socketClient?.socket) {
      const userMessage = message;
      if (!userMessage.notifierUserId) {
        userMessage.notifierUserId = userDataState?.data?.userId || 0;
      }
      if (!userMessage.projectId) {
        userMessage.projectId = collabState?.collaboration?.id || 0;
      }
      socketClient.socket.emit('notifyUsers', userMessage.projectId, userMessage);
    }
  }

  const closeIntroVideoScreen = () => {
    sessionStorage.setItem(`${sessionKeys.INTRO_VIDEO_HIDDEN}-${projectId}`, 'true');
    setIntroVideoActive(false);
  }

  const checkAgentMessagesForFlags = (message = '') => {
    let progress = 0;
    switch (message) {
      case '#### UPLOAD TO PORT':
        progress = 1;
        break;
      case '#### UPLOAD TO EB_BUILD':
        progress = 25;
        break;
      case '#### FINISH EB_BUILD':
        progress = 50;
        break;
      case '#### UPLOAD TO AGENT':
        progress = 75;
        break;
      case '#### FINISH AGENT':
        progress = 100;
        // THIS MAKEs A DOUBLE TOAST NOTIFICATION ON LOCAL DEVELOPMENT ENVIRONMENT BECAUSE BOTH BROWSER ARE CONNECTED TO LOCAL SOCKETAGENT!!
        if (builderCode) {
          notifySocketUsers({ projectId: builderCode.projectId, compilationState: compilationStates.COMPILED });
          updatePendingCompilationState(builderCode.compilationId, compilationStates.COMPILED);
          setNewAgentMessage('#### AGENT IDLE');
          setCompilationRetryCount(0);
        }
        break;
      case '#### ERROR EB_BUILD':
        progress = -2;
        // THIS MAKEs A DOUBLE TOAST NOTIFICATION ON LOCAL DEVELOPMENT ENVIRONMENT BECAUSE BOTH BROWSER ARE CONNECTED TO LOCAL SOCKETAGENT!!
        if (builderCode) {
          setBuilderCode(null);          
          if (compilationRetryCount < 2) {
            setCompilationRetryCount(compilationRetryCount + 1);
            updatePendingCompilationState(builderCode.compilationId, compilationStates.PENDING);
          } else {
            notifySocketUsers({ projectId: builderCode.projectId, compilationState: compilationStates.ERROR });
            updatePendingCompilationState(builderCode.compilationId, compilationStates.ERROR);
            setCompilationRetryCount(0);            
          }
          setNewAgentMessage('#### AGENT IDLE');
        }
        break;

      default:
    }

    setBuildProgress(progress);
  }


  const checkTutorialCode = () => {
    if (collabState.collaboration?.collaborationTutorial
      && collabState.tutorial?.tutorialStep >= 0
    ) {
      updateCollaborationTutorialState({ checkCode: blocklyState.project?.canvasCode || '' });
    }
  }


  const checkTutorialHasChallenge = () => {
    let hasChallenge = false;
    if (collabState.collaboration?.collaborationTutorial
      && collabState.tutorial?.tutorialStep >= 0
    ) {
      if (collabState.levels[collabState.tutorial.tutorialStep]?.canvasCode && collabState.levels[collabState.tutorial.tutorialStep]?.canvasCode !== 'null') {
        hasChallenge = true;
      }
    }
    return hasChallenge;
  }


  const tutorialGetCurrentStepTitle = () => {
    let title = null;
    if (collabState.levels && collabState.tutorial?.tutorialCurrentStep && collabState.levels[collabState.tutorial.tutorialStep]) {
      title = intl.formatMessage({ id: 'collaboration.tutorial.successModalMessage' }, { stepName: collabState.levels[collabState.tutorial.tutorialStep].title })
    }
    return title;
  }


  const tutorialGetNextStepTitle = () => {
    let title = null;
    if (collabState.levels && collabState.tutorial?.tutorialCurrentStep && collabState.levels[collabState.tutorial.tutorialStep]) {
      title = collabState.levels[collabState.tutorial.tutorialStep].title;
    }
    return title;
  }

  const tutorialGetNextStepDescription = () => {
    let description = null;
    if (collabState.levels && collabState.tutorial?.tutorialCurrentStep && collabState.levels[collabState.tutorial.tutorialStep]) {
      description = `${collabState.levels[collabState.tutorial.tutorialStep].description} ${(!collabState.levels[collabState.tutorial.tutorialStep].canvasCode) ? intl.formatMessage({ id: 'collaboration.tutorial.noValidationGoal' }) : ''}`;
    }
    return description;
  }

  const tutorialGetNextStepImage = () => {
    let image = null;
    if (collabState.levels && collabState.tutorial?.tutorialCurrentStep && collabState.levels[collabState.tutorial.tutorialStep]) {
      image = `/images/collaboration/${(!collabState.levels[collabState.tutorial.tutorialStep].canvasCode) ? 'tutorialStepFree.png' : `tutorialStep_${(collabState.tutorial.tutorialStep + 1)}.png`}`;
    }
    return image;
  }


  const closeTutorialValidateModal = () => {
    notifySocketUsers({ tutorialStepEvaluation: tutorialEvaluationTypes.START });
    setShowTutorialSuccess(false);
    setShowTutorialError(false);
  }

  const closeTutorialSuccessModal = () => {
    notifySocketUsers({ tutorialStepEvaluation: tutorialEvaluationTypes.START, tutorialStep: collabState.tutorial.tutorialStep + 1 });
    setShowTutorialSuccess(false);
    setShowTutorialError(false);
  }

  const closeTutorialStepIntroModal = () => {
    notifySocketUsers({ tutorialStepEvaluation: tutorialEvaluationTypes.START });
    setShowTutorialStepIntro(false);
    // setStreamsMaximized(false); // close video stream
    setShowTutorialSuccess(false);
    setShowTutorialError(false);
  }


  const buildStep = () => {
    notifySocketUsers({ streamsMaximize: true, tutorialStepEvaluation: tutorialEvaluationTypes.START });
    buildBlockly();
  }


  const buildBlockly = (params = {}) => {
    // COMPILE TO LOCAL BOARD
    if (amITheOwner()) {
      if (builderCode === null) {
        const newBuilderCode = {
          code: (params.code) ? params.code : blocklyState.project.canvasCode,
          projectId: (params.projectId) ? params.projectId : projectId,
          compilationId: (params.compilationId) ? params.compilationId : 0,
        };
        setBuilderCode(newBuilderCode);
      }
      // COMPILE TO REMOTE BOARD
    } else if (amITheTeacher() || amITheLeader()) {
      const selectedPort = collabState.collaboration?.jsonBody?.port || '';
      let projectCollabUserId = 0;
      for (let i = 0; i < collabState.collaboration.ProjectsCollabUsers.length; i += 1) {
        if (collabState.collaboration.ProjectsCollabUsers[i].userRole === userRoles.OWNER) {
          projectCollabUserId = collabState.collaboration.ProjectsCollabUsers[i].id;
        }
      }
      if (projectCollabUserId && selectedPort !== '') {
        const projectJsonBody = {
          canvas: blocklyState.project.canvas,
          canvasBBoxWidth: blocklyState.project.canvasBBoxWidth,
          canvasBBoxHeight: blocklyState.project.canvasBBoxHeight,
          canvasBBoxX: blocklyState.project.canvasBBoxX,
          canvasBBoxY: blocklyState.project.canvasBBoxY,
          canvasCode: blocklyState.project.canvasCode,
        };
        postCompilation({
          projectCollabUserId,
          projectId: collabState.collaboration.id,
          userId: userDataState.data.userId,
          userData: {
            userAvatarUrl: userDataState.data.user.avatarUrl,
            userFullName: `${userDataState.data.user.firstName} ${userDataState.data.user.lastName}`,
            userRole,
          },
          date: new Date(),
          state: compilationStates.PENDING,
          jsonBody: projectJsonBody,
        });
      } else {
        props.showToast({
          title: intl.formatMessage({ id: 'blocks.compilation.boardNotConnectedTitle' }),
          message: intl.formatMessage({ id: 'blocks.compilation.boardNotConnected' }),
          type: TOAST_TYPE.ERROR,
          options: { autoClose: 3000 }
        });
      }
    }
  }


  const isAnyChangeInProject = (newProjectData) => {
    let changed = false;
    if (
      projectData && (
        projectData.jsonBody.canvas !== newProjectData.jsonBody.canvas
        || projectData.jsonBody.canvasBBoxX !== newProjectData.jsonBody.canvasBBoxX
        || projectData.jsonBody.canvasBBoxY !== newProjectData.jsonBody.canvasBBoxY
        || projectData.jsonBody.canvasBBoxWidth !== newProjectData.jsonBody.canvasBBoxWidth
        || projectData.jsonBody.canvasBBoxHeight !== newProjectData.jsonBody.canvasBBoxHeight
        // || projectData.jsonBody.canvasCode !== newProjectData.jsonBody.canvasCode
        || projectData.jsonBody.code !== newProjectData.jsonBody.code
        || projectData.jsonBody.port !== newProjectData.jsonBody.port
        || JSON.stringify(projectData.jsonBody.agentPorts) !== JSON.stringify(newProjectData.jsonBody.agentPorts)
        || projectData.jsonBody.portOpen !== newProjectData.jsonBody.portOpen
      )
    ) {
      changed = true;
    }
    return changed;
  }


  const getProject = () => {
    return (projectData) ? JSON.parse(JSON.stringify(projectData)) : JSON.parse(JSON.stringify(collabState.collaboration));
  }


  const updateBlocklyProject = () => {
    if (amIAllowedToSave() && !introVideoActive) {
      const newProjectData = getProject();
      if (!newProjectData.jsonBody) {
        newProjectData.jsonBody = {};
      }
      newProjectData.canvasCss = blocklyState.project.canvasCss;
      newProjectData.blocksCss = blocklyState.project.blocksCss;
      newProjectData.jsonBody.withWorkspace = blocklyState.project.withWorkspace;
      newProjectData.jsonBody.canvas = blocklyState.project.canvas;
      newProjectData.jsonBody.canvasBBoxX = blocklyState.project.canvasBBoxX;
      newProjectData.jsonBody.canvasBBoxY = blocklyState.project.canvasBBoxY;
      newProjectData.jsonBody.canvasBBoxWidth = blocklyState.project.canvasBBoxWidth;
      newProjectData.jsonBody.canvasBBoxHeight = blocklyState.project.canvasBBoxHeight;
      newProjectData.jsonBody.code = blocklyState.project.code;
      newProjectData.jsonBody.canvasCode = blocklyState.project.canvasCode;
      newProjectData.jsonBody.agentPorts = (blocklyState.project.agentPorts) ? blocklyState.project.agentPorts : collabState.collaboration?.jsonBody?.agentPorts || [];
      // newProjectData.jsonBody.port = (blocklyState.project.port) ? blocklyState.project.port : collabState.collaboration?.jsonBody?.port || '';
      // newProjectData.collaborationTutorial = collabState.collaboration?.collaborationTutorial;
      // newProjectData.collaborationTutorialStep = collabState.collaboration?.collaborationTutorialStep;
      
      if ((isAnyChangeInProject(newProjectData) || !projectData) && (newProjectData.jsonBody.withWorkspace || newProjectData.boardType === 'code')) {
        setProjectData(newProjectData);
      }
    }
  }


  const updatePendingCompilationState = (id, state) => {
    if (amITheOwner()) {
      const newCompilationList = compilationList;
      for (let i = 0; i < newCompilationList.length; i += 1) {
        if (newCompilationList[i].id === id) {
          newCompilationList[i].state = state;
          putCompilation(id, { projectId: newCompilationList[i].projectId, state });
        }
      }
      if (state === compilationStates.COMPILED || state === compilationStates.ERROR) {
        setBuilderCode(null);
      }
      setCompilationList(newCompilationList);
    }
  }


  const updateCompilationState = (id, state) => {
    let changed = false;
    const newCompilationList = compilationList;
    for (let i = 0; i < newCompilationList.length; i += 1) {
      if (newCompilationList[i].id === id) {
        newCompilationList[i].state = state;
        changed = true;
      }
    }
    if (changed) {
      setCompilationList(newCompilationList);
    }
  }


  const checkPendingCompilation = () => {
    if (amITheOwner()) {

      let pendingCompilation = {};
      for (let i = 0; i < compilationList.length; i += 1) {
        if (compilationList[i].state === compilationStates.COMPILING) {
          // console.log('======================COMPILING', differenceInSeconds(parseISO(format(new Date(), 'yyyy-MM-dd\'T\'HH:mm:ss.SSS')), new Date(parseISO(compilationList[i].updatedAt))));
          if (differenceInSeconds(parseISO(format(new Date(), 'yyyy-MM-dd\'T\'HH:mm:ss.SSS')), new Date(parseISO(compilationList[i].updatedAt))) > 90) {
            updatePendingCompilationState(compilationList[i].id, compilationStates.ERROR);
          } else {
            return;
          }
          return;
        }
        if (!pendingCompilation.projectId && compilationList[i].state === compilationStates.PENDING) {
          pendingCompilation = compilationList[i];
        }
      }
      if (pendingCompilation.jsonBody && pendingCompilation.projectId) {
        updateCompilationState(pendingCompilation.id, compilationStates.COMPILING);
        buildBlockly({ compilationId: pendingCompilation.id, projectId: pendingCompilation.projectId, code: pendingCompilation.jsonBody.canvasCode });
      }
    }
  }


  const getCompilationList = () => {
    let pendingCompilationList = [];
    if (collabState?.compilationList?.length > 0) {
      if (projectId) {
        pendingCompilationList = collabState.compilationList.filter(c => c.projectId === projectId && (c.state === compilationStates.PENDING || c.state === compilationStates.COMPILING));
      } else {
        pendingCompilationList = collabState.compilationList.filter(c => (c.state === compilationStates.PENDING || c.state === compilationStates.COMPILING));
      }
    }
    setCompilationList(pendingCompilationList);
  }


  useEffect(() => {
    if (compilationList && compilationList.length > 0 && amITheOwner() && socketAgent) {
      checkPendingCompilation();
    }
  }, [compilationList, socketAgent]);

  useEffect(() => {
    getCompilationList();
  }, [collabState?.compilationList]);


  // NOTIFY ME ABOUT SENT CODE IS IN THE COMPILATION QUEUE
  useEffect(() => {
    if ((collabState.compilation?.projectId && collabState.compilation?.id && collabState.compilation?.state === compilationStates.PENDING && compilationRetryCount === 0) || collabState.compilation?.insertId) {
      notifySocketUsers({ projectId: collabState.compilation.projectId, compilationState: compilationStates.PENDING });
      updateCollaborationCompilationState({});
    }
  }, [collabState.compilation]);


  // IF PROJECT IS PURGED, KICK THIS USER OUT
  useEffect(() => {
    if (projectId, userDataState.data) {
      if (collabState.collaboration?.ProjectsCollabUsers) {
        const teamArray = [];
        let amIStillInThisSession = false;
        for (let i = 0; i < collabState.collaboration.ProjectsCollabUsers.length; i += 1) {
          if (collabState.collaboration.ProjectsCollabUsers[i].userId === userDataState.data.userId) {
            amIStillInThisSession = true;
          }
          teamArray.push({
            ...collabState.collaboration.ProjectsCollabUsers[i],
            color: colors[i] || '#FFF200',
            onLine: teamList.filter(tl => tl.User.id === collabState.collaboration.ProjectsCollabUsers[i].User.id)[0]?.onLine || false,
          })
        }
        setTeamList(teamArray);
        if (!amIStillInThisSession) {
          setSessionEnded(true);
        }
      }
    }

  }, [collabState.collaboration?.ProjectsCollabUsers]);


  // BUILDING ROUTINE
  useEffect(() => {
    if (builderCode?.code && builderCode?.projectId && socketAgent) {
      let currentBuildPort = '';
      let currentBoard = {};
      let currentCompilerBoard = '';
      for (let i = 0; i < collabSessionState.session.length; i += 1) {
        if (collabSessionState.session[i].id === builderCode.projectId) {
          currentBuildPort = collabSessionState.session[i].jsonBody.port;
          currentBoard = collabSessionState.session[i].jsonBody.board;
          currentCompilerBoard = collabSessionState.session[i].jsonBody.board.compilerBoard;
        }
      }
      if (currentBuildPort !== '') {
        setBuildProgress(1)
        socketAgent.uploadToPort(builderCode.code, currentBoard, currentBuildPort);
        putCompilation(builderCode.compilationId, { projectId: builderCode.projectId, state: compilationStates.COMPILING });
        const newBuilderCode = builderCode;
        newBuilderCode.code = null;
        setBuilderCode(newBuilderCode);
        if (compilationRetryCount === 0) {
          notifySocketUsers({ projectId: builderCode.projectId, compilationState: compilationStates.COMPILING });
        }
      } else {
        updatePendingCompilationState(builderCode.compilationId, compilationStates.ERROR);
        putCompilation(builderCode.compilationId, { projectId: builderCode.projectId, state: compilationStates.ERROR });
        notifySocketUsers({ projectId: builderCode.projectId, compilationState: compilationStates.ERROR });
      }
    }
  }, [builderCode, compilationList, collabSessionState]);


  useEffect(() => {
    // THIS IS GETTING CALLED TWICE ON LOCAL DEVELOPMENT ENVIRONMENT BECAUSE BOTH BROWSER ARE CONNECTED TO LOCAL SOCKETAGENT!!
    switch (collabState.notifications.compilationState) {
      case compilationStates.PENDING:
        props.showToast({
          title: intl.formatMessage({ id: 'blocks.compilation.pendingTitle' }),
          message: intl.formatMessage({ id: 'blocks.compilation.pending' }),
          type: TOAST_TYPE.CODE_SENT,
          options: { autoClose: 3000 }
        });
        break;
      case compilationStates.COMPILING:
        props.showToast({
          title: intl.formatMessage({ id: 'blocks.compilation.compilingTitle' }),
          message: intl.formatMessage({ id: 'blocks.compilation.compiling' }),
          type: TOAST_TYPE.CODE_SENDING,
          options: { autoClose: 3000 }
        });
        break;
      case compilationStates.COMPILED:
        props.showToast({
          title: intl.formatMessage({ id: 'blocks.compilation.compiledTitle' }),
          message: intl.formatMessage({ id: 'blocks.compilation.compiled' }),
          type: TOAST_TYPE.CODE_UPLOADED,
          options: { autoClose: 3000 }
        });
        break;
      case compilationStates.ERROR:
        props.showToast({
          title: intl.formatMessage({ id: 'blocks.compilation.errorTitle' }),
          message: intl.formatMessage({ id: 'blocks.compilation.error' }),
          type: TOAST_TYPE.ERROR,
          options: { autoClose: 3000 }
        });
        break;
    }
    updateCollaborationNotificationsState({ compilationState: null });
  }, [collabState.notifications.compilationState]);


  useEffect(() => {
    const newProjectData = getProject();
    if (projectId && newProjectData.jsonBody && collabState.levels && collabState.tutorial) {
      const newStep = collabState.notifications.tutorialStep;
      if (newStep >= collabState.levels.length) {
        newProjectData.collaborationTutorial = false;
        newProjectData.collaborationTutorialStep = -1;
      } else if (newStep > newProjectData.collaborationTutorialStep) {
        newProjectData.collaborationTutorial = true;
        newProjectData.collaborationTutorialStep = newStep;
      }
      // if (amIAllowedToSave() || amIAllowedToBuild()) {
      if (amIAllowedToSave()) {
        setProjectData(newProjectData);
      }
    }
  }, [collabState.notifications.tutorialStep]);
  
  useEffect(() => {
    if (amITheOwner()) {
      const newProjectData = getProject();
      if (newProjectData.jsonBody) {
        newProjectData.jsonBody.agentPorts = blocklyState.project?.agentPorts || [];
        // newProjectData.jsonBody.port = blocklyState.project?.port || '';
        setProjectData(newProjectData);
      }
    }
  }, [blocklyState.project.agentPorts]);

  useEffect(() => {
    if (collabState.tutorial.tutorialStep !== null && collabState.tutorial.tutorialStep < collabState.levels.length) {
      setShowTutorialStepIntro(true);
      setShowTutorialSuccess(false);
      setShowTutorialError(false);
    } else {
      setShowTutorialStepIntro(false);
      setShowTutorialSuccess(false);
      setShowTutorialError(false);
    }
  }, [collabState.tutorial?.tutorialStep]);


  useEffect(() => {
    if (collabState.tutorial?.tutorialStepEvaluation === tutorialEvaluationTypes.SUCCESS) {
      setShowTutorialStepIntro(false);
      setShowTutorialSuccess(false);
      setShowTutorialError(false);

      setShowTutorialError(false);
      setShowTutorialSuccess(true);
    } else if (collabState.tutorial?.tutorialStepEvaluation === tutorialEvaluationTypes.ERROR) {
      setShowTutorialStepIntro(false);
      setShowTutorialSuccess(false);
      setShowTutorialError(false);

      setShowTutorialSuccess(false);
      setShowTutorialError(true);
    } else {
      setShowTutorialSuccess(false);
      setShowTutorialError(false);
    }
  }, [collabState.tutorial?.tutorialStepEvaluation]);


  useEffect(() => {
    if (collabState.notifications.streamsMaximize !== null) {
      setStreamsMaximized(collabState.notifications.streamsMaximize);
      updateCollaborationNotificationsState({ streamsMaximize: null });
    }
  }, [collabState.notifications.streamsMaximize]);


  useEffect(() => {
    if (projectId !== null && userDataState.data) {
      if (projectId) {
        setUserRole(getUserRole());
      } else if (collabSessionState?.session?.length > 0) {
        setUserRole(getUserRoleFromSession());
      }
    }
  }, [projectId, userDataState, collabSessionState?.session, collabState?.collaboration]);


  useEffect(() => {
    if (!socketClient && userRole && projectId !== null && !introVideoActive) {
      if (projectId) {
        setSocketClient(getSocketClient(userRole));
      } else if (collabSessionState?.session?.length > 0) {
        setSocketClient(getSocketClient(userRole, collabSessionState.session.map(s => s.id)));
      }
    }
  }, [projectId, userRole, collabSessionState?.session, introVideoActive]);


  useEffect(() => {
    if (socketClient) {
      socketClient.socket.on('onLineUsers', (connectedUsers) => {
        const teamListState = teamList;
        teamListState.map((u) => {
          const newU = u;
          if (connectedUsers.includes(newU.User.id)) {
            newU.onLine = true;
          } else {
            newU.onLine = false;
          }
          return newU;
        });
        setTeamList(teamListState);
      });
    }
  }, [socketClient]);


  useEffect(() => {
    if (amITheOwner() && !socketAgent) {
      setSocketAgent(new Agent(store, collabState.collaboration?.jsonBody?.board || {}));
    }
    if (amITheOwner() || (amITheTeacher() && !projectId)) {
      setIntroVideoActive(false);
    }
  }, [userRole]);


  useEffect(() => {
    if (amIAllowedToBuild() && agentState) {
      checkAgentMessagesForFlags(agentState.state.msgs[agentState.state.msgs.length - 1]);

      // console.log('===============COLLABORATIONPORTS', agentState.ports);
      // let selectedPort = buildPort;
      // if (agentState.ports.length <= 0) {
      //   selectedPort = '';
      // } else if (!agentState.ports.includes(selectedPort)) {
      //   selectedPort = agentState.ports[0];
      // }
      // if (selectedPort !== buildPort) {
      //   setBuildPort(selectedPort);

      //   // TODO: if teacher is in session administration mode, save port changed on every project
      //   const newProjectData = getProject();
      //   if (newProjectData.jsonBody) {
      //     newProjectData.jsonBody.port = selectedPort;
      //     setProjectData(newProjectData);
      //   }
      // }
    }

  }, [agentState]);


  useEffect(() => {
    if (socketAgent && collabState.collaboration?.jsonBody?.board?.compilerBoard !== 'microbit' && !intervalPortListing) {
      const intervalPortListing = setInterval(() => {
        socketAgent.findPorts();
      }, 1000);
      setIntervalPortListing(intervalPortListing);
    }

    return () => clearInterval(intervalPortListing);
  }, [socketAgent]);


  useEffect(() => {
    if (collabState.collaboration.id && collabSessionState?.session?.length > 0 && (amITheTeacher() || amITheOwner())) {
      updateSessionProjectState({ id: collabState.collaboration.id, jsonBody: collabState.collaboration.jsonBody });
    }

    if (collabState.collaboration.id && (!collabState.collaboration?.jsonBody?.collaborationIntroVideo || collabState.collaboration?.jsonBody?.collaborationIntroVideo === 'null' || amITheOwner())) {
      setIntroVideoActive(false);
    }
  }, [collabState?.collaboration?.jsonBody]);


  useEffect(() => {
    setProjectId(parseInt(location
      .pathname
      .split('/')
      .filter(Boolean)[0]
      .split('-')
      .filter(Boolean)[1], 10) || 0);

    // setIntroVideoActive((!sessionStorage.getItem(`${sessionKeys.INTRO_VIDEO_HIDDEN}-${projectId}`)));
    // setIntroVideoActive(true);
  }, []);


  useEffect(() => {
    if (userDataState.data) {
      LogRocket.identify(userDataState.data.userId, {
        name: `${userDataState.data.user.firstName} ${userDataState.data.user.lastName}`,
        email: userDataState.data.user.email,
      });
    }
  }, [userDataState]);


  useUpdateProjectInterval(updateBlocklyProject, 1000);
  useRefreshCompilationsInterval(userDataState?.data?.userId || 0, amITheOwner(), 10000);
  useRefreshOnwerSession(userDataState?.data?.userId || null, amITheOwner(), 1000 * 1200);

  const tabInfo = (collabState.collaboration?.jsonBody?.board) ? {
    tagsSelected: [],
    projectId: collabState.collaboration.id || 0,
    id: blocklyId,
    name: collabState.collaboration.title || '',
    description: collabState.collaboration.description || '',
    userId: (userDataState.data) ? userDataState.data.userId : 0,
    board: collabState.collaboration.jsonBody.board || {},
    port: collabState.collaboration.jsonBody.port || '',
    agentPorts: amITheOwner() ? agentState?.agentPorts || [] : collabState.collaboration.jsonBody?.agentPorts || [],
    boardName: collabState.collaboration.jsonBody.board.Name || '',
    className: collabState.collaboration.jsonBody.board.class || '',
    portOpen: false,
    active: true,
    disabled: false,
    withToolTip: false,
    withWorkspace: collabState.collaboration.jsonBody.withWorkspace || false,
    // withWorkspace: '',
    rightPanel: '',
    diagramImageUrl: collabState.collaboration.imageUrl || '',

    remoteSession: collabState.collaboration.jsonBody.remoteSession || 0,
    remoteProject: collabState.collaboration.jsonBody.remoteProject || false,
    remoteUserRole: collabState.collaboration.jsonBody.remoteUserRole || 'STUDENT',
    remoteSessionStarted: true,
    remoteSessionNeedInit: false,
    remoteProjectPassword: collabState.collaboration.jsonBody.remoteProjectPassword || null,
    remoteProjectPasswordToggle: collabState.collaboration.jsonBody.remoteProjectPasswordToggle || false,

    assignmentSession: collabState.collaboration.jsonBody.assignmentSession || 0,
    assignmentProject: collabState.collaboration.jsonBody.assignmentProject || false,
    assignmentUserRole: collabState.collaboration.jsonBody.assignmentUserRole || 'STUDENT',
    assignmentProjectDeliveryDate: collabState.collaboration.jsonBody.assignmentProjectDeliveryDate || '',
    assignmentProjectPassword: collabState.collaboration.jsonBody.assignmentProjectPassword || null,
    assignmentProjectPasswordToggle: collabState.collaboration.jsonBody.assignmentProjectPasswordToggle || false,

    collaborationSession: collabState.collaboration.id || 0,
    collaborationProject: collabState.collaboration.jsonBody.collaborationProject || false,
    collaborationProjectUserRole: collabState.collaboration.jsonBody.collaborationProjectUserRole || 'OWNER',
    userRole: userRole,

    boardType: collabState.collaboration.boardType || 'senior',
    code: collabState.collaboration.jsonBody.code || '',
  } : {};

  return (
    <div className={styles.collaboration}>
      <Header
        onSessionEnds={onSessionEnds}
        sessionEnded={sessionEnded}
        projectId={projectId}
        collabSessionState={collabSessionState}
        collabState={collabState}
        sessionSelectorActive={(amITheTeacher() || amITheOwner())}
      />
      {(projectId && collabState.collaboration?.jsonBody && (!sessionEnded || amITheOwner())) ? (
        <div className={(amIAllowedToBuild()) ? styles.content : styles.contentStudent}>
          {(introVideoActive && collabState.collaboration?.jsonBody?.collaborationIntroVideo && collabState.collaboration?.jsonBody?.collaborationIntroVideo !== 'null') ? (
            <IntroVideoModal
              videoUrl={collabState.collaboration?.jsonBody?.collaborationIntroVideo || ''}
              onClose={closeIntroVideoScreen}
            />
          ) : (
            <>
              <Blockly
                userData={userDataState.data || {}}
                tabInfo={tabInfo}
                socket={socketClient?.socket || null}
                socketAgent={socketAgent || null}
                teamList={teamList || []}
                buildProgress={buildProgress}
                buildingFunction={(amIAllowedToBuild()) ? buildBlockly : null}
                buildAllowed={(amIAllowedToBuild())}
                portChangeAllowed={(amIAllowedToBuild())}
              />
              <RightPanel
                title={collabState.collaboration?.title}
                tutorialLevel={collabState.tutorial?.tutorialCurrentStep || null}
                tabInfo={tabInfo}
                userData={userDataState.data || {}}
                port={collabState.collaboration?.jsonBody?.port || ''}
                teamList={teamList}
                streamsList={collabState.collaboration?.ProjectsCollabStreams || []}
                buildingFunction={(amIAllowedToSave() || amIAllowedToBuild()) ? buildStep : null}
                validateTutorialFunction={(checkTutorialHasChallenge() && amIAllowedToSave()) ? checkTutorialCode : null}
                streamsMaximized={streamsMaximized && collabState.collaboration?.ProjectsCollabStreams}
                onStreamsMinimize={() => { setStreamsMaximized(false) }}
              />
              <CollabModals
                id={'tutorialSuccessModal'}
                className={'success'}
                title={intl.formatMessage({ id: 'collaboration.tutorial.successModalTitle' })}
                message={tutorialGetCurrentStepTitle()}
                actionMessage={intl.formatMessage({ id: 'common.continue' })}
                image={'/images/collaboration/tutorialSuccess.png'}
                action={closeTutorialSuccessModal}
                active={showTutorialSuccess}
              />
              <CollabModals
                id={'tutorialErrorModal'}
                className={'error'}
                title={intl.formatMessage({ id: 'collaboration.tutorial.errorModalTitle' })}
                message={intl.formatMessage({ id: 'collaboration.tutorial.errorModalMessage' })}
                actionMessage={intl.formatMessage({ id: 'common.continue' })}
                image={'/images/collaboration/tutorialError.png'}
                action={closeTutorialValidateModal}
                active={showTutorialError}
              />
              <CollabModals
                id={'tutorialIntroModal'}
                className={'success'}
                title={tutorialGetNextStepTitle()}
                message={tutorialGetNextStepDescription()}
                actionMessage={intl.formatMessage({ id: 'collaboration.tutorial.start' })}
                image={tutorialGetNextStepImage()}
                action={closeTutorialStepIntroModal}
                active={showTutorialStepIntro}
              />
            </>
          )}
        </div>
      ) : ('')}
      <CollabModals
        id={'sessionEnded'}
        className={'error'}
        title={intl.formatMessage({ id: 'collaboration.sessionEndedModal.title' })}
        message={intl.formatMessage({ id: 'collaboration.sessionEndedModal.message' })}
        image={'/images/collaboration/sessionEnded.png'}
        actionMessage={intl.formatMessage({ id: 'collaboration.sessionEndedModal.actionMessage' })}
        action={kickUserOut}
        active={(sessionEnded && !amITheOwner())}
      />
      <ConnectingModal
        userRole={userRole}
        socket={socketClient?.socket || null}
      />
      {(!projectId && (amITheTeacher() || amITheOwner())) && (
        <SessionThumbnails
          session={collabSessionState?.session || []}
        />
      )}
    </div>
  );
};


const mapDispatchToProps = (dispatch) => {
  return {
    showToast: (params) => dispatch(showToastAction.showToast(params)),
  }
}

export default connect(null, mapDispatchToProps)(Collaboration);
