import { BasicBlock, BLOCK_CATEGORY_TYPE, BlockConfig } from '@sections/creabots/types/kits'
import getBlocksInfoSchema from '../../../config/getBlocksInfoSchema'

const blocks: BasicBlock[] = [
  {
    id: 0,
    name: 'serial_println',
  },
  {
    id: 1,
    name: 'serial_print',
  },
  {
    id: 2,
    name: 'BHOOT_blue_read_tag',
  },
  {
    id: 3,
    name: 'BHOOT_serial_blue_tag',
  },
  {
    id: 4,
    name: 'BHOOT_bt_serial_available',
  },
  {
    id: 5,
    name: 'BHOOT_bluetooth_name',
  },
  {
    id: 6,
    name: 'SALIDAS_buttons',
  },
];

export const comunicationConfig: BlockConfig = {
  blockCategory: 5,
  canvasDisplacement: { x: 380, y: 0 },
  blocks: getBlocksInfoSchema(blocks, BLOCK_CATEGORY_TYPE.COMUNICATION),
}
