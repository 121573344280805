/* eslint react/prop-types: 0 */
import React, { Component } from 'react';

class InputComponent extends Component {
  constructor(props) {
    super(props);
    const { changeField, form, name, type, keyname, onEnterPress, placeholder, label } = this.props;
    this.state = {
      onEnterPress: onEnterPress || (() => {}),
      changeField,
      form,
      name,
      type,
      keyname,
      placeholder,
      label: (typeof label === 'undefined') ? true : label,
      focused: false,
    };
    // const responsive = new Responsive(this); //eslint-disable-line
    // this.detectEnter = this.detectEnter.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      onEnterPress: newProps.onEnterPress || (() => {}),
      changeField: newProps.changeField,
      form: newProps.form,
      name: newProps.name,
      type: newProps.type,
      keyname: newProps.keyname,
      placeholder: newProps.placeholder,
    });
  }

  detectEnter(e) {
    const { onEnterPress } = this.state;

    if (e.keyCode === 13) { onEnterPress(e); }
  }

  render() {
    const { changeField, form, name, type, keyname, placeholder, label, focused } = this.state;

    return (
      <div className={`form-group bmd-form-group ${!label ? 'p-0 m-0' : ''}`}>
        <input onFocus={() => this.setState({ focused: true })} onBlur={() => this.setState({ focused: false })} onKeyDown={(e) => { this.detectEnter(e); }} type={type} className={`form-control ${(form[keyname] !== '') ? 'is-dirty' : 'is-not-dirty'} ${focused ? 'is-focused' : ''}`} id={`id-${keyname}`} onChange={(e) => { changeField(keyname, e); }} value={form[keyname]} placeholder={placeholder || ''} />
        {label && (
          <label htmlFor={`id-${keyname}`} className="bmd-label-floating">{ name }</label>
        )}
      </div>
    );
  }
}

export default InputComponent;
