import { BLOCK_CATEGORY_TYPE, BlockConfig, CREABOTS_KIT_ID_TYPE } from '@sections/creabots/types/kits';
import { initiationBlocksConfig } from '../initiation/config';
import { automationBlocksConfig } from '../automation/config';
import { drivingBlocksConfig } from '../driving/config';

export interface ConfigForBlock {
  config: {
    blockCategory: number,
    blockSubCategory?: number,
    canvasDisplacement: { x: number, y: number },
    block?: number,
  },
  element: {
    class: string,
    image?: string,
    xOffset: number,
    yOffset: number,
  }
}

export type BlockConfigByKit = Partial<{ [key in BLOCK_CATEGORY_TYPE]: BlockConfig }>;

const getBlockConfigByKitId = (kitId: CREABOTS_KIT_ID_TYPE): BlockConfigByKit => {
  switch(kitId) {
    case CREABOTS_KIT_ID_TYPE.INITIATION: return initiationBlocksConfig;
    case CREABOTS_KIT_ID_TYPE.AUTOMATION: return automationBlocksConfig;
    case CREABOTS_KIT_ID_TYPE.DRIVING: return drivingBlocksConfig;
    default: return initiationBlocksConfig;
  }
}

export const getAllConfigForBlock = (kitId: CREABOTS_KIT_ID_TYPE, blockCategoryType: BLOCK_CATEGORY_TYPE, blockNumber: number): ConfigForBlock => {
  const blockConfig = getBlockConfigByKitId(kitId);
  const config = blockConfig[blockCategoryType]
  const element = config && config.blocks.find(({ id }) => id === blockNumber)

  if (!config || !element) {
    throw new Error('Something went wrong.')
  }

  const { blockCategory, canvasDisplacement, blockSubCategory } = config
  const { className, image } = element;
  return {
    config: {
      blockCategory,
      canvasDisplacement,
      ...(typeof blockSubCategory === 'number' && { blockSubCategory }),
      block: blockNumber,
    },
    element: {
      class: className,
      image,
      xOffset: 0,
      yOffset: 0,
    },
  }
}

export const getConfigForBlock = (
  kitId: CREABOTS_KIT_ID_TYPE,
  blockCategoryType: BLOCK_CATEGORY_TYPE,
  blockNumber: number,
  extraOptions?: { ignoreBlock?: boolean, x?: number, y?: number, },
): ConfigForBlock['config'] => {

  const { config } = getAllConfigForBlock(kitId, blockCategoryType, blockNumber)
  const { block, ...restOfConfig } = config
  const ignoreBlock = extraOptions?.ignoreBlock

  return {
    ...restOfConfig,
    ...(!ignoreBlock && { block }),
    canvasDisplacement: {
      x: extraOptions?.x || restOfConfig.canvasDisplacement.x,
      y: extraOptions?.y || restOfConfig.canvasDisplacement.y,
    }
  }
}

export const getElementForBlock = (
  kitId: CREABOTS_KIT_ID_TYPE,
  blockCategoryType: BLOCK_CATEGORY_TYPE,
  blockNumber: number,
  extraOptions?: { extraClasses?: string, xOffset?: number, yOffset?: number, },
): ConfigForBlock['element'] => {
  const { element } = getAllConfigForBlock(kitId, blockCategoryType, blockNumber)
  const extraClasses = extraOptions?.extraClasses || ''
  const isValidXOffset = extraOptions?.xOffset
  const isValidYOffset = extraOptions?.yOffset
  return {
    ...element,
    class: extraClasses.trim() !== '' ? `${extraClasses} ${element.class}` : element.class,
    ...(isValidXOffset && { xOffset: extraOptions.xOffset }),
    ...(isValidYOffset && { yOffset: extraOptions.yOffset }),
  }
}