import { BasicBlock, BLOCK_CATEGORY_TYPE, BlockConfig } from '@sections/creabots/types/kits'
import getBlocksInfoSchema from '../../../config/getBlocksInfoSchema'

const blocks: BasicBlock[] = [
  {
    id: 0,
    name: 'entradas_suelo',
  },
  {
    id: 1,
    name: 'ENTRADAS_photoresistor'
  }
]

export const analogConfig: BlockConfig = {
  blockCategory: 6,
  blockSubCategory: 1,
  canvasDisplacement: { x: 420, y: 0 },
  blocks: getBlocksInfoSchema(blocks, BLOCK_CATEGORY_TYPE.ANALOG),
}