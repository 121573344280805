import { controlConfig } from './categories/control';
import { logicConfig } from './categories/logic';
import { mathConfig } from './categories/math';
import { variablesConfig } from './categories/variables';
import { textConfig } from './categories/text';
import { comunicationConfig } from './categories/comunication';
import { digitalConfig } from './categories/digital';
import { analogConfig } from './categories/analog';
import { outputsConfig } from './categories/outputs';
import { displaysConfig } from './categories/displays';
import { motorsConfig } from './categories/motors';
import { BLOCK_CATEGORY_TYPE, CREABOTS_KIT_ID_TYPE } from '@sections/creabots/types/kits';
import { ConfigForBlock, BlockConfigByKit, getConfigForBlock, getElementForBlock } from '../../config';

export const drivingBlocksConfig: BlockConfigByKit = {
  [BLOCK_CATEGORY_TYPE.CONTROL]: controlConfig,
  [BLOCK_CATEGORY_TYPE.LOGIC]: logicConfig,
  [BLOCK_CATEGORY_TYPE.MATH]: mathConfig,
  [BLOCK_CATEGORY_TYPE.VARIABLES]: variablesConfig,
  [BLOCK_CATEGORY_TYPE.TEXT]: textConfig,
  [BLOCK_CATEGORY_TYPE.COMUNICATION]: comunicationConfig,
  [BLOCK_CATEGORY_TYPE.DIGITAL]: digitalConfig,
  [BLOCK_CATEGORY_TYPE.ANALOG]: analogConfig,
  [BLOCK_CATEGORY_TYPE.OUTPUTS]: outputsConfig,
  [BLOCK_CATEGORY_TYPE.DISPLAYS]: displaysConfig,
  [BLOCK_CATEGORY_TYPE.MOTORS]: motorsConfig,
}

export const getDrivingConfigForBlock = (
  blockCategoryType: BLOCK_CATEGORY_TYPE,
  blockNumber: number,
  extraOptions?: { ignoreBlock?: boolean, x?: number, y?: number, },
): ConfigForBlock['config'] => {
  return getConfigForBlock(CREABOTS_KIT_ID_TYPE.DRIVING, blockCategoryType, blockNumber, extraOptions);
}

export const getDrivingElementForBlock = (
  blockCategoryType: BLOCK_CATEGORY_TYPE,
  blockNumber: number,
  extraOptions?: { extraClasses?: string, xOffset?: number, yOffset?: number, },
): ConfigForBlock['element'] => {
  return getElementForBlock(CREABOTS_KIT_ID_TYPE.DRIVING, blockCategoryType, blockNumber, extraOptions);
}