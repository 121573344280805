import * as routes from '@educabot/educablocks-cosmos';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './headerLinks.mod.scss';

class HeaderLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: props.userData,
      user: props.user,
      isElectron: props.isElectron || false,
      haveInternet: props.haveInternet || true,
    };
    this.renderList = this.renderList.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      userData: newProps.userData,
      user: newProps.user,
      isElectron: newProps.isElectron || false,
      haveInternet: newProps.haveInternet || true,
    });
  }

  renderList() {
    const { userData, isElectron } = this.state;
    return (
      <ul className={styles.menuList}>
        {(!isElectron) && (
          <li key="link1">
            <a href={routes.bloquesDashboardUri}>
              <FormattedMessage id="appSections.projects" />
            </a>
          </li>
        )}
        {((userData && userData.roles && (userData.roles.roleName === 'Administrador' || userData.roles.roleName === 'Premium')) >= 0) && (
        <li key="link2">
          <a href={routes.bloquesLibraryUri}>
            <FormattedMessage id="appSections.library" />
          </a>
        </li>
        )}
        <li key="link3">
          <a href={(isElectron) ? `${window.location.pathname}#/bloques` : routes.bloquesBloquesUri}>
            <FormattedMessage id="appSections.blocks" />
          </a>
        </li>
        <li key="link4">
          <a href={(isElectron) ? `${window.location.pathname}#/junior` : routes.bloquesJuniorUri}>
            <FormattedMessage id="appSections.blocksJr" />
          </a>
        </li>
        <li key="link5">
          <a href={(isElectron) ? `${window.location.pathname}#/codigo` : routes.bloquesCodeUri}>
            <FormattedMessage id="appSections.code" />
          </a>
        </li>
        {(!isElectron) && (
        <li key="link6">
          <a href={`${routes.bloquesLandingUri}/#download`} target="_blank" rel="noreferrer noopener">
            <FormattedMessage id="appSections.offlineVersion" />
          </a>
        </li>
        )}
      </ul>
    );
  }

  render() {
    const { userData, isElectron } = this.state;
    let buildName = '';
    if (userData && userData.user) {
      buildName = `${userData.user.firstName} ${userData.user.lastName}`;
      buildName = (buildName.length > 12) ? `${buildName.substring(0, 12)}...` : buildName;
    }

    return (
      <div className={styles.HeaderLinks}>
        {((userData && userData.user) && (
        <div className={styles.userCard}>
          {(userData.user.avatarUrl && userData.user.avatarUrl !== 'NULL') && (
            <div
              className={styles.avatar}
              style={
                userData.user.avatarUrl && userData.user.avatarUrl !== 'NULL'
                  ? { backgroundImage: `url(${userData.user.avatarUrl})` }
                  : {}
              }
            />
          )}
          <div className={styles.userData}>
            <p className={styles.name}>{buildName}</p>
            <p className={styles.role}>{userData.roles.roleName}</p>
          </div>
        </div>
        ))}
        { this.renderList() }
        {(!isElectron) && (
          <div className={styles.loginOut}>
            {((userData && userData.roles) && (
              <a href={`${routes.bloquesUri}/login`}>
                <FormattedMessage id="appSections.logOut" />
              </a>
            ))}
            {(!(userData.user) && (
              <a href={`${routes.bloquesUri}/login`}>
                <FormattedMessage id="appSections.logIn" />
              </a>
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default HeaderLinks;
