import { BasicBlock, BLOCK_CATEGORY_TYPE, BlockConfig } from '@sections/creabots/types/kits'
import getBlocksInfoSchema from '../../../config/getBlocksInfoSchema'

const blocks: BasicBlock[] = [
  {
    id: 0,
    name: 'ENTRADAS_button',
  },
  {
    id: 1,
    name: 'entradas_ultrasonido',
  },
]

export const digitalConfig: BlockConfig = {
  blockCategory: 5,
  blockSubCategory: 0,
  canvasDisplacement: { x: 460, y: 0 },
  blocks: getBlocksInfoSchema(blocks, BLOCK_CATEGORY_TYPE.DIGITAL),
}