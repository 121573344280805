import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  useTheme,
} from '@mui/material';
import { FieldStateKey } from '@sections/creabots/containers/configAccount';
import React, { useState } from 'react';
import { emailPattern } from '@sections/creabots/helpers/regex';
import {
  Close,
  EmailOutlined,
  LockOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import {
  changeResponsableEmail,
  checkUserEmailAndPassword,
} from '@services/rest/creabots';
import {
  TextBodyRegular,
  TextBodySmallRegular,
  TextHeadlineEmphasized,
  TextTitleRegular,
} from '../ui/typographies';
import { LabsInput } from '../ui/input';
import { useDispatch } from 'react-redux';
import { checkAuthAction } from 'src/actions/authAction';
import { useIntl } from 'react-intl';

interface FormControlProps {
  keyFieldState: FieldStateKey;
  isOpen: boolean;
  parentId: number;
  parentValue?: string;
  handleOpenFieldState: (field: FieldStateKey) => void;
}

export function ChangeEmailFormControl({
  isOpen,
  keyFieldState,
  handleOpenFieldState,
  parentValue,
  parentId,
}: FormControlProps) {
  const [isLoading, setIsLoading] = useState(false);
  const muiTheme = useTheme();
  const [fields, setFields] = useState({
    email: '',
    emailPassword: '',
  });
  const [errors, setErrors] = useState({
    email: '',
    emailPassword: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const intl = useIntl();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  const handleOnSubmit = async () => {
    if (!fields.email && !fields.emailPassword) {
      setErrors({
        email: intl.formatMessage({
          id: 'creabots.validations.fieldCannotBeEmpty',
        }),
        emailPassword: intl.formatMessage({
          id: 'creabots.validations.fieldCannotBeEmpty',
        }),
      });
      return;
    }
    if (!fields.email) {
      setErrors(prev => ({
        ...prev,
        email: intl.formatMessage({
          id: 'creabots.validations.fieldCannotBeEmpty',
        }),
      }));
      return;
    }
    if (!fields.emailPassword) {
      setErrors(prev => ({
        ...prev,
        emailPassword: intl.formatMessage({
          id: 'creabots.validations.fieldCannotBeEmpty',
        }),
      }));
      return;
    }
    if (!parentValue) return;
    try {
      setIsLoading(true);
      await checkUserEmailAndPassword({
        email: parentValue,
        newEmail: fields.email,
        password: fields.emailPassword,
      });
      setOpen(true);
    } catch (error) {
      if (error.response?.data.message === 'email-registered') {
        setErrors(prev => ({
          ...prev,
          email: intl.formatMessage({
            id: 'creabots.validations.emailRegistered',
          }),
        }));
        return;
      }
      setErrors(prev => ({
        ...prev,
        emailPassword: intl.formatMessage({
          id: 'creabots.validations.wrongPassword',
        }),
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    handleOpenFieldState(keyFieldState);
    setOpen(false);
    if (!isOpen) {
      setFields({
        email: '',
        emailPassword: '',
      });
      setErrors({
        email: '',
        emailPassword: '',
      });
      setShowPassword(false);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2.2}
      paddingX={1.5}
      paddingY={1}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <TextBodyRegular>
          {intl.formatMessage({ id: 'creabots.labels.email' })}
        </TextBodyRegular>
        <Button
          variant="text"
          disableRipple
          sx={theme => ({
            color: theme.palette.primary.main,
            textTransform: 'capitalize',
            width: 'fit-content',
            fontWeight: theme.typography.fontWeightMedium,
          })}
          onClick={handleClose}
          disabled={isLoading}
        >
          {isOpen
            ? intl.formatMessage({ id: 'common.cancel' })
            : intl.formatMessage({ id: 'common.modify' })}
        </Button>
      </Box>
      {isOpen ? (
        <Box
          component="form"
          sx={theme => ({
            height: 'min-content',
            gap: theme.spacing(2.5),
            margin: 0,
            padding: 0,
          })}
        >
          <Box>
            <InputLabel
              shrink={false}
              htmlFor="email"
              className="bmd-label-static"
              sx={{ marginBottom: '1rem' }}
              style={{
                fontSize: '1rem',
                lineHeight: '1.5rem',
                fontWeight: muiTheme.typography.fontWeightMedium,
                fontFamily: muiTheme.typography.fontFamily,
                color: muiTheme.palette.text.primary,
              }}
            >
              {intl.formatMessage({ id: 'creabots.labels.newParentEmail' })}
            </InputLabel>
            <LabsInput
              size="medium"
              autoComplete="off"
              variant="outlined"
              type="text"
              name="email"
              id="email"
              error={!!errors.email}
              value={fields.email}
              onChange={e => {
                if (e.target.value.match(emailPattern) === null) {
                  setErrors(prev => ({
                    ...prev,
                    email: intl.formatMessage({
                      id: 'creabots.validations.invalidEmail',
                    }),
                  }));
                } else {
                  setErrors(prev => ({ ...prev, email: '' }));
                }
                handleChange(e);
              }}
              onFocus={() => setErrors(prev => ({ ...prev, email: '' }))}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <EmailOutlined
                      sx={theme => ({
                        color: errors.email
                          ? theme.palette.error.main
                          : 'inherit',
                      })}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <TextBodySmallRegular paddingLeft={1.5} itemType="error">
              {errors.email}
            </TextBodySmallRegular>
          </Box>
          <Box>
            <InputLabel
              shrink={false}
              htmlFor="emailPassword"
              className="bmd-label-static"
              sx={{ marginBottom: '1rem' }}
              style={{
                fontSize: '1rem',
                lineHeight: '1.5rem',
                fontWeight: muiTheme.typography.fontWeightMedium,
                fontFamily: muiTheme.typography.fontFamily,
                color: muiTheme.palette.text.primary,
              }}
            >
              {intl.formatMessage({ id: 'creabots.labels.currentPassword' })}
            </InputLabel>
            <LabsInput
              size="medium"
              autoComplete="off"
              variant="outlined"
              type={showPassword ? 'text' : 'password'}
              name="emailPassword"
              id="emailPassword"
              error={!!errors.emailPassword}
              value={fields.emailPassword}
              onFocus={() =>
                setErrors(prev => ({ ...prev, emailPassword: '' }))
              }
              onChange={handleChange}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="cambiador de visibiliad de contraseña"
                      onClick={() => setShowPassword(prev => !prev)}
                      sx={{ padding: 0, margin: 0 }}
                    >
                      {showPassword ? (
                        <VisibilityOutlined
                          sx={theme => ({
                            color: errors.emailPassword
                              ? theme.palette.error.main
                              : 'inherit',
                          })}
                        />
                      ) : (
                        <VisibilityOffOutlined
                          sx={theme => ({
                            color: errors.emailPassword
                              ? theme.palette.error.main
                              : 'inherit',
                          })}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextBodySmallRegular paddingLeft={1.5} itemType="error">
              {errors.emailPassword}
            </TextBodySmallRegular>
          </Box>
          <Button
            onClick={handleOnSubmit}
            variant="primary"
            size="small"
            sx={{ width: 'fit-content', textTransform: 'math-auto' }}
            disabled={isLoading}
          >
            {intl.formatMessage({ id: 'common.saveChanges' })}
          </Button>
        </Box>
      ) : (
        <TextTitleRegular>{parentValue}</TextTitleRegular>
      )}
      <VerificationEmailModal
        open={open}
        onClose={handleClose}
        email={fields.email}
        parentId={parentId}
      />
    </Box>
  );
}

type VerificationEmailModalProps = {
  open: boolean;
  onClose: () => void;
  email: string;
  parentId: number;
};

function VerificationEmailModal({
  open,
  email,
  parentId,
  onClose,
}: VerificationEmailModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const muiTheme = useTheme();
  const dispatch = useDispatch();
  const intl = useIntl();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  const handleOnClose = () => {
    onClose();
    setError('');
    setCode('');
  };

  const handleConfirmAction = async () => {
    if (!code) {
      setError(
        intl.formatMessage({ id: 'creabots.validations.fieldCannotBeEmpty' })
      );
    }
    try {
      setIsLoading(true);
      await changeResponsableEmail({ userId: parentId, newEmail: email, code });
      checkAuthAction(dispatch, false);
      handleOnClose();
    } catch (error) {
      setError(
        intl.formatMessage({ id: 'creabots.account.errors.updatingEmail' })
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleOnClose}
      disableEscapeKeyDown={isLoading}
      aria-labelledby="Modal de verificación de correo electrónico"
      aria-describedby="Modal de verificación de correo electrónico"
    >
      <Box
        sx={theme => ({
          backgroundColor: theme.palette.common.white,
          borderRadius: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '450px',
          width: '100%',
          minWidth: '300px',
          height: 'fit-content',
          padding: theme.spacing(3),
          gap: theme.spacing(2.5),
        })}
      >
        <Box
          sx={() => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          })}
        >
          <TextHeadlineEmphasized>
            {intl.formatMessage({ id: 'creabots.validations.verifyEmail' })}
          </TextHeadlineEmphasized>
          <IconButton
            sx={{ padding: 0 }}
            disabled={isLoading}
            onClick={handleOnClose}
          >
            <Close
              sx={theme => ({
                height: '24px',
                width: '24px',
                color: theme.palette.text.secondary,
              })}
            />
          </IconButton>
        </Box>
        <TextBodyRegular>
          {intl.formatMessage({ id: 'creabots.newCodeSended' })}
        </TextBodyRegular>
        <Box
          sx={theme => ({
            height: 'min-content',
            gap: theme.spacing(2.5),
            margin: 0,
            padding: 0,
          })}
        >
          <Box>
            <InputLabel
              shrink={false}
              htmlFor="email"
              className="bmd-label-static"
              sx={{ marginBottom: '1rem' }}
              style={{
                fontSize: '1rem',
                lineHeight: '1.5rem',
                fontWeight: muiTheme.typography.fontWeightMedium,
                fontFamily: muiTheme.typography.fontFamily,
                color: muiTheme.palette.text.primary,
              }}
            >
              {intl.formatMessage({ id: 'creabots.labels.verificationCode' })}
            </InputLabel>
            <LabsInput
              size="medium"
              autoComplete="off"
              variant="outlined"
              type="text"
              name="code"
              id="code"
              value={code}
              error={!!error}
              fullWidth
              onChange={handleOnChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <LockOutlined />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <TextBodySmallRegular paddingLeft={1.5} itemType="error">
            {error}
          </TextBodySmallRegular>
          <Button
            sx={{ float: 'inline-end', marginTop: '1rem' }}
            size="large"
            variant="primary"
            disabled={isLoading}
            onClick={handleConfirmAction}
          >
            {intl.formatMessage({ id: 'common.confirm' })}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
