import * as routes from '@educabot/educablocks-cosmos';

export function range(start, end, step = 1) {
  // Test that the first 3 arguments are finite numbers.
  // Using Array.prototype.every() and Number.isFinite().
  const allNumbers = [start, end, step].every(Number.isFinite);

  // Throw an error if any of the first 3 arguments is not a finite number.
  if (!allNumbers) {
    throw new TypeError('range() expects only finite numbers as arguments.');
  }

  // Ensure the step is always a positive number.
  if (step <= 0) {
    throw new Error('step must be a number greater than 0.');
  }

  // When the start number is greater than the end number,
  // modify the step for decrementing instead of incrementing.
  if (start > end) {
    step =- step; // eslint-disable-line
  }

  // Determine the length of the array to be returned.
  // The length is incremented by 1 after Math.floor().
  // This ensures that the end number is listed if it falls within the range.
  const length = Math.floor(Math.abs((end - start) / step)) + 1;

  // Fill up a new array with the range numbers
  // using Array.from() with a mapping function.
  // Finally, return the new array.
  return Array.from(Array(length), (x, index) => start + index * step);
}

export function createElementFromHTML(htmlString) {
  const div = document.createElement('div');
  div.innerHTML = htmlString.trim();

  // Change this to div.childNodes to support multiple top-level nodes
  return div.firstChild;
}


export function getHtmlByTutorialCodename(tutorialCodename) {
  let htmlContent;
  if (tutorialCodename) htmlContent = `${routes.bloquesUri}/html/blockHelp/${tutorialCodename}.html`;

  return htmlContent;
}


export function checkIfHaveInternet(callback) {
  const xhr = new XMLHttpRequest();
  const file = 'https://www.kirupa.com/blank.png';
  const randomNum = Math.round(Math.random() * 10000);

  xhr.open('HEAD', `${file}?rand=${randomNum}`, true);
  xhr.send();

  function processRequest() {
    if (xhr.readyState === 4) {
      callback((xhr.status >= 200 && xhr.status < 304));
    }
  }
  xhr.addEventListener('readystatechange', processRequest, false);
}

export default {};
