import { blocksApiV1, blocksApi } from './api';

export const getAll = () => blocksApiV1
  .get('/users/projects')
  .then((response) => response.data);

export const getAllBySeniorityId = (seniorityId) => blocksApiV1
  .get(seniorityId ? `/users/projects?seniority=${seniorityId}` : '/users/projects')
  .then((response) => response.data);

export const putProject = (id, project) => blocksApi
  .put(`/collaboration/${id}`, project)
  .then((response) => response.data);

export const createProject = (project) => blocksApi
  .post('/users/projects', project)
  .then((response) => response.data);

export const updateProject = (id, project) => blocksApi
  .put(`/users/projects/${id}`, project)
  .then((response) => response.data);

export const getUserProject = (projectId) => blocksApi
  .get(`/projects/${projectId}`)
  .then((response) => response.data);

export const deleteProject = (projectId) => blocksApi.delete(`/users/projects/${projectId}`).then((response) => response.data);

export const projectsService = {
  getAll,
  getAllBySeniorityId,
  putProject,
  createProject,
  updateProject,
  getUserProject,
  deleteProject,
};


