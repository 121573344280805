/* eslint no-underscore-dangle: 0 */
import React from 'react';

class ResetPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitForm: props.submitForm,
      form: props.form,
      formError: props.formError || null,
      referrer: props.referrer,
      loginLink: props.loginLink,
    }
    this.changeField = this.changeField.bind(this);
    this._prev = this._prev.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      submitForm: newProps.submitForm,
      form: newProps.form,
      formError: newProps.formError || null,
      loginLink: newProps.loginLink,
    });
  }

  changeField(field, e) {
    const { form } = this.state;
    this.changeField = this.changeField.bind(this);
    form[field] = e.target.value;
    this.setState({ form });
  }

  _prev() {
    const { loginLink } = this.state;

    window.location = loginLink;
  }

  render() {
    const { submitForm, form, formError } = this.state;
    return (
      <form>
        <div className="form-group header">
          <span className="first-line">¿Olvidaste tu contraseña?</span>
          <span className="second-line">Por favor, ingresá tu correo para reestablecerla</span>
        </div>
        <div className="form-group">
          <label htmlFor="Email">Correo electrónico *</label>
          <input type="email" id="email" className={formError && 'border border-danger'} value={form.email} htmlref="Email" onChange={(e) => this.changeField('email', e)} />
        </div>
        <div class="center c-red">
          {formError && formError}
        </div>
        <div className="form-group btn-action">
          <button type="submit" onClick={submitForm} className="logBtn btn-primary btn-block btn-lg mt-40">
            Reiniciar contraseña
          </button>
          <button type="button" onClick={this._prev} className="backBtn btn-primary btn-block btn-lg mt-40">
            Volver
          </button>
        </div>
      </form>
    );
  }
}

export default ResetPasswordForm;
