/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import * as routes from '@educabot/educablocks-cosmos';

class AssignmentLinkComponent extends Component {
  constructor(props) {
    super(props);
    const { id, password, url } = this.props;
    this.state = {
      id,
      password,
      url,
    };
  }

  componentWillReceiveProps(newprops) {
    this.setState({
      id: newprops.id,
      password: newprops.password,
      url: newprops.url,
    });
  }

  render() {
    const { id, password, url } = this.state;
    return (
      (parseInt(id, 10) > 0) ? (
        <React.Fragment>
          <div className="sidebar-content">
            <div className="project-remote">
              <h4>Actividad</h4>
              <p>
                <strong>Número: </strong>
                {id}
                {(password) ? (
                  <React.Fragment>
                    <br />
                    <strong>Contraseña: </strong>
                    {password}
                  </React.Fragment>
                ) : ('')}
              </p>
            </div>
            {(url.indexOf('actividad') === -1) ? (
              <a href={`${routes.bloquesUri}/actividad-${id}`} className="btn btn-primary main-btn">
                Acceder a esta actividad
              </a>
            ) : ('')}
          </div>
        </React.Fragment>
      ) : ('')
    );
  }
}

export default AssignmentLinkComponent;
