import { BasicBlock, BLOCK_CATEGORY_TYPE, BlockConfig } from '@sections/creabots/types/kits'
import getBlocksInfoSchema from '../../../config/getBlocksInfoSchema'

const blocks: BasicBlock[] = [
  {
    id: 0,
    name: 'base_delay',
  },
  {
    id: 1,
    name: 'controls_if',
  },
  {
    id: 2,
    name: 'controls_repeat_educabot',
  }
]

export const controlConfig: BlockConfig = {
  blockCategory: 0,
  canvasDisplacement: { x: 340, y: 0 },
  blocks: getBlocksInfoSchema(blocks, BLOCK_CATEGORY_TYPE.CONTROL),
}