import { Box, Button, IconButton, Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { TextTitleThreeEmphasized } from './typographies';
import { Close } from '@mui/icons-material';
import { LabsInput } from './input';
import { useDispatch, useSelector } from 'react-redux';
import {
  renameProject,
  selectCreabots,
} from '@sections/creabots/features/creabotsSlice';
import { LOADING_STATUS, SUCCESS_STATUS } from '@utils/redux';
import { useIntl } from 'react-intl';

interface Props {
  isOpen: boolean;
  project: any;
  handleClose: () => void;
}

export const RenameProjectModal: React.FC<Props> = ({
  isOpen,
  project,
  handleClose,
}) => {
  const intl = useIntl();
  const [name, setName] = useState(project.title);
  const [error, setError] = useState('');
  const { projectOptions } = useSelector(selectCreabots);
  const dispatch = useDispatch();
  const isLoading = projectOptions.renameStatus === LOADING_STATUS;

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleCloseModal = () => {
    setName(project.title);
    handleClose();
  };

  const handleConfirmAction = () => {
    if (name === '') {
      setError(
        intl.formatMessage({
          id: 'creabots.dashboard.renameProjectModal.emptyName',
        })
      );
      return;
    }
    dispatch(renameProject({ ...project, title: name }));
  };

  useEffect(() => {
    if (projectOptions.renameStatus === SUCCESS_STATUS) {
      handleClose();
    }
  }, [projectOptions.renameStatus]);

  return (
    <Modal
      open={isOpen}
      onClose={handleCloseModal}
      disableEscapeKeyDown={isLoading}
    >
      <Box
        sx={theme => ({
          backgroundColor: theme.palette.common.white,
          borderRadius: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '600px',
          width: '100%',
          height: 'fit-content',
          maxHeight: '450px',
          paddingX: theme.spacing(3),
          paddingY: theme.spacing(1),
        })}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <TextTitleThreeEmphasized>
            {intl.formatMessage({
              id: 'creabots.dashboard.renameProjectModal.title',
            })}
          </TextTitleThreeEmphasized>
          <IconButton onClick={handleCloseModal} disabled={isLoading}>
            <Close />
          </IconButton>
        </Box>
        <Box paddingY={2}>
          <LabsInput
            onChange={handleOnChange}
            name="name"
            value={name}
            fullWidth
          />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          paddingY={2}
          width="100%"
        >
          {error && (
            <TextTitleThreeEmphasized color="error">
              {error}
            </TextTitleThreeEmphasized>
          )}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="end"
            width="100%"
            gap={2}
          >
            <Button
              size="small"
              variant="secondary"
              onClick={handleCloseModal}
              disabled={isLoading}
            >
              {intl.formatMessage({
                id: 'common.cancel',
              })}
            </Button>
            <Button
              size="small"
              variant="primary"
              onClick={handleConfirmAction}
              disabled={isLoading}
            >
              {intl.formatMessage({
                id: 'creabots.dashboard.renameProjectModal.saveName',
              })}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
