import { BasicBlock, BLOCK_CATEGORY_TYPE, BlockConfig } from '@sections/creabots/types/kits'
import getBlocksInfoSchema from '../../../config/getBlocksInfoSchema'

const blocks: BasicBlock[] = [
  {
    id: 0,
    name: 'matrix8x8',
  },
  {
    id: 1,
    name: 'matrix8x8_text',
  },
  {
    id: 2,
    name: 'lcd_educabot',
  },
  {
    id: 3,
    name: 'lcd_setBacklight',
  },
  {
    id: 4,
    name: 'lcd_clear',
  },
];

export const displaysConfig: BlockConfig = {
  blockCategory: 7,
  canvasDisplacement: { x: 600, y: 0 },
  blocks: getBlocksInfoSchema(blocks, BLOCK_CATEGORY_TYPE.DISPLAYS),
}
