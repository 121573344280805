import { boards as Boards, getBoards } from '@educabot/educablocks-boards';
import * as routes from '@educabot/educablocks-cosmos';
import themeVariables from './themes/exports.scss';

export const getCosmosItem = (key) => {
  const domain = (process.env.THEME_DOMAIN) ? process.env.THEME_DOMAIN : null;
  const environment = (process.env.THEME_ENV) ? process.env.THEME_ENV : null;

  const cosmosItem = routes.getCosmosItem(domain, environment, key);

  return cosmosItem || null;
};

export const getBoardsByCosmos = (
  type = 'all',
  includePremiumBoards = true,
  includeDisabled = true,
  includeHidden = false,
) => {
  const boardsOptions = getCosmosItem('boardsOptions');
  return getBoards(
    Boards,
    type,
    includePremiumBoards,
    includeDisabled,
    includeHidden,
    boardsOptions?.exclude || undefined,
  );
};

export const getCosmosImageItem = (key, fallback, internalFolderStructure = '') => {
  const imageRoute = getCosmosItem(key);
  return `${process.env.IS_ELECTRON ? '.' : ''}/images/${internalFolderStructure}${imageRoute || fallback}`;
};

export const getLogo = () => getCosmosImageItem('logo', 'logo.svg');
export const getFavicon = () => getCosmosImageItem('favicon', 'default.svg', 'favicons/');

export const getThemeVariable = (propertyName) => {
  const themeKey = getCosmosItem('theme');

  if (themeKey) return themeVariables[`${themeKey}${propertyName}`];
  return '';
};

export const applyTheme = () => {
  const themeKey = getCosmosItem('theme');
  if (themeKey) {
    const body = window.document.querySelector('body');
    body.removeAttribute('class');
    body.classList.add(themeKey);
  }
};
