import { BasicBlock, BLOCK_CATEGORY_TYPE, BlockConfig } from '@sections/creabots/types/kits'
import getBlocksInfoSchema from '../../../config/getBlocksInfoSchema'

const blocks: BasicBlock[] = [
  {
    id: 0,
    name: 'logic_compare',
  },
  {
    id: 1,
    name: 'logic_operation',
  },
  {
    id: 2,
    name: 'logic_negate',
  }
]

export const logicConfig: BlockConfig = {
  blockCategory: 1,
  canvasDisplacement: { x: 210, y: 0 },
  blocks: getBlocksInfoSchema(blocks, BLOCK_CATEGORY_TYPE.LOGIC),
}