import React from 'react';
import { GridView, ListRounded } from '@mui/icons-material';
import { Box, ToggleButtonGroup, alpha, ToggleButton } from '@mui/material';

export type Props = {
  viewFormat: string;
  handleFormat: (
    _event: React.MouseEvent<HTMLElement>,
    format: string | null
  ) => void;
};

export function ToggleView({ viewFormat, handleFormat }: Props) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 1,
      }}
    >
      <ToggleButtonGroup
        value={viewFormat}
        onChange={handleFormat}
        aria-label="view formatting"
        exclusive
        sx={(theme) => ({
          boxShadow: `0px 0px 2px 0px ${alpha(
            theme.palette.common.black,
            0.12,
          )} inset`,
          borderRadius: '8px',
          border: 'none',
        })}
      >
        <ToggleButton
          value="card"
          aria-label="card"
          disableTouchRipple
          sx={(theme) => ({
            border: 'none',
            '&:hover': {
              backgroundColor: alpha(theme.palette.text.primary, 0.03),
            },
          })}
        >
          <GridView />
        </ToggleButton>
        <ToggleButton
          value="list"
          aria-label="list"
          disableTouchRipple
          sx={(theme) => ({
            border: 'none',
            '&:hover': {
              backgroundColor: alpha(theme.palette.text.primary, 0.03),
            },
          })}
        >
          <ListRounded />
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}
