import Tutorial1Step1 from '@sections/creabots/components/steps/Tutorial1Step1';
import { BLOCK_CATEGORY_TYPE, BOARD_ID_TYPE, COMMON_CLASSES, MODAL_TYPE, Project } from '@sections/creabots/types/kits';
import * as routes from '@educabot/educablocks-cosmos';
import { getAutomationConfigForBlock, getAutomationElementForBlock } from '../config';
import Tutorial4Step2 from '@sections/creabots/components/steps/automation/Tutorial4Step2';
import { higherThan0Regex } from '@sections/creabots/features/utils/commonRegexs';

const intlPrefix = 'creabots.tutorials.automatization.projects.automaticFan';
const imgBaseUrl = '/images/creabots/automatization/projects/automaticFan';

export const automaticFan: Project = {
  id: 4,
  name: `${intlPrefix}.name`,
  description: `${intlPrefix}.description`,
  image: `${routes.bloquesUri}${imgBaseUrl}/project_image.png`,
  boardId: BOARD_ID_TYPE.AUTOMATION,
  tutorial: [
    // Step 1.
    {
      step: {
        stepId: 0,
        modal: MODAL_TYPE.FULL,
        component: Tutorial1Step1,
        videoUrl: 'https://storage.googleapis.com/educabot-aec-cdn-1/labs/ventilador-automatico.mp4',
      },
    },
    // Step 2.
    {
      step: {
        stepId: 1,
        modal: MODAL_TYPE.FULL,
        component: Tutorial4Step2,
      },
    },
    // Step 3.
    {
      step: {
        stepId: 2,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step3`,
        image: `${imgBaseUrl}/steps/step3.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 1),
        completionCode: 'voidsetup\\(\\)\\{\\}voidloop\\(\\)\\{if\\(0==0\\)\\{\\}\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getAutomationElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 1),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 75,
          },
        },
      ],
    },
    // Step 4.
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/steps/step4.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.DIGITAL, 2),
        completionCode: 'voidsetup\\(\\)\\{\\}voidloop\\(\\)\\{DHT11A0\\.read\\(DHT11PINA0\\);if\\(DHT11A0\\.humidity==0\\)\\{\\}\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getAutomationElementForBlock(BLOCK_CATEGORY_TYPE.DIGITAL, 2),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 95,
            yOffset: 75,
          },
        },
      ],
    },
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/steps/step4.png`,
        completionCode: 'voidsetup\\(\\)\\{\\}voidloop\\(\\)\\{DHT11A0\\.read\\(DHT11PINA0\\);if\\(DHT11A0\\.temperature==0\\)\\{\\}\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 210,
            yOffset: 85,
          },
        },
      ],
    },
    // Step 5.
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/steps/step5.png`,
        completionCode: 'voidsetup\\(\\)\\{\\}voidloop\\(\\)\\{DHT11A0\\.read\\(DHT11PINA0\\);if\\(DHT11A0\\.temperature>0\\)\\{\\}\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 450,
            yOffset: 85,
          },
        },
      ],
    },
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/steps/step5.png`,
        completionCode: `voidsetup\\(\\)\\{\\}voidloop\\(\\)\\{DHT11A0\\.read\\(DHT11PINA0\\);if\\(DHT11A0\\.temperature>${higherThan0Regex}\\)\\{\\}\\}`,
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 510,
            yOffset: 85,
          },
        },
      ],
    },
    // Step 6.
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/steps/step6.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 0),
        completionCode: `voidsetup\\(\\)\\{pinMode\\(A3,OUTPUT\\);pinMode\\(A2,OUTPUT\\);\\}voidloop\\(\\)\\{DHT11A0\\.read\\(DHT11PINA0\\);if\\(DHT11A0\\.temperature>${higherThan0Regex}\\)\\{digitalWrite\\(A3,HIGH\\);digitalWrite\\(A2,LOW\\);\\}\\}`,
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getAutomationElementForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 57,
            yOffset: 130,
          },
        },
      ],
    },
    // Step 7.
    {
      step: {
        stepId: 6,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step7`,
        image: `${imgBaseUrl}/steps/step7.png`,
        completionCode: `voidsetup\\(\\)\\{pinMode\\(A3,OUTPUT\\);pinMode\\(A2,OUTPUT\\);\\}voidloop\\(\\)\\{DHT11A0\\.read\\(DHT11PINA0\\);if\\(DHT11A0\\.temperature>${higherThan0Regex}\\)\\{digitalWrite\\(A3,HIGH\\);digitalWrite\\(A2,LOW\\);\\}else\\{\\}\\}`,
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 38,
            yOffset: 182,
          },
        },
      ],
    },
    // Step 8.
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/steps/step8.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 0),
        completionCode: `voidsetup\\(\\)\\{pinMode\\(A3,OUTPUT\\);pinMode\\(A2,OUTPUT\\);\\}voidloop\\(\\)\\{DHT11A0\\.read\\(DHT11PINA0\\);if\\(DHT11A0\\.temperature>${higherThan0Regex}\\)\\{digitalWrite\\(A3,HIGH\\);digitalWrite\\(A2,LOW\\);\\}else\\{digitalWrite\\(A3,HIGH\\);digitalWrite\\(A2,LOW\\);\\}\\}`,
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getAutomationElementForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 57,
            yOffset: 215,
          },
        },
      ],
    },
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/steps/step8.png`,
        completionCode: `voidsetup\\(\\)\\{pinMode\\(A3,OUTPUT\\);pinMode\\(A2,OUTPUT\\);\\}voidloop\\(\\)\\{DHT11A0\\.read\\(DHT11PINA0\\);if\\(DHT11A0\\.temperature>${higherThan0Regex}\\)\\{digitalWrite\\(A3,HIGH\\);digitalWrite\\(A2,LOW\\);\\}else\\{digitalWrite\\(A3,LOW\\);digitalWrite\\(A2,LOW\\);\\}\\}`,
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 177,
            yOffset: 225,
          },
        },
      ],
    },
    // Step 9.
    {
      step: {
        stepId: 8,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step9`,
        image: `${imgBaseUrl}/steps/step9.gif`,
        completionCodeCheck: 'built',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.BUILD_BLOCKLY_BTN_CLASS,
            xOffset: -10,
            yOffset: -10,
          },
        },
      ],
    },
    // Step 10.
    {
      step: {
        stepId: 9,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step10`,
        image: `${imgBaseUrl}/steps/step10.gif`,
      },
    },
  ],
};
