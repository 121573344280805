/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';

export const HeroCreabots = () => {
  const muiTheme = useTheme();
  const isMdSize = useMediaQuery((theme) => theme.breakpoints.up('md'));

  return (
    <Box
      sx={(theme) => ({
        marginTop: { xs: '7.5rem', md: '3.5rem' },
        backgroundColor: theme.palette.common.white,
        height: { xs: 'calc(min-content - 7.5rem)', md: 'calc(min-content - 3.5rem)' },
        paddingX: { xs: '1.5rem', md: '4.75rem' },
        paddingY: { xs: '2.5rem', md: '4rem' },
        display: 'flex',
        justifyContent: 'center',
      })}
    >
      <Box sx={{
        maxWidth: '1080px',
        height: { xs: 'auto', sm: '400px', md: '600px' },
      }}
      >
        <video
          controls
          src="https://educablocks-staging-space-v1.sfo2.digitaloceanspaces.com/creabots/Creabots_Publicitario.mp4"
          style={{
            width: '100%',
            height: '100%',
            borderRadius: isMdSize ? muiTheme.spacing(5) : muiTheme.spacing(1.5),
          }}
        />
      </Box>
    </Box>
  );
};
