/* eslint no-underscore-dangle: 0 */
/* eslint react/no-access-state-in-setstate: 0 */
/* eslint react/destructuring-assignment: 0 */
/* eslint no-useless-escape: 0 */
import React from 'react';
import * as routes from '@educabot/educablocks-cosmos';
import axios from 'axios';
import FacebookLogin from 'react-facebook-login';
import { GoogleLogin } from 'react-google-login';
import InputComponent from '../InputComponent';

const FB = (typeof window !== 'undefined') ? window.FB : {};

class SignupForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitForm: props.submitForm,
      form: props.form,
      formError: '',
      fieldsWithErrors: [],
      currentStep: 1,
      loginLink: props.loginLink,
    }
    this.changeField = this.changeField.bind(this);
    this.showPass = this.showPass.bind(this);
    this._next = this._next.bind(this)
    this._prev = this._prev.bind(this)
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      submitForm: newProps.submitForm,
      form: newProps.form,
      formError: newProps.formError || null,
      fieldsWithErrors: newProps.fieldsWithErrors || [],
    });
  }

  changeField(field, e) {
    const { form, currentStep } = this.state;
    form[field] = e.target.value;
    this.setState({ form, formError: '' }, () => this.checkStepValidation(currentStep));
  }

  showPass() {
    const { form } = this.state;
    form.hidden = !form.hidden;
    this.setState({ form });
  }

  checkStepValidation(stepNumber) {
    const { form } = this.state;
    const isEMAIL = function (email) {
      const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return (emailRegex.test(email));
    }

    switch (stepNumber) {
      case 1:
        if (!isEMAIL(form.email)) return [['email'], 'El correo electrónico es incorrecto']
        if (form.password + form.confirmPassword === '') return [['password', 'confirmPassword'], 'Ingrese una contraseña']
        if (form.password !== form.confirmPassword) return [['password', 'confirmPassword'], 'La contraseña no coincide']
        return [[], '']
      case 2:
        if (!form.firstName) return [['firstName'], 'Ingrese un nombre']
        if (!form.lastName) return [['lastName'], 'Ingrese un apellido']
        return [[], '']
      default:
        return [[], '']
    }
  }

  _next() {
    let currentStep = this.state.currentStep
    currentStep = currentStep >= 2 ? 2 : currentStep + 1
    this.setState({
      currentStep,
    })
  }

  _prev() {
    let currentStep = this.state.currentStep;
    let signStatus = this.state;
    signStatus = null;
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStep = currentStep <= 1 ? 1 : currentStep - 1
    this.setState({ currentStep, signStatus })
  }

  previousButton() {
    const currentStep = this.state.currentStep;
    // If the current step is not 1, then render the "previous" button
    if (currentStep !== 1) {
      return (
        <button type="button" onClick={this._prev} className="backBtn btn-primary btn-block btn-lg mt-40">
          Volver
        </button>
      )
    }
    // ...else return nothing
    return null;
  }

  nextButton() {
    const currentStep = this.state.currentStep;
    if (currentStep === 1) {
      return (
        <button
          type="button"
          onClick={() => {
            const [fieldsWithErrors, error] = this.checkStepValidation(currentStep)
            this.setState({ ...this.state, fieldsWithErrors, formError: error })
            if (!error) this._next()
          }}
          className="logBtn btn-primary btn-block btn-lg mt-40"
        >
          Siguiente
        </button>
      )
    }
    return null;
  }

  signUpButton() {
    const currentStep = this.state.currentStep;
    if (currentStep === 2) {
      return (
        <button
          type="submit"
          onClick={(e) => {
            const [fieldsWithErrors, error] = this.checkStepValidation(currentStep)
            this.setState({ ...this.state, fieldsWithErrors, formError: error })
            if (!error) this.state.submitForm(e)
            else {
              e.preventDefault()
              e.stopPropagation()
            }
          }}
          className="logBtn btn-primary btn-block btn-lg mt-40"
        >
          Registrarme
        </button>
      )
    }
    return null;
  }

  render() {
    const { form, formError, currentStep, loginLink, fieldsWithErrors } = this.state;
    return (
      <form className="signUpForm">
        <div className={`stepsContent step-${currentStep}`}>
          <div className="stepWizard">
            <div className="form-group header">
              <span className="first-line"><img alt="" src={`${routes.bloquesUri}/images/hello.png`} />Crear cuenta en Robots</span>
              <span className="second-line">Ingresá correo y contraseña</span>
            </div>
            <div className="form-group">
              <label htmlFor="Email">Correo electrónico *</label>
              <input className={fieldsWithErrors.includes('email') && 'input-error'} type="email" id="email" value={form.email} htmlref="Email" onChange={(e) => this.changeField('email', e)} />
              {/* <InputComponent changeField={this.changeField} form={form} name="Email" type="email" keyname="email" /> */}
            </div>
            <div className="form-group">
              <label htmlFor="Password">Contraseña *</label>
              <input className={fieldsWithErrors.includes('password') && 'input-error'} type="password" id="password" value={form.password} htmlref="Password" onChange={(e) => this.changeField('password', e)} />
            </div>
            <div className="form-group">
              <label htmlFor="Password">Repetir contraseña *</label>
              <input className={fieldsWithErrors.includes('confirmPassword') && 'input-error'} type="password" id="confirmPassword" value={form.confirmPassword} htmlref="confirmPassword" onChange={(e) => this.changeField('confirmPassword', e)} />
            </div>
            {formError && (
              <div><p className="error">{formError}</p></div>
            )}
            <div className="form-group btn-action">
              {this.nextButton()}
            </div>
          </div>

          <div className="stepWizard ">
            <div className="form-group header">
              <span className="first-line"><img alt="" src={`${routes.bloquesUri}/images/hello.png`} />Crear cuenta en Robots</span>
              <span className="second-line">Ingresá nombre y apellido</span>
            </div>
            <div className="form-group">
              <label htmlFor="firstName">Nombre *</label>
              <input className={fieldsWithErrors.includes('firstName') && 'input-error'} type="text" id="firstName" value={form.firstName} htmlref="firstName" onChange={(e) => this.changeField('firstName', e)} />
            </div>
            <div className="form-group">
              <label htmlFor="lastName">Apellido *</label>
              <input className={fieldsWithErrors.includes('lastName') && 'input-error'} type="text" id="lastName" value={form.lastName} htmlref="lastName" onChange={(e) => this.changeField('lastName', e)} />
            </div>
            {formError && (
              <div><p className="error">{formError}</p></div>
            )}

            <div className="form-group btn-action">
              {this.signUpButton()}
              {this.previousButton()}
            </div>
          </div>
        </div>
        <div className="form-group annotation">
          ¿Ya tenés una cuenta? <a href={loginLink}>Iniciar sesión</a>
        </div>
        <div className="form-group footer">
          <span>Al crear una cuenta, aceptás nuestros <a href="https://educabot.com/nosotros/">Términos y Condiciones</a> y nuestra <a href="https://educabot.com/nosotros/">Política de Privacidad</a></span>
        </div>
      </form>
    );
  }
}

export default SignupForm;
