import Tutorial1Step1 from '@sections/creabots/components/steps/Tutorial1Step1'
import Tutorial5Step2 from '@sections/creabots/components/steps/Tutorial5Step2'
import { BLOCK_CATEGORY_TYPE, BOARD_ID_TYPE, COMMON_CLASSES, MODAL_TYPE, Project } from '@sections/creabots/types/kits'
import * as routes from '@educabot/educablocks-cosmos';
import { getInitiationConfigForBlock, getInitiationElementForBlock } from '../config'

const intlPrefix = 'creabots.tutorials.initiation.projects.guessTheFuture'
const imgBaseUrl = '/images/creabots/initiation/projects/guessTheFuture/steps'
const validNumber = '(?:100[1-9]|10[1-9]\\d|1[1-9]\\d{2,}|[2-9]\\d{3,}|[1-9]\\d{4,})';

const guessTheFuture: Project = {
  id: 5,
  name: `${intlPrefix}.name`,
  description: `${intlPrefix}.description`,
  image: `${routes.bloquesUri}/images/creabots/initiation/projects/guessTheFuture/project_image.png`,
  boardId: BOARD_ID_TYPE.INITIATION,
  tutorial: [
    // Step 1.
    {
      step: {
        stepId: 0,
        modal: MODAL_TYPE.FULL,
        component: Tutorial1Step1,
        videoUrl: 'https://storage.googleapis.com/educabot-aec-cdn-1/labs/adivinar-el-futuro.mp4',
      },
    },
    // Step 2.
    {
      step: {
        stepId: 1,
        modal: MODAL_TYPE.FULL,
        component: Tutorial5Step2,
      },
    },
    // Step 3.
    {
      step: {
        stepId: 2,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step3`,
        image: `${imgBaseUrl}/step3.png`,
        blockCategory: 3,
        canvasDisplacement: { x: 200, y: 0 },
        completionCodeCheck: 'variable',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.ADD_VARIABLE_BTN_CLASS,
            xOffset: 40,
            yOffset: -18,
          },
        },
      ],
    },
    {
      step: {
        stepId: 2,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step3`,
        image: `${imgBaseUrl}/step3.png`,
        blockCategory: 3,
        block: 4,
        canvasDisplacement: { x: 470, y: 0 },
        completionCode: '(String\\w+\\[\\];)(?=.*voidsetup\\(\\)\\{//\\w+\\})',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: '.variables_declare_array_dynamic',
            image: '/images/creabots/buty/blocks/variables/variables_declare_array_dynamic.png',
            xOffset: 0,
            yOffset: 0,
          },
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 47,
            yOffset: 17,
          },
        },
      ],
    },
    {
      step: {
        stepId: 2,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step3`,
        image: `${imgBaseUrl}/step3.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.MATH, 0),
        completionCode: '(String\\w+\\[0\\];)(?=.*voidsetup\\(\\)\\{//\\w+\\})',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.MATH, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 360,
            yOffset: 17,
          },
        },
      ],
    },
    {
      step: {
        stepId: 2,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step3`,
        image: `${imgBaseUrl}/step3.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.MATH, 0),
        completionCode: '(String\\w+\\[3\\];)(?=.*voidsetup\\(\\)\\{//\\w+\\})',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 360,
            yOffset: 17,
          },
        },
      ],
    },
    // Step 4.
    // First block.
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/step4.png`,
        blockCategory: 3,
        block: 5,
        canvasDisplacement: { x: 470, y: 0 },
        completionCode: 'voidsetup\\(\\)\\{//\\w+\\[\\]=;\\}voidloop\\(\\)\\{\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: '.variables_set_array_dynamic',
            image: '/images/creabots/buty/blocks/variables/variables_set_array_dynamic.png',
            xOffset: 0,
            yOffset: 0,
          },
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 47,
            yOffset: 45,
          },
        },
      ],
    },
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/step4.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.MATH, 0),
        completionCode: 'voidsetup\\(\\)\\{//\\w+\\[0\\]=;\\}voidloop\\(\\)\\{\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.MATH, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 185,
            yOffset: 60,
          },
        },
      ],
    },
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/step4.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.TEXT, 0),
        completionCode: 'voidsetup\\(\\)\\{//\\w+\\[0\\]="";\\}voidloop\\(\\)\\{\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.TEXT, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 257,
            yOffset: 60,
          },
        },
      ],
    },
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/step4.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.TEXT, 0),
        completionCode: 'voidsetup\\(\\)\\{//\\w+\\[0\\]="\\w{2,}";\\}voidloop\\(\\)\\{\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 257,
            yOffset: 60,
          },
        },
      ],
    },
    // Second block.
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/step4.png`,
        blockCategory: 3,
        block: 5,
        canvasDisplacement: { x: 470, y: 0 },
        completionCode: 'voidsetup\\(\\)\\{.+\\w\\[\\]=;\\}voidloop\\(\\)\\{\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: '.variables_set_array_dynamic',
            image: '/images/creabots/buty/blocks/variables/variables_set_array_dynamic.png',
            xOffset: 0,
            yOffset: 0,
          },
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 47,
            yOffset: 85,
          },
        },
      ],
    },
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/step4.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.MATH, 0),
        completionCode: 'voidsetup\\(\\)\\{.+\\w\\[0\\]=;\\}voidloop\\(\\)\\{\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.MATH, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 185,
            yOffset: 95,
          },
        },
      ],
    },
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/step4.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.MATH, 0),
        completionCode: 'voidsetup\\(\\)\\{.+\\w\\[1\\]=;\\}voidloop\\(\\)\\{\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 185,
            yOffset: 95,
          },
        },
      ],
    },
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/step4.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.TEXT, 0),
        completionCode: 'voidsetup\\(\\)\\{.+\\w\\[1\\]="";\\}voidloop\\(\\)\\{\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.TEXT, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 257,
            yOffset: 95,
          },
        },
      ],
    },
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/step4.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.TEXT, 0),
        completionCode: 'voidsetup\\(\\)\\{.+\\w\\[1\\]="\\w{2,}";\\}voidloop\\(\\)\\{\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 257,
            yOffset: 95,
          },
        },
      ],
    },
    // Third block.
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/step4.png`,
        blockCategory: 3,
        block: 5,
        canvasDisplacement: { x: 470, y: 0 },
        completionCode: 'voidsetup\\(\\)\\{.+\\w\\[\\]=;\\}voidloop\\(\\)\\{\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: '.variables_set_array_dynamic',
            image: '/images/creabots/buty/blocks/variables/variables_set_array_dynamic.png',
            xOffset: 0,
            yOffset: 0,
          },
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 47,
            yOffset: 120,
          },
        },
      ],
    },
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/step4.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.MATH, 0),
        completionCode: 'voidsetup\\(\\)\\{.+\\w\\[0\\]=;\\}voidloop\\(\\)\\{\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.MATH, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 185,
            yOffset: 135,
          },
        },
      ],
    },
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/step4.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.MATH, 0),
        completionCode: 'voidsetup\\(\\)\\{.+\\w\\[2\\]=;\\}voidloop\\(\\)\\{\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 185,
            yOffset: 135,
          },
        },
      ],
    },
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/step4.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.TEXT, 0),
        completionCode: 'voidsetup\\(\\)\\{.+\\w\\[2\\]="";\\}voidloop\\(\\)\\{\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.TEXT, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 257,
            yOffset: 135,
          },
        },
      ],
    },
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/step4.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.TEXT, 0),
        completionCode: 'voidsetup\\(\\)\\{.+\\w\\[2\\]="[\\w ]{5,}";\\}voidloop\\(\\)\\{\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 257,
            yOffset: 135,
          },
        },
      ],
    },
    // Step 5.
    // First block.
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/step5.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 2),
        completionCode: 'voidloop\\(\\)\\{lcd\\.setCursor\\(0,0\\);lcd\\.print\\("\\w+"\\);\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 2),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 47,
            yOffset: 200,
          },
        },
      ],
    },
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/step5.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 2),
        completionCode: 'voidloop\\(\\)\\{lcd\\.setCursor\\(0,0\\);lcd\\.print\\("[\\w ]{5,}"\\);\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 170,
            yOffset: 215,
          },
        },
      ],
    },
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/step5.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 2),
        completionCode: 'voidloop\\(\\)\\{lcd\\.setCursor\\(3,0\\);lcd\\.print\\("[\\w ]{5,}"\\);\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 415,
            yOffset: 215,
          },
        },
      ],
    },
    // Second block.
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/step5.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 2),
        completionCode: 'voidloop\\(\\)\\{.+lcd\\.setCursor\\(0,0\\);lcd\\.print\\("\\w+"\\);\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 2),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 47,
            yOffset: 245,
          },
        },
      ],
    },
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/step5.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 2),
        completionCode: 'voidloop\\(\\)\\{.+lcd\\.setCursor\\(0,0\\);lcd\\.print\\("[\\w ]{5,}"\\);\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 170,
            yOffset: 260,
          },
        },
      ],
    },
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/step5.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 2),
        completionCode: 'voidloop\\(\\)\\{.+lcd\\.setCursor\\(0,1\\);lcd\\.print\\("[\\w ]{5,}"\\);\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 295,
            yOffset: 260,
          },
        },
      ],
    },
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/step5.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 2),
        completionCode: 'voidloop\\(\\)\\{.+lcd\\.setCursor\\(4,1\\);lcd\\.print\\("[\\w ]{5,}"\\);\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 420,
            yOffset: 260,
          },
        },
      ],
    },
    // Step 6.
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/step6.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 1),
        completionCode: 'voidloop\\(\\)\\{.+if\\(0==0\\)\\{\\}\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 1),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 47,
            yOffset: 285,
          },
        },
      ],
    },
    // Step 7.
    {
      step: {
        stepId: 6,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step7`,
        image: `${imgBaseUrl}/step7.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.DIGITAL, 0),
        completionCode: 'voidloop\\(\\)\\{.+if\\(!digitalRead\\(A0\\)==0\\)\\{\\}\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.DIGITAL, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 95,
            yOffset: 308,
          },
        },
      ],
    },
    {
      step: {
        stepId: 6,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step7`,
        image: `${imgBaseUrl}/step7.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.DIGITAL, 0),
        completionCode: 'voidloop\\(\\)\\{.+if\\(!digitalRead\\(A0\\)==1\\)\\{\\}\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 425,
            yOffset: 315,
          },
        },
      ],
    },
    // Step 8.
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/step8.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 4),
        completionCode: 'voidloop\\(\\)\\{.+if\\(!digitalRead\\(A0\\)==1\\)\\{lcd\\.clear\\(\\);\\}\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 4),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 57,
            yOffset: 360,
          },
        },
      ],
    },
    // Step 9.
    // First block.
    {
      step: {
        stepId: 8,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step9`,
        image: `${imgBaseUrl}/step9.gif`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 2),
        completionCode: 'voidloop\\(\\)\\{.+if\\(!digitalRead\\(A0\\)==1\\)\\{.+lcd\\.setCursor\\(0,0\\);lcd\\.print\\("\\w+"\\);\\}\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 2),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 60,
            yOffset: 400,
          },
        },
      ],
    },
    {
      step: {
        stepId: 8,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step9`,
        image: `${imgBaseUrl}/step9.gif`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 2),
        completionCode: 'voidloop\\(\\)\\{.+if\\(!digitalRead\\(A0\\)==1\\)\\{.+lcd\\.setCursor\\(0,0\\);lcd\\.print\\("[\\w ]{5,}"\\);\\}\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 190,
            yOffset: 415,
          },
        },
      ],
    },
    {
      step: {
        stepId: 8,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step9`,
        image: `${imgBaseUrl}/step9.gif`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 2),
        completionCode: 'voidloop\\(\\)\\{.+if\\(!digitalRead\\(A0\\)==1\\)\\{.+lcd\\.setCursor\\(4,0\\);lcd\\.print\\("[\\w ]{5,}"\\);\\}\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 435,
            yOffset: 415,
          },
        },
      ],
    },
    // Second block.
    {
      step: {
        stepId: 8,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step9`,
        image: `${imgBaseUrl}/step9.gif`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        completionCode: 'voidloop\\(\\)\\{.+if\\(!digitalRead\\(A0\\)==1\\)\\{.+delay\\(1000\\);\\}\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 57,
            yOffset: 445,
          },
        },
      ],
    },
    // Third block.
    {
      step: {
        stepId: 8,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step9`,
        image: `${imgBaseUrl}/step9.gif`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 4),
        completionCode: 'voidloop\\(\\)\\{.+if\\(!digitalRead\\(A0\\)==1\\)\\{.+lcd\\.clear\\(\\);\\}\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 4),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 57,
            yOffset: 480,
          },
        },
      ],
    },
    // Fourth block.
    {
      step: {
        stepId: 8,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step9`,
        image: `${imgBaseUrl}/step9.gif`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        completionCode: 'voidloop\\(\\)\\{.+if\\(!digitalRead\\(A0\\)==1\\)\\{.+delay\\(1000\\);\\}\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 57,
            yOffset: 530,
          },
        },
      ],
    },
    {
      step: {
        stepId: 8,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step9`,
        image: `${imgBaseUrl}/step9.gif`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        completionCode: 'voidloop\\(\\)\\{.+if\\(!digitalRead\\(A0\\)==1\\)\\{.+delay\\(500\\);\\}\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 145,
            yOffset: 550,
          },
        },
      ],
    },
    // Step 10.
    {
      step: {
        stepId: 9,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step10`,
        image: `${imgBaseUrl}/step10.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 2),
        completionCode: 'voidloop\\(\\)\\{.+if\\(!digitalRead\\(A0\\)==1\\)\\{.+lcd\\.setCursor\\(0,0\\);lcd\\.print\\("\\w+"\\);\\}\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 2),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 57,
            yOffset: 570,
          },
        },
      ],
    },
    {
      step: {
        stepId: 9,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step10`,
        image: `${imgBaseUrl}/step10.png`,
        blockCategory: 3,
        canvasDisplacement: { x: 470, y: 0 },
        block: 6,
        completionCode: 'voidloop\\(\\)\\{.+if\\(!digitalRead\\(A0\\)==1\\)\\{.+lcd\\.setCursor\\(0,0\\);lcd\\.print\\(\\w+\\[\\]\\);\\}\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: '.variables_get_array_dynamic',
            image: '/images/creabots/buty/blocks/variables/variables_get_array_dynamic.png',
            xOffset: 0,
            yOffset: 0,
          },
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 180,
            yOffset: 590,
          },
        },
      ],
    },
    // Step 11.
    {
      step: {
        stepId: 10,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step11`,
        image: `${imgBaseUrl}/step11.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.MATH, 3),
        completionCode: 'voidloop\\(\\)\\{.+if\\(!digitalRead\\(A0\\)==1\\)\\{.+lcd\\.setCursor\\(0,0\\);lcd\\.print\\(\\w+\\[random\\(.+\\)\\]\\);\\}\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.MATH, 3),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 277,
            yOffset: 590,
          },
        },
      ],
    },
    {
      step: {
        stepId: 10,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step11`,
        image: `${imgBaseUrl}/step11.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.MATH, 3),
        completionCode: 'voidloop\\(\\)\\{.+if\\(!digitalRead\\(A0\\)==1\\)\\{.+lcd\\.setCursor\\(0,0\\);lcd\\.print\\(\\w+\\[random\\(0.+\\)\\]\\);\\}\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 380,
            yOffset: 590,
          },
        },
      ],
    },
    {
      step: {
        stepId: 10,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step11`,
        image: `${imgBaseUrl}/step11.png`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.MATH, 3),
        completionCode: 'voidloop\\(\\)\\{.+if\\(!digitalRead\\(A0\\)==1\\)\\{.+lcd\\.setCursor\\(0,0\\);lcd\\.print\\(\\w+\\[random\\(0,3\\)\\]\\);\\}\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 440,
            yOffset: 590,
          },
        },
      ],
    },
    // Step 12.
    {
      step: {
        stepId: 11,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step12`,
        image: `${imgBaseUrl}/step12.gif`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        completionCode: 'voidloop\\(\\)\\{.+if\\(!digitalRead\\(A0\\)==1\\)\\{.+delay\\(1000\\);\\}\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 57,
            yOffset: 620,
          },
        },
      ],
    },
    {
      step: {
        stepId: 11,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step12`,
        image: `${imgBaseUrl}/step12.gif`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        completionCode: `voidloop\\(\\)\\{.+if\\(!digitalRead\\(A0\\)==1\\)\\{.+delay\\(${validNumber}\\);\\}\\}`,
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 145,
            yOffset: 635,
          },
        },
      ],
    },
    {
      step: {
        stepId: 11,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step12`,
        image: `${imgBaseUrl}/step12.gif`,
        ...getInitiationConfigForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 4, { x: 615 }),
        completionCode: 'voidloop\\(\\)\\{.+if\\(!digitalRead\\(A0\\)==1\\)\\{.+lcd\\.clear\\(\\);\\}\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getInitiationElementForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 4),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 57,
            yOffset: 657,
          },
        },
      ],
    },
    // Step 13.
    {
      step: {
        stepId: 12,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step13`,
        image: `${imgBaseUrl}/step13.gif`,
        completionCodeCheck: 'built',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.BUILD_BLOCKLY_BTN_CLASS,
            xOffset: -10,
            yOffset: -10,
          },
        },
      ],
    },
    // Step 14.
    {
      step: {
        stepId: 13,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step14`,
        image: `${imgBaseUrl}/step14.gif`,
      },
    },
  ],
}

export default guessTheFuture