/* eslint prefer-promise-reject-errors: 0 */
/* eslint no-nested-ternary: 0 */
import axios from 'axios';
import * as routes from '@educabot/educablocks-cosmos';
import { getAgentPorts, getNewMessage, getNewState, getPorts } from '../actions/bloquesAction';
import AvrgirlArduino from './avrgirl/avrgirl-arduino-browser';

class WebSerialAgent {
  constructor(store, board, socketElectronConnection, httpElectronConnection, serialMonitorMessages, intl) {
    this.store = store;
    this.board = board;
    this.socketElectronConnection = socketElectronConnection;
    this.httpElectronConnection = httpElectronConnection;
    this.selectedPort = null;
    this.monitor = '';
    this.portList = [];
    this.serialPortList = [];
    const compilerBoardName = this.board.compilerBoard.split(':');
    const compilerBoard = compilerBoardName[2] || null;
    this.intl = intl;

    // console.log('==================', compilerBoard, board);
    this.avrgirl = null;
    try {
      this.avrgirl = new AvrgirlArduino({
        board: compilerBoard,
        debug: true,
      });
      this.avrgirl.connection.serialPort.on('connect', (port) => {
        // console.log('======================CONNECT', port);
        this.addPort(port);
      });
      this.avrgirl.connection.serialPort.on('disconnect', (port) => {
        // console.log('======================DISCONNECT', port);
        this.removePort(port);
      });
    } catch (error) {
      this.store.dispatch(getNewMessage(error.toString()));
    }

    if (process.env.IS_ELECTRON || 'serial' in navigator) {
      this.store.dispatch(getNewState('#### PORT CONNECTED'));
      this.store.dispatch(getNewState('#### BOARD NOT CONNECTED'));
    }

    this.serialMonitorMessages = serialMonitorMessages;
    this.findInitialPorts();
  }

  disconnectSocket = () => {}

  stopCheckingAgent = () => { }

  removePort = (port) => {
    for (let i = 0; i < this.serialPortList.length; i += 1) {
      if (this.serialPortList[i].SerialPort === port) {
        this.serialPortList.splice(i, 1);
        this.portList.splice(i, 1);
        this.store.dispatch(getPorts(this.portList));
        this.store.dispatch(getAgentPorts(this.serialPortList));
        if (this.portList.length > 0) {
          this.store.dispatch(getNewState('#### BOARD CONNECTED'));
        } else this.store.dispatch(getNewState('#### BOARD NOT CONNECTED'));
      }
    }
  }

  addPort = (port) => {
    let exists = false;
    this.serialPortList.map((p) => {
      if (p.SerialPort === port) {
        exists = true;
      }
      return true;
    });
    if (!exists) {
      const portInfo = port.getInfo();
      const vendorID = `0x${portInfo.usbVendorId.toString(16).padStart(4, '0')}`;
      const productID = `0x${portInfo.usbProductId.toString(16).padStart(4, '0')}`;
      // const name = `Puerto ${this.portList.length + 1}`;
      const name = `Puerto (${vendorID}-${productID})`;

      this.portList.push(name);
      this.serialPortList.push({
        Name: name,
        SerialPort: port,
        VendorID: vendorID,
        ProductID: productID,
      });
      this.store.dispatch(getPorts(this.portList));
      this.store.dispatch(getAgentPorts(this.serialPortList));
      if (this.portList.length > 0) {
        this.store.dispatch(getNewState('#### BOARD CONNECTED'));
      } else this.store.dispatch(getNewState('#### BOARD NOT CONNECTED'));
    }
  }

  getSerialPortByName = (name = '') => {
    let serialPort = null;
    for (let i = 0; i < this.serialPortList.length; i += 1) {
      if (this.serialPortList[i].Name === name) {
        serialPort = this.serialPortList[i].SerialPort;
        break;
      }
    }

    return serialPort;
  }

  connectNewBoard = () => {
    if (this.avrgirl) {
      this.avrgirl.connectNewBoard((error, port) => {
        if (error) {
          // console.log('==================new ports error', error);
          // this.store.dispatch(getNewMessage(error.toString()));
          this.store.dispatch(getNewMessage(this.intl.formatMessage({ id: 'agent.boardNotSelected' })));
          this.store.dispatch(getNewState('#### BOARD NOT CONNECTED'));
        } else {
          this.addPort(port);
        }
        // console.log('==================new port', port);
      });
    }
  }

  findPorts = () => {}

  findInitialPorts = () => {
    if (this.avrgirl) {
      this.avrgirl.findPorts((error, ports) => {
        if (error) {
          // console.log('==================ports error', error);
          this.store.dispatch(getNewMessage(error.toString()));
          return;
        }
        for (let i = 0; i < ports.length; i += 1) {
          this.addPort(ports[i]);
        }
      });
    }
  }

  openMonitor = (port = '', baudios = 9600) => {
    if (this.avrgirl) {
      const serialPort = this.getSerialPortByName(port);
      // console.log('==============', port, serialPort);
      this.avrgirl.openMonitor(serialPort, baudios, (error, message) => {
        if (error) {
          this.store.dispatch(getNewMessage(error.toString()));
          return;
        }
        this.monitor += message;
        if (message.match(/\n/g) || this.monitor.length > 100) {
          const date = new Date();
          const time = `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}.${String(date.getMilliseconds()).padStart(3, '0')}`;
          this.serialMonitorMessages.next({ time, message: this.monitor });
          this.monitor = '';
        }
      });
    }
  }

  closeMonitor = (port = '') => {
    if (this.avrgirl) {
      const serialPort = this.getSerialPortByName(port);
      this.avrgirl.closeMonitor(serialPort, () => { });
    }
  }

  sendMonitor = (port = '', command = '') => {
    if (this.avrgirl) {
      this.avrgirl.send(command, (error) => {
        if (error) this.store.dispatch(getNewMessage(error.toString()));
      });
    }
  };

  // changeBaudios = (port = '', baudios = 0) => { };

  uploadToPort = (code = '', board = '', port = '') => {
    if (this.avrgirl) {
      const serialPort = this.getSerialPortByName(port);
      // this.store.dispatch(getNewState('#### BOARD CONNECTED'));
      this.store.dispatch(getNewMessage(this.intl.formatMessage({ id: 'agent.codeIsUploading' })));
      this.store.dispatch(getNewState('#### UPLOAD TO PORT'));

      const selectedBoard = board.compilerBoard || 'arduino';

      let buildURI = (process.env.IS_ELECTRON) ? `${this.httpElectronConnection}/eb_build` : ((process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') ? routes.ebBuildUri : 'https://builder.staging.educabot.com/eb_build');
      let buildParams = `board=${board.compilerBoard}&text=${encodeURIComponent(code)}`;
      if (selectedBoard === 'microbit') {
        buildURI = (process.env.IS_ELECTRON) ? `${this.httpElectronConnection}/microbit_build` : routes.microbitBuildUri;
        buildParams = `text=${encodeURIComponent(code)}`;
        this.store.dispatch(getNewState('#### UPLOAD TO EB_BUILD'));
      }
      this.store.dispatch(getNewState('#### UPLOAD TO EB_BUILD'));
      axios.post(
        buildURI,
        buildParams,
        {
          withCredentials: false,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': '*/*', //eslint-disable-line
          },
        },
      ).then((response) => {
        if (response.data.status === 'SUCCESS' && response.data.payload.hex) {
          this.store.dispatch(getNewState('#### FINISH EB_BUILD'));
          this.store.dispatch(getNewState('#### UPLOAD TO AGENT'));

          if (selectedBoard === 'microbit') {
            // MICROBIT BUILDER
            const blob = new Blob([atob(response.data.payload.hex)], { type: 'octet/stream' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.target = '_blank';
            a.download = 'microbit.hex';
            a.setAttribute('id', 'clickme');
            document.body.appendChild(a);
            a.click();

            this.store.dispatch(getNewState('#### FINISH AGENT'));
          } else {
            const hexBuffer = Buffer.from(atob(response.data.payload.hex));
            this.avrgirl.flash(serialPort, hexBuffer, error => {
              if (error) {
                this.store.dispatch(getNewState('#### ERROR EB_BUILD'));
                this.store.dispatch(getNewMessage(error.toString()));
              } else {
                // this.store.dispatch(getNewState('#### FINISH EB_BUILD'));
                this.store.dispatch(getNewState('#### FINISH AGENT'));
              }
            });
          }
        } else {
          this.store.dispatch(getNewState('#### ERROR EB_BUILD'));
          this.store.dispatch(getNewMessage(response.data.message));
        }
      }).catch((responseError) => {
        this.store.dispatch(getNewState('#### ERROR EB_BUILD'));
      });
    }
  }

  uploadRemoteSession = (hex = '', board = '', port = '') => new Promise((resolve, reject) => {
    if (this.avrgirl) {
      const serialPort = this.getSerialPortByName(port);
      this.store.dispatch(getNewState('#### UPLOAD TO PORT'));
      /**
       * Response object
       * status: Possible values are SUCCESS, ERROR.
       * message: Error message, if there is any.
       */
      const selectedBoard = board.compilerBoard || 'arduino';
      if (selectedBoard !== 'microbit' && (!port || port === '')) {
        this.store.dispatch(getNewState('#### BOARD NOT CONNECTED'));
        this.store.dispatch(getNewMessage(this.intl.formatMessage({ id: 'agent.boardNotConnected' })));
        reject(this.intl.formatMessage({ id: 'agent.boardNotConnected' }));
      }
      this.store.dispatch(getNewState('#### BOARD CONNECTED'));
      if (!board) {
        this.store.dispatch(getNewMessage(this.intl.formatMessage({ id: 'agent.boardMustBeSelected' })));
        reject('Debes seleccionar una placa');
      }

      this.store.dispatch(getNewMessage(this.intl.formatMessage({ id: 'agent.remoteCodeIsUploading' })));

      this.store.dispatch(getNewState('#### UPLOAD TO EB_BUILD'));

      /* eslint-disable */
      if (selectedBoard === 'microbit') {
        this.store.dispatch(getNewState('#### FINISH AGENT'));
        // MICROBIT BUILDER
        const blob = new Blob([atob(hex)], { type: 'octet/stream' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        a.download = 'microbit.hex';
        a.setAttribute('id', 'clickme');
        document.body.appendChild(a);
        a.click();
      } else {
        const hexBuffer = Buffer.from(atob(hex));
        this.avrgirl.flash(serialPort, hexBuffer, error => {
          if (error) {
            this.store.dispatch(getNewState('#### ERROR EB_BUILD'));
            this.store.dispatch(getNewMessage(error.toString()));
          } else {
            // store.dispatch(getNewState('#### FINISH EB_BUILD'));
            this.store.dispatch(getNewState('#### FINISH AGENT'));
          }
        });
      }
    } else {
      reject('Placa no soportada');
    }
  });
}

export default WebSerialAgent;
