import React, { useState } from 'react';
import { LockOutlined } from '@mui/icons-material';
import clsx from 'clsx';
import {
  resendProspectCode,
  verifyProspectCode,
} from '@services/rest/creabots';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as showToastAction from '@modules/toasts/features/toastsSlice';
import { Grid, InputAdornment, InputLabel, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import styles from './steps.mod.scss';

const muiStyles = makeStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(66, 179, 255, 0.5)',
      },
      color: '#000',
    },
    fontWeight: 500,
  },
  error: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(244, 92, 58, 0.5)',
      },
      '&:hover fieldset': {
        borderColor: 'rgba(244, 92, 58, 0.5)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(244, 92, 58, 0.5)',
      },
    },
    color: '#F45C3A',
  },
  emailSpan: {
    fontFamily: 'DM Sans',
    fontWeight: 500,
    fontSize: '1rem',
    color: theme.palette.common.black,
  },
}));

export const ConfirmEmailStep = () => {
  const { search } = useLocation();
  const queryEmail = new URLSearchParams(search).get('email');
  const queryCode = new URLSearchParams(search).get('code');
  const [fields, setFields] = useState({
    email: queryEmail || '',
    code: queryCode || '',
  });
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const mui = muiStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const handleChangeField = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  const handleContinueStep = async (event) => {
    event.preventDefault();
    if (!fields.code) {
      setError(intl.formatMessage({ id: 'creabots.validations.enterCode' }));
      return;
    }
    try {
      setIsLoading(true);
      await verifyProspectCode({
        email: fields.email.trim(),
        verificationCode: +fields.code.trim(),
      });
      setError('');
      history.push(`/kits/registrarse/contraseña?email=${fields.email}`);
    } catch (err) {
      if (err?.response?.status === 400) {
        setError(intl.formatMessage({ id: 'common.errors.somethingWentWrong' }));
      } else {
        setError(
          err?.response?.data.message
            || intl.formatMessage({ id: 'common.errors.somethingWentWrong' }),
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendVerificationCode = async () => {
    try {
      setIsLoading(true);
      await resendProspectCode({ email: fields.email });
      dispatch(showToastAction.showToast({
        title: '',
        message: intl.formatMessage({ id: 'creabots.auth.resendCodeSuccess' }),
        type: 'success',
        options: {
          autoClose: true,
        },
      }));
    } catch {
      setError(
        intl.formatMessage({ id: 'creabots.auth.resendCodeFailure' }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <main className={styles.card}>
      <div className={styles.cardHeader}>
        <h1 className={styles.title}>{intl.formatMessage({ id: 'creabots.auth.sendCodeTitle' })}</h1>
        <p className={styles.description}>
          {intl.formatMessage({ id: 'creabots.auth.sendCodeDescription' })}{' '}
          <Typography className={mui.emailSpan}>{fields.email}</Typography>
        </p>
      </div>

      <form className={styles.cardContent} onSubmit={handleContinueStep}>
        <Grid container style={{ gap: '3em', marginTop: '6.5em' }}>
          <Grid item xs={12} className={clsx('form-group bmd-form-group', styles.inputControl)}>
            <InputLabel
              shrink={false}
              htmlFor="code"
              className={clsx('bmd-label-static', styles.inputLabel)}
            >
              {intl.formatMessage({ id: 'creabots.auth.activationCode' })}
            </InputLabel>
            <TextField
              autoComplete="off"
              variant="outlined"
              type="text"
              name="code"
              id="code"
              value={fields.code}
              className={clsx(mui.root, error && mui.error)}
              onChange={(e) => {
                const value = e.target.value;
                if (value === '') {
                  setError('');
                  handleChangeField(e);
                  return;
                }
                if (value.match(/^[0-9]+$/) !== null) {
                  setError('');
                  if (value.length <= 6) {
                    handleChangeField(e);
                  } else {
                    e.target.value = value.slice(0, 6);
                    handleChangeField(e);
                  }
                } else {
                  e.target.value = value.slice(0, 6);
                  handleChangeField(e);
                  setError(intl.formatMessage({ id: 'creabots.validations.codeMustBeNumeric' }));
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <LockOutlined
                      className={clsx(styles.inputIcon, error && styles.error)}
                    />
                  </InputAdornment>
                ),
              }}
            />
            {error && <Typography className={styles.errorMessage}>{error}</Typography>}
          </Grid>
        </Grid>
        <button
          type="submit"
          className={styles.btnPrimary}
          style={{ marginTop: '2em' }}
          disabled={error || isLoading}
        >
          {intl.formatMessage({ id: 'common.continue' })}
        </button>
        <button
          type="button"
          className={styles.btnText}
          onClick={() => history.push('/kits/registrarse')}
        >
          {intl.formatMessage({ id: 'creabots.auth.modifyEmail' })}
        </button>
      </form>

      <div className={styles.cardFooter} style={{ marginTop: '-2em' }}>
        <h4 className={styles.codeNotFinded}>
          {intl.formatMessage({ id: 'creabots.auth.dontFindCode' })}
        </h4>
        <button
          type="button"
          className={styles.btnText}
          onClick={handleResendVerificationCode}
          disabled={isLoading}
        >
          {intl.formatMessage({ id: 'creabots.auth.resendCode' })}
        </button>
      </div>
    </main>
  );
};
