/* eslint prefer-rest-params:0 */
/* eslint no-useless-escape:0 */
Object.defineProperty(exports, '__esModule', {
  value: true,
});
function range(start, end) {
  let step = arguments.length <= 2 || arguments[2] === undefined ? 1 : arguments[2];

  // Test that the first 3 arguments are finite numbers.
  // Using Array.prototype.every() and Number.isFinite().
  const allNumbers = [start, end, step].every(Number.isFinite);

  // Throw an error if any of the first 3 arguments is not a finite number.
  if (!allNumbers) {
    throw new TypeError('range() expects only finite numbers as arguments.');
  }

  // Ensure the step is always a positive number.
  if (step <= 0) {
    throw new Error('step must be a number greater than 0.');
  }

  // When the start number is greater than the end number,
  // modify the step for decrementing instead of incrementing.
  if (start > end) {
    step = -step; // eslint-disable-line
  }

  // Determine the length of the array to be returned.
  // The length is incremented by 1 after Math.floor().
  // This ensures that the end number is listed if it falls within the range.
  const length = Math.floor(Math.abs((end - start) / step)) + 1;

  // Fill up a new array with the range numbers
  // using Array.from() with a mapping function.
  // Finally, return the new array.
  return Array.from(Array(length), (x, index) => {
    return start + index * step;
  });
}

function createElementFromHTML(htmlString) {
  const div = document.createElement('div');
  div.innerHTML = htmlString.trim();

  // Change this to div.childNodes to support multiple top-level nodes
  return div.firstChild;
}

function detectBrowserAndOS() {
  function isMobile() {
    return (/Mobi/.test(window.navigator.userAgent)
    );
  }

  function getBrowserName() {
    let browserName = null;

    if ((window.opr && window.opr.addons) || window.opera || window.navigator.userAgent.indexOf(' OPR/') >= 0) {
      browserName = 'Opera';
    } else if (typeof InstallTrigger !== 'undefined') {
      browserName = 'Firefox';
    } else if (/constructor/i.test(window.HTMLElement) || (function (p) {
      return p.toString() === '[object SafariRemoteNotification]';
    }(!window.safari))) {
      browserName = 'Safari';
    } else if (document.documentMode) {
      browserName = 'Internet Explorer';
    } else if (!document.documentMode && window.StyleMedia) {
      browserName = 'Edge';
    } else if (window.chrome) {
      browserName = 'Chrome';
    }

    return browserName;
  }

  function getOSArchitecture() {
    let ARC = null;
    if (/Windows/.test(window.navigator.userAgent)) {
      if (/WOW64/.test(window.navigator.userAgent) || /Win64/.test(window.navigator.userAgent)) {
        ARC = 'amd64';
      } else {
        ARC = 'x86';
      }
    }
    return ARC;
  }

  function getOSName() {
    let OS = null;
    if (isMobile()) {
      if (/Android/.test(window.navigator.userAgent)) {
        let version = null;
        if (/Android/.test(window.navigator.appVersion)) {
          version = window.navigator.appVersion.match(/Android (\d+).(\d+)/);
        }
        OS = (version && version.length > 0) ? version[0] : 'Android';
      } else if (/iPhone;/.test(window.navigator.userAgent)) {
        OS = 'iOS';
      }
    } else if (/Windows/.test(window.navigator.userAgent)) {
      OS = 'Windows';
      if (/5.1;/.test(window.navigator.userAgent)) {
        OS += ' XP';
      } else if (/6.0;/.test(window.navigator.userAgent)) {
        OS += ' Vista';
      } else if (/6.1;/.test(window.navigator.userAgent)) {
        OS += ' 7';
      } else if (/6.2/.test(window.navigator.userAgent)) {
        OS += ' 8';
      } else if (/6.3/.test(window.navigator.userAgent)) {
        OS += ' 8.1';
      } else if (/10.0;/.test(navigator.userAgent)) {
        OS += ' 10';
      }
    } else if (/Macintosh/.test(window.navigator.userAgent)) {
      OS = 'Macintosh';
      if (/OS X/.test(window.navigator.userAgent)) {
        OS += ' OS X';
      }
    } else if (/X11/.test(window.navigator.userAgent) || /Linux/.test(window.navigator.userAgent)) {
      OS = 'Linux';
    }
    return OS;
  }

  function getOSVersion() {
    let osVersion = null;
    if (/Macintosh/.test(window.navigator.userAgent)) {
      const test = window.navigator.userAgent.match(/mac os x (.*)[\_\.](.*)/i);
      osVersion = test[1].replace('.', '_');
    }
    return osVersion;
  }

  return {
    os: getOSName(),
    version: getOSVersion(),
    arc: getOSArchitecture(),
    browser: getBrowserName(),
    device: isMobile() ? 'Mobile' : 'Desktop',
  };
}

function getHtmlByTutorialCodename(tutorialCodename) {
  let htmlContent = null;

  if (tutorialCodename) htmlContent = `http://localhost:9002/html/blockHelp/${tutorialCodename}.html`;

  return htmlContent;
}

function checkIfHaveInternet(callback) {
  const xhr = new XMLHttpRequest();
  const file = 'https://www.kirupa.com/blank.png';
  const randomNum = Math.round(Math.random() * 10000);

  xhr.open('HEAD', `${file}?rand=${randomNum}`, true);
  xhr.send();

  function processRequest() {
    if (xhr.readyState === 4) {
      callback(xhr.status >= 200 && xhr.status < 304);
    }
  }
  xhr.addEventListener('readystatechange', processRequest, false);
}

exports.range = range;
exports.createElementFromHTML = createElementFromHTML;
exports.detectBrowserAndOS = detectBrowserAndOS;
exports.getHtmlByTutorialCodename = getHtmlByTutorialCodename;
exports.checkIfHaveInternet = checkIfHaveInternet;
exports.default = {};
