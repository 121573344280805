import React, { Component } from 'react';
import Slider from '@mui/material/Slider';
import './light.scss';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';

export class LightElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      intensity: 0,
      sliderOpen: true,
      rotation: props.rotation || 0,
      id: props.id || '',
    }

    this.render = this.render.bind(this);
    this.openSlider = this.openSlider.bind(this);
    this.handleIntensityChange = this.handleIntensityChange.bind(this);
  }

  handleRotation = (e) => {
    const { rotation, id } = this.state;
    const newRotation = ((rotation - 90) < -270) ? 0 : rotation - 90;
    this.setState({ rotation: newRotation });
    const rotationEvent = new CustomEvent('component-rotation', { detail: { rotation: newRotation, component: id } });
    document.dispatchEvent(rotationEvent);
  }

  openSlider = (e) => {
    const { id, sliderOpen } = this.state;
    setTimeout(() => {
      if (!document.querySelector(`#${id}`).closest(".react-draggable").classList.contains("react-draggable-dragging")) {
        this.setState({ sliderOpen: !sliderOpen });
      }
    }, 100);
  }

  handleIntensityChange = (event, newValue) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ intensity: newValue });
    // console.log('================', newValue);
  }

  render = () => {
    const { id, sliderOpen, intensity, rotation } = this.state;

    return (
      <div className="light" id={id}>
        <div className={`slider${sliderOpen ? ' open' : ''}${(rotation === -90 || rotation === -270) ? ' rotate_90' : ''}`}>
          <div className="intensity">
            <div className="icon"><img src={`${process.env.IS_ELECTRON ? '.' : ''}/images/simulator/components/light.svg`} height="20" /></div>
            <Slider
              min={0}
              max={1023}
              step={10}
              marks
              defaultValue={0}
              className="bar"
              getAriaValueText={(v) => `${v}`}
              valueLabelDisplay="auto"
              onChange={this.handleIntensityChange}
              aria-labelledby="input-slider"
            />
            <div className="value">{intensity}</div>
          </div>
        </div>

        <div className={`rotation${sliderOpen ? ' open' : ''}${(rotation === -90 || rotation === -270) ? ' rotate_90' : ''}`} onClick={this.handleRotation}><ScreenRotationIcon fontSize="inherit" htmlColor="white" /></div>

        <svg
          id="Capa_2"
          data-name="Capa 2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 115.13 57.01"
          width="15mm"
          height="8mm"
          transform={`translate(0 0) rotate(${rotation})`}
        >
          <g id="Capa_1-2" data-name="Capa 1" onMouseDown={this.openSlider}>
            <g>
              <g id="LINE">
                <path class="cls-2" d="m53.71,55.44c0,.8-.65,1.45-1.45,1.45H5.81c-2.35,0-4.25-1.9-4.25-4.25V4.43c0-2.35,1.91-4.25,4.25-4.25h46.45c.8,0,1.45.64,1.45,1.45v2.78c0,2.5,2.03,4.53,4.54,4.54s4.53-2.03,4.54-4.53h0V1.6c0-.78.64-1.41,1.42-1.42h46.49c2.35,0,4.25,1.91,4.25,4.25v48.2c0,2.35-1.9,4.25-4.25,4.25h-46.49c-.78,0-1.42-.64-1.42-1.42v-2.81c0-2.51-2.03-4.54-4.54-4.54-2.5,0-4.53,2.03-4.54,4.54v2.78" />
              </g>
              <g id="SPLINE">
                <path class="cls-7" d="m30.71,4.71v-.57h-1.13s-2.27,0-2.27,0h-1.13v.57c.31,0,.57.25.57.57s-.25.57-.57.57v.57h1.13s2.27,0,2.27,0h1.13v-.57c-.07,0-.15-.02-.22-.04s-.13-.07-.18-.12-.1-.12-.12-.18-.03-.14-.04-.22c0-.07.02-.15.04-.22s.07-.13.12-.18.12-.1.18-.12.14-.03.22-.04Z" />
              </g>
              <rect class="cls-10" x="5.47" y="7.36" width="34.58" height="42.52" transform="translate(-5.86 51.38) rotate(-90)" />
              <rect class="cls-11" x="27.28" y="3.97" width="2.26" height="2.61" transform="translate(23.14 33.69) rotate(-90)" />
              <g id="LINE-2" data-name="LINE">
                <line class="cls-3" x1="96.63" y1="22.33" x2="96.5" y2="22.21" />
              </g>
              <g id="LINE-3" data-name="LINE">
                <line class="cls-3" x1="96.9" y1="22.62" x2="96.77" y2="22.45" />
              </g>
              <g id="LINE-4" data-name="LINE">
                <line class="cls-3" x1="96.77" y1="22.45" x2="96.63" y2="22.33" />
              </g>
              <g id="LINE-5" data-name="LINE">
                <line class="cls-3" x1="87.97" y1="34.6" x2="87.71" y2="34.37" />
              </g>
              <g id="LINE-6" data-name="LINE">
                <line class="cls-3" x1="88.11" y1="34.72" x2="87.97" y2="34.6" />
              </g>
              <g id="ARC">
                <path class="cls-1" d="m93.3,35.85c2.82,0,5.1-2.28,5.1-5.1v-4.56c0-1.42-.59-2.77-1.63-3.74l-.27-.23c-.91-.73-2.04-1.13-3.2-1.13h-1.98c-2.82,0-5.1,2.28-5.1,5.1v4.56c0,1.36.54,2.66,1.5,3.62l.4.36c.91.73,2.04,1.13,3.2,1.13h1.98Z" />
              </g>
              <path class="cls-14" d="m96.5,22.21v8.91c0,.23-.19.42-.42.42h-.23c-.23,0-.42-.19-.42-.42v-5.32c0-.47-.38-.84-.84-.84h-.16c-.47,0-.84.38-.84.84v5.32c0,.23-.19.42-.42.42h-.21c-.23,0-.42-.19-.42-.42v-5.32c0-.47-.38-.84-.84-.84h-.16c-.47,0-.84.38-.84.84v5.32c0,.23-.19.42-.42.42h-.21c-.23,0-.42-.19-.42-.42v-5.32c0-.47-.38-.84-.84-.84h-.16c-.47,0-.84.38-.84.84v8.45s.39.35.39.35v-8.83c0-.23.19-.42.42-.42h.21c.23,0,.42.19.42.42v5.32c0,.47.38.84.84.84h.16c.47,0,.84-.38.84-.84v-5.32c0-.23.19-.42.42-.42h.21c.23,0,.42.19.42.42v5.32c0,.47.38.84.84.84h.16c.47,0,.84-.38.84-.84v-5.32c0-.23.19-.42.42-.42h.21c.23,0,.42.19.42.42v5.32c0,.47.38.84.84.84h.17c.47,0,.84-.38.84-.84v-8.48" />
              <g id="ARC-2" data-name="ARC">
                <path class="cls-3" d="m97.01,22.68c-.16-.16-.33-.32-.5-.47" />
              </g>
              <rect class="cls-15" x="82.03" y="31.03" width="4.58" height="2.24" transform="translate(52.16 116.46) rotate(-90)" />
              <rect class="cls-12" x="83.04" y="31.01" width="2.55" height="2.24" transform="translate(52.18 116.45) rotate(-90)" />
              <rect class="cls-15" x="82.03" y="23.36" width="4.58" height="2.24" transform="translate(59.83 108.79) rotate(-90)" />
              <rect class="cls-12" x="83.04" y="23.34" width="2.55" height="2.24" transform="translate(59.85 108.77) rotate(-90)" />
              <g id="CIRCLE">
                <path class="cls-4" d="m58.39,34.2c-3.13,0-5.67-2.54-5.67-5.67s2.54-5.67,5.67-5.67,5.67,2.54,5.67,5.67-2.54,5.67-5.67,5.67h0Z" />
              </g>
              <text class="cls-8" transform="translate(99.06 10.18) rotate(-90) scale(.94 1)"><tspan x="0" y="0">44</tspan></text>
              <polygon class="cls-9" points="55 .02 55 5.3 55 5.64 55.14 6.3 55.4 6.93 55.79 7.49 56.04 7.73 56.27 7.95 56.81 8.31 57.41 8.55 58.05 8.67 58.38 8.67 58.43 8.67 58.77 8.67 59.43 8.54 60.06 8.29 60.62 7.92 60.86 7.69 61.09 7.46 61.46 6.92 61.72 6.32 61.85 5.68 61.85 5.36 61.85 .02 68.36 .02 68.36 16.16 64.06 20.34 52.73 20.34 48.43 16.16 48.43 .02 55 .02" />
              <polygon class="cls-9" points="52.73 36.69 64.06 36.69 68.36 40.87 68.36 57.01 61.78 57.01 61.78 51.72 61.78 51.39 61.65 50.72 61.38 50.1 61 49.54 60.75 49.3 60.52 49.07 59.98 48.72 59.37 48.48 58.73 48.35 58.41 48.35 58.35 48.35 58.02 48.35 57.35 48.48 56.73 48.74 56.17 49.1 55.93 49.34 55.69 49.57 55.32 50.11 55.07 50.71 54.94 51.34 54.94 51.67 54.94 57.01 48.43 57.01 48.43 40.87 52.73 36.69" />
              <line class="cls-5" x1="92.23" y1="52.49" x2="92.23" y2="51.39" />
              <line class="cls-6" x1="30.66" y1="55.96" x2="30.66" y2="55.06" />
              <line class="cls-6" x1="30.59" y1="55.96" x2="30.59" y2="55.06" />
              <line class="cls-6" x1="30.52" y1="55.96" x2="30.52" y2="55.06" />
              <line class="cls-6" x1="30.45" y1="55.96" x2="30.45" y2="55.06" />
              <line class="cls-6" x1="30.38" y1="55.96" x2="30.38" y2="55.06" />
              <line class="cls-6" x1="30.31" y1="55.96" x2="30.31" y2="55.06" />
              <line class="cls-6" x1="30.23" y1="55.96" x2="30.23" y2="55.06" />
              <line class="cls-6" x1="30.16" y1="55.96" x2="30.16" y2="55.06" />
              <line class="cls-6" x1="30.09" y1="55.96" x2="30.09" y2="55.06" />
              <line class="cls-6" x1="30.02" y1="55.96" x2="30.02" y2="55.06" />
              <line class="cls-6" x1="29.95" y1="55.96" x2="29.95" y2="55.06" />
              <line class="cls-6" x1="29.88" y1="55.96" x2="29.88" y2="55.06" />
              <line class="cls-6" x1="29.81" y1="55.96" x2="29.81" y2="55.06" />
              <line class="cls-6" x1="29.73" y1="55.96" x2="29.73" y2="55.06" />
              <line class="cls-6" x1="29.66" y1="55.96" x2="29.66" y2="55.06" />
              <line class="cls-6" x1="29.59" y1="55.96" x2="29.59" y2="55.06" />
              <line class="cls-6" x1="29.52" y1="55.96" x2="29.52" y2="55.06" />
              <line class="cls-6" x1="29.45" y1="55.96" x2="29.45" y2="55.06" />
              <line class="cls-6" x1="29.38" y1="55.96" x2="29.38" y2="55.06" />
              <line class="cls-6" x1="29.31" y1="55.96" x2="29.31" y2="55.06" />
              <line class="cls-6" x1="29.24" y1="55.96" x2="29.24" y2="55.06" />
              <line class="cls-6" x1="29.16" y1="55.96" x2="29.16" y2="55.06" />
              <line class="cls-6" x1="29.09" y1="55.96" x2="29.09" y2="55.06" />
              <line class="cls-6" x1="29.02" y1="55.96" x2="29.02" y2="55.06" />
              <line class="cls-6" x1="28.95" y1="55.96" x2="28.95" y2="55.06" />
              <line class="cls-6" x1="31.66" y1="55.12" x2="31.66" y2="54.22" />
              <line class="cls-6" x1="32.37" y1="55.12" x2="32.37" y2="54.22" />
              <line class="cls-6" x1="32.3" y1="55.12" x2="32.3" y2="54.22" />
              <line class="cls-6" x1="32.23" y1="55.12" x2="32.23" y2="54.22" />
              <line class="cls-6" x1="32.16" y1="55.12" x2="32.16" y2="54.22" />
              <line class="cls-6" x1="32.09" y1="55.12" x2="32.09" y2="54.22" />
              <line class="cls-6" x1="32.02" y1="55.12" x2="32.02" y2="54.22" />
              <line class="cls-6" x1="31.95" y1="55.12" x2="31.95" y2="54.22" />
              <line class="cls-6" x1="31.87" y1="55.12" x2="31.87" y2="54.22" />
              <line class="cls-6" x1="31.8" y1="55.12" x2="31.8" y2="54.22" />
              <line class="cls-6" x1="31.73" y1="55.12" x2="31.73" y2="54.22" />
              <line class="cls-6" x1="31.59" y1="55.12" x2="31.59" y2="54.22" />
              <line class="cls-6" x1="31.52" y1="55.96" x2="31.52" y2="54.22" />
              <line class="cls-6" x1="31.45" y1="55.96" x2="31.45" y2="55.06" />
              <line class="cls-6" x1="31.37" y1="55.96" x2="31.37" y2="55.06" />
              <line class="cls-6" x1="31.3" y1="55.96" x2="31.3" y2="55.06" />
              <line class="cls-6" x1="31.23" y1="55.96" x2="31.23" y2="55.06" />
              <line class="cls-6" x1="31.16" y1="55.96" x2="31.16" y2="55.06" />
              <line class="cls-6" x1="31.09" y1="55.96" x2="31.09" y2="55.06" />
              <line class="cls-6" x1="31.02" y1="55.96" x2="31.02" y2="55.06" />
              <line class="cls-6" x1="30.95" y1="55.96" x2="30.95" y2="55.06" />
              <line class="cls-6" x1="30.88" y1="55.96" x2="30.88" y2="55.06" />
              <line class="cls-6" x1="30.8" y1="55.96" x2="30.8" y2="55.06" />
              <line class="cls-6" x1="30.73" y1="55.96" x2="30.73" y2="55.06" />
              <line class="cls-5" x1="88.61" y1="47.84" x2="87.48" y2="47.84" />
              <line class="cls-6" x1="30.66" y1="49.92" x2="30.66" y2="49.02" />
              <line class="cls-6" x1="30.59" y1="49.92" x2="30.59" y2="49.02" />
              <line class="cls-6" x1="30.52" y1="49.92" x2="30.52" y2="49.02" />
              <line class="cls-6" x1="30.45" y1="49.92" x2="30.45" y2="49.02" />
              <line class="cls-6" x1="30.38" y1="49.92" x2="30.38" y2="49.02" />
              <line class="cls-6" x1="30.31" y1="49.92" x2="30.31" y2="49.02" />
              <line class="cls-6" x1="30.23" y1="49.92" x2="30.23" y2="49.02" />
              <line class="cls-6" x1="30.16" y1="49.92" x2="30.16" y2="49.02" />
              <line class="cls-6" x1="30.09" y1="49.92" x2="30.09" y2="49.02" />
              <line class="cls-6" x1="30.02" y1="49.92" x2="30.02" y2="49.02" />
              <line class="cls-6" x1="29.95" y1="49.92" x2="29.95" y2="49.02" />
              <line class="cls-6" x1="29.88" y1="49.92" x2="29.88" y2="49.02" />
              <line class="cls-6" x1="29.81" y1="49.92" x2="29.81" y2="49.02" />
              <line class="cls-6" x1="29.73" y1="49.92" x2="29.73" y2="49.02" />
              <line class="cls-6" x1="29.66" y1="49.92" x2="29.66" y2="49.02" />
              <line class="cls-6" x1="29.59" y1="49.92" x2="29.59" y2="49.02" />
              <line class="cls-6" x1="29.52" y1="49.92" x2="29.52" y2="49.02" />
              <line class="cls-6" x1="29.45" y1="49.92" x2="29.45" y2="49.02" />
              <line class="cls-6" x1="29.38" y1="49.92" x2="29.38" y2="49.02" />
              <line class="cls-6" x1="29.31" y1="49.92" x2="29.31" y2="49.02" />
              <line class="cls-6" x1="29.24" y1="49.92" x2="29.24" y2="49.02" />
              <line class="cls-6" x1="29.16" y1="49.92" x2="29.16" y2="49.02" />
              <line class="cls-6" x1="29.09" y1="49.92" x2="29.09" y2="49.02" />
              <line class="cls-6" x1="29.02" y1="49.92" x2="29.02" y2="49.02" />
              <line class="cls-6" x1="28.95" y1="49.92" x2="28.95" y2="49.02" />
              <line class="cls-6" x1="31.66" y1="50.75" x2="31.66" y2="49.85" />
              <line class="cls-6" x1="32.37" y1="50.75" x2="32.37" y2="49.85" />
              <line class="cls-6" x1="32.3" y1="50.75" x2="32.3" y2="49.85" />
              <line class="cls-6" x1="32.23" y1="50.75" x2="32.23" y2="49.85" />
              <line class="cls-6" x1="32.16" y1="50.75" x2="32.16" y2="49.85" />
              <line class="cls-6" x1="32.09" y1="50.75" x2="32.09" y2="49.85" />
              <line class="cls-6" x1="32.02" y1="50.75" x2="32.02" y2="49.85" />
              <line class="cls-6" x1="31.95" y1="50.75" x2="31.95" y2="49.85" />
              <line class="cls-6" x1="31.87" y1="50.75" x2="31.87" y2="49.85" />
              <line class="cls-6" x1="31.8" y1="50.75" x2="31.8" y2="49.85" />
              <line class="cls-6" x1="31.73" y1="50.75" x2="31.73" y2="49.85" />
              <line class="cls-6" x1="31.45" y1="49.92" x2="31.45" y2="49.02" />
              <line class="cls-6" x1="31.37" y1="49.92" x2="31.37" y2="49.02" />
              <line class="cls-6" x1="31.3" y1="49.92" x2="31.3" y2="49.02" />
              <line class="cls-6" x1="31.23" y1="49.92" x2="31.23" y2="49.02" />
              <line class="cls-6" x1="31.16" y1="49.92" x2="31.16" y2="49.02" />
              <line class="cls-6" x1="31.09" y1="49.92" x2="31.09" y2="49.02" />
              <line class="cls-6" x1="31.02" y1="49.92" x2="31.02" y2="49.02" />
              <line class="cls-6" x1="30.95" y1="49.92" x2="30.95" y2="49.02" />
              <line class="cls-6" x1="30.88" y1="49.92" x2="30.88" y2="49.02" />
              <line class="cls-6" x1="30.8" y1="49.92" x2="30.8" y2="49.02" />
              <line class="cls-6" x1="31.59" y1="50.75" x2="31.59" y2="49.85" />
              <line class="cls-6" x1="31.52" y1="50.75" x2="31.52" y2="49.02" />
              <line class="cls-6" x1="30.73" y1="49.92" x2="30.73" y2="49.02" />
              <line class="cls-5" x1="88.66" y1="39.92" x2="87.54" y2="39.92" />
              <line class="cls-5" x1="96.96" y1="47.84" x2="95.84" y2="47.84" />
              <line class="cls-5" x1="90.7" y1="40.49" x2="90.11" y2="39.91" />
              <line class="cls-5" x1="95.29" y1="39.91" x2="90.11" y2="39.91" />
              <line class="cls-5" x1="97.13" y1="40.46" x2="97.13" y2="39.37" />
              <line class="cls-5" x1="95.83" y1="44.42" x2="95.03" y2="45.19" />
              <line class="cls-5" x1="92.23" y1="44.27" x2="92.23" y2="43.17" />
              <line class="cls-5" x1="95.79" y1="51.18" x2="95" y2="50.41" />
              <line class="cls-5" x1="89.5" y1="45.1" x2="88.71" y2="44.33" />
              <line class="cls-5" x1="89.43" y1="50.39" x2="88.64" y2="51.16" />
              <line class="cls-5" x1="88.1" y1="40.46" x2="88.1" y2="39.37" />
              <line class="cls-6" x1="33.3" y1="54.22" x2="33.3" y2="50.75" />
              <line class="cls-6" x1="33.23" y1="54.22" x2="33.23" y2="50.75" />
              <line class="cls-6" x1="33.16" y1="54.22" x2="33.16" y2="50.75" />
              <line class="cls-6" x1="33.09" y1="54.22" x2="33.09" y2="50.75" />
              <line class="cls-6" x1="33.02" y1="54.22" x2="33.02" y2="50.75" />
              <line class="cls-6" x1="32.94" y1="54.22" x2="32.94" y2="50.75" />
              <line class="cls-6" x1="32.87" y1="54.22" x2="32.87" y2="50.75" />
              <line class="cls-6" x1="32.8" y1="54.22" x2="32.8" y2="50.75" />
              <line class="cls-6" x1="32.66" y1="54.22" x2="32.66" y2="50.75" />
              <line class="cls-6" x1="32.59" y1="54.22" x2="32.59" y2="50.75" />
              <line class="cls-6" x1="32.52" y1="54.22" x2="32.52" y2="50.75" />
              <line class="cls-6" x1="32.73" y1="54.22" x2="32.73" y2="50.75" />
              <line class="cls-6" x1="24.96" y1="53.39" x2="24.96" y2="51.59" />
              <line class="cls-6" x1="24.88" y1="53.39" x2="24.88" y2="51.59" />
              <line class="cls-6" x1="24.81" y1="53.39" x2="24.81" y2="51.59" />
              <line class="cls-6" x1="24.74" y1="53.39" x2="24.74" y2="51.59" />
              <line class="cls-6" x1="24.67" y1="53.39" x2="24.67" y2="51.59" />
              <line class="cls-6" x1="24.6" y1="53.39" x2="24.6" y2="51.59" />
              <line class="cls-6" x1="24.53" y1="53.39" x2="24.53" y2="51.59" />
              <line class="cls-6" x1="24.46" y1="53.39" x2="24.46" y2="51.59" />
              <line class="cls-6" x1="24.38" y1="53.39" x2="24.38" y2="51.59" />
              <line class="cls-6" x1="24.31" y1="53.39" x2="24.31" y2="51.59" />
              <line class="cls-6" x1="24.24" y1="53.39" x2="24.24" y2="51.59" />
              <line class="cls-6" x1="24.17" y1="53.39" x2="24.17" y2="51.59" />
              <line class="cls-6" x1="24.1" y1="53.39" x2="24.1" y2="51.59" />
              <line class="cls-6" x1="24.03" y1="53.39" x2="24.03" y2="51.59" />
              <line class="cls-6" x1="23.96" y1="53.39" x2="23.96" y2="51.59" />
              <line class="cls-6" x1="23.89" y1="53.39" x2="23.89" y2="51.59" />
              <line class="cls-6" x1="23.81" y1="53.39" x2="23.81" y2="51.59" />
              <line class="cls-6" x1="23.74" y1="53.39" x2="23.74" y2="51.59" />
              <line class="cls-6" x1="23.67" y1="53.39" x2="23.67" y2="51.59" />
              <line class="cls-6" x1="23.6" y1="53.39" x2="23.6" y2="51.59" />
              <line class="cls-6" x1="23.53" y1="53.39" x2="23.53" y2="51.59" />
              <line class="cls-6" x1="25.95" y1="54.22" x2="25.95" y2="50.75" />
              <line class="cls-6" x1="26.1" y1="54.22" x2="26.1" y2="50.75" />
              <line class="cls-6" x1="26.03" y1="54.22" x2="26.03" y2="50.75" />
              <line class="cls-6" x1="25.88" y1="54.22" x2="25.88" y2="50.75" />
              <line class="cls-6" x1="25.81" y1="54.22" x2="25.81" y2="50.75" />
              <line class="cls-6" x1="25.74" y1="54.22" x2="25.74" y2="50.75" />
              <line class="cls-6" x1="25.67" y1="54.22" x2="25.67" y2="50.75" />
              <line class="cls-6" x1="25.6" y1="54.22" x2="25.6" y2="50.75" />
              <line class="cls-6" x1="25.53" y1="54.22" x2="25.53" y2="50.75" />
              <line class="cls-6" x1="25.45" y1="54.22" x2="25.45" y2="50.75" />
              <line class="cls-6" x1="25.38" y1="54.22" x2="25.38" y2="50.75" />
              <line class="cls-6" x1="25.31" y1="54.22" x2="25.31" y2="50.75" />
              <line class="cls-6" x1="25.24" y1="53.39" x2="25.24" y2="51.59" />
              <line class="cls-6" x1="25.17" y1="53.39" x2="25.17" y2="51.59" />
              <line class="cls-6" x1="25.1" y1="53.39" x2="25.1" y2="51.59" />
              <line class="cls-6" x1="25.03" y1="53.39" x2="25.03" y2="51.59" />
              <line class="cls-5" x1="90.7" y1="39.33" x2="90.11" y2="39.91" />
              <line class="cls-6" x1="28.88" y1="55.96" x2="28.88" y2="49.02" />
              <line class="cls-6" x1="28.81" y1="55.96" x2="28.81" y2="49.02" />
              <line class="cls-6" x1="28.74" y1="55.96" x2="28.74" y2="49.02" />
              <line class="cls-6" x1="28.66" y1="55.96" x2="28.66" y2="49.02" />
              <line class="cls-6" x1="28.59" y1="55.96" x2="28.59" y2="49.02" />
              <line class="cls-6" x1="28.52" y1="55.96" x2="28.52" y2="49.02" />
              <line class="cls-6" x1="28.45" y1="55.96" x2="28.45" y2="49.02" />
              <line class="cls-6" x1="28.38" y1="55.96" x2="28.38" y2="49.02" />
              <line class="cls-6" x1="28.31" y1="55.96" x2="28.31" y2="49.02" />
              <line class="cls-6" x1="28.17" y1="55.96" x2="28.17" y2="49.02" />
              <line class="cls-6" x1="28.09" y1="55.96" x2="28.09" y2="49.02" />
              <line class="cls-6" x1="28.02" y1="55.96" x2="28.02" y2="49.02" />
              <line class="cls-6" x1="28.24" y1="55.96" x2="28.24" y2="49.02" />
              <line class="cls-6" x1="32.44" y1="55.12" x2="32.44" y2="49.85" />
              <line class="cls-6" x1="27.88" y1="55.12" x2="27.88" y2="49.85" />
              <line class="cls-6" x1="27.81" y1="55.12" x2="27.81" y2="49.85" />
              <line class="cls-6" x1="27.74" y1="55.12" x2="27.74" y2="49.85" />
              <line class="cls-6" x1="27.67" y1="55.12" x2="27.67" y2="49.85" />
              <line class="cls-6" x1="27.59" y1="55.12" x2="27.59" y2="49.85" />
              <line class="cls-6" x1="27.52" y1="55.12" x2="27.52" y2="49.85" />
              <line class="cls-6" x1="27.45" y1="55.12" x2="27.45" y2="49.85" />
              <line class="cls-6" x1="27.38" y1="55.12" x2="27.38" y2="49.85" />
              <line class="cls-6" x1="27.31" y1="55.12" x2="27.31" y2="49.85" />
              <line class="cls-6" x1="27.24" y1="55.12" x2="27.24" y2="49.85" />
              <line class="cls-6" x1="27.17" y1="55.12" x2="27.17" y2="49.85" />
              <line class="cls-6" x1="27.1" y1="55.12" x2="27.1" y2="49.85" />
              <line class="cls-6" x1="26.95" y1="55.12" x2="26.95" y2="49.85" />
              <line class="cls-6" x1="26.88" y1="55.12" x2="26.88" y2="49.85" />
              <line class="cls-6" x1="26.81" y1="55.12" x2="26.81" y2="49.85" />
              <line class="cls-6" x1="26.74" y1="55.12" x2="26.74" y2="49.85" />
              <line class="cls-6" x1="26.67" y1="55.12" x2="26.67" y2="49.85" />
              <line class="cls-6" x1="26.6" y1="55.12" x2="26.6" y2="49.85" />
              <line class="cls-6" x1="26.52" y1="55.12" x2="26.52" y2="49.85" />
              <line class="cls-6" x1="26.45" y1="55.12" x2="26.45" y2="49.85" />
              <line class="cls-6" x1="26.38" y1="55.12" x2="26.38" y2="49.85" />
              <line class="cls-6" x1="26.31" y1="55.12" x2="26.31" y2="49.85" />
              <line class="cls-6" x1="26.24" y1="55.12" x2="26.24" y2="49.85" />
              <line class="cls-6" x1="26.17" y1="55.12" x2="26.17" y2="49.85" />
              <line class="cls-6" x1="27.02" y1="55.12" x2="27.02" y2="49.85" />
              <line class="cls-6" x1="27.95" y1="55.96" x2="27.95" y2="49.02" />
              <path class="cls-5" d="m92.23,45.65c-1.24,0-2.25.98-2.25,2.18s1.01,2.18,2.25,2.18,2.25-.98,2.25-2.18-1.01-2.18-2.25-2.18" />
              <path class="cls-9" d="m79.69,21.5h56.94v9.69c0,2.34-1.9,4.25-4.25,4.25h-48.44c-2.34,0-4.25-1.9-4.25-4.25v-9.69h0Z" transform="translate(79.69 136.62) rotate(-90)" />
              <g>
                <path class="cls-13" d="m105.41,33.78v-.58h4.25v-1.9h.52v2.48h-4.76Z" />
                <path class="cls-13" d="m105.41,30.32h2.82c1.07,0,1.52-.44,1.52-1.04,0-.66-.47-1.08-1.52-1.08h-2.82v-.58h2.78c1.46,0,2.06.72,2.06,1.69,0,.91-.56,1.6-2.04,1.6h-2.8v-.58Z" />
                <path class="cls-13" d="m109.81,26.92l-3.86-2.47h-.02v2.26h-.52v-3.01h.37l3.85,2.46h.02v-2.49h.52v3.25h-.36Z" />
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}
