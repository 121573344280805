/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, connect, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import * as showToastAction from '@modules/toasts/features/toastsSlice';
import { useSaveCreabotsProgress } from '@services/rest/creabots/hooks';
import * as routes from '@educabot/educablocks-cosmos';
import { v1 as uuidv1 } from 'uuid';
import Header from '@sections/creabots/components/header';
import Bottom from '@sections/creabots/components/bottom';
import Blockly from '@modules/blockly/containers/blockly';
import BlocklyBuild from '@modules/blockly/components/blocklyBuild';
import { build } from '@modules/blockly/features/blocklySlice';
import { useCheckLogged } from '@utils/hooks/auth';
import { Kits } from '@sections/creabots/features/kits';
import { boards as Boards, getBoardById } from '@educabot/educablocks-boards';
import ConnectionModal from '@sections/creabots/components/connectionModal';
import BuildProgressModal from '@sections/creabots/components/buildProgressModal';
import { useBlockly } from '@modules/blockly/features/hooks';
import { useCreateUpdateProject, useGetUserProject } from '@modules/projects/features/hooks';
import boardTypes from '@modules/boards/features/constants';
import { BOARD_ID_TYPE } from '../types/kits';
import { getUserAgent } from '../../../helpers/browserAgent';
import Agent from '../../../helpers/agent';
import { store } from '../../../state/store';
import styles from './project.mod.scss';
import { selectCreabots, setSelectedKit } from '../features/creabotsSlice';


const CreabotsUserProject = (props) => {
  const intl = useIntl();
  const location = useLocation();
  const dispatch = useDispatch();
  const { kits } = useSelector(selectCreabots)
  const [id, setId] = useState(0);
  const [board, setBoard] = useState({});
  const [workspace, setWorkspace] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [kitName, setKitName] = useState(null);
  const [kitId, setKitId] = useState(1);
  const [tabInfo, setTabInfo] = useState({});
  const [socket, setSocket] = useState(null);
  const [userAgent, setUserAgent] = useState({});
  const [blocklyId, setBlocklyId] = useState(uuidv1());
  const [intervalPortListing, setIntervalPortListing] = useState(null);
  const userProject = useGetUserProject(projectId || 0);
  const [projectName, setProjectName] = useState('creabots.untitled');
  const userDataState = useCheckLogged(false);
  const blocklyState = useBlockly();
  const blocklyRef = useRef();
  // console.log('=============userProject', userProject.selectedProject?.jsonBody?.code);
  const { createUpdateProject } = useCreateUpdateProject(userDataState?.data?.userId || 0, 3000);

  useEffect(() => {
    const boardId = Kits[kitName]?.boardId || BOARD_ID_TYPE.INITIATION;
    setBoard(getBoardById(Boards, boardId));
  }, [kitName]);

  useEffect(() => {
    if (tabInfo?.id) {
      startSocket(true);
    }
  }, [tabInfo?.id]);

  useEffect(() => {
    if (board?.id) {
      setTabInfo({
        tagsSelected: [],
        projectId: userProject.selectedProject?.id || 0,
        id: blocklyId,
        name: userProject.selectedProject?.title || intl.formatMessage({ id: projectName }),
        description: userProject.selectedProject?.description || '',
        userId: userProject.selectedProject?.userId || userDataState?.data?.userId || 0,
        board: userProject.selectedProject?.jsonBody?.board || board,
        port: userProject.selectedProject?.jsonBody?.port || '',
        agentPorts: [],
        boardName: userProject.selectedProject?.jsonBody?.board.name || board.name || '',
        className: userProject.selectedProject?.jsonBody?.board.class || board.class || '',
        portOpen: false,
        active: true,
        disabled: false,
        withToolTip: false,
        withWorkspace: userProject.selectedProject?.jsonBody?.withWorkspace || false,
        rightPanel: '',
        diagramImageUrl: userProject.selectedProject?.imageUrl || '',

        boardType: 'senior',
        code: userProject.selectedProject?.jsonBody?.code || '',
      });
    }
  }, [userProject.selectedProject?.jsonBody, board]);

  useEffect(() => {
    setProjectId(parseInt(location
      .pathname
      .split('/')
      .filter(Boolean)[2]
      .split('-')
      .filter(Boolean)[1], 10) || 0);

    setKitName(location
      .pathname
      .split('/')
      .filter(Boolean)[1] || null);

    return () => {
      setId(0);
      setBlocklyId(uuidv1());
      setSocket(null);
      setKitName(null);
      setProjectId(null);
    };
  }, []);

  useEffect(() => {
    if (socket && !intervalPortListing) {
      const intervalPortListing = setInterval(() => {
        socket.findPorts();
      }, 1000);
      setIntervalPortListing(intervalPortListing);
    }

    return () => {
      clearInterval(intervalPortListing);
      setIntervalPortListing(null);
    }
  }, [socket?.agentType]);

  useEffect(() => {
    const mergeDeep = (target, source) => {
      const isObject = (obj) => obj && typeof obj === 'object';

      if (!isObject(target) || !isObject(source)) {
        return source;
      }

      Object.keys(source).forEach((key) => {
        const targetValue = target[key];
        const sourceValue = source[key];

        if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
          target[key] = targetValue.concat(sourceValue);
        } else if (isObject(targetValue) && isObject(sourceValue)) {
          target[key] = mergeDeep({ ...targetValue }, sourceValue);
        } else {
          target[key] = sourceValue;
        }
      });

      return target;
    }

    if (blocklyRef.current?.withWorkspace !== blocklyState?.project?.withWorkspace) {
      const newProject = mergeDeep({
        id: tabInfo.projectId || 0,
        title: tabInfo.name,
        description: tabInfo.description,
        boardType: tabInfo.boardType,
        jsonBody: {
          board: tabInfo.board,
        },
        isCreabots: true,
      }, userProject.selectedProject);
      createUpdateProject(mergeDeep(newProject, {
        blocksCss: blocklyState.project.blocksCss,
        canvasCss: blocklyState.project.canvasCss,
        jsonBody: blocklyState.project,
      }));
    }
    blocklyRef.current = blocklyState?.project || {};
  }, [blocklyState?.project?.withWorkspace, blocklyState?.project?.code]);

  const startSocket = () => {
    const newSocket = (tabInfo.board.profile !== 'codit' && tabInfo.board.profile !== 'drone')
      ? new Agent(store, tabInfo.board)
      : null

    if (newSocket?.agentType) {
      getUserAgent().then((ua) => {
        setUserAgent(ua);
        newSocket.setPlatform(ua.platform, 4000);
        // Hack to force redraw in order to update socket.agentType property
        setTimeout(() => {
          setId(1);
        }, 4000);
      });
    }

    setSocket(newSocket);
  }

  const setAgent = (type = 'serial') => {
    clearInterval(intervalPortListing);
    setIntervalPortListing(null);
    socket.forceAgent(type);
  }

  const buildBlockly = (params) => {
    dispatch(build(params));
  }

  const changeBuildPort = (name) => {
    // setTabInfo({ ...tabInfo, port: name });
  }

  const handleGoHome = () => {
    if (location.pathname.includes('proyecto')) {
      dispatch(setSelectedKit({
        id: 0,
        name: 'Mis Proyectos',
        projects: [],
      }))
      window.location.href = `${routes.bloquesUri}/kits/mis-proyectos`
      return
    }
    // history.push(`/kits/creabots/${kits.selected.urlName}`)
    window.location.href = `${routes.bloquesUri}/kits/creabots/${kits.selected.urlName}`
  }

  const scrollWorkspaceByX = (ws, x = 0, y = 0) => {
    const metrics = ws.getMetrics();
    if (metrics && ws.scrollbar) {
      let a = x + 20;
      let b = y + 10;
      a = Math.min(a, -metrics.contentLeft);
      b = Math.min(b, -metrics.contentTop);
      a = Math.max(a, metrics.viewWidth - metrics.contentLeft - metrics.contentWidth);
      b = Math.max(b, metrics.viewHeight - metrics.contentTop - metrics.contentHeight);
      ws.scrollbar.set(-a - metrics.contentLeft, -b - metrics.contentTop)
    }
  }

  const updateWorkspace = (ws) => {
    if (ws) {
      if (!workspace) {
        scrollWorkspaceByX(ws, 10, 0);
      }
      setWorkspace(ws);
    }
  }

  return (
    <div className={styles.project}>
      <Header
        titleString={userProject.selectedProject?.title || intl.formatMessage({ id: projectName })}
        handleGoHome={handleGoHome}
      />
      <div className={styles.courses}>
        {tabInfo.id ? (
          <>
            <Blockly
              tabInfo={tabInfo}
              socketAgent={socket}
              buildAllowed={false}
              portChangeAllowed={true}
              noCodePanel={true}
              setWorkspace={updateWorkspace}
            />
            {socket ? (
              <Bottom
                buildingFunction={buildBlockly}
                board={tabInfo.board}
                socketAgent={socket}
              />
            ) : ('')}
            <BlocklyBuild
              board={tabInfo.board}
              socketAgent={socket}
              id={tabInfo?.id || ''}
              compilerBoard={tabInfo?.board?.compilerBoard || ''}
              userAgent={userAgent}
              userData={userDataState?.data || {}}
              setAgent={setAgent}
              changeBuildPort={changeBuildPort}
              mainModal={ConnectionModal}
              progressModal={BuildProgressModal}
              buildingFunction={buildBlockly}
            />
          </>
        ) : ('')}
      </div>
    </div>
  );
};


const mapDispatchToProps = (dispatch) => {
  return {
    showToast: (params) => dispatch(showToastAction.showToast(params)),
  }
}

export default connect(null, mapDispatchToProps)(CreabotsUserProject);
