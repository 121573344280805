import React from 'react';
import { alpha, Box, Typography } from '@mui/material';
import * as routes from '@educabot/educablocks-cosmos';
import { useIntl } from 'react-intl';
import { Parts } from '../parts';
import { AutoAsistProjects } from '../autoAsistProjects';
import { ImageKit } from './imageKit';


export function AutomatizationTab() {
  const intl = useIntl()



  const electronicParts = [
    {
      name: intl.formatMessage({ id: 'creabots.landing.electronicParts.servoMotor' }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/automatization-kit/electronic_parts/servo_motor.png`,
    },
    {
      name: intl.formatMessage({ id: 'creabots.landing.electronicParts.humiditySensor' }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/automatization-kit/electronic_parts/sensor_temperatura.png`,
    },
    {
      name: intl.formatMessage({ id: 'creabots.landing.electronicParts.lightSensor' }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/automatization-kit/electronic_parts/sensor_luz.png`,
    },
    {
      name: intl.formatMessage({ id: 'creabots.landing.electronicParts.colorSensor' }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/automatization-kit/electronic_parts/sensor_color.png`,
    },
  ];

  const plasticParts = [
    {
      name: intl.formatMessage({ id: 'creabots.landing.plasticParts.servoSupport' }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/automatization-kit/plastic_parts/soporte_servo.png`,
    },
    {
      name: intl.formatMessage({ id: 'creabots.landing.plasticParts.axis' }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/automatization-kit/plastic_parts/eje.png`,
    },
    {
      name: intl.formatMessage({ id: 'creabots.landing.plasticParts.grades' }, { number: 90 }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/automatization-kit/plastic_parts/90_grados.png`,
    },

    {
      name: intl.formatMessage({ id: 'creabots.landing.plasticParts.crossInsert' }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/automatization-kit/plastic_parts/pasante.png`,
    },
    {
      name: intl.formatMessage({ id: 'creabots.landing.plasticParts.board' }, { number: '05' }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/automatization-kit/plastic_parts/placa_05.png`,
    },
    {
      name: intl.formatMessage({ id: 'creabots.landing.plasticParts.board' }, { number: '08' }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/automatization-kit/plastic_parts/placa_08.png`,
    },
    {
      name: intl.formatMessage({ id: 'creabots.landing.plasticParts.board' }, { number: '09' }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/automatization-kit/plastic_parts/placa_09.png`,
    },
    {
      name: intl.formatMessage({ id: 'creabots.landing.plasticParts.board' }, { number: 11 }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/automatization-kit/plastic_parts/placa_11.png`,
    },
    {
      name: intl.formatMessage({ id: 'creabots.landing.plasticParts.board' }, { number: 15 }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/automatization-kit/plastic_parts/placa_15.png`,
    },
    {
      name: intl.formatMessage({ id: 'creabots.landing.plasticParts.screwdriver' }),
      imageUrl: `${routes.bloquesUri}/images/creabots/landing/automatization-kit/plastic_parts/destornillador.png`,
    },
  ];

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: alpha(theme.palette.alternatives.a6, 0.1),
        padding: { xs: 1.5, md: 3 },
        minHeight: '100dvh',
      })}
    >
      <Box
        sx={{
          marginTop: { xs: '3rem', md: '6rem' },
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          width: '100%',
          maxWidth: '1080px',
          marginX: 'auto',
        }}
      >
        <Typography
          sx={(theme) => ({
            width: { xs: '100%', md: '90%' },
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.text.primary,
            fontSize: { xs: '2rem', md: '4rem' },
            letterSpacing: { xs: '-0.01rem', md: '-0.03rem' },
            lineHeight: { xs: '40px', md: '72px' },
          })}
        >
          {intl.formatMessage({ id: 'creabots.landing.kits.automatization.title' })}
        </Typography>
        <Typography
          sx={(theme) => ({
            width: { xs: '100%', md: '90%' },
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightRegular,
            color: theme.palette.text.secondary,
            fontSize: { xs: '1rem', md: '1.75rem' },
            letterSpacing: { xs: 0, md: '-0.01rem' },
            lineHeight: { xs: '24px', md: '36px' },
          })}
        >
          {intl.formatMessage({ id: 'creabots.landing.kits.automatization.description' })}

        </Typography>
      </Box>

      <ImageKit kit="automatization-kit" />

      <AutoAsistProjects kit="automatizacion" />
      <Parts kitName={intl.formatMessage({ id: 'creabots.landing.kits.automatization.name' })} plasticParts={plasticParts} electronicParts={electronicParts} />
    </Box>
  );
}
