import { BasicBlock, BLOCK_CATEGORY_TYPE, BlockConfig } from '@sections/creabots/types/kits'
import getBlocksInfoSchema from '../../../config/getBlocksInfoSchema'

const blocks: BasicBlock[] = [
  {
    id: 0,
    name: 'math_number',
  },
  {
    id: 1,
    name: 'math_arithmetic',
  },
  {
    id: 2,
    name: 'advanced_map',
  },
  {
    id: 3,
    name: 'math_random',
  },
]

export const mathConfig: BlockConfig = {
  blockCategory: 2,
  canvasDisplacement: { x: 415, y: 0 },
  blocks: getBlocksInfoSchema(blocks, BLOCK_CATEGORY_TYPE.MATH),
}