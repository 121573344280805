import React, { Component } from 'react';
import Slider from '@mui/material/Slider';
import './ultrasonicButy.scss';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';

export class UltrasonicButyElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      distance: 2,
      rotation: props.rotation || 0,
      id: props.id || '',
      sliderOpen: true,
    }

    this.openSlider = this.openSlider.bind(this);
    this.render = this.render.bind(this);
  }

  handleRotation = (e) => {
    const { rotation, id } = this.state;
    const newRotation = ((rotation - 90) < -270) ? 0 : rotation - 90;
    this.setState({ rotation: newRotation });
    const rotationEvent = new CustomEvent('component-rotation', { detail: { rotation: newRotation, component: id } });
    document.dispatchEvent(rotationEvent);
  }

  openSlider = (e) => {
    const { id, sliderOpen } = this.state;
    setTimeout(() => {
      if (!document.querySelector(`#${id}`).closest(".react-draggable").classList.contains("react-draggable-dragging")) {
        this.setState({ sliderOpen: !sliderOpen });
      }
    }, 100);
  }

  handleDistanceChange = (event, newValue) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ distance: newValue });
  }

  render = () => {
    const { id, sliderOpen, rotation, distance } = this.state;
    return (
      <div className="ultrasonicButy" id={id}>
        <div className={`slider${sliderOpen ? ' open' : ''}${(rotation === -90 || rotation === -270) ? ' rotate_90' : ''}`}>
          <div className="distance">
            <div className="icon"><img src={`${process.env.IS_ELECTRON ? '.' : ''}/images/simulator/components/ultrasonic_distance.svg`} height="20" /></div>
            <Slider
              min={2}
              max={200}
              step={1}
              marks
              defaultValue={2}
              className="bar"
              getAriaValueText={(v) => `${v}`}
              valueLabelDisplay="auto"
              onChange={this.handleDistanceChange}
              aria-labelledby="input-slider"
            />
            <div className="value">{distance}</div>
          </div>
        </div>

        <div className={`rotation${sliderOpen ? ' open' : ''}${(rotation === -90 || rotation === -270) ? ' rotate_90' : ''}`} onClick={this.handleRotation}><ScreenRotationIcon fontSize="inherit" htmlColor="white" /></div>
        <svg
          id="Capa_2"
          data-name="Capa 2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 159.05 217.58"
          width="22mm"
          height="28mm"
          transform={`translate(0 0) rotate(${rotation})`}
        >
          <g id="capa-ultrasonic" data-name="Capa 1" onMouseDown={this.openSlider}>
            <path class="cls-7" d="m158.92,155.43H.13v-76.55h158.8v76.55Zm-45.3-66.1c-.77,0-1.4.63-1.4,1.4s.63,1.4,1.4,1.4,1.4-.63,1.4-1.4-.63-1.4-1.4-1.4Zm7.3,0c-.77,0-1.4.63-1.4,1.4s.63,1.4,1.4,1.4,1.4-.63,1.4-1.4-.63-1.4-1.4-1.4Zm7.3,0c-.77,0-1.4.63-1.4,1.4s.63,1.4,1.4,1.4,1.4-.63,1.4-1.4-.63-1.4-1.4-1.4Zm7.23,0c-.77,0-1.4.63-1.4,1.4s.63,1.4,1.4,1.4,1.4-.63,1.4-1.4-.63-1.4-1.4-1.4Zm6.47,0c-.77,0-1.4.63-1.4,1.4s.63,1.4,1.4,1.4,1.4-.63,1.4-1.4-.63-1.4-1.4-1.4Z" />
            <rect class="cls-4" x="20.02" y="59.15" width="119" height="19.73" />
            <rect class="cls-3" x="19.98" y="39.15" width="119.04" height="20" />
            <rect class="cls-11" x="39.01" y="19.17" width="81.01" height="19.98" />
            <rect class="cls-9" x="58.99" width="41.24" height="19.33" />
            <g>
              <rect class="cls-2" x="69.39" y="94.38" width="19.84" height="17.01" />
              <line class="cls-2" x1="69.39" y1="102.88" x2="89.23" y2="102.88" />
              <line class="cls-2" x1="82.62" y1="94.38" x2="82.62" y2="111.39" />
              <line class="cls-2" x1="76" y1="94.38" x2="76" y2="111.39" />
            </g>
            <line class="cls-1" x1="20.02" y1="155.43" x2=".12" y2="155.43" />
            <line class="cls-1" x1="20.02" y1="175.23" x2="20.02" y2="155.43" />
            <line class="cls-1" x1="139.02" y1="175.23" x2="139.02" y2="155.43" />
            <line class="cls-1" x1="139.02" y1="155.43" x2="158.92" y2="155.43" />
            <rect class="cls-7" x="20.02" y="154.22" width="119" height="21.02" />
            <path class="cls-7" d="m119.22,217.58H39.82v-46.64h79.4v46.64Zm-67.25-17.14c-3.05-.47-5.63,2.12-5.16,5.16.29,1.89,1.84,3.44,3.74,3.73,3.04.46,5.62-2.11,5.15-5.15-.29-1.9-1.84-3.45-3.73-3.74Zm55.98-.06c-2.49,0-4.5,2.02-4.5,4.5s2.01,4.5,4.5,4.5,4.5-2.01,4.5-4.5-2.01-4.5-4.5-4.5Z" />
            <g>
              <path class="cls-12" d="m70.89,97.26h-1.04v-.37h2.5v.37h-1.04v2.81h-.41v-2.81Z" />
              <path class="cls-12" d="m75.28,100.07c-.08.02-.16.04-.26.04-.4,0-.57-.21-.74-.52l-.42-.79h-.63v1.28h-.41v-3.18h1.16c.59,0,1.03.33,1.03.96,0,.49-.27.79-.68.89l.39.76c.1.19.22.22.33.22.08,0,.14,0,.19-.02l.03.36Zm-1.3-1.61c.41,0,.62-.25.62-.6s-.21-.6-.62-.6h-.75v1.21h.75Z" />
            </g>
            <g>
              <path class="cls-12" d="m72.24,108.15v.36h-2.14v-3.18h2.11v.37h-1.71v.98h1.56v.37h-1.56v1.11h1.73Z" />
              <path class="cls-12" d="m74.99,107.39h.42c-.08.68-.51,1.15-1.35,1.15s-1.43-.6-1.43-1.62.61-1.62,1.43-1.62,1.28.47,1.35,1.16h-.42c-.06-.46-.33-.8-.93-.8s-1.02.48-1.02,1.26.44,1.26,1.02,1.26.87-.34.93-.79Z" />
            </g>
            <path class="cls-12" d="m80.69,96.9l-1.17,3.18h-.42l-1.17-3.18h.41l.97,2.67.97-2.67h.41Z" />
            <path class="cls-12" d="m87.2,98.42v1.66h-.32v-.55c-.19.34-.53.58-1.07.58-.85,0-1.44-.61-1.44-1.64,0-.95.6-1.6,1.49-1.6.82,0,1.26.47,1.32,1.07h-.42c-.05-.37-.31-.71-.9-.71-.65,0-1.08.5-1.08,1.24,0,.81.43,1.28,1.03,1.28.64,0,1-.45,1.04-.88v-.09h-1v-.35h1.34Z" />
            <g>
              <path class="cls-12" d="m99.99,171.73h8.19v-11.69h-8.19v11.69Zm5.85-7.02v4.68h-3.51v-7.02h3.51v2.34Z" />
              <polygon class="cls-12" points="109.93 160.03 109.93 162.37 112.27 162.37 112.27 171.73 114.61 171.73 114.61 162.37 116.95 162.37 116.95 160.03 109.93 160.03" />
              <polygon class="cls-12" points="73.09 162.37 78.36 162.37 78.36 160.03 70.76 160.03 70.76 171.73 78.36 171.73 78.36 169.39 73.09 169.39 73.09 162.37" />
              <polygon class="cls-12" points="66.66 160.03 66.66 169.39 63.15 169.39 63.15 160.03 60.82 160.03 60.82 171.73 69 171.73 69 160.03 66.66 160.03" />
              <path class="cls-12" d="m80.11,160.03v11.69h2.34v-4.68h3.51v4.68h2.34v-11.69h-8.19Zm5.85,4.68h-3.51v-2.34h3.51v2.34Z" />
              <path class="cls-12" d="m98.24,164.71v-4.68h-8.19v11.69h8.19v-4.68l-1.17-1.17,1.17-1.17Zm-2.34,4.68h-3.51v-2.34h3.51v2.34Zm0-4.68h-3.51v-2.34h3.51v2.34Z" />
              <path class="cls-12" d="m59.06,161.2l-1.17-1.17h-7.02v11.69h7.02l1.17-1.17v-9.36Zm-2.34,8.19h-3.51v-7.02h3.51v7.02Z" />
              <polygon class="cls-12" points="49.12 162.37 49.12 160.03 42.1 160.03 42.1 171.73 49.12 171.73 49.12 169.39 44.44 169.39 44.44 167.05 49.12 167.05 49.12 164.71 44.44 164.71 44.44 162.37 49.12 162.37" />
            </g>
            <circle class="cls-10" cx="42.1" cy="118.55" r="24.17" />
            <circle class="cls-6" cx="42.1" cy="118.55" r="18.7" />
            <circle class="cls-5" cx="42.1" cy="118.55" r="13.84" />
            <circle class="cls-8" cx="42.1" cy="118.55" r="8.73" />
            <circle class="cls-10" cx="116.95" cy="118.55" r="24.17" />
            <circle class="cls-6" cx="116.95" cy="118.55" r="18.7" />
            <circle class="cls-5" cx="116.95" cy="118.55" r="13.84" />
            <circle class="cls-8" cx="116.95" cy="118.55" r="8.73" />
          </g>
        </svg>
      </div>
    );
  }
}
