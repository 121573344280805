import axios from 'axios';

class Drone {
  constructor(profile) {
    this.apiURL = 'http://localhost:3001';
    this.missionRunning = false;
    this.commandQueue = [];
    this.retryCommandCount = 3;
    this.retryCount = 0;
    this.messageQueue = [];
    this.profile = profile;
  }

  getStatus() {
    const self = this;
    return new Promise((resolve, reject) => {
      axios.get(`${this.apiURL}/drone/poll`).then((result) => {
        // console.log('status: ', result.data);
        const messages = {};
        messages.status = result.data.status;
        messages.logs = self.messageQueue;
        // messages.logs = messages.logs.concat(result.data.logs);
        self.messageQueue = [];
        resolve(messages);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  getWifiList() {
    return new Promise((resolve, reject) => {
      axios.get(`${this.apiURL}/scanWifi`).then((result) => {
        resolve(result);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  setWifi(ssid) {
    return new Promise((resolve, reject) => {
      axios.get(`${this.apiURL}/setWifi/${ssid}/${this.profile}`).then((result) => {
        resolve(result);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  setSSID(newSSID) {
    return new Promise((resolve, reject) => {
      axios.get(`${this.apiURL}/drone/changeSSID/${newSSID.trim()}`).then((result) => {
        resolve(result);
      }).catch((err) => {
        reject(err);
      });
    });
  }


  parseBlocklyCommands(commandString) {
    const mission = eval(commandString); /* eslint-disable-line */
    const commands = mission.split('|');
    commands.unshift('command');

    let land = false;
    for (let i = 0; i < commands.length; i += 1) {
      commands[i] = commands[i].replace(/,null/g, '').replace(/,/g, '_');
      if (commands[i] === 'land') {
        land = true;
      }
    }
    if (!land) {
      commands.push('land');
    }

    return commands;
  }

  execute(command) {
    return new Promise((resolve, reject) => {
      axios.get(`${this.apiURL}/drone/send/${command}`).then((result) => {
        if (result.data.slice(0, 2) === 'Re') {
          resolve(result.data.slice(7));
        } else if (result.data === 'ok') {
          resolve(result.data);
        } else {
          reject(result.data);
        }
      }).catch((err) => {
        reject(err);
      });
    });
  }

  executeQueue() {
    if (this.commandQueue.length <= 0) {
      this.missionRunning = false;
      this.messageQueue.push('Vuelo terminado.');
      return;
    }

    const command = this.commandQueue.shift();
    this.messageQueue.push(`Comando: ${command}`);
    this.execute(command).then((resp) => {
      console.log('sent:', resp, command);
      this.messageQueue.push(`${resp}`);
      this.retryCount = this.retryCommandCount;
      this.retryCount = this.retryCommandCount;
      this.executeQueue();
    }).catch((err) => {
      this.retryCount -= 1;
      console.log('sent error:', command, err, this.retryCount);
      this.messageQueue.push(`Error: ${command}`);
      if (this.retryCount > 0) {
        this.commandQueue.unshift(command);
        this.executeQueue();
      } else {
        // this.missionRunning = false;
        // this.execute('land');
        this.retryCount = this.retryCommandCount;
        this.executeQueue();
      }
    });
  }

  checkBatteryCommands(commandString) {
    return new Promise((resolve, reject) => {
      if (commandString.indexOf('flip') !== -1) {
        this.getStatus().then((result) => {
          if (result.status && typeof result.status.bat !== 'undefined') {
            if (Number(result.status.bat) >= 50) {
              resolve(true);
            }
          }
          resolve(false);
        }).catch((err) => {
          reject(err);
        });
      } else {
        resolve(true);
      }
    });
  }

  executeCommands(commandString) {
    this.commandQueue = this.parseBlocklyCommands(commandString);

    this.retryCount = this.retryCommandCount;
    this.missionRunning = true;
    this.executeQueue();
    this.messageQueue.push('Comenzando el vuelo ...');
    console.log('Starting mission with: ', this.commandQueue);
  }
}

export default Drone;
