import Tutorial1Step1 from '@sections/creabots/components/steps/Tutorial1Step1';
import { BLOCK_CATEGORY_TYPE, BOARD_ID_TYPE, COMMON_CLASSES, MODAL_TYPE, Project } from '@sections/creabots/types/kits';
import * as routes from '@educabot/educablocks-cosmos';
import { getAutomationConfigForBlock, getAutomationElementForBlock } from '../config';
import Tutorial5Step2 from '@sections/creabots/components/steps/automation/Tutorial5Step2';
import { differentThan0Regex, differentThan90Regex } from '@sections/creabots/features/utils/commonRegexs';

const intlPrefix = 'creabots.tutorials.automatization.projects.classifier';
const imgBaseUrl = '/images/creabots/automatization/projects/classifier';

export const classifier: Project = {
  id: 5,
  name: `${intlPrefix}.name`,
  description: `${intlPrefix}.description`,
  image: `${routes.bloquesUri}${imgBaseUrl}/project_image.png`,
  boardId: BOARD_ID_TYPE.AUTOMATION,
  tutorial: [
    // Step 1.
    {
      step: {
        stepId: 0,
        modal: MODAL_TYPE.FULL,
        component: Tutorial1Step1,
        videoUrl: 'https://storage.googleapis.com/educabot-aec-cdn-1/labs/robot-clasificador.mp4',
      },
    },
    // Step 2.
    {
      step: {
        stepId: 1,
        modal: MODAL_TYPE.FULL,
        component: Tutorial5Step2,
      },
    },
    // Step 3.
    {
      step: {
        stepId: 2,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step3`,
        image: `${imgBaseUrl}/steps/step3.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 1),
        completionCode: 'voidsetup\\(\\)\\{\\}voidloop\\(\\)\\{if\\(0==0\\)\\{\\}\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getAutomationElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 1),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 75,
          },
        },
      ],
    },
    // Step 4.
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/steps/step4.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.DIGITAL, 4),
        completionCode: 'voidsetup\\(\\)\\{\\}voidloop\\(\\)\\{obtenerColor\\(\\);if\\(colorEs\\(0\\)\\)\\{\\}\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getAutomationElementForBlock(BLOCK_CATEGORY_TYPE.DIGITAL, 4),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 70,
            yOffset: 85,
          },
        },
      ],
    },
    // Step 5.
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/steps/step5.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 1),
        completionCode: 'voidsetup\\(\\)\\{servo_A0\\.attach\\(A0\\);\\}voidloop\\(\\)\\{obtenerColor\\(\\);if\\(colorEs\\(0\\)\\)\\{servo_A0\\.write\\(90\\);\\}\\}',
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getAutomationElementForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 1),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 57,
            yOffset: 125,
          },
        },
      ],
    },
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/steps/step5.png`,
        completionCode: `voidsetup\\(\\)\\{servo_A0\\.attach\\(A0\\);\\}voidloop\\(\\)\\{obtenerColor\\(\\);if\\(colorEs\\(0\\)\\)\\{servo_A0\\.write\\(${differentThan90Regex}\\);\\}\\}`,
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 260,
            yOffset: 135,
          },
        },
      ],
    },
    // Step 6.
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/steps/step6.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 1),
        completionCode: `voidsetup\\(\\)\\{servo_A0\\.attach\\(A0\\);\\}voidloop\\(\\)\\{obtenerColor\\(\\);if\\(colorEs\\(0\\)\\)\\{servo_A0\\.write\\(${differentThan90Regex}\\);\\}if\\(0==0\\)\\{\\}\\}`,
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getAutomationElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 1),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 47,
            yOffset: 200,
          },
        },
      ],
    },
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/steps/step6.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.DIGITAL, 4),
        completionCode: `voidsetup\\(\\)\\{servo_A0\\.attach\\(A0\\);\\}voidloop\\(\\)\\{obtenerColor\\(\\);if\\(colorEs\\(0\\)\\)\\{servo_A0\\.write\\(${differentThan90Regex}\\);\\}if\\(colorEs\\(0\\)\\)\\{\\}\\}`,
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getAutomationElementForBlock(BLOCK_CATEGORY_TYPE.DIGITAL, 4),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 70,
            yOffset: 230,
          },
        },
      ],
    },
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/steps/step6.png`,
        completionCode: `voidsetup\\(\\)\\{servo_A0\\.attach\\(A0\\);\\}voidloop\\(\\)\\{obtenerColor\\(\\);if\\(colorEs\\(0\\)\\)\\{servo_A0\\.write\\(${differentThan90Regex}\\);\\}if\\(colorEs\\(${differentThan0Regex}\\)\\)\\{\\}\\}`,
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 335,
            yOffset: 220,
          },
        },
      ],
    },
    // Step 7.
    {
      step: {
        stepId: 6,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step7`,
        image: `${imgBaseUrl}/steps/step7.gif`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 1),
        completionCode: `voidsetup\\(\\)\\{servo_A0\\.attach\\(A0\\);\\}voidloop\\(\\)\\{obtenerColor\\(\\);if\\(colorEs\\(0\\)\\)\\{servo_A0\\.write\\(${differentThan90Regex}\\);\\}if\\(colorEs\\(${differentThan0Regex}\\)\\)\\{servo_A0\\.write\\(90\\);\\}\\}`,
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: getAutomationElementForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 1),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 57,
            yOffset: 265,
          },
        },
      ],
    },
    {
      step: {
        stepId: 6,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step7`,
        image: `${imgBaseUrl}/steps/step7.gif`,
        completionCode: `voidsetup\\(\\)\\{servo_A0\\.attach\\(A0\\);\\}voidloop\\(\\)\\{obtenerColor\\(\\);if\\(colorEs\\(0\\)\\)\\{servo_A0\\.write\\(${differentThan90Regex}\\);\\}if\\(colorEs\\(${differentThan0Regex}\\)\\)\\{servo_A0\\.write\\(${differentThan90Regex}\\);\\}\\}`,
        completionCodeCheck: 'regex',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 260,
            yOffset: 270,
          },
        },
      ],
    },
    // Step 8.
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/steps/step8.gif`,
        completionCodeCheck: 'built',
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.BUILD_BLOCKLY_BTN_CLASS,
            xOffset: -10,
            yOffset: -10,
          },
        },
      ],
    },
    // Step 9.
    {
      step: {
        stepId: 8,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step9`,
        image: `${imgBaseUrl}/steps/step8.gif`,
      },
    },
  ],
};
