import {
  alpha,
  Box,
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  InputLabel,
  useTheme,
} from '@mui/material';
import { FieldStateKey } from '@sections/creabots/containers/configAccount';
import React, { useEffect, useState } from 'react';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { updateResponsableAccount } from '@services/rest/creabots';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import {
  TextBodyRegular,
  TextBodySmallEmphasized,
  TextBodySmallRegular,
  TextCaptionRegular,
} from '../ui/typographies';
import { LabsInput } from '../ui/input';
import { useIntl } from 'react-intl';

const initialValues = {
  minCharacters: false,
  checkLowercase: false,
  checkUppercase: false,
  checkNumber: false,
};
interface FormControlProps {
  keyFieldState: FieldStateKey;
  isOpen: boolean;
  parentId: number;
  handleOpenFieldState: (field: FieldStateKey) => void;
}

export function ChangePasswordFormControl({
  isOpen,
  parentId,
  keyFieldState,
  handleOpenFieldState,
}: FormControlProps) {
  const muiTheme = useTheme();
  const intl = useIntl();
  const [checkboxes, setCheckboxes] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState({
    currentPassword: '',
    newPassword: '',
  });
  const [errors, setErrors] = useState({
    currentPassword: '',
    newPassword: '',
  });
  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  const handleClose = () => {
    handleOpenFieldState(keyFieldState);
    if (!isOpen) {
      setShowPassword({
        currentPassword: false,
        newPassword: false,
      });
      setFields({
        currentPassword: '',
        newPassword: '',
      });
      setErrors({
        currentPassword: '',
        newPassword: '',
      });
    }
  };

  const handleOnSubmit = async () => {
    if (!fields.currentPassword && !fields.newPassword) {
      setErrors(prev => ({
        newPassword: intl.formatMessage({
          id: 'creabots.validations.enterNewPassword',
        }),
        currentPassword: intl.formatMessage({
          id: 'creabots.validations.enterCurrentPassword',
        }),
      }));
      return;
    }
    if (!fields.currentPassword) {
      setErrors(prev => ({
        ...prev,
        currentPassword: intl.formatMessage({
          id: 'creabots.validations.enterCurrentPassword',
        }),
      }));
      return;
    }
    if (!fields.newPassword) {
      setErrors(prev => ({
        ...prev,
        newPassword: intl.formatMessage({
          id: 'creabots.validations.enterNewPassword',
        }),
      }));
      return;
    }
    if (!Object.values(checkboxes).every(Boolean)) {
      setErrors(prev => ({
        ...prev,
        newPassword: intl.formatMessage({
          id: 'creabots.validations.enterValidPassword',
        }),
      }));
      return;
    }
    try {
      setIsLoading(true);
      await updateResponsableAccount(parentId, {
        password: fields.currentPassword,
        newPassword: fields.newPassword,
      });
      handleClose();
    } catch (error) {
      setErrors(prev => ({
        ...prev,
        currentPassword:
          error.response?.data.message ||
          intl.formatMessage({
            id: 'creabots.account.errors.updatingPassword',
          }),
      }));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const password = fields.newPassword;
    const conditions = {
      minCharacters: password.length >= 6,
      checkLowercase: /[a-z]/.test(password),
      checkUppercase: /[A-Z]/.test(password),
      checkNumber: /[0-9]/.test(password),
    };
    setCheckboxes(conditions);
    if (Object.values(checkboxes).every(Boolean)) {
      setErrors(prev => ({ ...prev, newPassword: '' }));
    }
  }, [fields.newPassword]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2.2}
      paddingX={1.5}
      paddingY={1}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <TextBodyRegular>
          {intl.formatMessage({ id: 'creabots.labels.password' })}
        </TextBodyRegular>
        <Button
          variant="text"
          disableRipple
          sx={theme => ({
            color: theme.palette.primary.main,
            textTransform: 'capitalize',
            width: 'fit-content',
            fontWeight: theme.typography.fontWeightMedium,
          })}
          onClick={handleClose}
        >
          {isOpen
            ? intl.formatMessage({ id: 'common.cancel' })
            : intl.formatMessage({ id: 'common.modify' })}
        </Button>
      </Box>
      {isOpen && (
        <Box
          component="form"
          sx={theme => ({
            height: 'min-content',
            gap: theme.spacing(2.5),
            margin: 0,
            padding: 0,
          })}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2.5,
            }}
          >
            <Box width="100%">
              <InputLabel
                shrink={false}
                htmlFor="currentPassword"
                className="bmd-label-static"
                sx={{ marginBottom: '1rem' }}
                style={{
                  fontSize: '1rem',
                  lineHeight: '1.5rem',
                  fontWeight: muiTheme.typography.fontWeightMedium,
                  fontFamily: muiTheme.typography.fontFamily,
                  color: muiTheme.palette.text.primary,
                }}
              >
                {intl.formatMessage({ id: 'creabots.labels.currentPassword' })}
              </InputLabel>
              <LabsInput
                size="medium"
                autoComplete="off"
                variant="outlined"
                type={showPassword.currentPassword ? 'text' : 'password'}
                name="currentPassword"
                id="currentPassword"
                error={!!errors.currentPassword}
                value={fields.currentPassword}
                onFocus={() =>
                  setErrors(prev => ({ ...prev, currentPassword: '' }))
                }
                onChange={handleChange}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="cambiador de visibiliad de contraseña"
                        onClick={() =>
                          setShowPassword(prev => ({
                            ...prev,
                            currentPassword: !prev.currentPassword,
                          }))
                        }
                        sx={{ padding: 0, margin: 0 }}
                      >
                        {showPassword.currentPassword ? (
                          <VisibilityOutlined
                            sx={theme => ({
                              color: errors.currentPassword
                                ? theme.palette.error.main
                                : 'inherit',
                            })}
                          />
                        ) : (
                          <VisibilityOffOutlined
                            sx={theme => ({
                              color: errors.currentPassword
                                ? theme.palette.error.main
                                : 'inherit',
                            })}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextBodySmallRegular paddingLeft={1.5} itemType="error">
                {errors.currentPassword}
              </TextBodySmallRegular>
            </Box>
            <Box width="100%">
              <InputLabel
                shrink={false}
                htmlFor="newPassword"
                className="bmd-label-static"
                sx={{ marginBottom: '1rem' }}
                style={{
                  fontSize: '1rem',
                  lineHeight: '1.5rem',
                  fontWeight: muiTheme.typography.fontWeightMedium,
                  fontFamily: muiTheme.typography.fontFamily,
                  color: muiTheme.palette.text.primary,
                }}
              >
                {intl.formatMessage({ id: 'creabots.labels.newPassword' })}
              </InputLabel>
              <LabsInput
                size="medium"
                autoComplete="off"
                variant="outlined"
                type={showPassword.newPassword ? 'text' : 'password'}
                name="newPassword"
                id="newPassword"
                error={!!errors.newPassword}
                value={fields.newPassword}
                onFocus={() =>
                  setErrors(prev => ({ ...prev, newPassword: '' }))
                }
                onChange={handleChange}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="cambiador de visibiliad de contraseña"
                        onClick={() =>
                          setShowPassword(prev => ({
                            ...prev,
                            newPassword: !prev.newPassword,
                          }))
                        }
                        sx={{ padding: 0, margin: 0 }}
                      >
                        {showPassword.newPassword ? (
                          <VisibilityOutlined
                            sx={theme => ({
                              color: errors.newPassword
                                ? theme.palette.error.main
                                : 'inherit',
                            })}
                          />
                        ) : (
                          <VisibilityOffOutlined
                            sx={theme => ({
                              color: errors.newPassword
                                ? theme.palette.error.main
                                : 'inherit',
                            })}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextBodySmallRegular paddingLeft={1.5} itemType="error">
                {errors.newPassword}
              </TextBodySmallRegular>
            </Box>
            <Box
              sx={theme => ({
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: alpha(theme.palette.secondary.main, 0.08),
                padding: theme.spacing(1.5),
                borderRadius: 2,
              })}
            >
              <TextCaptionRegular marginBottom="1em">
                {intl.formatMessage({
                  id: 'creabots.validations.passwordMustHave',
                })}
              </TextCaptionRegular>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <TextCaptionRegular
                  marginBottom="1em"
                  sx={theme => ({
                    color: checkboxes.minCharacters
                      ? theme.palette.success.main
                      : theme.palette.text.secondary,
                  })}
                >
                  {intl.formatMessage({
                    id: 'creabots.validations.sixCharactersAtLeast',
                  })}
                </TextCaptionRegular>
                <Checkbox
                  name="minCharacters"
                  id="minCharacters"
                  checked={checkboxes.minCharacters}
                  readOnly
                  color="primary"
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled color="success" />}
                  sx={theme => ({
                    color: theme.palette.text.secondary,
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    cursor: 'default',
                  })}
                />
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <TextCaptionRegular
                  marginBottom="1em"
                  sx={theme => ({
                    color: checkboxes.checkLowercase
                      ? theme.palette.success.main
                      : theme.palette.text.secondary,
                  })}
                >
                  {intl.formatMessage({
                    id: 'creabots.validations.oneLowerCaseLetterAtLeast',
                  })}
                </TextCaptionRegular>
                <Checkbox
                  name="checkLowercase"
                  id="checkLowercase"
                  checked={checkboxes.checkLowercase}
                  readOnly
                  color="primary"
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled color="success" />}
                  sx={theme => ({
                    color: theme.palette.text.secondary,
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    cursor: 'default',
                  })}
                />
              </Box>

              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <TextCaptionRegular
                  marginBottom="1em"
                  sx={theme => ({
                    color: checkboxes.checkUppercase
                      ? theme.palette.success.main
                      : theme.palette.text.secondary,
                  })}
                >
                  {intl.formatMessage({
                    id: 'creabots.validations.oneUpperCaseLetterAtLeast',
                  })}
                </TextCaptionRegular>
                <Checkbox
                  name="checkUppercase"
                  id="checkUppercase"
                  checked={checkboxes.checkUppercase}
                  readOnly
                  color="primary"
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled color="success" />}
                  sx={theme => ({
                    color: theme.palette.text.secondary,
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    cursor: 'default',
                  })}
                />
              </Box>

              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <TextCaptionRegular
                  marginBottom="1em"
                  sx={theme => ({
                    color: checkboxes.checkNumber
                      ? theme.palette.success.main
                      : theme.palette.text.secondary,
                  })}
                >
                  {intl.formatMessage({
                    id: 'creabots.validations.oneNumberAtLeast',
                  })}
                </TextCaptionRegular>
                <Checkbox
                  name="checkNumber"
                  id="checkNumber"
                  checked={checkboxes.checkNumber}
                  readOnly
                  color="primary"
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled color="success" />}
                  sx={theme => ({
                    color: theme.palette.text.secondary,
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    cursor: 'default',
                  })}
                />
              </Box>
            </Box>
          </Box>
          <Button
            onClick={handleOnSubmit}
            variant="primary"
            size="small"
            sx={{ width: 'fit-content', textTransform: 'math-auto' }}
            disabled={
              isLoading || !!errors.currentPassword || !!errors.newPassword
            }
          >
            {intl.formatMessage({
              id: 'common.saveChanges',
            })}
          </Button>
          <Box display="flex" alignItems="center" gap={1.25}>
            <TextBodySmallRegular>
              {intl.formatMessage({
                id: 'creabots.dontRememberPassword',
              })}
            </TextBodySmallRegular>
            <Link
              to="/kits/recuperar-contraseña"
              style={{ textDecoration: 'none' }}
            >
              <TextBodySmallEmphasized>
                {intl.formatMessage({
                  id: 'creabots.forgotMyPassword',
                })}
              </TextBodySmallEmphasized>
            </Link>
          </Box>
        </Box>
      )}
    </Box>
  );
}
