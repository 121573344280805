/* eslint-disable max-len */
import * as React from 'react';
import * as routes from '@educabot/educablocks-cosmos';
import { useMediaQuery } from '@mui/material';
import useCreabotsTheme from '@sections/creabots/hooks/useCreabotsTheme';

export const LabsLogo = (props) => {
  const { height = 40, width = 200 } = props;
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const { isDarkMode } = useCreabotsTheme();

  return (
    <img
      src={`${routes.bloquesUri}/images/creabots/${isDarkMode ? 'labsLogoWhite.png' : 'labsLogo.png' }`}
      alt="Labs Creabots Logo"
      style={{ height, width: isDesktop ? 200 : width, objectFit: 'contain', aspectRatio: '16/9', marginTop: isDarkMode ? '6px' : 0 }}
    />
  );
};
export default LabsLogo;
