import axios from 'axios';
import * as routes from '@educabot/educablocks-cosmos';
import types from './types';
import { checkAuthAction } from './authAction';

axios.defaults.withCredentials = true;


function actionRemoteSessionFetchingGet() {
  return { type: types.REMOTE_SESSION_GET_FETCHING, payload: { statusRemoteSessionGet: 'fetching' } };
}

function actionRemoteSessionFetchGet(dataRemoteSessionGet) {
  return { type: types.REMOTE_SESSION_GET_FETCH, payload: { statusRemoteSessionGet: 'fetch', dataRemoteSessionGet } };
}

function actionRemoteSessionFetchErrorGet(dataRemoteSessionGet) {
  return { type: types.REMOTE_SESSION_GET_FETCH_ERROR, payload: { statusRemoteSessionGet: 'fetch error', dataRemoteSessionGet } };
}

export function actionRemoteSessionGet(id) {
  return (dispatch) => {
    checkAuthAction(dispatch).then(() => {
      dispatch(actionRemoteSessionFetchingGet());
      return axios.get(`${routes.RemoteSessionUri}/${id}`).then((response) => {
        dispatch(actionRemoteSessionFetchGet(response.data));
      }).catch((e) => {
        dispatch(actionRemoteSessionFetchErrorGet(e));
      });
    });
  };
}


function actionRemoteSessionFetchingGetList() {
  return { type: types.REMOTE_SESSION_GET_LIST_FETCHING, payload: { statusRemoteSessionGetList: 'fetching' } };
}

function actionRemoteSessionFetchGetList(dataRemoteSessionGetList) {
  return { type: types.REMOTE_SESSION_GET_LIST_FETCH, payload: { statusRemoteSessionGetList: 'fetch', dataRemoteSessionGetList } };
}

function actionRemoteSessionFetchErrorGetList(dataRemoteSessionGetList) {
  return { type: types.REMOTE_SESSION_GET_LIST_FETCH_ERROR, payload: { statusRemoteSessionGetList: 'fetch error', dataRemoteSessionGetList } };
}

export function actionRemoteSessionGetList() {
  return (dispatch) => {
    // checkAuthAction(dispatch).then(() => {
    dispatch(actionRemoteSessionFetchingGetList());
    return axios.get(`${routes.RemoteSessionUri}`).then((response) => {
      dispatch(actionRemoteSessionFetchGetList(response.data));
    }).catch((e) => {
      dispatch(actionRemoteSessionFetchErrorGetList(e));
    });
    // });
  };
}


function actionRemoteSessionLoginFetchingGet() {
  return { type: types.REMOTE_SESSION_LOGIN_GET_FETCHING, payload: { statusRemoteSessionLoginGet: 'fetching' } };
}

function actionRemoteSessionLoginFetchGet(dataRemoteSessionLoginGet) {
  return { type: types.REMOTE_SESSION_LOGIN_GET_FETCH, payload: { statusRemoteSessionLoginGet: 'fetch', dataRemoteSessionLoginGet } };
}

function actionRemoteSessionLoginFetchErrorGet(dataRemoteSessionLoginGet) {
  return { type: types.REMOTE_SESSION_LOGIN_GET_FETCH_ERROR, payload: { statusRemoteSessionLoginGet: 'fetch error', dataRemoteSessionLoginGet } };
}

export function actionRemoteSessionLoginGet(id, password) {
  return (dispatch) => {
    // checkAuthAction(dispatch).then(() => {
    dispatch(actionRemoteSessionLoginFetchingGet());
    return axios.get(`${routes.RemoteSessionUri}/${id}/password/${encodeURIComponent(password)}`).then((response) => {
      dispatch(actionRemoteSessionLoginFetchGet(response.data));
    }).catch((e) => {
      dispatch(actionRemoteSessionLoginFetchErrorGet(e));
    });
    // });
  };
}


function actionRemoteSessionFetchingRefresh() {
  return { type: types.REMOTE_SESSION_REFRESH_FETCHING, payload: { statusRemoteSessionRefresh: 'fetching' } };
}

function actionRemoteSessionFetchRefresh(dataRemoteSessionRefresh) {
  return { type: types.REMOTE_SESSION_REFRESH_FETCH, payload: { statusRemoteSessionRefresh: 'fetch', dataRemoteSessionRefresh } };
}

function actionRemoteSessionFetchErrorRefresh(data) {
  return { type: types.REMOTE_SESSION_REFRESH_FETCH_ERROR, payload: { statusRemoteSessionRefresh: 'fetch error', dataRemoteSessionRefresh: data } };
}

function actionRemoteSessionNotLoggedErrorRefresh(data) {
  return { type: types.REMOTE_SESSION_REFRESH_FETCH_ERROR, payload: { statusRemoteSessionRefresh: 'not logged', dataRemoteSessionRefresh: data } };
}

export function actionRemoteSessionRefresh(id, remoteSessionProjectId) {
  return (dispatch) => {
    checkAuthAction(dispatch).then(() => {
      dispatch(actionRemoteSessionFetchingRefresh());
      return axios.get(`${routes.RemoteSessionUri}/${id}/${remoteSessionProjectId}`).then((response) => {
        dispatch(actionRemoteSessionFetchRefresh(response.data));
      }).catch((e) => {
        const message = (e.response) ? e.response.data.message : 'Datos incorrectos';
        if (e.response && e.response.status === 401) {
          dispatch(actionRemoteSessionNotLoggedErrorRefresh(message));
        } else {
          dispatch(actionRemoteSessionFetchErrorRefresh({ message }));
        }
      });
    });
  };
}


function actionRemoteSessionProjectsFetchingPost() {
  return { type: types.REMOTE_SESSION_PROJECTS_POST_FETCHING, payload: { statusRemoteSessionProjectPost: 'fetching' } };
}

function actionRemoteSessionProjectsFetchPost(dataRemoteSessionProjectPost) {
  return { type: types.REMOTE_SESSION_PROJECTS_POST_FETCH, payload: { statusRemoteSessionProjectPost: 'fetch', dataRemoteSessionProjectPost } };
}

function actionRemoteSessionProjectsFetchErrorPost() {
  return { type: types.REMOTE_SESSION_PROJECTS_POST_FETCH_ERROR, payload: { statusRemoteSessionProjectPost: 'fetch error' } };
}

export function actionRemoteSessionProjectsPost(projectData) {
  return (dispatch) => {
    checkAuthAction(dispatch).then(() => {
      dispatch(actionRemoteSessionProjectsFetchingPost());
      return axios.post(`${routes.RemoteSessionUri}/project`, projectData).then((response) => {
        dispatch(actionRemoteSessionProjectsFetchPost(response.data));
      }).catch((e) => {
        dispatch(actionRemoteSessionProjectsFetchErrorPost(e));
      });
    });
  };
}


function actionRemoteSessionProjectsFetchingUpdate() {
  return { type: types.REMOTE_SESSION_PROJECTS_UPDATE_FETCHING, payload: { statusRemoteSessionProjectUpdate: 'fetching' } };
}

function actionRemoteSessionProjectsFetchUpdate(dataRemoteSessionProjectUpdate) {
  return { type: types.REMOTE_SESSION_PROJECTS_UPDATE_FETCH, payload: { statusRemoteSessionProjectUpdate: 'fetch', dataRemoteSessionProjectUpdate } };
}

function actionRemoteSessionProjectsFetchErrorUpdate() {
  return { type: types.REMOTE_SESSION_PROJECTS_UPDATE_FETCH_ERROR, payload: { statusRemoteSessionProjectUpdate: 'fetch error' } };
}

export function actionRemoteSessionProjectUpdate(remoteSessionId, projectId, projectData) {
  return (dispatch) => {
    checkAuthAction(dispatch).then(() => {
      dispatch(actionRemoteSessionProjectsFetchingUpdate());
      return axios.post(`${routes.RemoteSessionUri}/${remoteSessionId}/project/${projectId}`, projectData).then((response) => {
        dispatch(actionRemoteSessionProjectsFetchUpdate(response.data));
      }).catch((e) => {
        dispatch(actionRemoteSessionProjectsFetchErrorUpdate(e));
      });
    });
  };
}


function actionPostRemoteSessionCompilationFetching() {
  return { type: types.POST_REMOTE_SESSION_COMPILATION_FETCHING, payload: { statusPostRemoteSessionCompilation: 'fetching' } };
}

function actionPostRemoteSessionCompilationFetch(dataPostRemoteSessionCompilation) {
  return { type: types.POST_REMOTE_SESSION_COMPILATION_FETCH, payload: { statusPostRemoteSessionCompilation: 'fetch', dataPostRemoteSessionCompilation } };
}

function actionPostRemoteSessionCompilationFetchError(dataPostRemoteSessionCompilation) {
  return { type: types.POST_REMOTE_SESSION_COMPILATION_FETCH_ERROR, payload: { statusPostRemoteSessionCompilation: 'fetch error', dataPostRemoteSessionCompilation } };
}

export function actionPostRemoteSessionCompilation(compilationData) {
  return (dispatch) => {
    checkAuthAction(dispatch).then(() => {
      dispatch(actionPostRemoteSessionCompilationFetching());
      return axios.post(`${routes.RemoteSessionUri}/compilations`, compilationData).then((response) => {
        dispatch(actionPostRemoteSessionCompilationFetch(response.data));
      }).catch((e) => {
        dispatch(actionPostRemoteSessionCompilationFetchError(e));
      });
    });
  };
}


function actionPutRemoteSessionCompilationFetching() {
  return { type: types.PUT_REMOTE_SESSION_COMPILATION_FETCHING, payload: { statusPutRemoteSessionCompilation: 'fetching' } };
}

function actionPutRemoteSessionCompilationFetch(dataPutRemoteSessionCompilation) {
  return { type: types.PUT_REMOTE_SESSION_COMPILATION_FETCH, payload: { statusPutRemoteSessionCompilation: 'fetch', dataPutRemoteSessionCompilation } };
}

function actionPutRemoteSessionCompilationFetchError(dataPutRemoteSessionCompilation) {
  return { type: types.PUT_REMOTE_SESSION_COMPILATION_FETCH_ERROR, payload: { statusPutRemoteSessionCompilation: 'fetch error', dataPutRemoteSessionCompilation } };
}

export function actionPutRemoteSessionCompilation(compilationId, compilationData) {
  return (dispatch) => {
    checkAuthAction(dispatch).then(() => {
      dispatch(actionPutRemoteSessionCompilationFetching());
      return axios.put(`${routes.RemoteSessionUri}/compilations/${compilationId}`, compilationData).then((response) => {
        dispatch(actionPutRemoteSessionCompilationFetch(response.data));
      }).catch((e) => {
        dispatch(actionPutRemoteSessionCompilationFetchError(e));
      });
    });
  };
}


function actionPutBulkRemoteSessionCompilationFetching() {
  return { type: types.PUT_BULK_REMOTE_SESSION_COMPILATION_FETCHING, payload: { statusPutBulkRemoteSessionCompilation: 'fetching' } };
}

function actionPutBulkRemoteSessionCompilationFetch(dataPutBulkRemoteSessionCompilation) {
  return { type: types.PUT_BULK_REMOTE_SESSION_COMPILATION_FETCH, payload: { statusPutBulkRemoteSessionCompilation: 'fetch', dataPutBulkRemoteSessionCompilation } };
}

function actionPutBulkRemoteSessionCompilationFetchError(dataPutBulkRemoteSessionCompilation) {
  return { type: types.PUT_BULK_REMOTE_SESSION_COMPILATION_FETCH_ERROR, payload: { statusPutBulkRemoteSessionCompilation: 'fetch error', dataPutBulkRemoteSessionCompilation } };
}

export function actionPutBulkRemoteSessionCompilation(compilationData) {
  return (dispatch) => {
    checkAuthAction(dispatch).then(() => {
      dispatch(actionPutBulkRemoteSessionCompilationFetching());
      return axios.put(`${routes.RemoteSessionUri}/compilations/bulk`, compilationData).then((response) => {
        dispatch(actionPutBulkRemoteSessionCompilationFetch(response.data));
      }).catch((e) => {
        dispatch(actionPutBulkRemoteSessionCompilationFetchError(e));
      });
    });
  };
}


function actionPutRemoteSessionProjectBulkFetching() {
  return { type: types.REMOTE_SESSION_PROJECTS_BULK_UPDATE_FETCHING, payload: { statusPutRemoteSessionProjectBulk: 'fetching' } };
}

function actionPutRemoteSessionProjectBulkFetch(dataPutRemoteSessionProjectBulk) {
  return { type: types.REMOTE_SESSION_PROJECTS_BULK_UPDATE_FETCH, payload: { statusPutRemoteSessionProjectBulk: 'fetch', dataPutRemoteSessionProjectBulk } };
}

function actionPutRemoteSessionProjectBulkFetchError() {
  return { type: types.REMOTE_SESSION_PROJECTS_BULK_UPDATE_FETCH_ERROR, payload: { statusPutRemoteSessionProjectBulk: 'fetch error' } };
}

export function actionPutRemoteSessionProjectBulk(remoteSessionId, projectData) {
  return (dispatch) => {
    checkAuthAction(dispatch).then(() => {
      dispatch(actionPutRemoteSessionProjectBulkFetching());
      return axios.put(`${routes.RemoteSessionUri}/${remoteSessionId}/project/bulk`, projectData).then((response) => {
        dispatch(actionPutRemoteSessionProjectBulkFetch(response.data));
      }).catch((e) => {
        dispatch(actionPutRemoteSessionProjectBulkFetchError(e));
      });
    });
  };
}


function actionPutRemoteSessionFetching() {
  return { type: types.REMOTE_SESSION_UPDATE_FETCHING, payload: { statusPutRemoteSession: 'fetching' } };
}

function actionPutRemoteSessionFetch(dataPutRemoteSession) {
  return { type: types.REMOTE_SESSION_UPDATE_FETCH, payload: { statusPutRemoteSession: 'fetch', dataPutRemoteSession } };
}

function actionPutRemoteSessionFetchError(dataPutRemoteSession) {
  return { type: types.REMOTE_SESSION_UPDATE_FETCH_ERROR, payload: { statusPutRemoteSession: 'fetch error', dataPutRemoteSession } };
}

export function actionPutRemoteSession(remoteSessionId, data) {
  return (dispatch) => {
    checkAuthAction(dispatch).then(() => {
      dispatch(actionPutRemoteSessionFetching());
      return axios.put(`${routes.RemoteSessionUri}/${remoteSessionId}`, data).then((response) => {
        dispatch(actionPutRemoteSessionFetch(response.data));
      }).catch((e) => {
        dispatch(actionPutRemoteSessionFetchError(e));
      });
    });
  };
}


function actionRemoteSessionProjectsFetchingDelete() {
  return { type: types.REMOTE_SESSION_PROJECTS_DELETE_FETCHING, payload: { statusRemoteSessionProjectDelete: 'fetching' } };
}

function actionRemoteSessionProjectsFetchDelete(dataRemoteSessionProjectDelete) {
  return { type: types.REMOTE_SESSION_PROJECTS_DELETE_FETCH, payload: { statusRemoteSessionProjectDelete: 'fetch', dataRemoteSessionProjectDelete } };
}

function actionRemoteSessionProjectsFetchErrorDelete() {
  return { type: types.REMOTE_SESSION_PROJECTS_DELETE_FETCH_ERROR, payload: { statusRemoteSessionProjectDelete: 'fetch error' } };
}

export function actionRemoteSessionProjectDelete(remoteSessionId) {
  return (dispatch) => {
    checkAuthAction(dispatch).then(() => {
      dispatch(actionRemoteSessionProjectsFetchingDelete());
      return axios.delete(`${routes.RemoteSessionUri}/${remoteSessionId}/project`).then((response) => {
        dispatch(actionRemoteSessionProjectsFetchDelete(response.data));
      }).catch((e) => {
        dispatch(actionRemoteSessionProjectsFetchErrorDelete(e));
      });
    });
  };
}


function actionRemoteSessionProjectsFetchingDeleteOne() {
  return { type: types.REMOTE_SESSION_PROJECTS_DELETE_ONE_FETCHING, payload: { statusRemoteSessionProjectDeleteOne: 'fetching' } };
}

function actionRemoteSessionProjectsFetchDeleteOne(dataRemoteSessionProjectDeleteOne) {
  return { type: types.REMOTE_SESSION_PROJECTS_DELETE_ONE_FETCH, payload: { statusRemoteSessionProjectDeleteOne: 'fetch', dataRemoteSessionProjectDeleteOne } };
}

function actionRemoteSessionProjectsFetchErrorDeleteOne() {
  return { type: types.REMOTE_SESSION_PROJECTS_DELETE_ONE_FETCH_ERROR, payload: { statusRemoteSessionProjectDeleteOne: 'fetch error' } };
}

export function actionRemoteSessionProjectDeleteOne(remoteSessionId, projectId) {
  return (dispatch) => {
    checkAuthAction(dispatch).then(() => {
      dispatch(actionRemoteSessionProjectsFetchingDeleteOne());
      return axios.delete(`${routes.RemoteSessionUri}/${remoteSessionId}/project/${projectId}`).then((response) => {
        dispatch(actionRemoteSessionProjectsFetchDeleteOne(response.data));
      }).catch((e) => {
        dispatch(actionRemoteSessionProjectsFetchErrorDeleteOne(e));
      });
    });
  };
}


function actionRemoteSessionStreamFetchingGet() {
  return { type: types.REMOTE_SESSION_STREAM_GET_FETCHING, payload: { statusRemoteSessionStreamGet: 'fetching' } };
}

function actionRemoteSessionStreamFetchGet(dataRemoteSessionStreamGet) {
  return { type: types.REMOTE_SESSION_STREAM_GET_FETCH, payload: { statusRemoteSessionStreamGet: 'fetch', dataRemoteSessionStreamGet } };
}

function actionRemoteSessionStreamFetchErrorGet() {
  return { type: types.REMOTE_SESSION_STREAM_GET_FETCH_ERROR, payload: { statusRemoteSessionStreamGet: 'fetch error' } };
}

export function actionRemoteSessionStreamGet(id) {
  return (dispatch) => {
    checkAuthAction(dispatch).then(() => {
      dispatch(actionRemoteSessionStreamFetchingGet());
      return axios.get(`${routes.RemoteSessionUri}/${id}/streams`).then((response) => {
        dispatch(actionRemoteSessionStreamFetchGet(response.data));
      }).catch((e) => {
        dispatch(actionRemoteSessionStreamFetchErrorGet(e));
      });
    });
  };
}


function actionResetRemoteSessionStatusFetch(ObjectToAssign) {
  return { type: types.REMOTE_SESSIONS_RESET_STATUS, payload: ObjectToAssign };
}

export function actionResetRemoteSessionStatus(ObjectToAssign) {
  return (dispatch) => {
    dispatch(actionResetRemoteSessionStatusFetch(ObjectToAssign));
  };
}


export default {
  actionRemoteSessionGet,
  actionResetRemoteSessionStatus,
  actionPostRemoteSessionCompilation,
  actionPutRemoteSessionCompilation,
  actionPutRemoteSession,
};
