import { BasicBlock, BLOCK_CATEGORY_TYPE, BlockConfig } from '@sections/creabots/types/kits'
import getBlocksInfoSchema from '../../../config/getBlocksInfoSchema'

const blocks: BasicBlock[] = [
  {
    id: 0,
    name: 'BHOOT_led_neopixel',
  },
  {
    id: 1,
    name: 'BHOOT_led_neopixel_clear',
  },
  {
    id: 2,
    name: 'BHOOT_led_rgb',
  },
  {
    id: 3,
    name: 'BHOOT_salidas_led_rgb',
  },
  {
    id: 4,
    name: 'BHOOT_piezo_buzzer',
  },
  {
    id: 5,
    name: 'BHOOT_piezo_buzzerno',
  },
  {
    id: 6,
    name: 'BHOOT_piezo_buzzer_variable',
  },
]

export const outputsConfig: BlockConfig = {
  blockCategory: 7,
  canvasDisplacement: { x: 660, y: 0 },
  blocks: getBlocksInfoSchema(blocks, BLOCK_CATEGORY_TYPE.OUTPUTS),
}